import React, { createRef, CSSProperties, FC, ReactNode, RefObject, useMemo } from 'react'
import { Trans } from 'react-i18next'

import classNames from 'classnames'

import InfoTooltip, { InfoTooltipProps } from '@components/InfoTooltip/InfoTooltip'
import Toggletip, { ToggletipProps } from '@components/Toggletip/Toggletip'

import './Typography.css'

export interface TypographyProps {
  text?: string | number | ReactNode
  values?: { [key in string]: any }
  tagProps?: { [key in string]: Partial<TypographyProps> }
  /**
   * Allows replacing html-like tags in the text string with a React component.
   * This enables natural inline language translation to occur.
   * @note
   * The React component *must* support using the `children` prop for the text that is displaying within it.
   * @example
   * text={t("Hello <LinkTextButton>World</LinkTextButton>")}
   * tagComponents={{ LinkTextButton: <LinkTextButton hideIconLeft /> }} */
  tagComponents?: { [key in string]: ReactNode }
  children?: ReactNode
  type?: TextType
  textAlign?: TextAlign
  inline?: boolean
  inlineBlock?: boolean
  weight?: TextWeight
  lineHeight?: LineHeight
  className?: string
  dataTest?: string
  reference?: RefObject<HTMLDivElement>
  role?: string
  shouldUnescape?: boolean
  noTrim?: boolean
  style?: CSSProperties
  tabIndex?: number
  title?: string
  infoTooltipProps?: InfoTooltipProps
  infoToggletipProps?: ToggletipProps
}

export enum LineHeight {
  EXTRA_TINY = '10',
  VERY_TINY = '12',
  TINY = '14',
  SMALL = '16',
  MEDIUM_SMALL = '18',
  MEDIUM = '20',
  MEDIUM_LARGE = '24',
  LARGE = '26',
  EXTRA_LARGE = '30',
  LARGER = '36',
  VERY_LARGE = '40',
}

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right ',
}

export enum TextWeight {
  REGULAR = 'regular',
  MEDIUM_LIGHT = 'medium-light',
  SEMI_MEDIUM = 'semi-medium',
  MEDIUM = 'medium',
  BOLD = 'bold',
  EXTRA_BOLD = 'extra-bold',
  ITALIC = 'italic',
}

export enum TextType {
  BODY_TEXT = 'body-text',
  BODY_TEXT_LIGHT = 'body-text-light',
  BODY_TEXT_LIGHTER = 'body-text-lighter',
  BODY_TEXT_SMALL = 'body-text-small',
  BODY_TEXT_SMALL_LIGHT = 'body-text-small-light',
  BODY_TEXT_SMALL_LIGHTER = 'body-text-small-lighter',
  BODY_TEXT_LARGE = 'body-text-large',
  BODY_TEXT_LARGE_LIGHT = 'body-text-large-light',
  BODY_TEXT_WHITE_SMALL = 'body-text-white-small',
  BODY_TEXT_TINY = 'body-text-tiny',
  BODY_TEXT_WHITE_TINY = 'body-text-white-tiny',
  BODY_TEXT_LIGHT_TINY = 'body-text-light-tiny',
  BODY_TEXT_WHITE = 'body-text-white',
  BODY_TEXT_WHITE_REGULAR = 'body-text-white-regular',
  BODY_TEXT_GRAY = 'body-text-gray',
  BODY_TEXT_GRAY_LARGE = 'body-text-gray-large',
  BODY_TEXT_GRAY_MEDIUM = 'body-text-gray-medium',
  BODY_TEXT_GRAY_SMALL = 'body-text-gray-small',
  BODY_TEXT_GRAY_TINY = 'body-text-gray-tiny',
  BODY_TEXT_LIGHTEST_GRAY = 'body-text-lightest-gray',
  BODY_TEXT_LIGHTEST_GRAY_SMALL = 'body-text-lightest-gray-small',
  BODY_TEXT_INPUT = 'body-text-input',
  CODE_TEXT_MEDIUM = 'code-text-medium',
  CODE_TEXT_LARGE = 'code-text-large',
  LINK = 'link',
  LINK_LARGE = 'link-large',
  LINK_LARGER = 'link-larger',
  LINK_SMALL = 'link-small',
  LINK_TINY = 'link-tiny',
  LINK_WHITE = 'link-white',
  LINK_WHITE_LARGE = 'link-white-large',
  LINK_WHITE_LARGER = 'link-white-larger',
  LINK_WHITE_SMALL = 'link-white-small',
  LINK_WHITE_TINY = 'link-white-tiny',
  LINK_SMS = 'link-sms',
  EMPHASIZED_TEXT_TEAL = 'emphasized-text-teal',
  NORMAL_TEXT_DARKER_BLUE = 'normal-text-darker-blue',
  NORMAL_TEXT_TEAL_SMALL = 'normal-text-teal-small',
  NORMAL_TEXT_TEAL = 'normal-text-teal',
  NORMAL_TEXT_TEAL_LARGE = 'normal-text-teal-large',
  NORMAL_TEXT_TEAL_LARGER = 'normal-text-teal-larger',
  NORMAL_TEXT_WHITE_LARGE = 'normal-text-white-large',
  NORMAL_TEXT_GRAY = 'normal-text-gray',
  NORMAL_TEXT_GRAY_LARGE = 'normal-text-gray-large',
  NORMAL_TEXT_DISABLED = 'normal-text-disabled',
  NORMAL_TEXT_DISABLED_LARGE = 'normal-text-disabled-large',
  ERROR = 'error',
  ERROR_SMALL = 'error-small',
  ERROR_LARGE = 'error-large',
  ERROR_LARGE_MEDIUM = 'error-large-medium',
  ERROR_NEW = 'error-new',
  EXTRA_LARGE = 'extra-large',
  VALIDATION_ERROR = 'validation-error',
  VALIDATION_ERROR_LARGE = 'validation-error-large',
  PAGE_HEADLINE = 'headline-page',
  PAGE_HEADLINE_SMALL = 'headline-page-small',
  MODAL_HEADLINE_CONFIRMATION = 'headline-modal-confirmation',
  MODAL_HEADLINE = 'headline-modal',
  MODAL_HEADLINE_LARGE = 'headline-modal-large',
  MODAL_HEADLINE_SMALL = 'headline-modal-small',
  MODAL_HEADER = 'header-modal',
  MODAL_HEADER_SMALL = 'header-modal-small',
  MODAL_HEADER_SUB_INFO = 'header-modal-sub-info',
  PAGE_HEADER = 'header-page',
  PAGE_HEADER_SEMI_BOLD = 'header-page-semi-bold',
  PAGE_HEADER_SEMI_BOLD_LARGE = 'header-page-semi-bold-large',
  SUB_HEADER = 'header-sub',
  SECTION_HEADER = 'header-section',
  SECTION_HEADER_LIGHT = 'header-section-light',
  SECTION_HEADER_DISABLED = 'header-section-disabled',
  TABLE_HEADER = 'header-table',
  FIELD_MAPPING_HEADER = 'header-field-mapping',
  FIELD_MAPPING_HEADER_SMALL = 'header-field-mapping-small',
  FIELD_MAPPING_SUB_HEADER = 'header-field-mapping-sub',
  BANNER_HEADER = 'header-banner',
  BANNER_HEADER_DARK = 'header-banner-dark',
  DATA_CARD_INFO = 'data-card-info',
  DATA_CARD_MODAL_HEADER = 'data-card-modal-header',
  DATA_CARD_TEXT = 'data-card-text',
  DATA_CARD_TEXT_LIGHT = 'data-card-text-light',
}

const rootClass = 'typography'

const allowBold = [
  TextType.BANNER_HEADER,
  TextType.BANNER_HEADER_DARK,
  TextType.BODY_TEXT,
  TextType.BODY_TEXT_SMALL,
  TextType.BODY_TEXT_SMALL_LIGHT,
  TextType.BODY_TEXT_WHITE,
  TextType.BODY_TEXT_WHITE_SMALL,
  TextType.BODY_TEXT_WHITE_TINY,
  TextType.BODY_TEXT_LIGHT,
  TextType.BODY_TEXT_LIGHT_TINY,
  TextType.BODY_TEXT_GRAY,
  TextType.BODY_TEXT_GRAY_MEDIUM,
  TextType.BODY_TEXT_GRAY_SMALL,
  TextType.BODY_TEXT_LARGE,
  TextType.DATA_CARD_INFO,
  TextType.DATA_CARD_TEXT,
  TextType.ERROR,
  TextType.VALIDATION_ERROR,
  TextType.VALIDATION_ERROR_LARGE,
  TextType.FIELD_MAPPING_HEADER,
  TextType.FIELD_MAPPING_HEADER_SMALL,
  TextType.FIELD_MAPPING_SUB_HEADER,
  TextType.LINK,
  TextType.MODAL_HEADER,
  TextType.NORMAL_TEXT_DARKER_BLUE,
  TextType.NORMAL_TEXT_GRAY,
  TextType.NORMAL_TEXT_TEAL,
  TextType.NORMAL_TEXT_TEAL_SMALL,
  TextType.NORMAL_TEXT_TEAL_LARGE,
  TextType.SECTION_HEADER,
  TextType.SECTION_HEADER_LIGHT,
  TextType.SECTION_HEADER_DISABLED,
  TextType.ERROR_NEW,
  TextType.EXTRA_LARGE,
]

const mustBold = [TextType.EMPHASIZED_TEXT_TEAL, TextType.TABLE_HEADER, TextType.PAGE_HEADER, TextType.ERROR_LARGE, TextType.EXTRA_LARGE]

const allowExtraBold = [TextType.MODAL_HEADLINE_LARGE]

const mustMedium = [
  TextType.BANNER_HEADER,
  TextType.BANNER_HEADER_DARK,
  TextType.BODY_TEXT_LARGE_LIGHT,
  TextType.DATA_CARD_MODAL_HEADER,
  TextType.MODAL_HEADER_SMALL,
  TextType.MODAL_HEADLINE,
  TextType.MODAL_HEADLINE_SMALL,
  TextType.PAGE_HEADLINE,
  TextType.PAGE_HEADER_SEMI_BOLD,
  TextType.PAGE_HEADER_SEMI_BOLD_LARGE,
  TextType.SUB_HEADER,
]

const allowMedium = [
  TextType.BODY_TEXT,
  TextType.BODY_TEXT_LIGHT,
  TextType.BODY_TEXT_LIGHTER,
  TextType.BODY_TEXT_SMALL,
  TextType.BODY_TEXT_SMALL_LIGHT,
  TextType.BODY_TEXT_SMALL_LIGHTER,
  TextType.BODY_TEXT_TINY,
  TextType.BODY_TEXT_LIGHT_TINY,
  TextType.BODY_TEXT_LARGE,
  TextType.BODY_TEXT_INPUT,
  TextType.BODY_TEXT_WHITE,
  TextType.BODY_TEXT_WHITE_SMALL,
  TextType.BODY_TEXT_WHITE_TINY,
  TextType.BODY_TEXT_GRAY,
  TextType.BODY_TEXT_GRAY_SMALL,
  TextType.BODY_TEXT_GRAY_MEDIUM,
  TextType.BODY_TEXT_GRAY_LARGE,
  TextType.CODE_TEXT_LARGE,
  TextType.DATA_CARD_TEXT,
  TextType.EMPHASIZED_TEXT_TEAL,
  TextType.ERROR,
  TextType.ERROR_LARGE_MEDIUM,
  TextType.ERROR_NEW,
  TextType.MODAL_HEADLINE_CONFIRMATION,
  TextType.MODAL_HEADLINE_LARGE,
  TextType.NORMAL_TEXT_DISABLED,
  TextType.NORMAL_TEXT_DISABLED_LARGE,
  TextType.NORMAL_TEXT_GRAY,
  TextType.NORMAL_TEXT_TEAL,
  TextType.NORMAL_TEXT_TEAL_SMALL,
  TextType.NORMAL_TEXT_TEAL_LARGE,
  TextType.NORMAL_TEXT_TEAL_LARGER,
  TextType.NORMAL_TEXT_WHITE_LARGE,
  TextType.LINK,
  TextType.LINK_LARGE,
  TextType.LINK_LARGER,
  TextType.LINK_SMALL,
  TextType.LINK_TINY,
  TextType.LINK_WHITE,
  TextType.LINK_WHITE_LARGE,
  TextType.LINK_WHITE_LARGER,
  TextType.LINK_WHITE_SMALL,
  TextType.LINK_WHITE_TINY,
  TextType.SECTION_HEADER,
  TextType.SECTION_HEADER_LIGHT,
  TextType.SECTION_HEADER_DISABLED,
  TextType.EXTRA_LARGE,
]

const allowMediumLight = [TextType.BODY_TEXT_SMALL]

const mustCenter = [TextType.MODAL_HEADLINE, TextType.PAGE_HEADLINE]
const allowCenter = [
  TextType.BODY_TEXT,
  TextType.BODY_TEXT_GRAY,
  TextType.BODY_TEXT_LARGE,
  TextType.BODY_TEXT_LARGE_LIGHT,
  TextType.BODY_TEXT_LIGHT,
  TextType.BODY_TEXT_LIGHTER,
  TextType.BODY_TEXT_SMALL,
  TextType.BODY_TEXT_SMALL_LIGHT,
  TextType.BODY_TEXT_SMALL_LIGHTER,
  TextType.BODY_TEXT_WHITE_SMALL,
  TextType.BODY_TEXT_WHITE_TINY,
  TextType.BODY_TEXT_LIGHT_TINY,
  TextType.MODAL_HEADLINE_LARGE,
  TextType.PAGE_HEADER,
  TextType.SECTION_HEADER,
]
const allowRight = [
  TextType.BODY_TEXT,
  TextType.BODY_TEXT_GRAY,
  TextType.BODY_TEXT_LIGHT,
  TextType.BODY_TEXT_LIGHTER,
  TextType.BODY_TEXT_LIGHT_TINY,
  TextType.BODY_TEXT_SMALL,
  TextType.BODY_TEXT_SMALL_LIGHTER,
  TextType.TABLE_HEADER,
]

export const ModalBodyStyle: Partial<TypographyProps> = {
  type: TextType.BODY_TEXT_LIGHT,
  textAlign: TextAlign.LEFT,
  weight: TextWeight.MEDIUM_LIGHT,
}

export const ModalHeaderStyle: Partial<TypographyProps> = {
  type: TextType.MODAL_HEADLINE,
  textAlign: TextAlign.CENTER,
  weight: TextWeight.MEDIUM,
  lineHeight: LineHeight.LARGER,
}

export const ModalHeaderFormStyle: Partial<TypographyProps> = {
  type: TextType.MODAL_HEADER_SMALL,
  textAlign: TextAlign.LEFT,
  weight: TextWeight.MEDIUM,
  lineHeight: LineHeight.LARGE,
}

export const ModalHeaderConfirmStyle: Partial<TypographyProps> = {
  type: TextType.MODAL_HEADLINE_LARGE,
  weight: TextWeight.MEDIUM,
  textAlign: TextAlign.CENTER,
  lineHeight: LineHeight.LARGER,
}

export const ModalHeaderListStyle: Partial<TypographyProps> = {
  type: TextType.MODAL_HEADER_SMALL,
  textAlign: TextAlign.LEFT,
  weight: TextWeight.MEDIUM,
  lineHeight: LineHeight.LARGE,
}

const clearTransProps = { title: undefined, tagProps: undefined, tagComponents: undefined, values: undefined, text: undefined, dataTest: undefined }

const Typography: FC<TypographyProps> = (props: TypographyProps) => {
  const {
    text,
    type = TextType.BODY_TEXT,
    textAlign = TextAlign.LEFT,
    inline = false,
    inlineBlock = false,
    weight = TextWeight.REGULAR,
    lineHeight,
    dataTest = rootClass,
    className = '',
    reference = createRef<HTMLDivElement>(),
    role = '',
    shouldUnescape = false,
    noTrim,
    style = {},
    tabIndex,
    title,
    children,
    tagProps,
    tagComponents,
    values,
    infoTooltipProps,
    infoToggletipProps,
  } = props

  const typeClass = type ? `${rootClass}__type-${type}` : ''
  const bold = mustBold.indexOf(type) > -1 || (allowBold.indexOf(type) > -1 && weight === TextWeight.BOLD)
  const extraBold = allowExtraBold.indexOf(type) > -1 && weight === TextWeight.EXTRA_BOLD
  const italic = weight === TextWeight.ITALIC
  const medium = (weight === 'medium' && allowMedium.indexOf(type) > -1) || mustMedium.indexOf(type) > -1
  const mediumLight = weight === 'medium-light' && allowMediumLight.indexOf(type) > -1
  const semiMedium = weight === 'semi-medium' && allowMedium.indexOf(type) > -1
  const center = mustCenter.indexOf(type) > -1 || (textAlign === TextAlign.CENTER && allowCenter.indexOf(type) > -1)
  const right = textAlign === TextAlign.RIGHT && allowRight.indexOf(type) > -1
  const inlineStyles = lineHeight ? { ...style, lineHeight: `${lineHeight}px` } : { ...style }
  let ariaLevel

  if (type === TextType.PAGE_HEADER || type === TextType.PAGE_HEADLINE || type === TextType.PAGE_HEADER_SEMI_BOLD) {
    ariaLevel = 1
  } else if (
    type === TextType.MODAL_HEADLINE ||
    type === TextType.MODAL_HEADLINE_SMALL ||
    type === TextType.SUB_HEADER ||
    type === TextType.MODAL_HEADER ||
    type === TextType.MODAL_HEADER_SUB_INFO
  ) {
    ariaLevel = 2
  }

  const titleAttr = title ? { title } : {}

  const transComponents = useMemo(() => {
    const typogaphies = tagProps
      ? Object.keys(tagProps).reduce(
          (prev, key, idx) => ({
            ...prev,
            [key]: (
              <Typography key={idx} {...props} {...tagProps[key]} {...clearTransProps} infoTooltipProps={undefined} infoToggletipProps={undefined} />
            ),
          }),
          {}
        )
      : {}
    return { ...typogaphies, ...tagComponents }
  }, [tagProps, tagComponents, props])

  const renderWithTrans = (tagProps || tagComponents || values) && typeof text === 'string'

  return (
    <div
      className={classNames(rootClass, typeClass, className, {
        [`${rootClass}__bold`]: bold,
        [`${rootClass}__extra-bold`]: extraBold,
        [`${rootClass}__italic`]: italic,
        [`${rootClass}__medium`]: medium,
        [`${rootClass}__medium-light`]: mediumLight,
        [`${rootClass}__semi-medium`]: semiMedium,
        [`${rootClass}__inline`]: inline,
        [`${rootClass}__inline-block`]: inlineBlock,
        [`${rootClass}__center`]: center,
        [`${rootClass}__right`]: right,
        [`${rootClass}__no-trim`]: noTrim,
      })}
      data-test={dataTest}
      ref={reference}
      role={ariaLevel ? 'heading' : role}
      aria-level={ariaLevel}
      style={inlineStyles}
      tabIndex={tabIndex}
      {...titleAttr}
    >
      {renderWithTrans && <Trans i18nKey={text} values={values} components={transComponents} shouldUnescape={shouldUnescape} />}
      {!renderWithTrans && (children ?? text)}
      {infoTooltipProps && <InfoTooltip {...infoTooltipProps} />}
      {infoToggletipProps && <Toggletip className={`${rootClass}__toggletip`} {...infoToggletipProps} />}
    </div>
  )
}

export default Typography
