import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import AssetPickerSidebar from '@complex/AssetPickerModal/Components/Sidebar/AssetPickerSidebar'
import { renderCustomFilterWithCount } from '@complex/AssetPickerModal/Components/Sidebar/MenuItems/AssetPickerSidebarMenuItems'
import Checkbox from '@components/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import { CollapsibleMenuItemWithHeaderData } from '@components/CollapsibleMenu/CollapsibleMenu'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import FilterContainer from '@components/FilterContainer/FilterContainer'
import Loader from '@components/Loader'
import Menu from '@components/Menu/Menu'
import SortableFolders from '@components/SortableFolders/SortableFolders'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@utils/const/globals'
import { allMediaFilesFilter, allMediaFilter, favoriteFilter, mediaFileFilter, mediaLinkFilter } from '@utils/filter'
import { toTrainCase } from '@utils/strings'

import { getMediaFilters, MediaPickerModalContext } from './MediaPickerModal.context'

const rootClass = 'media-picker-modal'

export interface MediaPickerModalSidebarContainer {
  isCssOrJs: boolean
}

export const MediaPickerModalSidebarContainer: FC<MediaPickerModalSidebarContainer> = ({ isCssOrJs = false }) => {
  const { update, loading, ...state } = useContext(MediaPickerModalContext)
  const { folders, activeFolder, filterCounts } = state

  const { t } = useTranslation()

  const getToggledSubTypes = (filterName: string) => {
    if (state.activeSubTypes.includes(filterName)) {
      return state.activeSubTypes.filter((filter) => filter !== filterName)
    }
    return [...state.activeSubTypes, filterName]
  }

  const onFilterClick = (filterName: string | number) => {
    if (+filterName === filterName) {
      update({ activeFolder: filterName, activeFilter: undefined })
    } else if (filterName === allMediaFilter.name) {
      update({ activeFilter: allMediaFilter.name, activeFolder: undefined, activeSubTypes: [] })
    } else if (filterName === allMediaFilesFilter.name) {
      update({ activeFilter: allMediaFilesFilter.name, activeFolder: undefined, activeSubTypes: [] })
    } else if (filterName === favoriteFilter.name) {
      update({ activeFilter: favoriteFilter.name, activeFolder: undefined, activeSubTypes: [] })
    } else if (filterName === mediaFileFilter.name || filterName === mediaLinkFilter.name) {
      update({ activeSubTypes: getToggledSubTypes(filterName) })
    }
  }

  const getDefaultFilters = (): CollapsibleMenuItemWithHeaderData => {
    return {
      header: '',
      content: (
        <FilterContainer
          filters={[isCssOrJs ? allMediaFilter : allMediaFilesFilter, favoriteFilter]}
          filterCounts={filterCounts}
          activeFilter={getMediaFilters(isCssOrJs).find((filter) => filter.name === state.activeFilter)}
          filterAction={(filter) => onFilterClick(filter.name)}
          hasRecent={false}
          hasRecommended={false}
          hasCreatedByMe={false}
          hasFavorites={true}
          hideCount={false}
        />
      ),
      icon: SvgNames.segmentFilters,
      dataTest: 'menu-items-filters',
    }
  }

  const getSubFilters = (): CollapsibleMenuItemWithHeaderData => {
    return {
      header: t('Media type'),
      icon: SvgNames.toggles,
      isOpen: true,
      content: (
        <CheckboxGroup className={`${rootClass}__custom-filters`} verticalLayout>
          {renderCustomFilterWithCount(
            <Checkbox
              key={mediaFileFilter.name}
              label={t(mediaFileFilter.name)}
              checked={state.activeSubTypes.includes(mediaFileFilter.name)}
              onChange={() => onFilterClick(mediaFileFilter.name)}
              dataTest={`${rootClass}-${toTrainCase(mediaFileFilter.name)}-filter`}
            />,
            filterCounts[mediaFileFilter.name],
            mediaFileFilter.name,
            false,
            `${rootClass}__sidebar`
          )}
          {renderCustomFilterWithCount(
            <Checkbox
              key={mediaLinkFilter.name}
              label={t(mediaLinkFilter.name)}
              checked={state.activeSubTypes.includes(mediaLinkFilter.name)}
              onChange={() => onFilterClick(mediaLinkFilter.name)}
              dataTest={`${rootClass}-${toTrainCase(mediaLinkFilter.name)}-filter`}
            />,
            filterCounts[mediaLinkFilter.name],
            mediaLinkFilter.name,
            false,
            `${rootClass}__sidebar`
          )}
        </CheckboxGroup>
      ),
    }
  }

  const getFolders = (): CollapsibleMenuItemWithHeaderData => {
    return {
      content: loading ? (
        <Loader className={`${rootClass}__sidebar-loader`} />
      ) : folders.length > 0 ? (
        <SortableFolders
          className={classNames(`${rootClass}__folders`)}
          activeFolder={activeFolder}
          folders={folders}
          onClick={(folder) => onFilterClick(folder)}
        />
      ) : (
        <EmptyListing
          imgSrc={StaticImageNames.aocNoFolders}
          text={t(`AssetPicker.Sidebar.EmptyListing.Folders`)}
          size={EmptyListingSize.SMALL}
          textType={TextType.BODY_TEXT_SMALL_LIGHT}
          withoutBorder
        />
      ),
      isOpen: true,
      header: folders.length > 0 ? `${t('Folders')} (${folders.length})` : t('Folders'),
      icon: SvgNames.folderClosed,
      dataTest: 'menu-items-folders',
    }
  }

  const items = [getDefaultFilters(), ...(!isCssOrJs ? [getSubFilters()] : []), getFolders()]

  return (
    <AssetPickerSidebar>
      <Menu items={items} className={`${rootClass}__sidebar__menu`} />
    </AssetPickerSidebar>
  )
}
