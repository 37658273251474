import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import Container from '@components/Container/index'
import SectionHeadline from '@components/SectionHeadline/SectionHeadline'
import Svg, { SvgColor, SvgType } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextLink from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { legacyActonContext, rootContext, useTranslation } from '@const/globals'
import { FormManagerContext } from '@src/pages/Content/Forms/FormManager/context/FormManager.context'
import useCRM from '@utils/hooks/useCRM'

import './SettingsAndReports.css'

interface SettingsAndReportsProps {
  className?: string
  dataTest?: string
}

interface Section {
  header: string
  icon: SvgNames
  url?: string
  subText: string | JSX.Element
  urlText: string
  svgFill?: SvgColor
  viewErrors?: VoidFunction
  isDisplayed: boolean
  isSameWindow?: boolean
  count?: number
}

const rootClass = 'settings-and-reports'

const SettingsAndReports: FC<SettingsAndReportsProps> = (props: SettingsAndReportsProps) => {
  const { dataTest = rootClass, className = '' } = props
  const {
    toggleModal,
    values: { formId, crmPushErrorsCount, payments, isClassic },
  } = useContext(FormManagerContext)
  const { connectorType } = useCRM()
  const { t } = useTranslation()

  const crmPushErrorsSection = {
    header: t('SettingsAndReports.Sections.CRMPE.Header', { count: crmPushErrorsCount, crmName: connectorType }),
    icon: SvgNames.error,
    urlText: 'View Errors',
    viewErrors: () => toggleModal('crmPushErrors'),
    subText: (
      <Typography
        text={t('FormManager.SettingsAndReports.TotalPushErrors', { count: crmPushErrorsCount, crmName: connectorType })}
        type={TextType.BODY_TEXT_LIGHT}
        values={{ crmPushErrorsCount }}
        tagProps={{ bold: { weight: TextWeight.BOLD } }}
        inline
      />
    ),
    svgFill: SvgColor.REMOVE_RED,
    isDisplayed: crmPushErrorsCount > 0,
    count: crmPushErrorsCount,
  }

  const getPaymentsUrl = (page: string) => {
    const backUrl = `/app/content/forms/${formId}`
    const params = encodeURIComponent(`?id=${formId}&closeURL=${backUrl}&ngFlag=1`)
    return encodeURIComponent(`${legacyActonContext}/forms/${page}.jsp${params}`)
  }

  const sections: Section[] = [
    {
      header: 'SettingsAndReports.Sections.PublicURLManager.Header',
      icon: SvgNames.url,
      urlText: 'SettingsAndReports.Sections.PublicURLManager.UrlText',
      url: `${rootContext}/classic/if/forms/listingFormURLs.jsp?id=${formId}&closebutton=0`,
      subText: 'SettingsAndReports.Sections.PublicURLManager.SubText',
      svgFill: SvgColor.TEAL,
      isDisplayed: isClassic,
    },
    {
      header: 'SettingsAndReports.Sections.EnablePayments.Header',
      icon: SvgNames.settingsThreeLines,
      urlText: 'Edit Settings',
      url: `${rootContext}/classic/if/lists/listFrame.jsp?url=${getPaymentsUrl('addFormPayment')}`,
      subText: 'SettingsAndReports.Sections.EnablePayments.SubText',
      svgFill: SvgColor.TEAL,
      isDisplayed: isClassic,
      isSameWindow: true,
    },
    {
      header: 'SettingsAndReports.Sections.PaymentReport.Header',
      icon: SvgNames.union,
      urlText: 'SettingsAndReports.Sections.PaymentReport.UrlText',
      url: `${rootContext}/classic/if/lists/listFrame.jsp?url=${getPaymentsUrl('paymentReport')}`,
      subText: 'SettingsAndReports.Sections.PaymentReport.SubText',
      isDisplayed: isClassic && !!payments,
      isSameWindow: true,
    },
    {
      header: 'SettingsAndReports.Sections.SEOReport.Header',
      icon: SvgNames.graphLineTeal,
      urlText: 'SettingsAndReports.Sections.SEOReport.UrlText',
      url: `${legacyActonContext}/_seo/seoReport.jsp?id=form:${formId}`,
      subText: 'SettingsAndReports.Sections.SEOReport.SubText',
      isDisplayed: isClassic,
    },
    crmPushErrorsSection,
  ]

  const displayedSections = sections.filter((section) => section.isDisplayed)

  const renderSections = displayedSections.map((section: Section, i) => {
    return (
      <div key={i} className={classNames(`${rootClass}__section`, className, { [`${rootClass}__single-section`]: displayedSections.length === 1 })}>
        <div className={`${rootClass}__section-subheader`}>
          <div className={`${rootClass}__section-subheader-left`}>
            <Svg className={`${rootClass}__svg`} name={section.icon} type={SvgType.LARGER_ICON} fill={section.svgFill} />
            <div>
              <Typography text={t(section.header, { count: section.count })} />
              <Typography text={typeof section.subText === 'string' ? t(section.subText) : section.subText} type={TextType.BODY_TEXT_SMALL_LIGHT} />
            </div>
          </div>
          <TextLink
            onClick={section.viewErrors}
            target={section.isSameWindow ? '_top' : undefined}
            text={t(section.urlText)}
            link={section.url}
            hideIcon
          />
        </div>
      </div>
    )
  })

  const renderPushErrors = (
    <>
      <SectionHeadline className={classNames(`${rootClass}__section-subheader`, `${rootClass}__section-header`)}>
        <div className={classNames(`${rootClass}__section-subheader-left`, `${rootClass}__section-header-left`)}>
          <Svg className={`${rootClass}__svg`} name={crmPushErrorsSection.icon} type={SvgType.LARGER_ICON} fill={crmPushErrorsSection.svgFill} />
          {t(crmPushErrorsSection.header, { count: crmPushErrorsSection.count })}
        </div>
        <TextLink onClick={crmPushErrorsSection.viewErrors} text={t(crmPushErrorsSection.urlText)} hideIcon />
      </SectionHeadline>
      {crmPushErrorsSection.subText}
    </>
  )

  return (
    <Container className={classNames(rootClass, className)} data-test={dataTest}>
      {isClassic && <SectionHeadline>{t('Additional Settings & Reports')}</SectionHeadline>}
      {isClassic && renderSections}
      {!isClassic && renderPushErrors}
    </Container>
  )
}

export default SettingsAndReports
