import { gql } from '@apollo/client'

export default gql`
  query retrieveAttachments($attachmentIds: [Long]) {
    retrieveAttachments(attachmentIds: $attachmentIds) {
      createdAt
      extension
      id
      name
      type
      updatedAt
    }
  }
`
