import { gql } from '@apollo/client'

export default gql`
  mutation saveWebhookDefinition($definition: InputDefinition!) {
    saveWebhookDefinition(definition: $definition) {
      status
      body
    }
  }
`
