import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react'

import classNames from 'classnames'

import NumberInput from '@components/NumberInput/NumberInput'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { useTranslation } from '@const/globals'
import { QuantityOptions } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/BehaviorExpression/BehaviorExpression.constants'
import { QuantityCriteria } from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/BehaviorExpression/BehaviorExpression.interfaces'

interface QuantityProps {
  children?: ReactNode
  className?: string
  criteria?: QuantityCriteria
  dataTest?: string
  isBasic?: boolean
  isRequired?: boolean
  onChange: (criteria: QuantityCriteria) => void
}

const rootClass = 'quantity'

const NUMERIC_OPTIONS: SelectV2SingleOption[] = [
  { value: QuantityOptions.AT_LEAST, label: 'SegmentComposer.Build.Quantity.AtLeast' },
  { value: QuantityOptions.AT_MOST, label: 'SegmentComposer.Build.Quantity.AtMost' },
  { value: QuantityOptions.EXACTLY, label: 'SegmentComposer.Build.Quantity.Exactly' },
]

const NONE_OPTION: SelectV2SingleOption = { value: QuantityOptions.NONE, label: 'SegmentComposer.Build.Quantity.None' }

const BASIC_SPECIFIC_ASSETS_OPTIONS: SelectV2SingleOption[] = [
  { value: QuantityOptions.AT_LEAST_ONE_THESE, label: 'SegmentComposer.Build.Quantity.AtLeastOneThese' },
  { value: QuantityOptions.NONE_THESE, label: 'SegmentComposer.Build.Quantity.NoneThese' },
]

const SPECIFIC_ASSETS_OPTIONS: SelectV2SingleOption[] = [
  BASIC_SPECIFIC_ASSETS_OPTIONS[0],
  { value: QuantityOptions.AT_LEAST_THESE, label: 'SegmentComposer.Build.Quantity.AtLeastThese' },
  { value: QuantityOptions.EXACTLY_THESE, label: 'SegmentComposer.Build.Quantity.ExactlyThese' },
  { value: QuantityOptions.ALL_THESE, label: 'SegmentComposer.Build.Quantity.AllThese' },
  BASIC_SPECIFIC_ASSETS_OPTIONS[1],
]

const Quantity: FC<QuantityProps> = (props: QuantityProps) => {
  const { children, className = '', criteria: criteriaProp, dataTest = rootClass, isBasic = false, isRequired = false, onChange } = props
  const { criteria, value } = { ...criteriaProp }

  const { t } = useTranslation()

  const selectOptions = useMemo<SelectV2SingleOption[]>(() => {
    let options: SelectV2SingleOption[] = []
    if (isBasic) {
      options = BASIC_SPECIFIC_ASSETS_OPTIONS
    } else {
      if (!isRequired) {
        options = [...NUMERIC_OPTIONS, NONE_OPTION]
      }
      options = [...options, ...SPECIFIC_ASSETS_OPTIONS]
    }

    return options.map((option) => ({
      ...option,
      label: t(option.label),
    }))
  }, [isBasic, isRequired, t])

  const hasNumericCriteria = (criteria: QuantityOptions) =>
    ![QuantityOptions.NONE, QuantityOptions.AT_LEAST_ONE_THESE, QuantityOptions.ALL_THESE, QuantityOptions.NONE_THESE].includes(criteria)

  const hasSpecificAssetsCriteria = (criteria?: QuantityOptions) => SPECIFIC_ASSETS_OPTIONS.some(({ value }) => value === criteria)

  const onSelectChange = useCallback(
    (selectedOption?: SelectV2SingleOption) => {
      if (selectedOption) {
        onChange({ ...criteriaProp, criteria: selectedOption.value as QuantityOptions })
      }
    },
    [criteriaProp, onChange]
  )

  const onNumericChange = (value?: number) => {
    onChange({ ...criteriaProp, value })
  }

  useEffect(() => {
    if (!selectOptions.some((option) => option.value === criteria)) {
      onSelectChange(selectOptions[0])
    }
  }, [criteria, isRequired, onSelectChange, selectOptions])

  const renderConditions = () => {
    const conditions = []
    if (criteria && hasNumericCriteria(criteria)) {
      conditions.push(<NumberInput dataTest={`${dataTest}-number-input`} onChange={onNumericChange} value={value} />)
    }
    if (children && hasSpecificAssetsCriteria(criteria)) {
      conditions.push(children)
    }
    return conditions
  }

  return (
    <>
      <SelectV2
        className={classNames(rootClass, className)}
        dataTest={dataTest}
        value={selectOptions.find((option) => option.value === criteria)}
        options={selectOptions}
        onChange={onSelectChange}
        isClearable={false}
      />
      {renderConditions()}
    </>
  )
}

export default Quantity
