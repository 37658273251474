import React, { FC, useState, useMemo } from 'react'

import PageContainer from '@components/PageContainer/PageContainer'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast from '@components/StatusToast/StatusToast'
import Tabs, { TabItemData } from '@components/TabsAO/TabsAO'
import { useTranslation } from '@const/globals'
import { StatusToastType } from '@interface/StatusToast'
import UsersPageHeader from '@src/pages/Settings/Users/components/UsersPageHeader/UsersPageHeader'
import MarketingUsersContainer from '@src/pages/Settings/Users/tabs/MarketingUsers/MarketingUsersContainer'
import SalesUsers from '@src/pages/Settings/Users/tabs/SalesUsers/SalesUsers'
import UsersContextProvider from '@src/pages/Settings/Users/UsersContextProvider'
import { useAccountSettings } from '@utils/account/account.utils'

import './UsersContainer.css'

const rootClass = 'users'

const UsersContainer: FC = () => {
  const [currentTab, setCurrentTab] = useState<string>('MarketingUsers')
  const [toast, setToastStatus] = useState<StatusToastType>()
  const {
    hasExternalUserAuth,
    userSettings: { isAdministrator },
  } = useAccountSettings()

  const { t } = useTranslation()
  const tabsData = useMemo<TabItemData[]>(
    () => [
      { index: 'MarketingUsers', label: t('Marketing Users'), content: <MarketingUsersContainer /> },
      { index: 'SalesUsers', label: t('Sales Users'), content: <SalesUsers /> },
    ],
    [t]
  )

  return (
    <PageContainer className={rootClass} noPadding>
      {toast?.showStatus && (
        <StatusToast
          className={`${rootClass}__toast`}
          isSuccess={toast.successStatus}
          message={toast.statusMessage}
          title={toast.title}
          closeStatus={() => {
            setToastStatus({ showStatus: false })
          }}
        />
      )}
      <UsersContextProvider isAdministrator={isAdministrator} setToastStatus={setToastStatus} hasExternalUserAuth={hasExternalUserAuth}>
        <div className={`${rootClass}__content`}>
          <PositionContainer>
            <UsersPageHeader />
            <Tabs childData={tabsData} defaultValue={currentTab} onChange={setCurrentTab} />
          </PositionContainer>
        </div>
      </UsersContextProvider>
    </PageContainer>
  )
}

export default UsersContainer
