import { gql } from '@apollo/client'

export default gql`
  query sentMessageDates {
    sentMessageDates {
      code
      name
      count
    }
  }
`
