import React, { FC, useEffect, useState } from 'react'

import { SavingMessage } from '@components/AutoSavingStatus/AutoSavingStatus'
import Container from '@components/Container'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import PeoplesChart from '@components/PeoplesChart/PeoplesChart'
import TableList from '@components/TableList/TableList'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { MarketingListResponse } from '@graphql/types/query-types'

import { SMSContextAPI } from '../../../context/SMSContext'

import './RecipientsSummary.css'

const rootClass = 'recipient-summary'

interface State {
  totalRecipients: number
  selectedRecipients: number
  withoutPhones: number
  notOptIn: number
  duplicates: number
  nonUS: number
  totalReceiving: number
  pctReceiving: string
  loading: boolean

  [key: string]: number | string | boolean
}

const intialState: State = {
  totalRecipients: 0,
  selectedRecipients: 0,
  duplicates: 0,
  withoutPhones: 0,
  notOptIn: 0,
  nonUS: 0,
  totalReceiving: 0,
  pctReceiving: '0',
  loading: false,
}

const contactRows = [
  {
    label: 'Selected recipients',
    field: 'selectedRecipients',
  },
  {
    label: 'Recipients without phone',
    field: 'withoutPhones',
  },
  {
    label: 'Recipients not on opt-in list',
    field: 'notOptIn',
  },
  {
    label: 'Duplicate phone numbers',
    field: 'duplicates',
  },
  {
    label: 'Numbers outside US/CA',
    field: 'nonUS',
  },
]

interface Props {
  context: SMSContextAPI
}

const RecipientsSummary: FC<Props> = ({ context }: Props) => {
  const {
    values: {
      smsMessage: { recipients },
      savingMessage,
      totalRecipients,
      internationalSendingEnabled,
    },
  } = context
  const [state, setState] = useState<State>(intialState)

  const { t } = useTranslation()

  useEffect(() => {
    setState({ ...state, loading: savingMessage === SavingMessage.SAVING })
  }, [savingMessage])

  useEffect(() => {
    const getNonUsCount = () => recipients?.reduce((acc, recip: MarketingListResponse) => acc + recip.suppressedInternationalContacts, 0)

    const selectedRecipients = recipients?.reduce((acc: number, recip: MarketingListResponse) => acc + recip.totalContacts, 0) ?? 0
    const duplicates = recipients?.reduce((acc: number, recip: MarketingListResponse) => acc + recip.countDuplicate, 0) ?? 0
    const withoutPhones = recipients?.reduce((acc: number, recip: MarketingListResponse) => acc + recip.countMissingPhone, 0) ?? 0
    const notOptIn = recipients?.reduce((acc: number, recip: MarketingListResponse) => acc + recip.countNotOptedIn, 0) ?? 0
    const totalReceiving = totalRecipients
    const pctReceiving = totalRecipients === 0 ? '0' : `${Math.abs((totalReceiving / selectedRecipients) * 100).toFixed(0)}`

    const nonUS = internationalSendingEnabled ? 0 : getNonUsCount()

    setState({
      ...state,
      selectedRecipients,
      totalRecipients,
      duplicates,
      withoutPhones,
      notOptIn,
      nonUS,
      totalReceiving,
      pctReceiving,
      loading: savingMessage === SavingMessage.SAVING,
    })
  }, [recipients])

  const renderReachLabel = () => {
    if (state.loading) {
      return <Loader loaderType={LoaderTypes.row} />
    }

    return (
      <>
        <Typography text={`${state.pctReceiving}%`} inline weight={TextWeight.BOLD} type={TextType.BODY_TEXT_SMALL} />{' '}
        <Typography text={`${t('Recipient reach')}`} inline type={TextType.BODY_TEXT_SMALL} />
      </>
    )
  }

  const getRecipientsCells = () => {
    const filteredRows = contactRows.filter((row) => (row.field !== 'nonUS' ? true : !internationalSendingEnabled && state.nonUS > 0))
    const rows = filteredRows.map((row) => {
      const showMinus = !state.loading && row.field !== 'selectedRecipients' && +state[row.field] > 0
      const value = state.loading ? (
        <Loader loaderType={LoaderTypes.row} />
      ) : (
        `${showMinus ? '-' : ''}${Intl.NumberFormat().format(state[row.field] as number)}`
      )
      return {
        left: <Typography text={t(row.label)} type={TextType.BODY_TEXT_SMALL} />,
        right: <Typography text={value} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />,
      }
    })

    return {
      rows,
      hasBorder: true,
      totalRow: {
        left: <Typography text={t('Total Recipients')} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} />,
        right: (
          <Typography
            text={state.loading ? <Loader loaderType={LoaderTypes.row} /> : Intl.NumberFormat().format(state.totalReceiving)}
            type={TextType.BODY_TEXT_LARGE}
            weight={TextWeight.BOLD}
          />
        ),
      },
    }
  }

  return (
    <Container data-text={rootClass} title={'Recipient Summary'} description={'Recipients who will receive this message:'}>
      <TableList {...getRecipientsCells()} className={`${rootClass}__table-contact-breakdown`} />
      <PeoplesChart pctReceiving={state.pctReceiving} label={renderReachLabel()} />
    </Container>
  )
}

export default RecipientsSummary
