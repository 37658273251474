import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo } from 'react'

import { defaultMenuActions, FilterQueryParams, isCustomFilterSelected } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import {
  CustomFilter,
  CustomSource,
  CustomSourceItems,
  FetchItems,
  FilterCounts,
  GetFilterItemsWithCustomRequests,
  ListPageCommonState,
  SearchItems,
  SearchItemsByCustomFilter,
  SetError,
  SetFilter,
  SortBy,
  Update,
} from '@complex/ListingPage/Context/ListingPageCommon.context'
import { useFilterRequests } from '@complex/ListingPage/GraphQL/Filters.graphQL'
import { useSearchRequests } from '@complex/ListingPage/GraphQL/Search.graphQL'
import { useFolderSubTypes } from '@complex/ListingPage/Hooks/useFolderSubTypes'
import { useSubTypeCondition } from '@complex/ListingPage/Hooks/useSubTypeCondition'
import {
  getCommonFetchParams,
  getCommonSearchParams,
  getDefaultEmptyListingProps,
  getSessionData,
  onSetCustomSourceItems,
  onSetError,
  onSetFilter,
  onSetFolder,
  onSetItems,
  onChangeCustomSource,
  onSetStatusToast,
  onSetBulkActionsModal,
  onSetTag,
  onSetSubType,
} from '@complex/ListingPage/Utils/ListingPage.utils'
import { BulkModalProps } from '@components/BulkActionsModal/BulkActionsModal'
import { Status } from '@components/StatusToast/StatusToast'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import { useTranslation } from '@const/globals'
import searchSegmentsByAuthor from '@graphql/microservices/categorization/searchSegmentsByAuthor'
import searchSegmentsByFavorites from '@graphql/microservices/categorization/searchSegmentsByFavorites'
import searchSegmentsByRecent from '@graphql/microservices/categorization/searchSegmentsByRecent'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { BulkAssetMutationResponse } from '@graphql/types/mutation-types'
import { ItemDto } from '@graphql/types/query-types'
import { commonFilters, createdByMeFilter, favoriteFilter, FilterDefinition, FilterTypes, recentFilter } from '@utils/filter'
import { logNewRelicError } from '@utils/new-relic.utils'

export const useListingPageBase = (
  containerValues: ListPageCommonState,
  update: Update,
  setContainerValues: Dispatch<SetStateAction<ListPageCommonState>>
) => {
  const {
    customSourceItems,
    activeFolderId,
    activeTagId,
    search,
    filterActive,
    activeSubTypes,
    selectedCustomSource,
    currentPage,
    initialPageLoading,
    searchAll = false,
    searchItemsResults,
    isProcessingAction,
    isEditingTag,
    listingPageProps,
    itemType,
    fetchItems,
    fetchNextPageItems,
    isTableSortInitialized,
    fetchFilterCounts,
    refetchCountsOnAction,
    defaultSubTypes,
    tags,
    listingPageProps: {
      alwaysPreserveSearchTerm,
      externalDataLoading,
      getCustomActiveFilter,
      getCustomDefaultEmptyListingProps,
      getCustomEmptyListingProps,
      getCustomFilterParams,
      onApiAvailable,
      searchFields,
      subTypes,
      sidebarProps,
      initialFilter,
      sidebarProps: { customFilterSelected, allItemFilter, hasFavorites, getCustomFilterCounts, getCustomDefaultFilters },
      disableSessionData,
      hasCustomRequests,
      customFilters,
      hasTabs,
      tableProps: { listPage, columns },
    },
  } = containerValues

  const folderSubTypes = useFolderSubTypes(listingPageProps)
  const subTypeCondition = useSubTypeCondition({ itemType, activeSubTypes, subTypes })
  const { searchItemsRequest, searchItemsByTagRequest, searchItemsByFilterRequest } = useSearchRequests(containerValues, folderSubTypes)
  const { sessionFilter, sessionCustomFilterSelected, sessionSearch, sessionFolderId, sessionTagId } = getSessionData(
    `${itemType}`,
    disableSessionData
  )

  const {
    getAllItemsRequest,
    getFavoriteItemsRequest,
    getItemsInFolderRequest,
    getCreatedByMeItemsRequest,
    getRecentItemsRequest,
    getItemsInTagRequest,
    getCustomFilterItemsRequest,
    getSubTypesByTypesRequest,
    getCountQueryRequest,
    getCountForFavoritesAndCreatedByMeAndRecentRequest,
  } = useFilterRequests(containerValues, folderSubTypes, subTypeCondition)

  const { t } = useTranslation()

  useEffect(() => {
    if (!onApiAvailable) {
      return
    }
    onApiAvailable({
      updateFilterCounts: (counts) => {
        setContainerValues((currentValues) => {
          return {
            ...currentValues,
            filterCounts: { ...currentValues.filterCounts, ...counts },
          }
        })
      },
    })
  }, [onApiAvailable, setContainerValues])

  const getActiveFilter = (): FilterDefinition | undefined => {
    let filter

    if (getCustomActiveFilter && (customFilterSelected || sessionCustomFilterSelected)) {
      filter = getCustomActiveFilter(sessionFilter ?? filterActive?.name)
    } else {
      filter = commonFilters.filter((commonFilters) => commonFilters?.name === sessionFilter)[0]
    }

    if (!filter) {
      if (initialFilter) {
        filter = initialFilter
      } else {
        filter = listingPageProps.sidebarProps.allItemFilter
      }
    }

    return sessionFolderId || sessionTagId ? undefined : filter
  }

  const itemsCommonParams = getCommonFetchParams(containerValues)
  const searchCommonParams = getCommonSearchParams(containerValues)

  useEffect(() => {
    if (initialPageLoading) {
      loadInitialSessionData(getActiveFilter())
    }
  }, [initialPageLoading])

  useEffect(() => {
    fetchFilterCounts && getAllFilterCounts()
  }, [fetchFilterCounts, itemType])

  useEffect(() => {
    const emptyListingProps =
      getCustomDefaultEmptyListingProps?.(containerValues, setFilter, t) ??
      getDefaultEmptyListingProps(containerValues, setFilter, t) ??
      getCustomEmptyListingProps(setFilter, update, filterActive)

    update({ emptyListingProps })
  }, [filterActive, activeTagId, activeFolderId, search, searchItemsResults, activeSubTypes])

  const loadInitialSessionData = (sessionFilter?: FilterDefinition) => {
    if (sessionFilter && sessionFilter !== filterActive) {
      update({ filterActive: sessionFilter })
    }

    if (sessionSearch) {
      onSearch(searchAll)
    } else {
      update({ fetchItems: true })
    }
  }

  const getSubTypeCounts = async () => {
    const { data, errors } = await getSubTypesByTypesRequest({ types: [`${itemType}`], subTypes: defaultSubTypes })

    const newCounts: FilterCounts = {}
    if (data?.getSubTypesByTypes?.length) {
      data.getSubTypesByTypes.forEach((subType) => {
        if (subType?.name) {
          newCounts[subType.name] = subType.count ?? 0
        }
      })
    }

    if (errors) {
      logNewRelicError(errors)
    }
    return newCounts
  }

  const getDefaultFilterCounts = async () => {
    const { data, errors } = await getCountForFavoritesAndCreatedByMeAndRecentRequest({ type: itemType, days: 14, subTypes: defaultSubTypes })

    const newCounts: FilterCounts = {}
    const fetchedCounts = data?.getCountForFavoritesAndCreatedByMeAndRecent
    if (fetchedCounts?.length) {
      newCounts[FilterTypes.CREATED_BY_ME] = fetchedCounts[0]?.createdByMeCount
      newCounts[FilterTypes.RECENT] = fetchedCounts[0]?.recentCount
      newCounts[FilterTypes.FAVORITES] = fetchedCounts[0]?.favoritesCount
    }

    if (errors) {
      logNewRelicError(errors)
    }
    return newCounts
  }

  const getAllFilterCounts = async () => {
    const filters: CustomFilter[] = [...(customFilters ?? []), { field: 'name', values: [{ id: allItemFilter.name, value: '' }] }]
    const newCounts: FilterCounts = {
      ...(subTypes ? await getSubTypeCounts() : {}),
      ...(hasFavorites ? await getDefaultFilterCounts() : {}),
      ...(getCustomFilterCounts ? await getCustomFilterCounts() : {}),
    }

    for (const filter of filters) {
      if (!filter) {
        continue
      }

      const { data, errors } = await getCountQueryRequest({
        ...{ field: filter.field, values: filter.values.map((filter) => filter.value) },
        type: itemType,
        subTypes: defaultSubTypes,
      })

      if (data?.getCountQuery?.length) {
        data.getCountQuery.forEach((filterCount) => {
          if (filterCount?.field) {
            const values = filters.find((filter) => filter.field === filterCount.field)?.values
            const id = values?.filter((value) => value.value === filterCount.value)[0]?.id

            if (id) {
              newCounts[id] = parseInt(filterCount?.count) ?? 0
            }
          }
        })
      }
      if (Object.keys(newCounts).length) {
        // Use setContainerValues to avoid state race conditions because the parent component may also be updating this
        setContainerValues((currentValues) => ({
          ...currentValues,
          filterCounts: { ...currentValues.filterCounts, ...newCounts },
          fetchFilterCounts: false,
        }))
      } else {
        update({ fetchFilterCounts: false })
      }

      if (errors) {
        logNewRelicError(errors)
      }
    }
  }

  const scrollToTop = () => {
    const assetPicker = document.querySelector('.asset-picker-table-container__table')
    if (assetPicker) {
      assetPicker.scrollTo(0, 0)
      return
    }

    const listingPageHeader = document.querySelector('.listing-page-header')
    const listingPageWithTabsHeader = document.querySelector('.listing-page-with-tabs__tab-headers__list')

    const header = (hasTabs ? listingPageWithTabsHeader : listingPageHeader) as HTMLDivElement
    const scrollElement = document.querySelector('#page-container') as HTMLDivElement

    if (!header || !scrollElement) {
      return
    }

    const offset = !hasTabs ? header.offsetHeight : header.offsetTop + header.offsetHeight
    if (scrollElement.scrollTop > offset) {
      scrollElement.scrollTo(0, offset)
    }
  }

  useEffect(() => {
    if (externalDataLoading) {
      update({ loading: true, items: [] })
    } else {
      update({ fetchItems: true })
    }
  }, [externalDataLoading])

  useEffect(() => {
    if (externalDataLoading) {
      return
    }
    if (fetchItems || fetchNextPageItems) {
      currentPage === 0 && scrollToTop()

      // If an endpoint override exists for the active filter use that instead
      const customRequestFilters = selectedCustomSource?.customRequestFilters
      const activeCustomRequestFilter = customRequestFilters && customRequestFilters.find(({ filter }) => filterActive?.name === filter?.name)

      if (activeCustomRequestFilter && search !== '' && alwaysPreserveSearchTerm) {
        onSearch(searchAll)
      } else if (activeCustomRequestFilter) {
        getFilterItemsWithCustomRequests({ customRequestFilters: customRequestFilters })
      } else if (search !== '') {
        searchItems(searchAll)
      } else if (activeFolderId) {
        getFolderItems()
      } else if (activeTagId) {
        getTagItems()
      } else {
        getFilterItems()
      }
    }
  }, [filterActive, activeFolderId, activeTagId, fetchItems, fetchNextPageItems, externalDataLoading])

  useEffect(() => {
    if (sessionSearch === '') {
      update({ fetchItems: true })
    }
  }, [sessionSearch])

  useEffect(() => {
    if (currentPage !== 0) {
      update({ fetchNextPageItems: true })
    }
  }, [currentPage])

  const onColumnSort = (sorts: SortBy[]) => {
    const sortColumnSelected = sorts?.length > 0
    if (sortColumnSelected && isTableSortInitialized) {
      if (columns.length && 'accessorKey' in columns[0]) {
        const tableV2Columns = columns as ColumnDefWithAdditionalProps<ItemDto, unknown>[]
        const tableV2Sorts = sorts.map((sort) => ({
          ...sort,
          fieldType: tableV2Columns.find((column) => (column as any).accessorKey === sort.id)?.fieldType,
        }))
        update({ items: [], loading: true, sortBy: tableV2Sorts, fetchItems: true, currentPage: 0 })
      } else {
        update({ items: [], loading: true, sortBy: sorts, fetchItems: true, currentPage: 0 })
      }
    } else {
      update({ isTableSortInitialized: true })
    }
  }

  const setFolder = (folderId: number) => onSetFolder(containerValues, update, folderId)

  const setFilter: SetFilter = (filter, customFilterSelected, clearSubTypes) =>
    onSetFilter(containerValues, update, filter, customFilterSelected, clearSubTypes)

  const toggleSubType = (subType: string, toggleOffSubTypes?: string[]) => {
    onSetSubType(containerValues, update, subType, toggleOffSubTypes)
  }

  const setTag = (tag: LabelDto, clicked?: boolean) => {
    activeTagId === tag.id && clicked ? setFilter(allItemFilter, false) : onSetTag(containerValues, update, tag, clicked)
  }

  const setSelectedCustomSource = (item: CustomSource) => onChangeCustomSource(containerValues, update, item)

  const setError: SetError = (message: string | ReactNode, error: any) => onSetError(update, message, error)

  const setItems = (newItems: ItemDto[]) => onSetItems(update, setError, containerValues, newItems)

  const setCustomSourceItems = (newItems: ItemDto[]) =>
    onSetCustomSourceItems(
      update,
      newItems,
      containerValues,
      customSourceItems as CustomSourceItems,
      selectedCustomSource as CustomSource,
      currentPage,
      search !== ''
    )

  const setFetchCounts = () => {
    if (!refetchCountsOnAction) {
      return
    }
    const fetchProps = {
      ...(activeFolderId ? { fetchFolders: true } : {}),
      ...(activeTagId ? { fetchTags: true } : {}),
      ...(subTypes || customFilters ? { fetchFilterCounts: true } : {}),
      refetchCountsOnAction: false,
    }
    update(fetchProps)
  }

  const setStatusToast = (message: string | ReactNode, status: Status, folderName?: string, hasTimeout = true) => {
    status === Status.SUCCESS && setFetchCounts()
    onSetStatusToast(update, message, status, folderName, hasTimeout)
  }

  const setBulkResponseModal = (bulkResponse: BulkAssetMutationResponse, bulkActionKey?: string, customProps?: Partial<BulkModalProps>) => {
    setFetchCounts()
    onSetBulkActionsModal(update, bulkResponse, bulkActionKey, customProps)
  }

  const getFolderItems: FetchItems = async () => {
    const data = await onGetItems(getItemsInFolderRequest, {
      ...itemsCommonParams,
      folder: activeFolderId,
    })

    setItems(data?.getItemsInFolder as ItemDto[])
  }

  const getTagItems: FetchItems = async () => {
    const data = await onGetItems(getItemsInTagRequest, {
      ...itemsCommonParams,
      activeTagId: activeTagId,
    })

    setItems(data?.getItemsInFolderAndLabels as ItemDto[])
  }

  const getFilterItems = async () => {
    if ((customFilterSelected || sessionCustomFilterSelected) && getCustomFilterParams) {
      update({ loading: true })
      const { field, query } = getCustomFilterParams(filterActive)
      const updatedField = search ? 'name' : field
      const updatedQuery = search ? search : query
      let newItems = (await getCustomFilterItemsRequest(itemsCommonParams, updatedField, updatedQuery)).data?.getItems as ItemDto[]
      const customDefaultsFilters = getCustomDefaultFilters && getCustomDefaultFilters(defaultMenuActions)
      const customFilter = customDefaultsFilters?.find((cdf) => cdf?.name === containerValues.filterActive?.name)
      if (customFilter?.extraFilter) {
        newItems = customFilter.extraFilter(newItems)
      }
      setItems(newItems)
    } else if (filterActive === favoriteFilter) {
      setItems((await onGetItems(getFavoriteItemsRequest, itemsCommonParams))?.getFavoriteItems as ItemDto[])
    } else if (filterActive === recentFilter) {
      setItems((await onGetItems(getRecentItemsRequest, itemsCommonParams)).getRecentItems as ItemDto[])
    } else if (filterActive === createdByMeFilter) {
      setItems((await onGetItems(getCreatedByMeItemsRequest, itemsCommonParams)).getItemsByAuthor as ItemDto[])
    } else {
      setItems((await onGetItems(getAllItemsRequest, itemsCommonParams)).getAllItems as ItemDto[])
    }
  }

  const getFilterItemsWithCustomRequests = async ({ customRequestFilters, sessionFilter }: GetFilterItemsWithCustomRequests) => {
    const filter = customRequestFilters.find(({ filter }) => filterActive?.name === filter?.name)
    if (filter) {
      update({ loading: true, isProcessingAction: true })
      const { data, error } = await filter.request(currentPage, itemsCommonParams)
      if (error) {
        setStatusToast(error, Status.FAIL)
      }
      setCustomSourceItems(data)
    }
    if (sessionFilter) {
      update({ filterActive: sessionFilter, fetchItems: true })
    }
  }

  const onGetItems = async (request: any, params: FilterQueryParams) => {
    if (request.name === getItemsInTagRequest.name) {
      update({ loading: !isEditingTag })
    } else {
      update({ loading: !isProcessingAction })
    }

    const { data, errors } = await request(params)

    if (data) {
      return data
    } else if (errors) {
      setError(t(`ListPage.${listPage}.FetchError`), errors)
    }
  }

  const onSearch = (searchAll: boolean) => {
    try {
      if (hasCustomRequests) {
        update({ loading: true, searchAll, isProcessingAction: true })
        const filterToFind = searchAll ? allItemFilter : filterActive
        const filter = selectedCustomSource?.customRequestFilters?.find(({ filter }) => filterToFind?.name === filter.name)
        filter ? searchCustomSourceItems(searchAll) : searchItems(searchAll)
      } else {
        update({ loading: true, fetchItems: true, searchAll, isProcessingAction: true })
      }
    } catch (errors) {
      setError(t(`ListPage.${listPage}.SearchError`), errors)
    }
  }

  const searchItems: SearchItems = async (searchAll: boolean) => {
    if (activeSubTypes.length && !activeTagId) {
      setItems(
        (
          await searchItemsRequest({
            ...searchCommonParams,
            allItems: searchAll,
            subTypes: activeSubTypes,
            folder: activeFolderId ? activeFolderId : undefined,
          })
        ).data?.searchByMultipleFields?.items as ItemDto[]
      )
    } else if (activeFolderId) {
      setItems(
        (await searchItemsRequest({ ...searchCommonParams, allItems: searchAll, folder: activeFolderId })).data?.searchByMultipleFields
          ?.items as ItemDto[]
      )
    } else if (activeTagId) {
      setItems(
        (
          await searchItemsByTagRequest({
            ...searchCommonParams,
            allItems: searchAll,
            activeTagId,
            subTypes: activeSubTypes,
          })
        ).data?.searchByLabels as ItemDto[]
      )
    } else if (searchAll) {
      setItems((await searchItemsRequest(searchCommonParams)).data?.searchByMultipleFields?.items as ItemDto[])
    } else {
      searchByFilter(searchAll)
    }
  }

  const searchByFilter: SearchItems = async () => {
    if (isCustomFilterSelected(allItemFilter, filterActive)) {
      const { field, query } = getCustomFilterParams?.(filterActive) ?? { field: 'name', query: '' }

      searchByCustomFilter(field, query)
    } else if (filterActive === favoriteFilter) {
      setItems((await searchItemsByFilterRequest(searchSegmentsByFavorites, searchCommonParams)).data?.searchByFavorites as ItemDto[])
    } else if (filterActive === recentFilter) {
      setItems((await searchItemsByFilterRequest(searchSegmentsByRecent, searchCommonParams)).data?.searchByRecent as ItemDto[])
    } else if (filterActive === createdByMeFilter) {
      setItems((await searchItemsByFilterRequest(searchSegmentsByAuthor, searchCommonParams)).data?.searchByAuthor as ItemDto[])
    }
  }

  const searchByCustomFilter: SearchItemsByCustomFilter = async (field: string, query: string) => {
    try {
      const params = {
        ...searchCommonParams,
        fields: [field],
        query,
        allItems: false,
      }

      const { data } = await searchItemsRequest(params)

      if (data) {
        const itemResults = data.searchByMultipleFields?.items?.filter((item) => {
          const parsedItem = JSON.parse(item?.item ?? '')
          return searchFields?.some((field) => parsedItem?.[field]?.toLowerCase().includes(query.toLowerCase()))
        })

        update({ listingPageProps: { ...listingPageProps, sidebarProps: { ...sidebarProps, customFilterSelected: true } } })
        setItems(itemResults as ItemDto[])
      }
    } catch (error) {
      setError(t(`ListPage.${listPage}.FetchError`), error)
    }
  }

  const searchCustomSourceItems: SearchItems = async (searchAll: boolean) => {
    const filterToFind = searchAll ? allItemFilter : filterActive
    const filter = selectedCustomSource?.customRequestFilters?.find(({ filter }) => filterToFind?.name === filter.name)

    if (filter) {
      const { data, error } = await filter.searchRequest(searchCommonParams.query, currentPage, itemsCommonParams)
      if (error) {
        setStatusToast(error, Status.FAIL)
      }
      setCustomSourceItems(data)
    }
  }

  const currentTag = useMemo(() => (activeTagId ? tags.find(({ id }) => id === activeTagId) : undefined), [activeTagId, tags])

  const onClickTagInRow = (name: string) => {
    if (name === currentTag?.name) {
      setFilter(allItemFilter, false)
    } else {
      const tagFound = tags.find(({ name: tagName }) => name === tagName)

      if (tagFound) {
        setTag(tagFound, true)
      }
    }
  }

  const onShouldFetch = () => {
    // If we are searching all, no different result would be returned
    const shouldFetch = !(search && searchAll)
    shouldFetch && update({ items: [], loading: true })
  }

  return {
    getActiveFilter,
    searchByCustomFilter,
    searchByFilter,
    searchItems,
    onSearch,
    setStatusToast,
    onGetItems,
    getFilterItems,
    getFilterItemsWithCustomRequests,
    getTagItems,
    getFolderItems,
    setItems,
    setCustomSourceItems,
    setError,
    setFilter,
    toggleSubType,
    setTag,
    setFolder,
    setSelectedCustomSource,
    loadInitialSessionData,
    onColumnSort,
    setBulkResponseModal,
    onClickTagInRow,
    onShouldFetch,
    currentTag,
    itemsCommonParams,
    searchCommonParams,
  }
}
