import React, { FC } from 'react'

import classNames from 'classnames'

import Container from '@components/Container'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextAlign, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './ImportInfo.css'

export enum ImportType {
  FILE = 'FILE',
  LIST = 'LIST',
}

const importTypes = {
  FILE: { headline: 'File Upload Complete', importSourceType: 'file' },
  LIST: { headline: 'Import Processing', importSourceType: 'list' },
}

type Props = {
  className?: string
  dataTest?: string
  userEmail: string
  type: ImportType
  importAnotherClick: () => void
}

const rootClass = 'import-info'

const ImportInfo: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', userEmail, type, importAnotherClick } = props
  const { t } = useTranslation()
  const { headline, importSourceType } = importTypes[type]
  const text = `We've got your ${importSourceType} and we're processing your records. You can continue using Act-On, or you can close this page if you've got somewhere else you need to be.`

  return (
    <Container className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography className={`${rootClass}__headline`} text={t(headline)} type={TextType.MODAL_HEADLINE} textAlign={TextAlign.CENTER} />
      <div className={`${rootClass}__wrapper-content`}>
        <Typography text={t(text)} type={TextType.BODY_TEXT} textAlign={TextAlign.CENTER} />
        <div className={`${rootClass}__wrapper-email-send`}>
          <Typography
            text={
              "When we finish processing your import, we'll send the results to {{email}}. Check the import log link above for status & error reports."
            }
            type={TextType.BODY_TEXT}
            textAlign={TextAlign.CENTER}
            values={{ email: userEmail }}
            tagProps={{ bold: { weight: TextWeight.BOLD } }}
            inline
          />
        </div>
      </div>
      <div className={`${rootClass}__import-another`}>
        <TextLink text={t('Import another list')} onClick={importAnotherClick} size={TextLinkSize.LARGE} hideIcon />
      </div>
    </Container>
  )
}

export default ImportInfo
