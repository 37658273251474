import { gql } from '@apollo/client'
import { allListsChildren, folderLists } from '@graphql/queries/fragments/lists'

export default gql`
  ${allListsChildren}
  ${folderLists}

  query searchLists($query: String!, $noMirroredJoinLists: Boolean) {
    searchLists(query: $query, noMirroredJoinLists: $noMirroredJoinLists) {
      count
      contacts {
        ...AllListsChildren
      }
      formSubmissions {
        ...FolderListsFragment
      }
      marketing {
        ...FolderListsFragment
      }
      extension {
        ...FolderListsFragment
      }
      webinar {
        ...FolderListsFragment
      }
      unifiedLists {
        ...FolderListsFragment
      }
    }
  }
`
