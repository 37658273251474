import React, { FC, useState } from 'react'

import IncomingWebhooks from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooks'
import {
  IncomingWebhooksAPI,
  IncomingWebhooksContainerInitialState,
  IncomingWebhooksContainerState,
  IncomingWebhooksContext,
} from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'

interface IncomingWebhooksContainerProps {
  className?: string
  dataTest?: string
}

const IncomingWebhooksContainer: FC<IncomingWebhooksContainerProps> = (props: IncomingWebhooksContainerProps) => {
  const { dataTest = `IncomingWebhooksContainer` } = props

  const [state, setState] = useState<IncomingWebhooksContainerState>(IncomingWebhooksContainerInitialState)

  const update = (fields: Partial<IncomingWebhooksContainerState>) => {
    setState((state) => ({ ...state, ...fields }))
  }

  const context: IncomingWebhooksAPI = {
    values: state,
    update,
  }

  return (
    <IncomingWebhooksContext.Provider value={context}>
      <IncomingWebhooks dataTest={dataTest} />
    </IncomingWebhooksContext.Provider>
  )
}

export default IncomingWebhooksContainer
