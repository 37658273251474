import React, { FC, forwardRef, ReactNode, RefObject, useEffect, useState } from 'react'

import classNames from 'classnames'

import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import iframes from '@utils/iframe/iframe'

import './dropDown.css'

export const rootClass = 'drop-down'

export enum DropDownType {
  REGULAR = 'regular',
  STYLED = 'styled',
}

export enum Triggers {
  DEFAULT = 'default',
  OVERFLOW_ICON = 'overflowIcon',
  CUSTOM = 'custom',
}

export interface DropDownProps extends DropdownMenu.DropdownMenuProps {
  /** Text to be displayed in button that expands/collapses drop down */
  label?: ReactNode
  children: ReactNode
  /**
   * Value to be set for data-test on root element
   * @default "drop-down"
   **/
  dataTest?: string
  /** Additional classname to be applied to component */
  className?: string
  contentClassName?: string
  alignContentEnd?: boolean
  /** An offset to align dropdown with trigger **/
  alignRight?: boolean
  /** Uses the overflow-menu icon instead of a label and a caret icon **/
  hasOverflowIcon?: boolean
  overflowIconTooltipText?: string
  isOpen: boolean
  toggleOpen: (isOpen: boolean) => void
  type?: DropDownType
  trigger?: ReactNode
  reference?: RefObject<HTMLDivElement>
  align?: 'start' | 'center' | 'end'
  sideOffset?: number
  dropdownContentProps?: DropdownMenu.DropdownMenuContentProps
  footer?: ReactNode
}

const DropDown: FC<DropDownProps> = forwardRef<HTMLDivElement, DropDownProps>((props, forwardedRef) => {
  const {
    isOpen: isDropDownOpen,
    toggleOpen,
    label,
    dataTest,
    className,
    contentClassName,
    alignContentEnd = false,
    alignRight,
    hasOverflowIcon = false,
    overflowIconTooltipText,
    children,
    trigger,
    reference,
    align,
    modal,
    type,
    sideOffset,
    dropdownContentProps,
    footer,
  } = props

  const [isOpen, setIsOpen] = useState<boolean>(isDropDownOpen)

  const onIFrameClick = () => {
    toggleOpen(false)
  }

  const openChange = () => {
    toggleOpen(!isOpen)
  }

  const divRef = reference || forwardedRef

  const renderOverflowIcon = () => <Svg name={SvgNames.overflowMenu} type={SvgType.LARGER_ICON} />

  const triggers = {
    [Triggers.DEFAULT]: (
      <>
        {label}
        <CaretIcon direction={CaretIconDirection.DOWN} className={classNames({ [`${rootClass}__open`]: isOpen })} />
      </>
    ),
    [Triggers.OVERFLOW_ICON]: !!overflowIconTooltipText ? (
      <Tooltip triggerClassName={`${rootClass}__tooltip`} position={'top'} trigger={renderOverflowIcon()} sideOffset={sideOffset}>
        {overflowIconTooltipText}
      </Tooltip>
    ) : (
      renderOverflowIcon()
    ),
    [Triggers.CUSTOM]: <DropdownMenu.Label>{trigger}</DropdownMenu.Label>,
  }

  const renderTriggerContent = () => {
    if (trigger) {
      return triggers[Triggers.CUSTOM]
    } else if (hasOverflowIcon) {
      return triggers[Triggers.OVERFLOW_ICON]
    }
    return triggers[Triggers.DEFAULT]
  }

  useEffect(() => {
    const contentIFrame: any = iframes.getIFrame()?.contentDocument?.getElementById('content')

    if (isDropDownOpen) {
      iframes.getIFrame()?.contentWindow?.document.addEventListener('click', onIFrameClick)
      contentIFrame?.contentWindow?.document.addEventListener('click', onIFrameClick)
    } else {
      iframes.getIFrame()?.contentWindow?.document.removeEventListener('click', onIFrameClick)
      contentIFrame?.contentWindow?.document.removeEventListener('click', onIFrameClick)
    }

    setIsOpen(isDropDownOpen)
  }, [isDropDownOpen])

  return (
    <div
      data-test={dataTest}
      className={classNames(rootClass, className)}
      ref={divRef}
      role={'button'}
      tabIndex={0}
      onKeyDown={(keyDownEvent) => {
        if (keyDownEvent.key === ' ' || keyDownEvent.key === 'Enter') {
          openChange()
          keyDownEvent.stopPropagation()
        }
      }}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <DropdownMenu.Root open={isOpen} onOpenChange={openChange} modal={modal}>
        <DropdownMenu.Trigger
          asChild={!!trigger}
          data-test={`${dataTest}-trigger`}
          tabIndex={-1}
          className={classNames(`${rootClass}__main-button`, `ellip`, {
            [`${rootClass}__overflow-button`]: hasOverflowIcon,
            [`${rootClass}__trigger-button`]: !!trigger,
          })}
        >
          {renderTriggerContent()}
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          data-test={`${dataTest}-drop-down`}
          className={classNames(`${rootClass}__drop-down`, [
            {
              [`${rootClass}__drop-down-align-right`]: alignRight,
              [`${rootClass}__drop-down-overflow`]: hasOverflowIcon,
              [`${rootClass}__drop-down-styled`]: type === DropDownType.STYLED,
              [`${contentClassName}`]: contentClassName,
            },
          ])}
          align={align || (hasOverflowIcon || alignContentEnd ? 'end' : 'start')}
          {...dropdownContentProps}
        >
          {children}
          {footer && <div className={`${rootClass}__footer`}>{footer}</div>}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  )
})

const DropDownMenuRoot = DropdownMenu.Root
const DropDownContent = DropdownMenu.Content
const DropDownTriggerItem = DropdownMenu.Trigger
const DropdownMenuItem = DropdownMenu.Item
const DropdownMenuLabel = DropdownMenu.Label
const DropDownMenuItemIndicator = DropdownMenu.ItemIndicator
const DropDownRadioGroup = DropdownMenu.RadioGroup
const DropDownRadioItem = DropdownMenu.RadioItem
const DropDownItemIndicator = DropdownMenu.ItemIndicator

export {
  DropDownMenuRoot,
  DropdownMenuLabel,
  DropdownMenuItem,
  DropDownMenuItemIndicator,
  DropDownTriggerItem,
  DropDownContent,
  DropDownRadioGroup,
  DropDownRadioItem,
  DropDownItemIndicator,
}

export default DropDown
