import { DocumentNode } from '@apollo/client'
import { SearchRequestParams } from '@complex/ListingPage/Utils/ListingPage.utils'
import searchByMultipleFields from '@graphql/microservices/categorization/searchByMultipleFields'
import searchByTags from '@graphql/microservices/categorization/searchByTags'
import {
  SearchByMultipleFieldsQuery,
  SearchByMultipleFieldsQueryVariables,
  SearchByTagsQuery,
  SearchByTagsQueryVariables,
} from '@graphql/types/microservice/categorization-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

import { ListPageCommonState } from '../Context/ListingPageCommon.context'

export interface GetSearchRequests {
  searchItemsRequest: (searchRequestCommonParams: SearchRequestParams) => FetchPromise<SearchByMultipleFieldsQuery>
  searchItemsByTagRequest: (searchRequestCommonParams: SearchRequestParams) => FetchPromise<SearchByTagsQuery>
  searchItemsByFilterRequest: (queryType: DocumentNode, searchRequestCommonParams: SearchRequestParams) => FetchPromise<any>
}

export const useSearchRequests = (containerValues: ListPageCommonState, folderSubTypes: string[] = []): GetSearchRequests => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const searchItemsRequest = (searchRequestCommonParams: SearchRequestParams) => {
    const { type, fields, query, folder, allItems, subTypes, pageSize, pageNumber } = searchRequestCommonParams

    return client.query<SearchByMultipleFieldsQuery, SearchByMultipleFieldsQueryVariables>({
      query: searchByMultipleFields,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        type,
        fields,
        query,
        folder,
        pageNumber,
        pageSize,
        allItems: allItems || !folder,
        subTypes,
        folderSubTypes,
      },
    })
  }

  const searchItemsByTagRequest = (searchRequestCommonParams: SearchRequestParams) => {
    const { type, field, query, activeTagId, subTypes } = searchRequestCommonParams

    return client.query<SearchByTagsQuery, SearchByTagsQueryVariables>({
      query: searchByTags,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        type,
        query,
        field,
        labelIds: [activeTagId],
        subTypes,
        folderSubTypes,
      },
    })
  }

  const searchItemsByFilterRequest = (queryRequest: DocumentNode, searchRequestCommonParams: SearchRequestParams) => {
    const { type, field, query, allItems, subTypes } = searchRequestCommonParams
    return client.query({
      query: queryRequest,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        type,
        query,
        field,
        allSegments: allItems,
        subTypes,
        folderSubTypes,
      },
    })
  }

  const custom = containerValues.selectedCustomSource?.customRequestGeneral

  return {
    searchItemsRequest: custom?.searchItemsRequest ?? searchItemsRequest,
    searchItemsByTagRequest,
    searchItemsByFilterRequest,
  }
}
