import React, { FC, KeyboardEventHandler, MouseEventHandler, ReactNode, useCallback, useContext } from 'react'

import classNames from 'classnames'

import { ContactStatus, MergeStrategy } from '@complex/ImportContacts/ImportContacts.constants'
import CaretIcon, { CaretIconDirection } from '@components/CaretIcon'
import Checkbox from '@components/Checkbox'
import CheckboxGroup from '@components/CheckboxGroup/CheckboxGroup'
import Container from '@components/Container'
import InfoTooltip, { InfoTooltipIconSize } from '@components/InfoTooltip/InfoTooltip'
import InputV2 from '@components/InputV2/InputV2'
import Radio from '@components/Radio'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ImportContactsV2Context } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.context'
import { advancedOptionsText } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.utils'
import { useAccountSettings } from '@utils/account/account.utils'

import './ReviewImportAdvancedOptions.css'

interface ReviewImportAdvancedOptionsProps {
  className?: string
  dataTest?: string
}

const rootClass = 'review-import-advanced-options'

const ReviewImportAdvancedOptions: FC<ReviewImportAdvancedOptionsProps> = (props: ReviewImportAdvancedOptionsProps) => {
  const { dataTest = rootClass, className = '' } = props

  const {
    update,
    values: { showAdvancedOptions },
  } = useContext(ImportContactsV2Context)

  const { hasFTPMirrorImport } = useAccountSettings()

  const { t } = useTranslation()

  const {
    values: {
      externalIdMatch,
      firstLineContains,
      fieldsSeparator,
      fieldQuotes,
      updateAction,
      mergeStrategy,
      mergeRule,
      objectType,
      rejectRules,
      removeEntriesFromList,
      segmentSelectedOption,
      useCustomPrefix,
      contactPreference,
    },
  } = useContext(ImportContactsV2Context)

  const renderRejectRules = rejectRules.rejectOptOut || rejectRules.rejectHardBounces || rejectRules.rejectSuppressedDomains

  const onHeaderClick = useCallback<MouseEventHandler>(() => {
    update({ showAdvancedOptions: !showAdvancedOptions })
  }, [showAdvancedOptions])

  const onHeaderKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      if ((event.key = 'Enter')) {
        update({ showAdvancedOptions: !showAdvancedOptions })
      }
    },
    [showAdvancedOptions]
  )

  const renderSection = (title: string, content: ReactNode, tooltip?: string) => (
    <div className={`${rootClass}__section`}>
      <div className={`${rootClass}__section-header`}>
        <Typography className={`${rootClass}__section-header-title`} text={t(title)} weight={TextWeight.MEDIUM} />
        {tooltip && <InfoTooltip iconSize={InfoTooltipIconSize.MEDIUM}>{tooltip}</InfoTooltip>}
      </div>
      <div className={`${rootClass}__section-content`}>{content}</div>
    </div>
  )

  return (
    <Container className={classNames(rootClass, className)} dataTest={dataTest}>
      <div className={`${rootClass}__header`} role={'button'} tabIndex={0} onKeyDown={onHeaderKeyDown} onClick={onHeaderClick}>
        <Typography text={t('Advanced options')} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_LARGE} />
        <CaretIcon direction={CaretIconDirection.RIGHT} toggle={showAdvancedOptions} isFilled={false} type={SvgType.ICON} />
      </div>
      <div className={classNames(`${rootClass}__content`, { [`${rootClass}__content-visible`]: showAdvancedOptions })}>
        {renderSection(
          'ImportContactsV2.Sections.ImportOptions.Fields.Title',
          <Radio label={t(advancedOptionsText[firstLineContains])} disabled checked />
        )}
        {renderSection(
          'ImportContactsV2.Sections.ImportOptions.FieldsSeparator.Title',
          <Radio label={t(advancedOptionsText[fieldsSeparator])} disabled checked />
        )}
        {renderSection(
          'ImportContactsV2.Sections.ImportOptions.Quoted.Title',
          <Radio label={t(advancedOptionsText[fieldQuotes])} disabled checked />
        )}
        {renderSection(
          'ImportContactsV2.Sections.ImportOptions.Update.Title',
          <Radio label={t(advancedOptionsText[updateAction])} disabled checked />
        )}
        {renderSection(
          'ImportContactsV2.Sections.ImportOptions.Merge.Title',
          <Radio label={t(advancedOptionsText[mergeRule])} disabled checked />,
          t('ImportContactsV2.Sections.ImportOptions.Merge.Tooltip')
        )}
        {renderSection(
          'ImportContactsV2.Sections.ImportOptions.MatchRules.Title',
          <div>
            <Radio label={t(mergeStrategy === MergeStrategy.Email ? 'Email' : 'External ID')} disabled checked />
            {mergeStrategy === MergeStrategy.ExternalId && (
              <>
                <SelectV2
                  className={classNames(`${rootClass}__select`, `${rootClass}__external-id-match-select`)}
                  options={[]}
                  defaultValue={externalIdMatch}
                  isDisabled
                />
                <InputV2
                  className={`${rootClass}__input`}
                  labelProps={{ label: t('ObjectType') }}
                  value={useCustomPrefix ? `custom.${objectType}` : objectType}
                  disabled
                />
              </>
            )}
          </div>
        )}
        {renderRejectRules &&
          renderSection(
            'ImportContactsV2.Sections.ImportOptions.RejectRules.Title',
            <CheckboxGroup verticalLayout>
              <Checkbox
                className={classNames({ [`${rootClass}__hidden`]: !rejectRules.rejectOptOut })}
                label={t(advancedOptionsText[ContactStatus.OptOut])}
                checked={rejectRules.rejectOptOut}
                disabled
                showTickOnDisabled
              />
              <Checkbox
                className={classNames({ [`${rootClass}__hidden`]: !rejectRules.rejectHardBounces })}
                label={t(advancedOptionsText[ContactStatus.HardBounce])}
                checked={rejectRules.rejectHardBounces}
                disabled
                showTickOnDisabled
              />
              <Checkbox
                className={classNames({ [`${rootClass}__hidden`]: !rejectRules.rejectSuppressedDomains })}
                label={t(advancedOptionsText[ContactStatus.SoftBounce])}
                checked={rejectRules.rejectSuppressedDomains}
                disabled
                showTickOnDisabled
              />
            </CheckboxGroup>
          )}
        {hasFTPMirrorImport &&
          renderSection(
            'Remove entries from list',
            <Checkbox
              className={`${rootClass}__remove-entries`}
              label={t(
                !segmentSelectedOption && mergeStrategy === MergeStrategy.ExternalId
                  ? `Remove records from All Contacts list if not in import list`
                  : `Remove records from the target segment if not in the import list.`
              )}
              checked={!segmentSelectedOption && mergeStrategy === MergeStrategy.Email ? false : removeEntriesFromList}
              disabled
              showTickOnDisabled
            />
          )}
        {contactPreference &&
          renderSection(
            'ImportContactsV2.Sections.ImportOptions.ContactPreference.Title',
            <SelectV2 options={[]} defaultValue={contactPreference} isDisabled />,
            t('ImportContactsV2.Sections.ImportOptions.ContactPreference.Tooltip')
          )}
      </div>
    </Container>
  )
}

export default ReviewImportAdvancedOptions
