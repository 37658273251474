import { gql } from '@apollo/client'

export default gql`
  query job($accountId: ID, $executionId: ID) {
    job(accountId: $accountId, executionId: $executionId) {
      id
      executionId
      accountId
      status
      config {
        id
        data {
          dataSource
          featureInclude
          featuresCat
          invalidFeaturesCat
          features
          featuresNum
          featureValuesInclude
          featureValueList {
            key
            value
          }
          label
        }
        model {
          algorithm
          hyperparameterList {
            key
            value
          }
          numberCluster
        }
        visualization {
          visualizationRequired
          topK
          outputPath
        }
        filter
      }
      startTime
      completionTime
      insertTime
      maximumRunTime
      memoryOverride
      targetSegment
    }
  }
`
