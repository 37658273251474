import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import { setCRMCampaignsUtils, setSegmentsMetadataUtils } from '@complex/ContactSegments/segmentInfoHover.utils'
import CRMSourcesDefinition from '@complex/ContactSegments/SegmentInfoHoverCard/components/CRMSourcesDefinition'
import FTPSyncSegmentDefinition from '@complex/ContactSegments/SegmentInfoHoverCard/components/FTPSyncSegmentDefinition/FTPSyncSegmentDefinition'
import { useSegmentInfoHoverCardRequests } from '@complex/ContactSegments/SegmentInfoHoverCard/GraphQL/SegmentInfoHoverCard.graphQL'
import { crmStatusObjects } from '@complex/ContactSegments/SegmentInfoHoverCard/SegmentInfoHoverCard.mock'
import { getCRMSourcedSegmentDetailsUtils } from '@complex/ContactSegments/SegmentInfoHoverCard/utils/SegmentInfoHoverCard.utils'
import SegmentInfoHoverCardSegmentDefinition, {
  SegmentInfoHoverCardSegmentDefinitionData,
} from '@complex/ContactSegments/SegmentInfoHoverCardSegmentDefinition/SegmentInfoHoverCardSegmentDefinition'
import { getSegmentHierarchyUtils } from '@complex/ListPickerModalV2/components/ListInfoHoverCard/GraphQL/SegmentActivity.graphQL'
import ExpandableBreadCrumbs from '@components/ExpandableBreadCrumbs/ExpandableBreadCrumbs'
import { LinkTextButton, LinkTextButtonSize } from '@components/LinkTextButton/LinkTextButton'
import Loader from '@components/Loader'
import { LoaderTypes } from '@components/Loader/Loader'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { rootContext, useTranslation } from '@const/globals'
import { SyncedSegment } from '@graphql/types/microservice/crm-types'
import { ListPageStatusToast } from '@interface/ListPage.context'
import { useAccountSettings } from '@utils/account/account.utils'
import { BouncesSegments, ItemSubType } from '@utils/categorization'
import {
  EXPRESSION_EVALUATION_TYPE,
  getSegmentPath,
  goEditCRMSourcedSegment,
  goEditSegment,
  Segment,
  SegmentDefinition,
  systemSegments,
} from '@utils/contactSegments/contactSegments.utils'
import { getSegmentTypeLabel } from '@utils/contactSegments/SegmentInfoHoverCardSegmentDefinitionUtils'
import { CRM_STATUS, CRMSourceOption } from '@utils/crm.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { logNewRelicError } from '@utils/new-relic.utils'
import { replaceAll } from '@utils/strings'

import './SegmentInfoHoverCard.css'

interface Props {
  className?: string
  crmSourceOptions?: CRMSourceOption[]
  dataTest?: string
  segmentData: Segment
  syncsWithFtp?: boolean
  hasExport?: boolean
  onFtpViewLogAction?: (target: string | undefined) => void
  showStatusToast?: (toast: ListPageStatusToast) => void
}

interface SegmentInfoHoverCardState extends SegmentInfoHoverCardSegmentDefinitionData {
  crmSources?: string[]
  crmSyncDetails?: SyncedSegment
  segmentHierarchy: Segment[]
  segmentDefinition: SegmentDefinition
}

export interface CRMStatusDisplayData {
  title: string
  subtitle?: string
  helpLink?: {
    text: string
    link: string
  }
  icon: SvgNames
}

const rootClass = 'segment-details-info-hover'

const SegmentInfoHoverCard: FC<Props> = (props: Props) => {
  const {
    dataTest = rootClass,
    className = '',
    segmentData,
    showStatusToast,
    syncsWithFtp = false,
    onFtpViewLogAction,
    hasExport = false,
    crmSourceOptions = [],
  } = props

  const [state, setState] = useState<SegmentInfoHoverCardState>({
    segmentHierarchy: [],
    campaigns: {},
    crmMapping: {},
    scoreSheets: {},
    supportedBehaviors: {},
    subscriptionMapping: {},
    metadataLoading: false,
    segmentDefinition: {
      type: '',
      customBooleanExpression: null,
      expressions: [],
      expressionEvaluationType: 'AND' as EXPRESSION_EVALUATION_TYPE,
    },
  })
  const { getCRMNotSupportedEntitiesRequest, syncedSegmentDetailsRequest, segmentDefinitionRequest } = useSegmentInfoHoverCardRequests()
  const isCRMSourced = segmentData.subTypes?.includes(ItemSubType.CRM_SOURCED)
  const isCRMSourcedParentSegment = isCRMSourced && !segmentData.parent
  const { getConnectedCrm, disableSegmentModifications } = useAccountSettings()
  const {
    crmSources,
    crmSyncDetails,
    segmentHierarchy,
    segmentDefinition,
    crmMapping,
    subscriptionMapping,
    metadataLoading,
    supportedBehaviors,
    campaigns,
    scoreSheets,
  } = state

  const update = (values: Partial<SegmentInfoHoverCardState>) => {
    setState((state) => ({ ...state, ...values }))
  }

  const setCampaigns = (campaigns: any) => {
    setState((state) => ({ ...state, campaigns: { ...state.campaigns, ...campaigns } }))
  }

  const { t } = useTranslation()

  const systemSegmentsContent = !segmentData.isEditable && systemSegments[segmentData.name]

  const crmStatusData = segmentData.crmStatus ? crmStatusObjects[segmentData.crmStatus as CRM_STATUS] : undefined
  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })
  const { client: segmentClient } = useMicroserviceClient({ serviceName: MicroserviceClients.SEGMENT })
  const { client: crmClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })
  const getSegmentPathCrumbs = () =>
    segmentHierarchy
      ? getSegmentPath(segmentData.externalId, segmentHierarchy).reduce((crumbs: string[], segment: Segment) => [...crumbs, segment.name], [])
      : []

  useEffect(() => {
    if (segmentData.isEditable) {
      setSegmentsMetadataUtils(update, segmentClient, setCampaigns)
      if (!isCRMSourcedParentSegment) {
        segmentDefinitionRequest(segmentData.externalId).then((definition) => setState((state) => ({ ...state, segmentDefinition: definition })))
      }
      getSegmentHierarchyUtils(segmentData.id, categorizationClient).then((segments) =>
        setState((state) => ({ ...state, segmentHierarchy: segments }))
      )
      if (getConnectedCrm !== 'UNSET') {
        getCRMNotSupportedEntitiesRequest({ connectedCRM: getConnectedCrm })
          .then(({ data }) => {
            if (!!data) {
              const unsupportedTypes = data.crmDefinition?.unsupportedTypes?.map((type) => type?.name)
              if (unsupportedTypes?.every((type) => type !== 'Campaign')) {
                setCRMCampaignsUtils(update, crmClient, setCampaigns)
              }
            }
          })
          .catch((error) => {
            logNewRelicError(error)
          })
      }
    }
  }, [])

  useEffect(() => {
    if (isCRMSourcedParentSegment) {
      getCRMSourcedSegmentDetailsUtils([segmentData.externalId], syncedSegmentDetailsRequest).then((segmentDetails) => {
        if (!!segmentDetails) {
          const { syncedSegmentEntityFactors, syncedSegmentQueryFactors } = segmentDetails[0] || {}
          const sources = !!syncedSegmentEntityFactors
            ? syncedSegmentEntityFactors.map((factor) => factor?.label)
            : syncedSegmentQueryFactors?.map((factor) => factor?.label)
          setState((state) => ({
            ...state,
            crmSources: sources as string[],
            crmSyncDetails: segmentDetails[0],
          }))
        }
      })
    }
  }, [segmentHierarchy])

  const renderSoftBouncesContent = () => (
    <div className={classNames(`${rootClass}__system`, `${rootClass}__system-soft-bounces`)}>
      <Typography
        text={t(
          'Contacts with delivery failure for typically temporary reasons, such as a recipient mail server being off line. Email delivery will be attempted, until the '
        )}
        type={TextType.BODY_TEXT_SMALL}
        inline
      />
      <LinkTextButton text={t('soft bounce limit')} link={`${rootContext}/contactSettings`} hideIcons />
      <Typography text={t(' is reached.')} type={TextType.BODY_TEXT_SMALL} inline />
    </div>
  )

  const renderCRMStatusTitle = () => (
    <div className={`${rootClass}__crm-status-title`}>
      <Typography text={t(crmStatusData?.title)} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} />
      <Svg name={crmStatusData?.icon as SvgNames} className={`${rootClass}__crm-status-icon-${crmStatusData?.icon}`} type={SvgType.ICON} />
    </div>
  )

  const renderSystemSegmentsContent = () => {
    if (segmentData.name === BouncesSegments.SOFT_BOUNCES) {
      return renderSoftBouncesContent()
    }
    return (
      <Typography
        className={`${rootClass}__system`}
        text={
          systemSegmentsContent && systemSegmentsContent !== ''
            ? t(systemSegmentsContent)
            : `${t('Contacts who have opted in to your subscription category')} ${segmentData.name}.`
        }
        type={TextType.BODY_TEXT_SMALL}
      />
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={classNames(`${rootClass}__section`, { [`${rootClass}__section-tree`]: segmentData.isEditable })}>
        {syncsWithFtp && <FTPSyncSegmentDefinition onFtpViewLogAction={onFtpViewLogAction} hasExport={hasExport} segment={segmentData} />}
        {isCRMSourced && !!crmStatusData && (
          <>
            <div className={`${rootClass}__crm-status`}>
              {renderCRMStatusTitle()}
              {crmStatusData.subtitle && (
                <div className={`${rootClass}__crm-status-subtitle`}>
                  <Typography text={t(crmStatusData.subtitle)} type={TextType.BODY_TEXT_SMALL} inline />
                  {crmStatusData.helpLink && <LinkTextButton text={t(crmStatusData.helpLink.text)} link={crmStatusData.helpLink.link} hideIconLeft />}
                </div>
              )}
            </div>
            {!!segmentData.crmErrorDetails && (
              <Tooltip
                className={`${rootClass}__crm-error-tooltip`}
                inline
                openOnClick
                closeOnClick
                minimalPadding={false}
                trigger={<Typography text={t('View details')} type={TextType.NORMAL_TEXT_TEAL} weight={TextWeight.MEDIUM} inline />}
                triggerClassName={`${rootClass}__crm-error-tooltip-trigger`}
                childrenProps={{ type: TextType.ERROR, weight: TextWeight.MEDIUM }}
              >
                {segmentData.crmErrorDetails}
              </Tooltip>
            )}
            <div className={`${rootClass}__divider`} />
          </>
        )}
        {segmentData.isEditable && (
          <>
            <Typography
              text={t('SEGMENT TREE')}
              type={TextType.BODY_TEXT_LIGHT_TINY}
              weight={TextWeight.MEDIUM}
              inline
              className={`${rootClass}__section-title`}
            />
            {segmentHierarchy.length !== 0 ? (
              <ExpandableBreadCrumbs crumbs={getSegmentPathCrumbs()} baseCrumb={t('All Contacts')} dataTest={segmentData.externalId} />
            ) : (
              <Loader loaderType={LoaderTypes.row} />
            )}
            <div className={`${rootClass}__divider`} />
            <div className={classNames(`${rootClass}__section`, `${rootClass}__section-type`)}>
              <Typography
                text={`${t('SEGMENT TYPE:')} ${
                  isCRMSourcedParentSegment
                    ? `CRM SOURCED ${replaceAll(
                        crmSourceOptions.find(({ name }) => name === crmSyncDetails?.sourceType)?.name ?? '',
                        '_',
                        ' '
                      )}`.toUpperCase()
                    : getSegmentTypeLabel(segmentDefinition)
                }`}
                type={TextType.BODY_TEXT_LIGHT_TINY}
                weight={TextWeight.MEDIUM}
                inline
                className={`${rootClass}__section-title`}
              />
              <LinkTextButton
                text={t('Edit')}
                size={LinkTextButtonSize.SMALL}
                hideIcons
                disabled={disableSegmentModifications}
                onClick={() =>
                  isCRMSourcedParentSegment ? goEditCRMSourcedSegment(segmentData) : goEditSegment(segmentData, window.location.pathname)
                }
              />
            </div>
            {isCRMSourcedParentSegment ? (
              !crmSources?.length && !segmentData.crmErrorDetails ? (
                <Loader loaderType={LoaderTypes.row} />
              ) : !!segmentData.crmErrorDetails ? (
                <Typography text={t('No data available')} inline weight={TextWeight.MEDIUM} type={TextType.ERROR} />
              ) : (
                <CRMSourcesDefinition crmSources={crmSources as string[]} crmSyncDetails={crmSyncDetails} showStatusToast={showStatusToast} />
              )
            ) : metadataLoading ? (
              <Loader loaderType={LoaderTypes.row} />
            ) : segmentDefinition ? (
              <SegmentInfoHoverCardSegmentDefinition
                data={segmentDefinition}
                contextValues={{
                  crmMapping,
                  subscriptionMapping,
                  metadataLoading,
                  supportedBehaviors,
                  campaigns,
                  scoreSheets,
                }}
              />
            ) : (
              <Typography text={t('No data available')} inline weight={TextWeight.MEDIUM} type={TextType.ERROR} />
            )}
          </>
        )}
      </div>
      {!segmentData.isEditable && renderSystemSegmentsContent()}
    </div>
  )
}

export default SegmentInfoHoverCard
