import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import AddButton from '@components/AddButton/AddButton'
import Input from '@components/Input/Input'
import SingleSelectDropdown from '@components/SingleSelectDropdown/SingleSelectDropdown'
import Typography, { TextType } from '@components/Typography/Typography'
import { INPUT_DEBOUNCE_TIME, useTranslation } from '@const/globals'
import { SelectOption } from '@interface/Select'
import { CHECK_CONDITIONS_STATUS_EVENT } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AddAlertFilterModal/utils/AddAlertFilterModal.constants'
import {
  AlertFilterCondition,
  Condition,
  ConditionField,
  contactDetailsFields,
  locationDetailsFields,
  LogicalCondition,
  otherDetailsFields,
} from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/utils/AlertFilters.utils'

import './VisitorsConditionsEditor.css'

interface VisitorsConditionsEditorProps {
  className?: string
  dataTest?: string
  conditions?: AlertFilterCondition[]
  onSaveConditions: (isValid: boolean) => void
  onChange: (conditionData: Partial<AlertFilterCondition>, index: number) => void
  onRemove: (index: number) => void
}

interface ConditionError {
  index: number
  validField?: boolean
  validValue?: boolean
}

interface VisitorsConditionsEditorState {
  isValid: boolean
  errors: ConditionError[]
}

const rootClass = 'visitors-conditions-editor'

const VisitorsConditionsEditor: FC<VisitorsConditionsEditorProps> = (props: VisitorsConditionsEditorProps) => {
  const { dataTest = rootClass, className = '', conditions = [], onChange, onRemove, onSaveConditions } = props

  const [state, setState] = useState<VisitorsConditionsEditorState>({
    isValid: true,
    errors: Array.from(conditions).map((_, index) => ({ index, validField: true, validValue: true })),
  })
  const { isValid, errors } = state

  const { t } = useTranslation()

  const fieldOptions: SelectOption[] = [
    ...contactDetailsFields.map((value) => ({ value, category: t('Contact details') })),
    ...locationDetailsFields.map((value) => ({ value, category: t('Location details') })),
    ...otherDetailsFields.map((value) => ({ value, category: t('Other details') })),
  ]

  const getOptionsFromObject = (data: object): SelectOption[] => Object.values(data).map((value) => ({ value }))

  const replaceErrorValues = (values: Partial<ConditionError>, index: number) => {
    setState(({ errors, ...state }) => ({
      ...state,
      errors: [...errors.slice(0, index), { ...errors[index], ...values }, ...errors.slice(index + 1)],
    }))
  }

  const checkErrors = (conditions: AlertFilterCondition[]) => {
    return conditions.reduce(
      ({ isValid, errors }: { isValid: boolean; errors: ConditionError[] }, { field, value, condition }, index) => {
        const validValue = condition === Condition.BLANK || condition === Condition.NOT_BLANK || !!value
        return {
          isValid: isValid && !!field && validValue,
          errors: [...errors, { index, validField: !!field, validValue }],
        }
      },
      { isValid: true, errors: [] }
    )
  }

  const saveConditions = () => {
    const status = checkErrors(conditions)
    setState(status)
    onSaveConditions(status.isValid)
  }

  const onRemoveCondition = (index: number) => {
    const tempErrors = errors.reduce((errors: ConditionError[], { index: currentErrorIndex, ...error }, currentIndex) => {
      return [...errors, ...(currentIndex !== index ? [{ ...error, index: currentIndex > index ? currentErrorIndex - 1 : currentErrorIndex }] : [])]
    }, [])
    setState((state) => ({ ...state, errors: tempErrors }))
    onRemove(index)
  }

  useEffect(() => {
    const saveConditionsFunction = () => saveConditions()
    document.addEventListener(CHECK_CONDITIONS_STATUS_EVENT, saveConditionsFunction)
    return () => document.removeEventListener(CHECK_CONDITIONS_STATUS_EVENT, saveConditionsFunction)
  })

  useEffect(() => {
    const isValid = errors.every(({ validValue, validField }) => validField && validValue)
    setState((state) => ({ ...state, isValid }))
  }, [errors])

  useEffect(() => {
    if (conditions.length === errors.length + 1) {
      setState(({ errors, ...state }) => ({ ...state, errors: [...errors, { index: conditions.length - 1, validField: true, validValue: true }] }))
    }
  }, [conditions.length])

  const renderRow = (filterCondition: AlertFilterCondition, index: number) => {
    const { condition, logicalCondition, field, value } = filterCondition
    const fieldSelectHasError = errors.length > index && !errors[index].validField
    const inputHasError = errors.length > index && !errors[index].validValue
    const showInput = condition !== Condition.BLANK && condition !== Condition.NOT_BLANK
    return (
      <div className={`${rootClass}__row`} data-test={`${dataTest}-row`} key={index}>
        {index > 0 && (
          <SingleSelectDropdown
            className={`${rootClass}__row-logical`}
            value={logicalCondition}
            options={getOptionsFromObject(LogicalCondition)}
            onSubmit={(value) => onChange({ logicalCondition: value as LogicalCondition }, index)}
          />
        )}
        <SingleSelectDropdown
          className={`${rootClass}__row-field`}
          dataTest={`${dataTest}-field-select`}
          value={field}
          options={fieldOptions}
          placeholder={t('Select a condition')}
          hasError={fieldSelectHasError}
          onSubmit={(value) => {
            onChange({ field: value as ConditionField }, index)
            if (fieldSelectHasError && value) {
              replaceErrorValues({ validField: true }, index)
            }
          }}
        />
        <SingleSelectDropdown
          className={classNames(`${rootClass}__row-condition`, { [`${rootClass}__row-condition-extended`]: !showInput })}
          dataTest={`${dataTest}-condition-select`}
          value={condition}
          options={getOptionsFromObject(Condition)}
          onSubmit={(value) => onChange({ condition: value as Condition }, index)}
        />
        {showInput && (
          <Input
            className={`${rootClass}__row-value`}
            dataTest={`${dataTest}-value`}
            value={value}
            placeholder={t('Add value')}
            onChange={({ target: { value } }) => onChange({ value }, index)}
            error={inputHasError}
            onBlur={({ target: { value } }) => replaceErrorValues({ validValue: !!value }, index)}
            onChangeDebounce={INPUT_DEBOUNCE_TIME}
          />
        )}
        <AddButton dataTest={`${dataTest}-remove-button`} onClick={() => onRemoveCondition(index)} isDelete />
      </div>
    )
  }

  return (
    <div className={classNames(rootClass, className, { [`${rootClass}__error`]: !isValid })} data-test={dataTest}>
      <div className={`${rootClass}__rows-container`}>{conditions.map(renderRow)}</div>
      {!isValid && (
        <Typography
          className={`${rootClass}__error-message`}
          text={t('You cannot have an empty value for conditions.')}
          type={TextType.ERROR_LARGE}
        />
      )}
    </div>
  )
}

export default VisitorsConditionsEditor
