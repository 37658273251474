import { Dispatch, SetStateAction } from 'react'

import { SegmentActivityDto } from '@graphql/types/microservice/segment-types'
import { SegmentActivityState } from '@src/pages/ContactSegments/components/SegmentActivity/SegmentActivity'

export const getAllActivityUtils = (
  setState: Dispatch<SetStateAction<SegmentActivityState>>,
  getAllActivityRequest: (externalId: string) => Promise<SegmentActivityDto[]>,
  segmentId: string
) => {
  setState((state: SegmentActivityState) => ({
    ...state,
    loadingAllActivity: true,
  }))
  getAllActivityRequest(segmentId)
    .then((allActivity) => {
      setState((state) => ({
        ...state,
        allActivity,
        loadingAllActivity: false,
      }))
    })
    .catch(() => {
      setState((state: SegmentActivityState) => ({
        ...state,
        loadingAllActivity: false,
      }))
    })
}
