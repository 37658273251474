import { gql } from '@apollo/client'

export default gql`
  query getDataCards {
    getDataCards {
      accountId
      available
      createdTime
      currentSends
      enabled
      id
      isCPMAccount
      listSentToThisMonth
      numContactLimit
      pendingContacts
      remainingContacts
      remainingDays
      sentContacts
      updatedTime
      used
      usedContacts
    }
  }
`
