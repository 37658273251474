import React, { FC, useState } from 'react'

import AssetPickerModal, { AssetPickerModalBaseProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { CustomSource } from '@complex/ListingPage/Context/ListingPageCommon.context'

import { EmailType, useEmailPickerProps } from './useEmailPickerProps'

import './EmailPickerModal.css'

export type EmailPickerModalProps = AssetPickerModalBaseProps

const EmailPickerModal: FC<EmailPickerModalProps> = (props) => {
  const [itemType, setItemType] = useState(EmailType.SENT)

  const emailPickerProps = useEmailPickerProps(itemType)

  const onSelectedCustomSourceChange = (selectedCustomSource: CustomSource) => {
    setItemType(selectedCustomSource.value as EmailType)
  }

  return <AssetPickerModal {...props} {...emailPickerProps} onSelectedCustomSourceChange={onSelectedCustomSourceChange} />
}

export default EmailPickerModal
