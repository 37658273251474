import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import { rootContext, useTranslation } from '@const/globals'
import LastUpdated from '@src/pages/programs/dashboard/components/LastUpdated/LastUpdated'

import './ProgramStepsHeader.css'

interface Props {
  onRefresh(): void
  lastUpdated: number
  programId: string
  showViewProgram?: boolean
  className?: string
  dataTest?: string
}

const rootClass = 'program-steps-header'

const ProgramStepsHeader: FC<Props> = (props: Props) => {
  const { onRefresh, programId, lastUpdated, showViewProgram = true, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  return (
    <div
      className={classNames(rootClass, className, {
        [`${rootClass}__button-hidden`]: !showViewProgram,
      })}
      data-test={dataTest}
    >
      <LastUpdated lastUpdated={lastUpdated} onRefresh={onRefresh} />
      {showViewProgram && (
        <Button
          buttonType={ButtonType.FLOAT}
          to={`${rootContext}/programs/${programId}/edit`}
          isLink
          className={`${rootClass}__button`}
          dataTest={`${rootClass}-view-program`}
        >
          <Svg name={SvgNames.viewProgram} type={SvgType.MEDIUM_LARGE_ICON} />
          {t('View program')}
        </Button>
      )}
    </div>
  )
}

export default ProgramStepsHeader
