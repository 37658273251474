import { SvgNames } from '@components/Svg'
import { RowAction } from '@components/Table/Table'
import { RSS_PATTERN } from '@src/pages/EmailComposer/components/PlainTextComposer/PlainTextComposer.utils'
import { detectEmailType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { DynamicContentListItem } from '@utils/composer/context/EmailComposer.context'
import { ExtendedMessageType } from '@utils/composer/emailComposer/types'
import { isHtmlEmpty } from '@utils/isHtmlEmpty'
import { ensureFirstLetterIsCapitalized } from '@utils/strings'
import { download } from '@utils/utils'

import { ValidationSectionStatus } from '../common/ValidationsList/ValidationsList'

export const MAX_BYTE_SIZE = 102000
export const MAX_ACCOUNT_BYTE_SIZE = 8192000
export const MAX_COMPOSER_BYTE_SIZE = 2000000
export const WARNING_PERCENTAGE = 90
export const ERROR_PERCENTAGE = 100

export const LITMUS_TESTING_URL = 'https://connect.act-on.com/hc/en-us/articles/360023943713-Testing-an-Email-Message-with-Litmus '
export const LITMUS_COUNT_URL =
  'https://help.litmus.com/article/73-how-email-previews-are-counted#:~:text=Choose%20the%20Email%20Previews%20tab,in%20the%20Previews%20%26%20QA%20tab.'
export const LITMUS_CLIENTS_URL = 'https://litmus.com/settings/clients'

export enum ComposerPreviewActions {
  LIVE_PREVIEW = 'Live preview',
  DOWNLOAD = 'Download',
  TEST = 'TEST',
  VIEW_HTML_CODE = 'View HTML code',
  EDIT_PLAIN_TEXT = 'Edit plain text',
  PRINT = 'Print',
}

export enum LitmusPreviewGroups {
  DESKTOP = 'Desktop',
  MOBILE_TABLET = 'Mobile/Tablet',
  WEB_BASED = 'Web-based',
}

export const actions = (
  onClick: (action: ComposerPreviewActions) => void,
  getActionProps: (action: ComposerPreviewActions) => Partial<RowAction> | undefined,
  messageType: ExtendedMessageType,
  plainTextMode: boolean,
  uploadHtmlMode: boolean,
  isLandingPage?: boolean
): RowAction[] => {
  const { isEmailResend, isOptInEmail } = detectEmailType(messageType)

  return [
    ...(plainTextMode || isOptInEmail
      ? []
      : [
          {
            label: 'EmailComposer.Preview.Plain.Text.Edit.Button',
            icon: SvgNames.editClassic,
            hasTooltip: !isEmailResend,
            disabledType: 'resend',
            inDropdown: true,
            onClick: () => onClick(ComposerPreviewActions.EDIT_PLAIN_TEXT),
            ...getActionProps(ComposerPreviewActions.EDIT_PLAIN_TEXT),
            disabled: isEmailResend,
          },
        ]),
    {
      label: 'Download',
      icon: SvgNames.download,
      hasTooltip: true,
      hidden: isLandingPage,
      onClick: () => onClick(ComposerPreviewActions.DOWNLOAD),
    },
    {
      label: 'Send test email',
      icon: SvgNames.test,
      hasTooltip: true,
      disabledType: 'resend',
      hidden: isLandingPage,
      onClick: () => onClick(ComposerPreviewActions.TEST),
    },
    ...(plainTextMode || uploadHtmlMode
      ? []
      : [
          {
            label: 'View HTML code',
            icon: SvgNames.codeIcon,
            hasTooltip: false,
            inDropdown: true,
            hidden: isLandingPage,
            onClick: () => onClick(ComposerPreviewActions.VIEW_HTML_CODE),
          },
        ]),
    // NOTE: Temporarily hidden per AO-90325
    // {
    //   label: 'Duplicate email',
    //   icon: SvgNames.cloneSegment,
    //   hasTooltip: false,
    //   inDropdown: true,
    //   topSection: true,
    //   onClick: () => onClick(ComposerPreviewActions.DUPLICATE),
    // },
    {
      label: 'Print',
      icon: SvgNames.print,
      hasTooltip: false,
      inDropdown: true,
      hidden: isLandingPage,
      onClick: () => onClick(ComposerPreviewActions.PRINT),
    },
  ] as RowAction[]
}

export const previewDropDownActions = [ComposerPreviewActions.DOWNLOAD]

export const downloadHtml = (html: string): void => {
  const convertedHtml = html.replace(/<style>body\s*{\s*pointer-events:\s*none;\s*}<\/style>/g, '')
  download(`data:text/plain;charset=utf-8,${encodeURIComponent(convertedHtml)}`, 'index.html')
}

export const processIframePreviewHtml = (html: string): string => {
  const darkModeScript = '<script src="https://app-rsrc.getbee.io/public/resources/preview/dark-mode/dark-mode.min.js"></script>'
  const blockLinkClicks = '<style>body { pointer-events: none; }</style>'
  return html.replace('<!-- End -->', `<!-- End -->${darkModeScript}${blockLinkClicks}`)
}

export const getStatusIcon = (status: ValidationSectionStatus): SvgNames => {
  switch (status) {
    case ValidationSectionStatus.SUCCESS:
      return SvgNames.spamCheckSuccess
    case ValidationSectionStatus.WARNING:
      return SvgNames.spamCheckWarning
    case ValidationSectionStatus.ERROR:
      return SvgNames.spamCheckFail
    default:
      return SvgNames.spamCheckRest
  }
}

export const checkIsContentMissing = (html: string): boolean => {
  const tempDivElement = document.createElement('div')
  tempDivElement.innerHTML = html
  return isHtmlEmpty(tempDivElement)
}

export const findClickthroughLinks = (html: string): ({ type: string; label: string | HTMLAnchorElement | null; url: string | null } | null)[] => {
  const tempElement = document.createElement('div')
  tempElement.innerHTML = html

  const blocks = tempElement.querySelectorAll('[class*= block]')
  const clickthroughLinks: { type: string; label: string | HTMLAnchorElement | null; url: string | null }[] = []

  const processAnchorTag = (anchorTag: HTMLAnchorElement, type: string) => {
    const isButton = type === 'Button'
    const isImage = type === 'Image'

    const label = anchorTag.querySelector('img')
      ? `<img style="${isImage ? 'max-height:72px;' : 'width:36px;height:36px;'}" src="${anchorTag?.querySelector('img')?.src}"/>`
      : isButton
      ? anchorTag
      : anchorTag.textContent

    const url = anchorTag.getAttribute('href')

    if (url && !url.match('mailto:') && !url.match('cloudfront.net/public/users/') && !url.match('^javascript:')) {
      clickthroughLinks.push({
        type,
        label,
        url,
      })
    }
  }

  for (const block of blocks) {
    const anchorTags = block.getElementsByTagName('a')
    const type = ensureFirstLetterIsCapitalized(block.className.split('_')[0]) || ''

    for (const anchorTag of anchorTags) {
      processAnchorTag(anchorTag, type)
    }
  }

  const allAnchorTags = tempElement.getElementsByTagName('a')

  for (const anchorTag of allAnchorTags) {
    if (!clickthroughLinks.some((link) => link.url === anchorTag.getAttribute('href'))) {
      processAnchorTag(anchorTag, 'General')
    }
  }

  return clickthroughLinks
}

export const findClickthroughLinksText = (text: string): ({ type: string; label: string | null; url: string | null } | null)[] => {
  const re = /[^h]?https?:\/\/\S+/gi
  const linkText = text.match(re) || []
  const clickthroughLinksText: { type: string; label: string | null; url: string | null }[] = []

  linkText.forEach(function (url) {
    if (url.toLocaleLowerCase().indexOf('http') === 1) {
      const stripLastChar = url.charAt(0).match(/['"<{(\[]/) && url.charAt(url.length - 1).match(/['">})\]]/)
      url = url.substring(1, stripLastChar ? url.length - 1 : url.length)
    }

    if (!url.match('^mailto:') && !url.match('cloudfront.net/public/users/')) {
      clickthroughLinksText.push({
        type: 'Plain text',
        label: '',
        url,
      })
    }
  })

  return clickthroughLinksText
}

export const findProhibitedDataURIs = (html?: string) => {
  const regex = /data:[^;]+;base64,\s*([A-Za-z0-9+/=\s]+)/g
  const matches = html?.match(regex)?.map((match) => match.replace(/[\s\n]/g, ''))

  return matches || []
}

export const bytesToSize = (bytes: number, decimals = 2) => {
  if (!Number(bytes)) {
    return '0B'
  }

  const kbToBytes = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const index = Math.floor(Math.log(bytes) / Math.log(kbToBytes))

  return `${parseFloat((bytes / Math.pow(kbToBytes, index)).toFixed(dm))} ${sizes[index]}`
}

export const printHtml = (html: string) => {
  const iframe = document.createElement('iframe')
  // This allows emailComposerPreview.cy.js to check for the call to print
  Object.assign(window, { printIframe: iframe })
  iframe.style.display = 'none'

  document.body.appendChild(iframe)

  iframe.contentDocument!.open()
  iframe.contentDocument!.write(html)
  iframe.contentDocument!.close()

  iframe.contentWindow!.addEventListener('load', () => {
    iframe.contentWindow!.print()
    setTimeout(() => {
      document.body.removeChild(iframe)
    }, 1000)
  })
}

export const handleDynamicContent = (list: DynamicContentListItem[], html: string) => {
  const tempElement = document.createElement('div')
  tempElement.innerHTML = html

  list.forEach((item) => {
    const row = tempElement.querySelector(`.row-${item.rowIndex}`)
    const style = row?.getAttribute('style')
    row?.setAttribute('style', `${style};display: ${item.isActive ? 'table' : 'none'};`)
  })

  return tempElement.outerHTML
}

export const findRssBlock = (html: string, isPlainText = false) => {
  if (html === undefined) {
    // This should never happen but I observed it happening at least once
    html = ''
  }
  if (isPlainText) {
    return !!html?.match(RSS_PATTERN)
  } else {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = html

    return !!tempElement.querySelector('.rss-block')
  }
}

export const webinarRegistrationMessageStatuses = ['accepted', 'pending', 'declined', 'rejected']

export const webinarMessageCategories = ['invitation', 'reminder', 'followUp', 'lastChance']
