import { gql } from '@apollo/client'

export default gql`
  mutation SaveProgram($program: ProgramInput!) {
    saveProgram(program: $program) {
      status
      valid
      stepErrors {
        errors
        id
        name
        type
      }
      mainErrors
      exitErrors
    }
  }
`
