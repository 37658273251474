import React, { FC, ReactNode, useEffect, useState } from 'react'

import { ListingPageItem } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { Column } from '@components/ColumnsOrderModal/components/DraggableColumn/DraggableColumn'
import { MenuItem } from '@components/DropDownActions/DropDownActions'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import { CardsOptions } from '@components/InfoCard/InfoCard'
import { SectionType } from '@components/ItemActivity/ItemActivity'
import Loader from '@components/Loader'
import {
  SegmentDetailsContainerInitialState,
  SegmentDetailsContainerState,
  SegmentDetailsContext,
} from '@components/SegmentDetails/context/SegmentDetails.context'
import SegmentDetails from '@components/SegmentDetails/SegmentDetails'
import { useSegmentDetailsQueries } from '@components/SegmentDetails/SegmentDetails.graphQL'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import StatusToast from '@components/StatusToast/StatusToast'
import { HeaderAction, RowAction } from '@components/Table/Table'
import { UnifiedListFieldMapping } from '@graphql/types/microservice/list-types'
import { PageInput } from '@graphql/types/microservice/segment-types'
import { AccountSettings } from '@graphql/types/query-types'
import { Row } from '@tanstack/react-table'
import { ItemType } from '@utils/categorization'
import { rootContext, useTranslation } from '@utils/const/globals'
import { Contact } from '@utils/contact/contact.constants'
import { buildSegment, Segment } from '@utils/contactSegments/contactSegments.utils'
import { FileUplaodModalType } from '@utils/formUtils'

export interface FilesData {
  id: number
  name: string
  fullFileName?: string
}

interface SegmentDetailsContainerProps {
  canExport?: boolean
  canSendMessages?: boolean
  customTabsTitles?: { contacts?: string; details?: string }
  customContactsTableHeaderActions?: (selectedContacts: Contact[], unifiedListFieldMappings: UnifiedListFieldMapping[]) => HeaderAction[]
  detailsActivityCustomSections?: SectionType[]
  customContactsTableRowActions?: (
    unifiedListFieldMappings: UnifiedListFieldMapping[],
    contactHeaders?: Column[],
    newFormFileUploadBlock?: boolean,
    segmentContacts?: PageInput,
    userAllowedToDownload?: boolean
  ) => RowAction[]
  contactsTableLabelSuffix?: string
  customPerformanceCards?: CardsOptions
  customDropdownItems?: (
    segment: Segment,
    update: (values: Partial<SegmentDetailsContainerState>) => void,
    itemType: ItemType,
    accountSettings: AccountSettings
  ) => MenuItem[]
  onContactRowClicked?: (row: Row<Contact>, uuid: string) => void
  renderCustomModal?: (customAction: any, segment: ListingPageItem, update: (values: Partial<SegmentDetailsContainerState>) => void) => ReactNode
  pageHeader?: string
  previousCount?: number
  toggleDownloadModal?: (type: FileUplaodModalType, contact: string[], headerText?: string) => void
  handleDownloadOrPreviewFile?: (fileIds: number[], type?: FileUplaodModalType, initialAttachmentsData?: Omit<FilesData, 'url' | 'date'>[]) => void
}

const SegmentDetailsContainer: FC<SegmentDetailsContainerProps> = (props: SegmentDetailsContainerProps) => {
  const {
    canExport,
    canSendMessages,
    customTabsTitles,
    customDropdownItems,
    contactsTableLabelSuffix,
    customContactsTableHeaderActions,
    customContactsTableRowActions,
    customPerformanceCards,
    detailsActivityCustomSections,
    onContactRowClicked,
    renderCustomModal,
    pageHeader,
    previousCount,
    toggleDownloadModal,
    handleDownloadOrPreviewFile,
  } = props
  const [containerValues, setContainerValues] = useState<SegmentDetailsContainerState>(SegmentDetailsContainerInitialState)
  const {
    statusToast: { showStatusToast, status, statusMessage },
    segment,
    itemType,
    backUrl,
    segmentContacts,
    showCustomModal,
    customAction,
  } = containerValues

  const { getItemDetailsRequest } = useSegmentDetailsQueries()

  const [segmentNotFound, setSegmentNotFound] = useState<Boolean>(false)

  const { t } = useTranslation()

  const update = (values: Partial<SegmentDetailsContainerState>) => {
    setContainerValues((containerValues) => ({ ...containerValues, ...values }))
  }

  const closeStatusToast = () => {
    setContainerValues((containerValues) => ({ ...containerValues, statusToast: { ...containerValues.statusToast, showStatusToast: false } }))
  }

  useEffect(() => {
    const search = new URLSearchParams(window.location.search)
    const id = search.get('id') || undefined
    const externalId = search.get('externalId') || undefined
    const type = search.get('type') as ItemType
    const backUrl = search.get('backTo')
    if (backUrl) {
      update({ backUrl: backUrl })
    }

    if ((id || externalId) && type) {
      getItemDetailsRequest(type as ItemType, id, externalId).then(({ data }) => {
        if (data?.getItem) {
          update({ segment: buildSegment(data?.getItem), itemType: type })
        } else {
          setSegmentNotFound(true)
        }
      })
    }
  }, [])

  return (
    <SegmentDetailsContext.Provider value={{ values: containerValues, update }}>
      {showStatusToast && <StatusToast status={status} message={statusMessage} closeStatus={closeStatusToast} />}
      {segment && showCustomModal && renderCustomModal && renderCustomModal(customAction, segment, update)}
      {!segment && !itemType && <Loader />}
      {segmentNotFound && (
        <EmptyListing
          headline={t(`We couldn't find any results to show you.`)}
          text={t(`Sorry, there's nothing to see here`)}
          imgSrc={StaticImageNames.errorNothingFound}
          buttonText={t('View forms')}
          buttonURL={`${rootContext}/content/forms`}
          size={EmptyListingSize.LARGE}
        />
      )}
      {segment && itemType && (
        <SegmentDetails
          segmentContacts={segmentContacts}
          activityCustomSections={detailsActivityCustomSections}
          update={update}
          segment={segment}
          itemType={itemType}
          customDropdownItems={customDropdownItems}
          customContactsTableRowActions={customContactsTableRowActions}
          customContactsTableHeaderActions={customContactsTableHeaderActions}
          returnLink={backUrl}
          canExport={canExport}
          canSendMessages={canSendMessages}
          contactsTableLabelSuffix={contactsTableLabelSuffix}
          customTabsTitles={customTabsTitles}
          customPerformanceCards={customPerformanceCards}
          onItemFolderChange={(moveToFolderId) => update({ segment: { ...segment, folderId: moveToFolderId } })}
          onApplyAndRemoveTagsSuccess={(updatedTags) => update({ segment: { ...segment, tags: updatedTags } })}
          onContactRowClicked={(row, uuid) => onContactRowClicked && onContactRowClicked(row, uuid)}
          pageHeader={pageHeader}
          previousCount={previousCount}
          toggleDownloadModal={toggleDownloadModal}
          handleDownloadOrPreviewFile={handleDownloadOrPreviewFile}
        />
      )}
    </SegmentDetailsContext.Provider>
  )
}

export default SegmentDetailsContainer
