import React, { ReactNode } from 'react'

import classNames from 'classnames'

import { AssetPickerInfoType } from '@complex/AssetPickerModal/Components/Info/AssetPickerInfoContainer'
import {
  AssetPickerTableType,
  renderDefaultLocationColumn,
  renderDefaultNameColumn,
  renderDefaultRelativeDateColumn,
  renderDefaultTagsColumn,
} from '@complex/AssetPickerModal/Components/Table/AssetPickerTable.utils'
import { CustomSourceItems, ItemDtoRow } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { renderBoldTextOnMatch } from '@components/FolderSearch/FolderSearch.utils'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { ItemDto } from '@graphql/types/microservice/categorization-types'
import { Program } from '@graphql/types/query-types'
import { Row } from '@tanstack/react-table'
import { allProgramsFilter, FilterDefinition, FilterTypes } from '@utils/filter'
import { getCurrentProgramStatus } from '@utils/program/program'
import { ProgramState } from '@utils/program/program.constants'
import { titleCase } from '@utils/strings'

import { ColumnDefWithAdditionalProps } from '../../TableV2/tableV2TS/types'
import Tooltip from '../../Tooltip/Tooltip'

const rootClass = 'automated-program-picker-modal'

export const getInfoBanner = (items: CustomSourceItems, _filterActive: FilterDefinition, t: Function) => ({
  [FilterTypes.SUGGESTED_AUTOMATED_PROGRAMS]: {
    values: {
      title: t('AssetPicker.AutomatedPrograms.Suggested.Title', { count: items['Automated Programs']?.length }),
      type: AssetPickerInfoType.DEFAULT,
      showBorderBottom: true,
      hideOnSearch: true,
    },
  },
})

const renderProgramState = (programState: ProgramState) => {
  const trigger =
    programState === ProgramState.ERROR ? (
      <Svg name={SvgNames.error} type={SvgType.SMALLER_ICON} className={classNames(`${rootClass}__program-state-${programState}`)} />
    ) : (
      <div className={classNames(`${rootClass}__program-state`, `${rootClass}__program-state-${programState}`)}>&nbsp;</div>
    )
  return <Tooltip trigger={trigger}>{titleCase(programState === ProgramState.PAUSED ? 'paused' : programState)}</Tooltip>
}

const renderItemNameVal = (
  original: ItemDto,
  search: string,
  rootClass: string,
  disabled?: boolean,
  disabledRowTooltip?: (asset: Program) => string | ReactNode
) => {
  if (!original) {
    return ''
  }

  const asset = original as Program
  const programState = getCurrentProgramStatus(asset)
  const disabledTooltip = disabledRowTooltip ? disabledRowTooltip(asset) : ''

  return (
    <div className={classNames(`${rootClass}__program-name`, { [`${rootClass}__program-name-disabled`]: !!disabledTooltip }, 'ellip')}>
      {renderProgramState(programState)}
      <Tooltip
        ellipOnTrigger
        withoutTail={!!disabledTooltip}
        trigger={
          <div className={classNames(`${rootClass}__program-name-typography`, { [`${rootClass}__program-name-typography-disabled`]: disabled })}>
            <div className={'ellip'}>{search ? renderBoldTextOnMatch(asset.name, search) : asset.name}</div>
          </div>
        }
      >
        {disabled && disabledTooltip ? disabledTooltip : asset.name}
      </Tooltip>
    </div>
  )
}

export const renderTableColumns = (
  t: Function,
  disableRowByCriteria?: (row: Row<any>) => boolean,
  disabledRowTooltip?: (program: Program) => string | ReactNode
): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  ...[
    renderDefaultNameColumn(
      t('Program title'),
      '',
      rootClass,
      (original: ItemDto, search: string, rootClass: string, disabled?: boolean) =>
        renderItemNameVal(original, search, rootClass, disabled, disabledRowTooltip),
      disableRowByCriteria
    ),
  ],
  ...[renderDefaultTagsColumn(AssetPickerTableType.DEFAULT, t('Tags'))],
  ...[renderDefaultRelativeDateColumn(AssetPickerTableType.DEFAULT, t('Last Edited'), 'lastUpdated')],
]

export const renderSearchTableColumns = (
  search: string,
  folders: FolderData[],
  t: Function,
  disableRowByCriteria?: (row: Row<any>) => boolean,
  disabledRowTooltip?: (program: Program) => string | ReactNode
): ColumnDefWithAdditionalProps<ItemDtoRow, any>[] => [
  ...[
    renderDefaultNameColumn(
      t('Program title'),
      search,
      rootClass,
      (original: ItemDto, search: string, rootClass: string, disabled?: boolean) =>
        renderItemNameVal(original, search, rootClass, disabled, disabledRowTooltip),
      disableRowByCriteria
    ),
  ],
  ...[renderDefaultLocationColumn(AssetPickerTableType.SEARCH, t('Location'), folders, t(allProgramsFilter.name))],
  ...[renderDefaultTagsColumn(AssetPickerTableType.SEARCH, t('Tags'))],
  ...[renderDefaultRelativeDateColumn(AssetPickerTableType.SEARCH, t('Last Edited'), 'lastUpdated')],
]
