import { gql } from '@apollo/client'

export default gql`
  query marketingNetworkManagers($parentAccountId: Long!, $accountId: Long!, $userId: Long!) {
    marketingNetworkManagers(parentAccountId: $parentAccountId, accountId: $accountId, userId: $userId) {
      managers {
        manager
        name
        userId
      }
    }
  }
`
