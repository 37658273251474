import React, { Children, ReactNode } from 'react'

import classNames from 'classnames'

import HoverSVG from '@components/HoverSVG/HoverSVG'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { SvgColor, SvgProps } from '@components/Svg/Svg'
import Tooltip, { TooltipProps } from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './labelV2.css'

export enum LabelType {
  input = 'input',
  large = 'large',
  standard = 'standard',
  title = 'title',
  section = 'section',
  medium = 'medium',
  small = 'small',
}

interface TypographyMapping {
  type?: TextType
  weight?: TextWeight
  lineHeight?: LineHeight
}

const typographyMapping: { [k in LabelType]: TypographyMapping } = {
  [LabelType.input]: {
    type: undefined,
    weight: TextWeight.MEDIUM,
    lineHeight: LineHeight.MEDIUM,
  },
  [LabelType.small]: {
    type: TextType.BODY_TEXT_SMALL,
    weight: TextWeight.MEDIUM,
    lineHeight: LineHeight.MEDIUM_SMALL,
  },
  [LabelType.medium]: {
    type: undefined,
    weight: TextWeight.MEDIUM,
    lineHeight: LineHeight.MEDIUM,
  },
  [LabelType.section]: {
    type: TextType.BODY_TEXT_LARGE,
    weight: TextWeight.MEDIUM,
    lineHeight: LineHeight.MEDIUM_LARGE,
  },
  [LabelType.title]: {
    type: TextType.PAGE_HEADER,
    weight: TextWeight.BOLD,
    lineHeight: LineHeight.LARGER,
  },

  [LabelType.standard]: {
    type: TextType.PAGE_HEADER_SEMI_BOLD,
    weight: TextWeight.MEDIUM,
    lineHeight: LineHeight.LARGE,
  },
  [LabelType.large]: {
    type: TextType.MODAL_HEADLINE_LARGE,
    weight: TextWeight.MEDIUM,
    lineHeight: LineHeight.LARGER,
  },
}

interface TooltipInterface extends Omit<TooltipProps, 'children' | 'trigger'> {
  content?: ReactNode | ReactNode[]
}

export interface LabelProps {
  count?: number
  title?: string
  display?: string
  //* Choose label prop or children as ReactNode
  label?: ReactNode
  dataTest?: string
  required?: boolean
  className?: string
  children?: ReactNode
  htmlFor?: string | void
  tooltip?: TooltipInterface
  iconSvgProps?: SvgProps
  withoutMargin?: boolean
  labelType?: keyof typeof LabelType
}

export const rootClass = 'labelV2'

export const LabelV2 = React.memo(
  ({
    children,
    className,
    htmlFor = '',
    title,
    required,
    labelType = LabelType.small,
    count,
    dataTest = rootClass,
    tooltip,
    label,
    iconSvgProps,
    withoutMargin,
  }: LabelProps) => {
    const { t } = useTranslation()

    const { content, ...tooltipProps } = tooltip ?? {}
    const isChildrenValidElement = children && React.isValidElement(Children.toArray(children)[0])
    const isLabelValidElement = label && React.isValidElement(Children.toArray(label)[0])
    const hasCount = typeof count === 'number'
    const hasGap = !!required || hasCount || !!content
    return (
      <div
        data-test={`${rootClass}-container`}
        className={classNames(className, `${rootClass}__container`, {
          [`${rootClass}__container-with-flex `]: iconSvgProps,
          [`${rootClass}__container-input `]: labelType === LabelType.input,
          [`${rootClass}__container-margin-bottom `]: !withoutMargin,
        })}
      >
        {iconSvgProps && labelType !== LabelType.input && (
          <Svg
            fill={SvgColor.TEXT_GRAY}
            dataTest={`${dataTest}-icon`}
            {...iconSvgProps}
            className={classNames(`${rootClass}__${[LabelType[labelType]]}-icon`, iconSvgProps.className)}
          />
        )}

        <label
          title={title}
          className={classNames(rootClass, className, {
            [`${rootClass}-with-flex`]: hasGap,
            [`${rootClass}__${[LabelType[labelType]]}-has-gap`]: hasGap,
          })}
          htmlFor={htmlFor}
          data-test={`${dataTest}-${[LabelType[labelType]]}`}
        >
          {isChildrenValidElement || isLabelValidElement ? (
            children ?? label
          ) : (
            <Typography
              dataTest={`${dataTest}-content`}
              text={children ?? label}
              type={typographyMapping[labelType]?.type}
              weight={typographyMapping[labelType]?.weight}
              lineHeight={typographyMapping[labelType]?.lineHeight}
            />
          )}

          {hasCount && labelType !== LabelType.input && (
            <Typography
              dataTest={`${dataTest}-count`}
              text={`(${t(count)})`}
              type={typographyMapping[labelType]?.type}
              weight={typographyMapping[labelType]?.weight}
              lineHeight={typographyMapping[labelType]?.lineHeight}
            />
          )}

          {required && (
            <Typography
              dataTest={`${dataTest}-required`}
              text={`(${t('required')})`}
              type={typographyMapping[labelType]?.type}
              weight={TextWeight.MEDIUM_LIGHT}
              lineHeight={typographyMapping[labelType]?.lineHeight}
              className={classNames(`${rootClass}__required`, {
                [`${rootClass}__${[LabelType[labelType]]}-required`]: required,
              })}
            />
          )}

          {!!content && (
            <Tooltip
              {...tooltipProps}
              trigger={
                <HoverSVG
                  className={classNames(`${rootClass}__info-tooltip-svg`, {
                    [`${rootClass}__${[LabelType[labelType]]}-info-tooltip-svg`]: !!tooltip,
                  })}
                  svg={SvgNames.info}
                  hoverSvg={SvgNames.infoHover}
                  fill={SvgColor.LIGHT_GRAY}
                  type={SvgType.LARGER_ICON}
                />
              }
              className={classNames({ [`${className}__tooltip`]: !!className, [`${rootClass}__${[LabelType[labelType]]}-info-tooltip`]: !!tooltip })}
            >
              {content}
            </Tooltip>
          )}
        </label>
      </div>
    )
  }
)
