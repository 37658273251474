import React from 'react'

import classNames from 'classnames'

import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import InfoAction from '@components/InfoAction/InfoAction'
import PageHeader from '@components/PageHeader'
import Search, { SearchType } from '@components/Search/Search'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { MORE_INFO } from '@src/pages/Settings/OtherSettings/FromAddresses/utils/FromAddresses.constants'

interface Props {
  rootClass: string
  showActions: boolean
  onAdd: (state: boolean) => void
  onSearch: (state: string) => void
  searchTerm: string
  showInfoAction: boolean
  isAdmin: boolean
}

const FromAddressHeader = ({ rootClass, showActions, onAdd, onSearch, searchTerm, showInfoAction, isAdmin }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <PageHeader dataTest={`${rootClass}__page-header`} className={`${rootClass}__title`} primaryText={t('From Addresses')} leftContent />
      <div className={`${rootClass}__sub-header-container`}>
        <Typography
          text={t(
            'Use from addresses to customize the sender that your email recipients see. By default, you can send messages from your own email. To send messages from an alias, add the email address here and click through the confirmation message to verify.'
          )}
          type={TextType.BODY_TEXT_LIGHT}
          dataTest={`${rootClass}__sub-header-description`}
        />
      </div>
      {showInfoAction && (
        <InfoAction
          svgName={SvgNames.lightBulb}
          message={
            <div>
              <Typography
                text={t(
                  'In addition to verifying the email, every domain you send from must be configured with SPF and DKIM, or messages may not be delivered. Learn more about setting up Email From domains.'
                )}
                inline
              />
              &nbsp;
              <TextLink text={t('More info')} link={MORE_INFO} size={TextLinkSize.LARGE} />
            </div>
          }
          className={`${rootClass}__info-action`}
          dataTest={rootClass}
        />
      )}
      {showActions && (
        <div className={classNames(`${rootClass}__actions`, { [`${rootClass}__actions-search-end`]: !isAdmin })}>
          {isAdmin && (
            <Button
              buttonType={ButtonType.PRIMARY}
              iconPosition={ButtonIconPosition.LEFT}
              dataTest={`${rootClass}-button__add`}
              onClick={() => onAdd(true)}
            >
              <Svg name={SvgNames.plus} type={SvgType.LARGER_ICON} />
              {t('Add sender')}
            </Button>
          )}
          <Search
            className={`${rootClass}__actions-search`}
            dataTest={`${rootClass}-search-input`}
            incomingValue={searchTerm}
            onChangeHandler={onSearch}
            placeholder={t('Search addresses')}
            searchType={SearchType.LARGE}
            canClear
            clearOnChange={[searchTerm]}
          />
        </div>
      )}
    </>
  )
}

export default FromAddressHeader
