import { gql } from '@apollo/client'

export default gql`
  mutation refreshRecordCount($isUCLList: Boolean, $segmentIds: [String]) {
    refreshRecordCount(isUCLList: $isUCLList, segmentIds: $segmentIds) {
      body
      message
      objectId
      statusCode
    }
  }
`
