import React from 'react'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { TFunction } from 'i18next'
import { renderToString } from 'react-dom/server'

import Bee from '@beefree.io/sdk'
import { IPluginColumn, IPluginRow, RowLayoutType } from '@beefree.io/sdk/dist/types/bee'
import { YesNo } from '@components/ConfirmationModal'
import { Status } from '@components/StatusToast/StatusToast'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { WebinarConfig } from '@graphql/types/query-types'
import { AddOnModal, RowLayoutValues } from '@src/pages/EmailComposer/utils/BeeEditor.types'
import { detectWebinarType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { IEntityContentJsonExtended, IPluginModuleWithInternalModule, IPluginRowExtended } from '@utils/composer/beeEditor/beeEditorTypes'
import { CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { EmailComposerAPI } from '@utils/composer/context/EmailComposer.context'

import { getWebinarDefaultValues, ManageWebinarDetailsModalToggleState } from '../../../utils/ManageWebinarDetailsModal.utils'
import { WebinarBlock, WebinarCustomFields } from '../WebinarBlock'

dayjs.extend(utc)
dayjs.extend(timezone)

const borderStyle = '2px solid #1F62E4'
const colorStyle = '#1F62E4'
const whiteColor = '#FFF'

type WebinarBlockConfigReturnType = Omit<ManageWebinarDetailsModalToggleState, 'showJoinLink'>

interface ShowWebinarButtonBasedOnType {
  checkForRegistrationButton: boolean
  checkForJoinButton: boolean
  hasRegistration: boolean
  hasForm: boolean
  webinarConfig?: WebinarConfig
}

interface HandleRemovedWebinarModal {
  t: TFunction
  blockId: string
  accountId: string
  messageId: string
  parentRowUUID?: string
  webinarConfig?: WebinarConfig
  hasDeletedGridItem?: boolean
  isRemovedEntireRow?: boolean
  parentRowInitialState?: IPluginRowExtended
  columnUUID?: string
  webinarName: string
  beeEditorRef: React.MutableRefObject<Bee | undefined>
  templateJson: IEntityContentJsonExtended<WebinarCustomFields>
  update: EmailComposerAPI['update']
  onTabChange: EmailComposerAPI['onTabChange']
  updateModal: EmailComposerAPI['updateModal']
}

interface PathsForCustomBlock {
  blockId: string
  parentRowUUID: string
  columnUUID: string
  path: string[]
}

const rowLayoutTypeValues: RowLayoutValues[] = Object.values(RowLayoutType)

export const getTimezoneAbbreviation = (startDate: string, timeZone: string) => {
  const abbr = dayjs(startDate).tz(timeZone).format('z')
  return abbr
}

export const convertTo12HourFormat = (time: string) => {
  return dayjs(time, 'HH:mm A').format('hh:mm A')
}

export const useWebinarBlockConfig = (customFields: WebinarCustomFields, webinarConfig?: WebinarConfig): WebinarBlockConfigReturnType | undefined => {
  const {
    hasForm,
    hasRegistration,
    calendarDownloadLink,
    joinButtonText,
    passcode,
    registrationButtonText,
    webinarId,
    phoneDetails,
    toggleState,
    registrationLink,
  } = customFields
  const { isWebex, isZoom, isGoTo, isAccepted, isInvitation, isMultiSession, isSingleSession, isReminder } = detectWebinarType(webinarConfig)

  const multiSessionTypesForJoinButton = isReminder || isAccepted

  const {
    showCalendarBlock = !!calendarDownloadLink,
    showJoinButton = (isWebex && isMultiSession) || (!hasRegistration && !!joinButtonText),
    showPasscode = !!passcode,
    showRegistrationButton = hasRegistration && hasForm && !!(registrationButtonText && registrationLink),
    showWebinarId = !!webinarId,
    showPhoneNumbers = hasRegistration ? false : !!phoneDetails,
  } = toggleState ?? {}

  const configFields: WebinarBlockConfigReturnType = {
    showCalendarBlock,
    showJoinButton,
    showPasscode,
    showRegistrationButton,
    showWebinarId,
    showPhoneNumbers,
  }

  let showJoinButtonMulti
  let registrationButtonMulti
  if (isMultiSession) {
    if (isWebex || isGoTo) {
      if (multiSessionTypesForJoinButton) {
        if (!configFields['showJoinButton']) {
          showJoinButtonMulti = false
        } else {
          showJoinButtonMulti = configFields['showJoinButton']
        }
      }

      if (multiSessionTypesForJoinButton) {
        registrationButtonMulti = false
      } else {
        registrationButtonMulti = configFields['showRegistrationButton']
      }

      if (isInvitation) {
        if (!hasForm && hasRegistration) {
          if (isGoTo) {
            showJoinButtonMulti = false
            registrationButtonMulti = configFields['showRegistrationButton'] ?? !!(registrationButtonText || registrationLink)
          } else {
            registrationButtonMulti = false
            showJoinButtonMulti = configFields['showJoinButton'] ?? !!joinButtonText
          }
        } else if (hasForm && hasRegistration) {
          showJoinButtonMulti = false
          registrationButtonMulti = configFields['showRegistrationButton']
        }
      }
    }
  } else if ((isWebex || isZoom) && isSingleSession) {
    if (multiSessionTypesForJoinButton) {
      configFields['showRegistrationButton'] = false
      if (!configFields['showJoinButton']) {
        showJoinButtonMulti = false
      } else {
        configFields['showJoinButton'] = showJoinButton
      }
    }
  }

  const config: Record<string, WebinarBlockConfigReturnType> = {
    zoom: configFields,
    webex: {
      ...configFields,
      ...(isMultiSession && { showJoinButton: showJoinButtonMulti, showRegistrationButton: registrationButtonMulti }),
    },
    goto: { ...configFields, showJoinButton: showJoinButtonMulti, showRegistrationButton: registrationButtonMulti },
  }

  return webinarConfig?.name ? config[webinarConfig?.name] : undefined
}

const deleteProp = (rows: IPluginModuleWithInternalModule<WebinarCustomFields>[], blockId: string) => {
  //! Important. The additional prop needs to be taken out of the row. BEE is experiencing a JSON problem.
  const deletedRow = rows.find((row) => row.descriptor.html?.customFields?.blockId === blockId)
  delete deletedRow?.parentRowInitialState
}

const handleWebinarCases = (uid?: string) => {
  const hasUid = [AddOnModal.WEBINAR_BLOCK, AddOnModal.HEADER_BLOCK, AddOnModal.FOOTER_BLOCK].includes(uid as AddOnModal)
  return hasUid
}

export const handleRemovedWebinarModal = ({
  t,
  blockId,
  accountId,
  messageId,
  columnUUID,
  webinarName,
  beeEditorRef,
  templateJson,
  parentRowUUID,
  webinarConfig,
  hasDeletedGridItem,
  parentRowInitialState,
  isRemovedEntireRow,
  update,
  onTabChange,
  updateModal,
}: HandleRemovedWebinarModal) => {
  updateModal('confirmation', {
    title: t('EmailComposer.RestoreWebinar.title'),
    body: (
      <Typography
        text={t('EmailComposer.RestoreWebinar.bodyText', { webinarName })}
        type={TextType.BODY_TEXT_LIGHT}
        tagProps={{ medium: { weight: TextWeight.MEDIUM, inline: true } }}
      />
    ),
    yesButtonText: t('EmailComposer.RestoreWebinar.confirmationText'),
    noButtonText: t('Cancel'),
    isYesNo: true,
    onAnswer: (answer) => {
      if (answer === YesNo.YES) {
        // This will not function if one of the grid items is deleted because it will be absent in page rows.
        // This will find col when there is a grid row and webinar has been deleted form grid
        const detectedGridCol: IPluginColumn | undefined = !isRemovedEntireRow
          ? templateJson.page.rows.find((row) => row.uuid == parentRowUUID)?.columns.find((col) => col.uuid == columnUUID)
          : undefined

        const filteredDeletedRows =
          templateJson.page.deletedRows?.filter((row) => {
            const removedParentRowWebinarFields = parentRowInitialState?.columns?.[0]?.modules
            const removedParentRowWebinarHtml = (
              removedParentRowWebinarFields?.find(
                (module: IPluginModuleWithInternalModule<WebinarCustomFields>) => module?.descriptor?.html?.customFields?.blockId === blockId
              ) as IPluginModuleWithInternalModule<WebinarCustomFields>
            )?.descriptor.html

            const customFields = isRemovedEntireRow ? removedParentRowWebinarHtml?.customFields : row.descriptor?.html?.customFields
            const foundedCustomField = customFields?.blockId == blockId

            if (foundedCustomField && customFields) {
              if (row.descriptor.html?.customFields?.blockId === customFields.blockId) {
                row.descriptor.html.html = renderToString(
                  <WebinarBlock
                    messageId={messageId}
                    accountId={accountId}
                    customFields={customFields?.defaultWebinarInfo}
                    webinarConfig={webinarConfig}
                  />
                )
                // We need to restore always with default values
                Object.assign(customFields, {
                  ...customFields,
                  ...customFields.defaultWebinarInfo,
                  toggleState: undefined,
                })

                if (handleWebinarCases(parentRowInitialState?.rowInternal?.uid) && parentRowInitialState?.uuid === parentRowUUID) {
                  const replacedHeaderOrFooter = (removedParentRowWebinarFields as IPluginModuleWithInternalModule[])?.[0]?.descriptor?.html
                  if (replacedHeaderOrFooter) {
                    replacedHeaderOrFooter.html = row.descriptor.html.html
                    replacedHeaderOrFooter.customFields = customFields
                  }
                }
              }
            }

            return foundedCustomField
          }) ?? []

        if (detectedGridCol) {
          detectedGridCol.modules = [...filteredDeletedRows, ...(detectedGridCol?.modules ?? [])]
          deleteProp(filteredDeletedRows, blockId)
        }

        if (!detectedGridCol && parentRowInitialState && parentRowInitialState.uuid === parentRowUUID) {
          parentRowInitialState?.columns.forEach((col) => {
            switch (true) {
              case isRemovedEntireRow || handleWebinarCases(parentRowInitialState?.rowInternal?.uid):
                col.modules = col.modules.filter(
                  (module: IPluginModuleWithInternalModule<WebinarCustomFields>) => module?.descriptor?.html?.customFields?.blockId === blockId
                )
                break

              case rowLayoutTypeValues.includes(parentRowInitialState.type):
                if (col.uuid === columnUUID) {
                  col.modules = []
                }
                break

              default:
                col.modules = []
                break
            }
          })

          const mutatedParentState = parentRowInitialState?.columns.find((col) => col.uuid == columnUUID)
          if (mutatedParentState) {
            if (!isRemovedEntireRow) {
              mutatedParentState.modules = filteredDeletedRows
            }
          }

          deleteProp(filteredDeletedRows, blockId)

          const rows = templateJson.page.rows
          const lastItemOfRows = rows.length - 1
          const hasHeaderBlock = rows[0].rowInternal?.uid === AddOnModal.HEADER_BLOCK
          const isRestoringHeaderBlockWithWebinar = parentRowInitialState.rowInternal?.uid === AddOnModal.HEADER_BLOCK
          const hasFooterBlock = rows[lastItemOfRows].rowInternal?.uid === AddOnModal.FOOTER_BLOCK
          const isRestoringFooterBlockWithWebinar = parentRowInitialState.rowInternal?.uid === AddOnModal.FOOTER_BLOCK

          const foundedDeletedParentRow = rows.find((row) => row.uuid === parentRowUUID)?.columns.find((col) => col.uuid == columnUUID)

          switch (true) {
            case !!(isRemovedEntireRow && foundedDeletedParentRow): {
              foundedDeletedParentRow?.modules.splice(0, 0, mutatedParentState!.modules[0])
              break
            }

            case hasDeletedGridItem: {
              const foundedCol = parentRowInitialState.columns.find((col) => col.uuid === columnUUID) as IPluginColumn
              const foundedColIdx = rows.findIndex((row) => row.uuid === parentRowInitialState?.uuid)
              if (foundedCol) {
                foundedCol.modules = filteredDeletedRows
              }

              rows.splice(foundedColIdx, 1, parentRowInitialState)
              break
            }

            default: {
              switch (true) {
                // This covers the case when header or even footer is deleted/replaced with webinar blocks and want to restore the webinars
                case isRestoringHeaderBlockWithWebinar: {
                  const foundedHeaderIdx = rows.findIndex((row) => row?.rowInternal?.uid === AddOnModal.HEADER_BLOCK)
                  rows.splice(0, foundedHeaderIdx >= 0 ? 1 : 0, parentRowInitialState)
                  break
                }
                case isRestoringFooterBlockWithWebinar: {
                  const foundedFooterIdx = rows.findIndex((row) => row?.rowInternal?.uid === AddOnModal.FOOTER_BLOCK)
                  rows.splice(foundedFooterIdx >= 0 ? foundedFooterIdx : rows.length, foundedFooterIdx >= 0 ? 1 : 0, parentRowInitialState)
                  break
                }

                // Clicking “Restore webinar details” will insert the webinar block back into the email at the top of the email (under the header block if one is present.)
                case hasHeaderBlock:
                  rows.splice(1, 0, parentRowInitialState)
                  break
                // If no header block then above the footer block
                case !hasHeaderBlock && hasFooterBlock:
                  rows.splice(lastItemOfRows, 0, parentRowInitialState)
                  break
                // If neither header or footer blocks exist, just insert it at the top of the email.
                default:
                  templateJson.page.rows = [parentRowInitialState, ...rows]
                  break
              }
              break
            }
          }
        }

        templateJson.page.deletedRows = templateJson.page.deletedRows?.filter((el) => el.descriptor.html?.customFields?.blockId !== blockId)
        update({ message: { templateJson }, haveUnsavedChanges: true, isPreview: false })
        beeEditorRef.current?.load(templateJson)
        onTabChange(CommonComposerTab.DESIGN)

        updateModal('statusToast', {
          message: t(`EmailComposer.RestoreWebinar.restoreText`),
          hasTimeout: true,
          status: Status.SUCCESS,
        })
      } else {
        updateModal('confirmation', undefined)
      }
    },
  })
}

export const showWebinarButtonBasedOnType = ({
  checkForRegistrationButton,
  checkForJoinButton,
  hasRegistration,
  hasForm,
  webinarConfig,
}: ShowWebinarButtonBasedOnType) => {
  const { isGoTo, isInvitation, isMultiSession } = detectWebinarType(webinarConfig)

  let buttonCheck, color, backgroundColor, border

  switch (true) {
    case isMultiSession:
      buttonCheck = checkForRegistrationButton || checkForJoinButton

      if (isGoTo) {
        color = !isInvitation ? whiteColor : colorStyle
        backgroundColor = !isInvitation ? colorStyle : undefined
        border = !isInvitation ? undefined : borderStyle
      } else {
        color = !hasForm ? whiteColor : colorStyle
        backgroundColor = !hasForm ? colorStyle : undefined
        border = !hasForm ? undefined : borderStyle
      }
      break

    case hasRegistration:
      buttonCheck = checkForRegistrationButton
      color = colorStyle
      backgroundColor = undefined
      border = borderStyle
      break

    default:
      buttonCheck = checkForJoinButton
      color = whiteColor
      backgroundColor = colorStyle
      border = undefined
      break
  }

  return {
    buttonCheck,
    color,
    backgroundColor,
    border,
  }
}

const handleUidForWebinar = (uid?: string) => {
  const hasUid = [AddOnModal.WEBINAR_BLOCK, AddOnModal.HEADER_BLOCK, AddOnModal.FOOTER_BLOCK].includes(uid as AddOnModal)
  return hasUid
}

export const getWebinarHTMLFields = (
  templateJson: IEntityContentJsonExtended,
  accountId: string,
  messageId: string,
  getCustomFields?: boolean,
  webinarConfig?: WebinarConfig,
  accountTimeZoneId?: string,
  addToggleState?: boolean
) => {
  const customFields: WebinarCustomFields[] = []

  templateJson.page.rows.forEach((row) =>
    row.columns.forEach((column) => {
      column.modules.forEach((module: IPluginModuleWithInternalModule) => {
        const html = module.descriptor.html

        if (handleWebinarCases(module.moduleInternal?.uid)) {
          if (getCustomFields) {
            customFields.push(html?.customFields as WebinarCustomFields)
            return
          }
          const webinarBlockHtml = renderToString(
            <WebinarBlock
              customFields={html?.customFields as WebinarCustomFields}
              accountId={accountId}
              messageId={messageId}
              webinarConfig={webinarConfig}
            />
          )

          if (html) {
            html.html = webinarBlockHtml
          }
          return
        }
      })
    })
  )

  const webinarCustomFieldsWithToggleState = customFields?.map((customFields) => {
    const toggleState = getWebinarDefaultValues(
      customFields.defaultWebinarInfo,
      customFields.hasRegistration,
      accountTimeZoneId,
      webinarConfig
    ).toggleState
    return {
      ...customFields,
      toggleState,
    }
  })

  const updatedTemplateJsonRows = templateJson.page.rows.map((row) => ({
    ...row,
    columns: row.columns.map((column) => ({
      ...column,
      modules: column.modules.map((module: IPluginModuleWithInternalModule) => {
        if (module.moduleInternal?.uid === AddOnModal.WEBINAR_BLOCK) {
          const customFieldsIndex = customFields.findIndex(
            (field) => field.blockId === (module.descriptor?.html?.customFields as WebinarCustomFields).blockId
          )
          if (customFieldsIndex !== -1 && addToggleState) {
            return {
              ...module,
              descriptor: {
                ...module.descriptor,
                html: {
                  ...module.descriptor.html,
                  customFields: addToggleState ? webinarCustomFieldsWithToggleState[customFieldsIndex] : customFields[customFieldsIndex],
                },
              },
            }
          }
        }
        return module
      }),
    })),
  }))

  const updatedTemplateJson = {
    ...templateJson,
    page: {
      ...templateJson.page,
      rows: updatedTemplateJsonRows as IPluginRow[],
    },
  }

  return { customFields, updatedJson: updatedTemplateJson }
}

const findAllPathsToCustomBlocks = (rows: IPluginRow[], path: string[] = [], paths: PathsForCustomBlock[] = []): PathsForCustomBlock[] => {
  rows.forEach((row, rowIndex) => {
    const rowPath = [...path, 'rows', rowIndex.toString()]
    const parentRowUUID = row.uuid

    row.columns.forEach((column, colIndex) => {
      const columnPath = [...rowPath, 'columns', colIndex.toString()]

      column.modules.forEach((module: IPluginModuleWithInternalModule<WebinarCustomFields>, moduleIndex: number) => {
        const modulePath = [...columnPath, 'modules', moduleIndex.toString()]

        if (handleUidForWebinar(module.rowAddonUid || module.moduleInternal?.uid)) {
          const customFields = module.descriptor?.html?.customFields

          paths.push({
            blockId: customFields?.blockId ?? '',
            parentRowUUID,
            columnUUID: column.uuid,
            path: modulePath,
          })
        }
      })
    })
  })
  return paths
}

const getObjectByPath = (rows: IPluginRow[], pathObj: PathsForCustomBlock) => {
  const { path } = pathObj

  //* in this case acc can be any type
  return path.reduce<any>(
    (acc, part) => {
      if (!acc) return

      const arrayMatch = part.match(/(\d+)/)
      if (arrayMatch) {
        const index = parseInt(arrayMatch[0], 10)
        return acc ? acc[index] : undefined
      } else {
        return acc ? acc[part] : undefined
      }
    },
    { rows }
  )
}

export const synchDeletedRowsForWebinar = (pageJson: string, templateJsonRef: React.MutableRefObject<IEntityContentJsonExtended | undefined>) => {
  const parsedPageJson: IEntityContentJsonExtended = JSON.parse(pageJson)
  parsedPageJson.page.deletedRows = templateJsonRef.current!.page.deletedRows
  return JSON.stringify(parsedPageJson)
}

export const handleWebinarRestore = (
  pageJson: string,
  templateJsonRef: React.MutableRefObject<IEntityContentJsonExtended | undefined>,
  isHistoryTriggered?: boolean
) => {
  /* 
    This function handles how webinars should be restored.
    This solves two problems when we use BEE internal history API or our restore functionality.
    Here handled the synchronization of deletedRows
  */

  const mutatedJsonObject: IEntityContentJsonExtended = JSON.parse(pageJson)
  const templateJsonRefRows = templateJsonRef.current!.page.rows

  const allWebinarPaths = findAllPathsToCustomBlocks(templateJsonRefRows)
  const currentWebinarPaths = findAllPathsToCustomBlocks(mutatedJsonObject.page.rows)
  const deletedWebinarPaths = allWebinarPaths.filter(
    (pathObj) => !currentWebinarPaths?.some((currentPathObj) => pathObj.blockId === currentPathObj.blockId)
  )

  const deletedWebinars = deletedWebinarPaths.flatMap((pathObj) => {
    const foundedParenRow = mutatedJsonObject.page.rows.find((row) => row.uuid === pathObj.parentRowUUID)
    const foundedParentRowRef = templateJsonRefRows.find((row) => row.uuid === pathObj.parentRowUUID)
    const hasDeletedGridItem =
      foundedParenRow && rowLayoutTypeValues.includes(foundedParenRow.type) && foundedParentRowRef && foundedParentRowRef.columns.length > 1
    const foundedDeletedGridOrRow = !foundedParenRow || hasDeletedGridItem ? foundedParentRowRef : undefined

    if (foundedDeletedGridOrRow) {
      // This handles the case when from a row we can add additional grid column then remove the webinar via removing column; to prevent multiple iterations over columns just put the deleted column
      const modifiedRow = { ...foundedDeletedGridOrRow, columns: foundedDeletedGridOrRow.columns.filter((col) => col.uuid === pathObj.columnUUID) }
      const foundedGridRowCols = hasDeletedGridItem ? modifiedRow : foundedDeletedGridOrRow

      return foundedGridRowCols.columns.flatMap((col) =>
        col.modules.flatMap((module: IPluginModuleWithInternalModule<WebinarCustomFields>) => {
          const moduleInternal = module
          if (handleUidForWebinar(module.rowAddonUid || module.moduleInternal?.uid)) {
            return {
              ...moduleInternal,
              hasDeletedGridItem,
              columnUUID: col.uuid,
              parentRowUUID: foundedDeletedGridOrRow.uuid,
              parentRowInitialState: foundedDeletedGridOrRow,
              isRemovedEntireRow: !hasDeletedGridItem,
            }
          }
          return []
        })
      )
    } else {
      const retrievedObject = getObjectByPath(templateJsonRefRows, pathObj)

      return retrievedObject
        ? [
            {
              ...retrievedObject,
              columnUUID: pathObj.columnUUID,
              parentRowUUID: pathObj.parentRowUUID,
              parentRowInitialState: foundedParenRow || foundedDeletedGridOrRow,
              isRemovedEntireRow: !!foundedDeletedGridOrRow,
            },
          ]
        : []
    }
  })

  let deletedRows = templateJsonRef.current?.page['deletedRows'] as IPluginModuleWithInternalModule<WebinarCustomFields>[]

  if (!deletedRows?.length) {
    templateJsonRef.current!.page['deletedRows'] = []
  }

  if (isHistoryTriggered) {
    const deletedWebinarPathsBlockIds = currentWebinarPaths.map(({ blockId }) => blockId)
    deletedRows = deletedRows?.filter((el) => {
      const customFields = el.descriptor?.html?.customFields as WebinarCustomFields
      return customFields && !deletedWebinarPathsBlockIds.includes(customFields.blockId)
    })
  }

  const updatedDeletedRows = [...(deletedRows ?? []), ...(deletedWebinars ?? [])].reduce(
    (acc: IPluginModuleWithInternalModule<WebinarCustomFields>[], current) => {
      const customFields = current.descriptor?.html?.customFields
      if (customFields && !acc.some((item) => item.descriptor.html?.customFields?.blockId === customFields.blockId)) {
        acc.push(current)
      }
      return acc
    },
    []
  )

  mutatedJsonObject.page['deletedRows'] = updatedDeletedRows

  return JSON.stringify(mutatedJsonObject)
}
