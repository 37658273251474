import { Update } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { EmptyListingProps } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { FilterDefinition, favoriteFilter, createdByMeFilter, allFormTemplatesFilter } from '@utils/filter'
import { FORM_INFO_LINK } from '@utils/forms'

export const getCustomEmptyListingProps = (
  setFilter: (filterDefinition: FilterDefinition, customFilterSelected: boolean) => void,
  _update: Update,
  _onCreateTemplate: (update: Update) => void,
  goToCatalog: () => void,
  filter?: FilterDefinition
): EmptyListingProps => {
  const defaultProps = {
    headline: "Let's start building!",
    text: 'ListPage.Common.Templates.EmptyState.AllFilter.Text',
    buttonText: 'Check out our template catalog',
    buttonOnClick: () => goToCatalog(),
    linkText: 'Learn more about forms',
    link: FORM_INFO_LINK,
    hideIcon: false,
  }

  if (filter === favoriteFilter) {
    return {
      imgSrc: StaticImageNames.emptyFavorites,
      headline: "You don't have any favorites yet",
      text: 'Add hearts to templates that you want to easily find later.',
      buttonText: 'View all templates',
      buttonOnClick: () => setFilter(allFormTemplatesFilter, false),
    }
  } else if (filter === createdByMeFilter) {
    return {
      imgSrc: StaticImageNames.emptyCreatedByMe,
      ...defaultProps,
    }
  } else {
    return {
      imgSrc: StaticImageNames.emptyChart,
      ...defaultProps,
    }
  }
}
