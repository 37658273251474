import React from 'react'

import Pill from '@components/Pill/Pill'
import {
  renderColWithTooltip,
  renderColWithUnderline,
  renderEnhancedColWithTooltip,
  renderTooltipHeader,
} from '@components/Table/components/tableColumns'
import { TableColumn } from '@components/Table/Table'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import {
  ColWithTooltip,
  EnhancedColWithTooltip as EnhancedColWithTooltipV2,
  withTooltipAlwaysEnabled,
} from '@components/TableV2/utils/tableV2ColumnUtils'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { CellContext } from '@tanstack/react-table'
import { getCountDisplayWithTooltip } from '@utils/numbers'

import { EmailSMSPerformanceData } from './EmailSMSPerformanceContainer'
import { SMSPerformanceData } from './SMSPerformanceContainer'

const getTitle = (cellValue: string, appendixSingle: string, appendMultiple: string) => {
  const appendix = parseInt(cellValue[1]) <= 1 ? appendixSingle : appendMultiple
  return `${cellValue[0]} ${appendix}`
}

const getStepTitle = (row: any) => `${row.cell.value[0]} - ${row.cell.value[1]}`
const getStepTitleV2 = (row: CellContext<EmailSMSPerformanceData, unknown>) => `${row.getValue<string[]>()[0]} - ${row.getValue<string[]>()[1]}`

const RenderWinnerPill = (row: any) => {
  const { t } = useTranslation()
  if (row.row.original.isWinner) {
    return <Tooltip trigger={<Pill text={t('Winner')} />}>{t('A/B Winner Pill Tooltip', { abThreshold: row.row.original.abThreshold })}</Tooltip>
  }
}

export const emailColumns: TableColumn[] = [
  {
    Header: 'Step',
    accessor: 'STEP_ID',
    align: 'left',
    minWidth: 60,
    maxWidth: 60,
    sortType: 'caseInsensitive',
    Cell: (row: any) => renderColWithTooltip(row, getStepTitle(row)),
  },
  {
    Header: 'Subject',
    accessor: 'SUBJECT',
    align: 'left',
    minWidth: 80,
    flexColumn: true,
    sortType: 'caseInsensitive',
    Cell: (row: any) => renderEnhancedColWithTooltip({ row, extraContent: RenderWinnerPill(row) }),
  },
  {
    Header: renderTooltipHeader('Sent', '# of contacts who were sent this email'),
    accessor: 'SENT',
    align: 'right',
    minWidth: 80,
    maxWidth: 80,
    sortType: 'caseInsensitive',
    Cell: (row: any) => renderColWithTooltip(row),
  },
  {
    Header: 'Delivered',
    accessor: 'delivered',
    align: 'right',
    minWidth: 90,
    maxWidth: 90,
    Cell: (row: any) => renderColWithUnderline(row, `${getTitle(row.cell.value, 'delivery', 'deliveries')}`),
  },
  {
    Header: 'Opened',
    accessor: 'opened',
    align: 'right',
    minWidth: 80,
    maxWidth: 80,
    Cell: (row: any) => renderColWithUnderline(row, `${getTitle(row.cell.value, 'open', 'opens')}`),
  },
  {
    Header: 'Clicked',
    accessor: 'clicked',
    align: 'right',
    minWidth: 80,
    maxWidth: 80,
    Cell: (row: any) => renderColWithUnderline(row, `${getTitle(row.cell.value, 'click', 'clicks')}`),
  },
  {
    Header: 'Click To Open',
    accessor: 'clickToOpen',
    align: 'right',
    minWidth: 105,
    maxWidth: 105,
    Cell: (row: any) => renderColWithTooltip(row),
  },
  {
    Header: 'Opt-Out',
    accessor: 'optOut',
    align: 'right',
    minWidth: 67,
    maxWidth: 67,
    Cell: (row: any) => renderColWithUnderline(row, `${getTitle(row.cell.value, 'opt-out', 'opt-outs')}`),
  },
]

export const emailColumnsV2: ColumnDefWithAdditionalProps<EmailSMSPerformanceData>[] = [
  {
    id: 'STEP_ID',
    header: 'Step',
    accessorKey: 'STEP_ID',
    textAlign: 'left',
    maxSize: 80,
    sortingFn: 'caseInsensitive',
    enableCustomCellValue: true,
    cell: (cell) => <ColWithTooltip cell={cell} tooltipTitle={getStepTitleV2(cell)} />,
  },
  {
    id: 'SUBJECT',
    header: 'Subject',
    accessorKey: 'SUBJECT',
    textAlign: 'left',
    sortingFn: 'caseInsensitive',
    enableCustomCellValue: true,
    cell: (cell) => (
      <EnhancedColWithTooltipV2 cell={cell} extraContent={RenderWinnerPill(cell)} rootClass="email-sms-performance-detail__with-label" />
    ),
  },
  {
    id: 'SENT',
    header: 'Sent',
    accessorKey: 'SENT',
    textAlign: 'right',
    maxSize: 120,
    sortingFn: 'caseInsensitive',
    enableCustomCellValue: true,
    cell: ({ row }) => getCountDisplayWithTooltip(row.original.SENT),
  },

  {
    id: 'delivered',
    header: 'Delivered',
    accessorKey: 'delivered',
    textAlign: 'right',
    maxSize: 100,
    enableCustomCellValue: true,
    cell: (cell) => withTooltipAlwaysEnabled(cell.getValue<string>()[0], getTitle(cell.getValue<string>(), 'delivery', 'deliveries')),
  },
  {
    id: 'opened',
    header: 'Opened',
    accessorKey: 'opened',
    textAlign: 'right',
    maxSize: 100,
    enableCustomCellValue: true,
    cell: (cell) => withTooltipAlwaysEnabled(cell.getValue<string>()[0], getTitle(cell.getValue<string>(), 'open', 'opens')),
  },
  {
    id: 'clicked',
    header: 'Clicked',
    accessorKey: 'clicked',
    textAlign: 'right',
    maxSize: 100,
    enableCustomCellValue: true,
    cell: (cell) => withTooltipAlwaysEnabled(cell.getValue<string>()[0], getTitle(cell.getValue<string>(), 'click', 'clicks')),
  },
  {
    id: 'clickToOpen',
    header: 'Click To Open',
    accessorKey: 'clickToOpen',
    textAlign: 'right',
    maxSize: 125,
    enableCustomCellValue: true,
    cell: (cell) => withTooltipAlwaysEnabled(cell.getValue<string>()[0], getTitle(cell.getValue<string>(), 'click to open', 'clicks to open')),
  },
  {
    id: 'optOut',
    header: 'Opt-Out',
    accessorKey: 'optOut',
    textAlign: 'right',
    maxSize: 100,
    enableCustomCellValue: true,
    cell: (cell) => withTooltipAlwaysEnabled(cell.getValue<string>()[0], getTitle(cell.getValue<string>(), 'opt-out', 'opt-outs')),
  },
]

export const smsColumns: TableColumn[] = [
  {
    Header: 'Step',
    accessor: 'step',
    align: 'left',
    minWidth: 60,
    maxWidth: 60,
    sortType: 'caseInsensitive',
  },
  {
    Header: 'SMS Title',
    accessor: 'stepName',
    align: 'left',
    minWidth: 80,
    flexColumn: true,
    sortType: 'caseInsensitive',
    Cell: (row: any) => renderColWithTooltip(row, 'Click to view message report'),
  },
  {
    Header: 'Sent',
    accessor: 'sent',
    align: 'right',
    minWidth: 80,
    maxWidth: 80,
    sortType: 'caseInsensitive',
  },
  {
    Header: 'Delivered',
    accessor: 'delivered',
    align: 'right',
    minWidth: 80,
    maxWidth: 80,
    sortType: 'caseInsensitive',
    disableSortBy: true,
    Cell: (row: any) => renderColWithUnderline(row, `${getTitle(row.cell.value, 'delivery', 'deliveries')}`),
  },
  {
    Header: 'Clicked',
    accessor: 'clicked',
    align: 'right',
    minWidth: 80,
    maxWidth: 80,
    sortType: 'caseInsensitive',
    disableSortBy: true,
    Cell: (row: any) => renderColWithUnderline(row, `${getTitle(row.cell.value, 'click', 'clicks')}`),
  },
  {
    Header: 'Replied',
    accessor: 'replied',
    align: 'right',
    minWidth: 80,
    maxWidth: 80,
    sortType: 'caseInsensitive',
    disableSortBy: true,
    Cell: (row: any) => renderColWithUnderline(row, `${getTitle(row.cell.value, 'reply', 'replies')}`),
  },
  {
    Header: 'Failed',
    accessor: 'failed',
    align: 'right',
    minWidth: 80,
    maxWidth: 80,
    sortType: 'caseInsensitive',
    disableSortBy: true,
    Cell: (row: any) => renderColWithUnderline(row, `${getTitle(row.cell.value, 'fail', 'fails')}`),
  },
  {
    Header: 'Opt-Out',
    accessor: 'optOut',
    align: 'right',
    minWidth: 80,
    maxWidth: 80,
    sortType: 'caseInsensitive',
    disableSortBy: true,
    Cell: (row: any) => renderColWithUnderline(row, `${getTitle(row.cell.value, 'opt-out', 'opt-outs')}`),
  },
]

export const smsColumnsV2: ColumnDefWithAdditionalProps<SMSPerformanceData>[] = [
  {
    id: 'step',
    header: 'Step',
    accessorKey: 'step',
    textAlign: 'left',
    maxSize: 90,
    sortingFn: 'caseInsensitive',
  },
  {
    id: 'stepName',
    header: 'SMS Title',
    accessorKey: 'stepName',
    textAlign: 'left',
    sortingFn: 'caseInsensitive',
    enableCustomCellValue: true,
    cell: (cell) => withTooltipAlwaysEnabled(cell.getValue<string>()[0], 'Click to view message report'),
  },
  {
    id: 'sent',
    header: 'Sent',
    accessorKey: 'sent',
    textAlign: 'right',
    maxSize: 100,
    sortingFn: 'caseInsensitive',
  },
  {
    id: 'delivered',
    header: 'Delivered',
    accessorKey: 'delivered',
    textAlign: 'right',
    maxSize: 100,
    disableSorting: true,
    enableCustomCellValue: true,
    cell: (cell) => withTooltipAlwaysEnabled(cell.getValue<string>()[0], getTitle(cell.getValue<string>(), 'delivery', 'deliveries')),
  },
  {
    id: 'clicked',
    header: 'Clicked',
    accessorKey: 'clicked',
    textAlign: 'right',
    maxSize: 100,
    disableSorting: true,
    enableCustomCellValue: true,
    cell: (cell) => withTooltipAlwaysEnabled(cell.getValue<string>()[0], getTitle(cell.getValue<string>(), 'click', 'clicks')),
  },
  {
    id: 'replied',
    header: 'Replied',
    accessorKey: 'replied',
    textAlign: 'right',
    maxSize: 100,
    disableSorting: true,
    enableCustomCellValue: true,
    cell: (cell) => withTooltipAlwaysEnabled(cell.getValue<string>()[0], getTitle(cell.getValue<string>(), 'reply', 'replies')),
  },
  {
    id: 'failed',
    header: 'Failed',
    accessorKey: 'failed',
    textAlign: 'right',
    maxSize: 100,
    disableSorting: true,
    enableCustomCellValue: true,
    cell: (cell) => withTooltipAlwaysEnabled(cell.getValue<string>()[0], getTitle(cell.getValue<string>(), 'fail', 'fails')),
  },
  {
    id: 'optOut',
    header: 'Opt-Out',
    accessorKey: 'optOut',
    textAlign: 'right',
    maxSize: 100,
    disableSorting: true,
    enableCustomCellValue: true,
    cell: (cell) => withTooltipAlwaysEnabled(cell.getValue<string>()[0], getTitle(cell.getValue<string>(), 'opt-out', 'opt-outs')),
  },
]

export const smsTableArgs = {
  tableTitle: 'Individual SMS Messages',
  tableSubTitle: 'Track the performance of each unique message. Contacts who receive a message multiple times will count once in this report.',
  canPaginate: true,
  columns: smsColumns,
  initialState: {
    pageIndex: 0,
    pageSize: 10,
  },
}

export const getEmailHeaderStrings = (accessor: string, isPercent: boolean) => {
  switch (accessor) {
    case 'STEP_ID':
      return ['Step']
    case 'SUBJECT':
      return ['Subject']
    case 'SENT':
      return ['Sent', '# of contacts who were sent this email']
    case 'delivered':
      return ['Delivered', isPercent ? '(Delivered / Sent) * 100' : '# of contacts who received this email']
    case 'opened':
      return ['Opened', isPercent ? '(Opened / Delivered) * 100' : '# of contacts who opened this email']
    case 'clicked':
      return ['Clicked', isPercent ? '(Clicked / Delivered) * 100' : '# of contacts who clicked a link in this email']
    case 'clickToOpen':
      return ['Click to Open', '(Clicked / Opened) * 100']
    case 'optOut':
      return ['Opt-Out', isPercent ? '(Opted Out / Delivered) * 100' : '# of contacts who opted out']
    default:
      throw new Error('Unknown accessor')
  }
}

export const getSMSHeaderStrings = (accessor: string, isPercent: boolean) => {
  switch (accessor) {
    case 'step':
      return ['Step']
    case 'stepName':
      return ['SMS Title']
    case 'sent':
      return ['Sent', '# of contacts who were sent this SMS']
    case 'delivered':
      return ['Delivered', isPercent ? '(Delivered / Sent) * 100' : '# of contacts who received this SMS']
    case 'clicked':
      return ['Clicked', isPercent ? '(Clicked / Delivered) * 100' : '# of contacts who clicked a link in this SMS']
    case 'replied':
      return ['Replied', isPercent ? '(Replied / Delivered) * 100' : '# of contacts who replied to this SMS']
    case 'failed':
      return ['Failed', isPercent ? '(Failed / Sent) * 100' : '# of contacts who failed to receive this SMS']
    case 'optOut':
      return ['Opt-Out', isPercent ? '(Opted Out / Delivered) * 100' : '# of contacts who opted out']
    default:
      throw new Error('Unknown accessor')
  }
}
