import React, { FC, useCallback, useContext, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import MoreOptions from '@components/MoreOptions/MoreOptions'
import { Status } from '@components/StatusToast/StatusToast'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Segment, SegmentStatus } from '@graphql/types/microservice/ml-audience-types'
import { ALL_ACTON_CONTACTS_SEGMENT_NAME } from '@src/pages/ContactSegments/utils/ContactSegments.constants'
import DiscardedSegmentFeedbackModal from '@src/pages/RecommendedSegments/components/DiscardedSegmentFeedbackModal/DiscardedSegmentFeedbackModal'
import { FeedbackOption } from '@src/pages/RecommendedSegments/components/DiscardedSegmentFeedbackModal/utils/DiscardedSegmentFeedbackModal.constants'
import RecommendedSegmentIcon from '@src/pages/RecommendedSegments/components/RecommendedSegmentIcon/RecommendedSegmentIcon'
import { RecommendedSegmentsContext } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.context'
import { useRecommendedSegmentsRequests } from '@src/pages/RecommendedSegments/utils/RecommendedSegments.graphQL'
import { ensureFirstLetterIsCapitalized } from '@utils/strings'

import './CustomInsightsResultsRow.css'

interface CustomInsightsResultsRowProps {
  className?: string
  dataTest?: string
  segment: Segment
}

const rootClass = 'custom-insights-results-row'

const CustomInsightsResultsRow: FC<CustomInsightsResultsRowProps> = (props: CustomInsightsResultsRowProps) => {
  const { dataTest = rootClass, className = '', segment } = props

  const { name = '', id = '', selectionReason, type, parentSegmentId } = segment

  const {
    update,
    loadSegments,
    values: { allParentSegments, feedbackModalEnabled },
  } = useContext(RecommendedSegmentsContext)

  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [showReason, setShowReason] = useState(false)

  const { updateSegment } = useRecommendedSegmentsRequests()

  const { t } = useTranslation()

  const nameWithFormat = useMemo(() => {
    if (name.includes('\t')) {
      const processedLines = name.split('\n').map((line) => {
        return line.startsWith('\t') ? `<li>${line.trim()}</li>` : line
      })
      return processedLines.join('')
    }
    return name
  }, [name])

  const insightDescription = useMemo(() => {
    const insightDescription = type ? '<bold>{{type}}:</bold> {{name}}' : '{{name}}'
    const parentSegment = parentSegmentId && allParentSegments.length > 0 ? allParentSegments.find(({ id }) => id === parentSegmentId) : undefined
    const showParent = !!parentSegment && parentSegment.name !== ALL_ACTON_CONTACTS_SEGMENT_NAME
    return showParent ? `${insightDescription} ${t('Based on the {{name}} segment.', { name: parentSegment.name })}` : insightDescription
  }, [segment, allParentSegments, t])

  const onCreateSegmentClick = useCallback(() => update({ segmentToCreate: segment }), [segment])

  const discardSegment = (feedback?: FeedbackOption[]) => {
    updateSegment(id, 'REJECTED' as SegmentStatus, feedback)
      .then(() => {
        update({
          status: {
            message: t('Suggestion discarded'),
            status: Status.SUCCESS,
          },
        })
        loadSegments()
      })
      .catch(() =>
        update({
          status: {
            message: t('There was a problem processing your request'),
            status: Status.FAIL,
          },
        })
      )
  }

  const onDiscardSegmentClick = () => {
    if (feedbackModalEnabled) {
      setShowFeedbackModal(true)
    } else {
      discardSegment()
    }
  }

  const onFeedbackSubmit = (feedbackOptions?: Map<FeedbackOption, boolean>) => {
    setShowFeedbackModal(false)
    if (feedbackOptions) {
      const selectedFeedback = Array.from(feedbackOptions.keys()).filter((option) => feedbackOptions.get(option))
      discardSegment(selectedFeedback)
    } else {
      discardSegment()
    }
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {feedbackModalEnabled && showFeedbackModal && <DiscardedSegmentFeedbackModal onFeedbackSubmit={onFeedbackSubmit} />}
      <div className={`${rootClass}__flex-container`}>
        <RecommendedSegmentIcon className={`${rootClass}__icon`} />
        <div className={`${rootClass}__description`}>
          <Typography
            dataTest={`${dataTest}-description`}
            text={insightDescription}
            values={{
              type: ensureFirstLetterIsCapitalized(type?.toLowerCase() || ''),
              name: nameWithFormat,
            }}
            tagComponents={{ li: <li /> }}
            tagProps={{ bold: { weight: TextWeight.MEDIUM } }}
            inline
          />
        </div>
        <Button buttonType={ButtonType.FLOAT_TEAL} onClick={onCreateSegmentClick}>
          {t('Create segment')}
        </Button>
        <Button buttonType={ButtonType.FLOAT} onClick={onDiscardSegmentClick}>
          {t('Discard')}
        </Button>
      </div>
      {selectionReason && (
        <div className={`${rootClass}__reason`}>
          <Button dataTest={`${dataTest}-reason-button`} buttonType={ButtonType.TEXT_TEAL} onClick={() => setShowReason(!showReason)}>
            {t('Why this segment?')}
          </Button>
          {showReason && (
            <MoreOptions>
              <Typography dataTest={`${dataTest}-reason-text`} text={selectionReason} />
            </MoreOptions>
          )}
        </div>
      )}
    </div>
  )
}

export default CustomInsightsResultsRow
