import React, { FC } from 'react'

import classNames from 'classnames'

import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

export interface AddToCRMDetailProps {
  campaignName: string
  status: string
  className?: string
  dataTest?: string
}

const rootClass = 'add-to-crm-detail'

const AddToCRMDetail: FC<AddToCRMDetailProps> = (props: AddToCRMDetailProps) => {
  const { campaignName, status = 'Default Status', dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const renderCampaign = () => {
    return (
      <>
        <div>
          <Typography text={`${t('Campaign name')}: `} inline />
          {campaignName !== '' && <Typography text={campaignName} weight={TextWeight.MEDIUM} inline />}
          {campaignName === '' && <Typography text={t('undefined')} weight={TextWeight.ITALIC} inline />}
        </div>
        <div>
          <Typography text={`${t('Campaign member status is set to')}: `} inline />
          {status !== '' && <Typography text={status} weight={TextWeight.MEDIUM} inline />}
          {status === '' && <Typography text={t('undefined')} weight={TextWeight.ITALIC} inline />}
        </div>
      </>
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {renderCampaign()}
    </div>
  )
}

export default AddToCRMDetail
