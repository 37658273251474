import { gql } from '@apollo/client'

export default gql`
  query download($isDownload: Boolean, $attachmentId: Long) {
    download(isDownload: $isDownload, attachmentId: $attachmentId) {
      downloadUrl
      fileName
    }
  }
`
