import React, { FC } from 'react'

import { StatusToastType } from '@interface/StatusToast'
import FavIconContainer from '@src/pages/Content/Branding/tabs/WebIdentity/FavIcon/FavIconContainer'
import MarketingDomainContainer from '@src/pages/Content/Branding/tabs/WebIdentity/MarketingDomain/MarketingDomainContainer'

interface Props {
  setToastStatus: (value: StatusToastType) => void
}

const WebIdentityContainer: FC<Props> = ({ setToastStatus }) => {
  return (
    <div>
      <MarketingDomainContainer setToastStatus={setToastStatus} />
      <FavIconContainer setToastStatus={setToastStatus} />
    </div>
  )
}

export default WebIdentityContainer
