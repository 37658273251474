import React, { FC, Fragment, useMemo } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  AlertFilter,
  Condition,
  filtersIcons,
  visitorsFilters,
} from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/utils/AlertFilters.utils'
import GroupHeader from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/GroupHeader/GroupHeader'

import './AlertFilterCard.css'

import Button, { ButtonType } from '@components/Button/Button'

interface AlertFilterCardProps {
  className?: string
  dataTest?: string
  filter: AlertFilter
  onEdit?: () => void
  onDelete?: () => void
  readOnly?: boolean
}

const rootClass = 'alert-filter-card'

const AlertFilterCard: FC<AlertFilterCardProps> = (props: AlertFilterCardProps) => {
  const { dataTest = rootClass, className = '', filter, readOnly = false, onEdit, onDelete } = props

  const { t } = useTranslation()

  const { type, conditions } = filter

  const isVisitorsType = useMemo(() => visitorsFilters.includes(type), [filter])

  const renderVisitorsFilterConditions = () => {
    const conditionLabel: { [key in Condition]: string } = {
      [Condition.BLANK]: 'IsBlank',
      [Condition.NOT_BLANK]: 'IsNotBlank',
      [Condition.CONTAINS]: 'Contains',
      [Condition.NOT_CONTAINS]: 'DoesNotContain',
      [Condition.IS]: 'Is',
      [Condition.IS_NOT]: 'IsNot',
      [Condition.STARTS_WITH]: 'StartsWith',
      [Condition.ENDS_WITH]: 'EndsWith',
    }

    return conditions.map(({ logicalCondition, condition, field, value }, index) => (
      <Fragment key={`logical-condition-${index}`}>
        {logicalCondition && index > 0 && (
          <div className={`${rootClass}__conditions-visitors`}>
            <Typography
              dataTest={`${dataTest}-logical-condition`}
              className={`${rootClass}__conditions-visitors-logical`}
              text={t(logicalCondition)}
              weight={TextWeight.MEDIUM}
              inlineBlock
            />
          </div>
        )}
        <div className={`${rootClass}__conditions-visitors`}>
          <Svg name={SvgNames.atSign} type={SvgType.LARGER_ICON} />
          <Typography
            dataTest={`${dataTest}-condition`}
            text={`AlertFilterCard.Conditions.VisitorType.${conditionLabel[condition]}`}
            tagProps={{ bold: { weight: TextWeight.MEDIUM } }}
            values={{ field, value }}
            inlineBlock
          />
        </div>
      </Fragment>
    ))
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__filter-header`}>
        <div className={`${rootClass}__filter-name`}>
          <Svg name={filtersIcons[type]} type={SvgType.LARGE_ICON} />
          <Typography text={t(type)} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
        </div>
        {!readOnly && (
          <div className={`${rootClass}__filter-actions-container`}>
            <Tooltip
              trigger={
                <Button
                  buttonType={ButtonType.ICON}
                  dataTest={`${dataTest}-edit-filter`}
                  className={classNames(`${rootClass}__filter-action`, `${rootClass}__filter-action-edit`)}
                  onClick={onEdit}
                >
                  <Svg className={`${rootClass}__filter-action-edit-svg`} name={SvgNames.pencil} type={SvgType.LARGER_ICON} />
                </Button>
              }
            >
              {t('Edit filter')}
            </Tooltip>
            <Tooltip
              trigger={
                <Button
                  buttonType={ButtonType.ICON}
                  dataTest={`${dataTest}-delete-filter`}
                  className={classNames(`${rootClass}__filter-action`, `${rootClass}__filter-action-delete`)}
                  onClick={onDelete}
                >
                  <Svg name={SvgNames.delete} type={SvgType.LARGER_ICON} />
                </Button>
              }
            >
              {t('Delete filter')}
            </Tooltip>
          </div>
        )}
      </div>
      {conditions.length > 0 && (
        <div className={`${rootClass}__conditions`}>
          <GroupHeader
            dataTest={`${dataTest}-group-header`}
            className={`${rootClass}__conditions-header`}
            label={t('AlertFilterCard.Conditions.Header', { amount: filter.conditions.length })}
          >
            {isVisitorsType ? renderVisitorsFilterConditions() : null}
          </GroupHeader>
        </div>
      )}
    </div>
  )
}

export default AlertFilterCard
