import React from 'react'
import { MultiValueProps } from 'react-select'

import classNames from 'classnames'

import Chip from '@components/Chip/Chip'
import { selectRootClass } from '@components/SelectV2/SelectV2.utils'
import { css } from '@emotion/css'

const CustomMultiValue = (props: MultiValueProps<any>) => {
  const { data, getStyles, removeProps, isDisabled } = props

  const onRemove = (event: React.MouseEvent<any, MouseEvent>) => {
    removeProps.onClick && removeProps.onClick(event)
  }

  return (
    <Chip
      className={classNames(css(getStyles('multiValue', props)), `${selectRootClass}__multi-value`)}
      id={data.value}
      name={data.label}
      disabled={isDisabled}
      onRemove={(_, event) => onRemove(event)}
    />
  )
}

export default CustomMultiValue
