import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import * as ScrollAreaRadix from '@radix-ui/react-scroll-area'

import './ScrollArea.css'

type Props = {
  children: ReactNode | ReactNode[]
  className?: string
  dataTest?: string
  showOnEvent?: ScrollAreaRadix.ScrollAreaContextValue['type']
  register?: any
  hasHorizontalScroll?: boolean
}

const rootClass = 'scroll-area'

const ScrollArea: FC<Props> = ({ children, register, dataTest, className = '', showOnEvent = 'hover', hasHorizontalScroll = false }: Props) => {
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <ScrollAreaRadix.Root className={`${rootClass}__root`} type={showOnEvent}>
        <ScrollAreaRadix.Viewport tabIndex={0} className={`${rootClass}__viewport`} ref={register}>
          {children}
        </ScrollAreaRadix.Viewport>
        <ScrollAreaRadix.Scrollbar orientation="vertical" className={classNames(`${rootClass}__scrollbar`, `${className}__scrollbar`)}>
          <ScrollAreaRadix.Thumb className={`${rootClass}__thumb`} />
        </ScrollAreaRadix.Scrollbar>
        {hasHorizontalScroll && (
          <ScrollAreaRadix.Scrollbar
            orientation="horizontal"
            className={classNames(`${rootClass}__scrollbar-horizontal`, `${className}__scrollbar-horizontal`)}
          >
            <ScrollAreaRadix.Thumb className={classNames(`${rootClass}__thumb`, `${rootClass}__thumb-horizontal`)} />
          </ScrollAreaRadix.Scrollbar>
        )}
      </ScrollAreaRadix.Root>
    </div>
  )
}

export default ScrollArea
