import React, { FC, Fragment } from 'react'

import classNames from 'classnames'

import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import { ProgramOptionDetails } from './ProgramOptions.contants'
import { ProgramOption } from '../ProgramSettings/ProgramSettings'

import './ProgramOptions.css'

interface Props {
  options: ProgramOption
  alternateUniqueFieldName?: string
  className?: string
  dataTest?: string
}

const rootClass = 'program-options'

const ProgramOptions: FC<Props> = (props: Props) => {
  const { options, alternateUniqueFieldName, dataTest = rootClass, className = '' } = props

  const { t } = useTranslation()

  const renderOptions = () => {
    return ProgramOptionDetails.map((detail) => {
      const { optionKey, title, description }: { optionKey: keyof ProgramOption; title: string; description: string } = detail
      if (options[optionKey]) {
        if (optionKey === 'useAlternateUniqueField') {
          return (
            <Fragment key={optionKey}>
              <Typography text={t(title)} className={`${rootClass}__card-subheader`} />
              <Typography
                text={`${t('Selected alternate unique identifier is')}: ${alternateUniqueFieldName}`}
                type={TextType.BODY_TEXT_SMALL_LIGHT}
              />
            </Fragment>
          )
        }
        return (
          <Fragment key={optionKey}>
            <Typography text={t(title)} className={`${rootClass}__card-subheader`} />
            <Typography text={t(description)} type={TextType.BODY_TEXT_SMALL_LIGHT} />
          </Fragment>
        )
      }
    })
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {renderOptions()}
    </div>
  )
}

export default ProgramOptions
