export enum AssetTypes {
  ALL = 'all',
  CONTENT_FRAGMENT = 'CONTENT_FRAGMENT',
  EMAIL = 'EMAIL',
  EMAIL_TEMPLATE = 'TEMPLATE',
  FORM = 'FORM',
  FORM_TEMPLATE = 'FORM_TEMPLATE',
  LANDING_PAGE = 'LANDINGPAGE',
  LANDING_PAGE_LINK = 'LANDINGPAGE_LINK',
  LANDING_PAGE_TEMPLATE = 'LANDINGPAGE_TEMPLATE',
  LINK = 'LINK',
  MEDIA = 'MEDIA',
  PROGRAM = 'AUTOMATED_PROGRAM',
  PROGRAM_TEMPLATE = 'AUTOMATED_PROGRAM_TEMPLATE',
  SEGMENT = 'SEGMENT',
  SMS = 'SMS',
  WEBINAR = 'WEBINAR',
  WEB_PAGE = 'WEB_PAGE',
}
