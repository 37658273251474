import React, { ComponentProps } from 'react'

import { Meta, StoryFn } from '@storybook/react'

import SystemExpressionComponent from '../SystemExpression'

type SystemExpressionProps = ComponentProps<typeof SystemExpressionComponent>

export default {
  title: 'Acton-Web/Segment/SegmentComposer/Build/SystemExpression',
  component: SystemExpressionComponent,
  decorators: [(story) => <div style={{ padding: '2rem', minWidth: '87rem' }}>{story()}</div>],
} as Meta

const Template: StoryFn<SystemExpressionProps> = (args) => <SystemExpressionComponent {...args} />

export const SystemExpression = Template.bind({})
SystemExpression.args = {}
