import React, { FC } from 'react'

import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Box } from '@src/pages/programs/UpgradeCommonComponents/Box/Box'
import { UpgradedSource } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

import './RemovedEarlyExitSourcesSection.css'

interface RemovedEarlyExitSourcesSectionProps {
  className?: string
  dataTest?: string
  sources: UpgradedSource[]
}

const rootClass = 'removed-early-exit-sources-section'

export const RemovedEarlyExitSourcesSection: FC<RemovedEarlyExitSourcesSectionProps> = (props: RemovedEarlyExitSourcesSectionProps) => {
  const { sources } = props

  const removedEarlyExitSources = sources.filter(({ removed }) => removed)

  const { t } = useTranslation()

  return (
    <Box className={rootClass}>
      <Typography
        text={t(`UpgradePrograms.Page.ReviewUpgrade.RemovedEarlyExitSourcesSection.Title`, { count: removedEarlyExitSources.length })}
        type={TextType.VALIDATION_ERROR_LARGE}
        weight={TextWeight.BOLD}
      />
      <div className={`${rootClass}__sources`}>
        <Typography text={t(`UpgradePrograms.Page.ReviewUpgrade.RemovedEarlyExitSourcesSection.SubTitle`)} />
        {removedEarlyExitSources.map((source) => (
          <Box key={source.upgradeId} className={`${rootClass}__source`} isCurrent isSourceValue>
            <TextWithTooltipOnEllip typographyProps={{ text: source?.source?.sourceName ?? '', type: TextType.BODY_TEXT }} />
            <Typography
              text={t(`AutomatedPrograms.UpgradeManager.SourceReplacement.Contacts`, { count: source.source?.count })}
              type={TextType.BODY_TEXT_LIGHT}
            />
          </Box>
        ))}
      </div>
    </Box>
  )
}
