import React from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { RowAction } from '@components/TableV2/tableV2TS/interfaces'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Toggle from '@components/Toggle'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { AlertFilter } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertFilters/utils/AlertFilters.utils'
import { Recipient } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/components/RecipientsList/RecipientsList'
import { recipientsListMock } from '@src/pages/WebsiteProspectorAlerts/components/CreateAlertModal/components/AlertRecipients/components/RecipientsList/utils/RecipientsList.mock'
import { VisitorAlertsTooltips } from '@src/pages/WebsiteProspectorAlerts/VisitorAlerts.constants'
import { Alert } from '@src/pages/WebsiteProspectorAlerts/WebsiteProspectorAlertsContext'
import { Row } from '@tanstack/react-table'

const rootClass = 'visitor-alerts-table'
export const getVisitorAlertsColumns = (
  t: Function,
  userId: string,
  onEnableChange: Function,
  onMuteChange: Function
): ColumnDefWithAdditionalProps<Alert>[] => [
  {
    accessorKey: 'name',
    header: () => 'Name',
    sortingFn: 'caseInsensitive',
    textAlign: 'left',
    maxSize: 250,
    cell: ({ row }) => getAlertName(row.original),
    enableClickableCell: true,
  },
  {
    accessorKey: 'status',
    header: () => 'Status',
    sortingFn: 'caseInsensitive',
    textAlign: 'center',
    minSize: 150,
    cell: ({ row }) => getAlertStatusContent(row.original, userId, onMuteChange, onEnableChange),
    enableClickableCell: true,
  },
  {
    accessorKey: 'recipients',
    header: () => 'Recipients',
    textAlign: 'center',
    minSize: 150,
    cell: ({ row }) => getRecipients(row.original.recipients, row.original.enabled && !row.original.muted, t, userId),
    enableClickableCell: true,
  },
  {
    accessorKey: 'schedule',
    header: () => 'Schedule',
    textAlign: 'left',
    minSize: 150,
    cell: ({ row }) => getScheduledInfo(row.original.schedule, row.original.enabled && !row.original.muted, t),
    enableClickableCell: true,
  },
  {
    accessorKey: 'filters',
    header: () => getFiltersTableHeader(t),
    textAlign: 'left',
    minSize: 150,
    cell: ({ row }) => getAppliedFilters(row.original.filters, row.original.enabled && !row.original.muted, t),
    enableClickableCell: true,
  },
  {
    accessorKey: 'owner',
    header: () => 'Owner',
    textAlign: 'left',
    minSize: 150,
    cell: ({ row }) => getOwner(row.original, userId, t),
    enableClickableCell: true,
  },
  {
    accessorKey: 'lastModified',
    header: () => 'Last modified',
    textAlign: 'left',
    minSize: 150,
    cell: ({ row }) => getModifiedDate(row.original.lastModified, row.original.enabled && !row.original.muted),
    enableClickableCell: true,
  },
]

export const getVisitorAlertsRowActions = (
  isAdministrator: boolean,
  userId: string,
  onEnableChange: Function,
  onMuteChange: Function,
  onDelete: Function
): RowAction<Alert>[] => [
  {
    label: 'Edit',
    icon: SvgNames.pencil,
    tooltipMessage: 'Edit',
    hidden: (row) => (row as Row<Alert>).original.owner !== userId && !isAdministrator,
    onClick: () => alert('Edit clicked, here is where you would trigger the real action'),
  },
  {
    label: 'Duplicate',
    icon: SvgNames.cloneSegment,
    tooltipMessage: 'Duplicate',
    onClick: () => alert('Duplicate clicked, here is where you would trigger the real action'),
  },
  {
    label: 'Delete',
    icon: SvgNames.delete,
    tooltipMessage: 'Delete',
    hidden: (row) => (row as Row<Alert>).original.owner !== userId && !isAdministrator,
    onClick: (row) => onDelete(row.original),
  },
  {
    label: (row) => ((row as Row<Alert>).original.muted ? 'Unmute alert' : 'Mute alert'),
    hidden: (row) => (row as Row<Alert>).original.owner !== userId,
    icon: (row) => ((row as Row<Alert>).original.muted ? SvgNames.bellOff : SvgNames.bellRing),
    inDropdown: true,
    onClick: (row) => onMuteChange(row.original),
  },
  {
    label: (row) => ((row as Row<Alert>).original.enabled ? 'Disable alert' : 'Enable alert'),
    hidden: (row) => !isAdministrator || (row as Row<Alert>).original.owner === userId,
    icon: (row) => ((row as Row<Alert>).original.enabled ? SvgNames.toggleOff : SvgNames.toggleOn),
    inDropdown: true,
    onClick: (row) => onEnableChange(row.original, !row.original.enabled),
  },
]

export const getAlertName = (alert: Alert) => {
  const { name, description, enabled, muted } = alert
  const newDescription = enabled ? (muted ? 'Muted' : description) : 'Disabled'
  const isActive = enabled && !muted
  const getContent = (inTooltip = false) => (
    <div className={classNames(`${rootClass}__alert-name`, { [`ellip`]: !inTooltip })}>
      <Typography
        text={name}
        type={inTooltip ? TextType.BODY_TEXT_WHITE : TextType.BODY_TEXT}
        weight={TextWeight.BOLD}
        className={classNames({ [`${rootClass}__disabled`]: !isActive && !inTooltip })}
      />
      <Typography
        text={newDescription}
        type={inTooltip ? TextType.BODY_TEXT_WHITE_SMALL : TextType.BODY_TEXT_SMALL_LIGHT}
        className={classNames({
          [`${rootClass}__alert-description`]: !inTooltip,
          [`${rootClass}__disabled`]: !enabled && !inTooltip,
        })}
      />
    </div>
  )
  return (
    <Tooltip trigger={getContent()} position={'bottom'} align={'start'}>
      {getContent(true)}
    </Tooltip>
  )
}

export const getAlertStatusContent = (alert: Alert, userId: string, onMute: Function, onEnable: Function) => {
  const getContent = () => {
    if (userId === String(alert.owner)) {
      return <Toggle isOn={alert.enabled} onToggle={(isOnUpdate: boolean) => onEnable(alert, isOnUpdate)} />
    } else {
      return (
        <Button buttonType={ButtonType.ICON} className={`${rootClass}__mute`} onClick={() => onMute(alert)}>
          <Svg
            name={alert.muted ? SvgNames.bellOffRounded : SvgNames.bellRing}
            type={alert.muted ? SvgType.LARGER_ICON : SvgType.MEDIUM}
            className={classNames(`${rootClass}__mute-icon`, { [`${rootClass}__mute-icon-large`]: alert.muted })}
          />
        </Button>
      )
    }
  }
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={classNames(`${rootClass}__alert-status-wrapper`, 'flex-justify-center')} onClick={(e) => e.stopPropagation()}>
      {getContent()}
    </div>
  )
}

export const getFiltersTableHeader = (t: Function) => {
  return (
    <div className={`${rootClass}__custom-header`}>
      <Typography text={t('FILTERS')} className={`${rootClass}__custom-header-title`} type={TextType.TABLE_HEADER} />
      <Tooltip trigger={<Svg name={SvgNames.info} type={SvgType.MEDIUM} dataTest={`${rootClass}-filter-tooltip`} />} position="top" align={'start'}>
        {t(VisitorAlertsTooltips.TABLE_HEADER_FILTER)}
      </Tooltip>
    </div>
  )
}

export const getRecipients = (recipients: Recipient[], enabled: boolean, t: Function, userId: string) => {
  return (
    <div className={`${rootClass}__recipients`}>
      {recipients.length === 1 && recipients[0].id === Number(userId) ? (
        <Typography text={t('Only you')} className={classNames({ [`${rootClass}__disabled`]: !enabled })} />
      ) : (
        <div className={classNames({ [`${rootClass}__disabled`]: !enabled })}>
          <Tooltip trigger={`${recipients.length} ${t('users')}`} position={'bottom'} align={'start'} hide={recipients.length === 0}>
            {recipients.map((recipient, index) => (
              <Typography text={recipientsListMock.find((rec) => rec.id === recipient.id)?.name} key={index} type={TextType.BODY_TEXT_WHITE} />
            ))}
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export const getScheduledInfo = (scheduled: string, enabled: boolean, t: Function) => {
  return (
    <div className={classNames({ [`${rootClass}__disabled`]: !enabled })}>
      <Tooltip trigger={scheduled} position={'bottom'} align={'start'}>
        <Typography text={`${t('Scheduled to send')}:`} type={TextType.BODY_TEXT_WHITE} weight={TextWeight.BOLD} />
        <Typography text={scheduled === 'Immediately' ? scheduled : `${t('Weekly on')} ${scheduled}`} type={TextType.BODY_TEXT_WHITE} />
      </Tooltip>
    </div>
  )
}

export const getAppliedFilters = (filters: AlertFilter[], enabled: boolean, t: Function) => (
  <div className={classNames({ [`${rootClass}__disabled`]: !enabled })}>
    <Tooltip trigger={`${filters.length} ${t('filters')}`} position={'bottom'} align={'start'}>
      <Typography text={`${filters.length} ${t('alert filters applied')}`} type={TextType.BODY_TEXT_WHITE} />
    </Tooltip>
  </div>
)
export const getOwner = (alert: Alert, userId: string, t: Function) => {
  const getContent = (inTooltip = false) => {
    return (
      <Typography
        text={`${alert.ownerName}${alert.owner === userId ? ` (${t('You')})` : ''}`}
        className={classNames({ [`${rootClass}__disabled`]: !(alert.enabled && !alert.muted) && !inTooltip })}
        type={inTooltip ? TextType.BODY_TEXT_WHITE : TextType.BODY_TEXT}
      />
    )
  }
  return (
    <Tooltip trigger={getContent()} position={'bottom'} align={'start'}>
      {getContent(true)}
    </Tooltip>
  )
}

export const getModifiedDate = (date: string, enabled: boolean) => (
  <Typography text={date} className={classNames({ [`${rootClass}__disabled`]: !enabled })} />
)
