import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Input, { InputProps, InputSize } from '@components/Input/Input'
import Typography, { LineHeight, TextType } from '@components/Typography/Typography'

import './InputWithMaxLength.css'

export type InputWithMaxLengthProps = {
  hideLengthIfEmpty?: boolean
  inputProps?: InputProps
  maxLength: number
  lengthTextLineHeight?: LineHeight
  lengthTextType?: TextType
  showLimitError?: boolean
  autoFocus?: boolean
  className?: string
  dataTest?: string
}

interface State {
  length: number
}

const rootClass = 'input-with-max-length'

/**
 * @deprecated use <InputV2 instead
 */
const InputWithMaxLength: FC<InputWithMaxLengthProps> = (props: InputWithMaxLengthProps) => {
  const {
    hideLengthIfEmpty = false,
    inputProps = {},
    lengthTextType = TextType.BODY_TEXT_LIGHT,
    lengthTextLineHeight = LineHeight.MEDIUM,
    maxLength,
    showLimitError = false,
    autoFocus = false,
    className = '',
    dataTest = rootClass,
  } = props
  const { label, onChange, size, defaultValue } = inputProps
  const [state, setState] = useState<State>({ length: defaultValue?.length || 0 })
  const { length } = state
  const showCounter = (hideLengthIfEmpty && length > 0) || !hideLengthIfEmpty

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, length: event.target.value.length })
    if (onChange) {
      onChange(event)
    }
  }

  useEffect(() => {
    // In scenarios where value is controlled directly and doesn't trigger the change handler
    const propsValue = inputProps.value ?? inputProps.defaultValue
    if (propsValue) {
      setState((state) => ({ ...state, length: propsValue.length }))
    }
  }, [inputProps.value, inputProps.defaultValue])

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
      <Input {...inputProps} autoFocus={autoFocus} maxlength={maxLength} onChange={onChangeHandler}>
        {showCounter && (
          <div
            className={classNames(`${rootClass}__counter`, {
              [`${rootClass}__counter-labelled`]: label,
              [`${rootClass}__counter-small`]: size === InputSize.SMALL,
              [`${rootClass}__counter-error`]: showLimitError && length === maxLength,
            })}
          >
            <Typography type={lengthTextType} text={`${length}/${maxLength}`} lineHeight={lengthTextLineHeight} />
          </div>
        )}
      </Input>
    </div>
  )
}

export default InputWithMaxLength
