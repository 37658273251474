import React, { FC, useCallback, useMemo } from 'react'

import AssetPickerModal, { AssetPickerModalBaseProps, AssetPickerModalProps } from '@complex/AssetPickerModal/AssetPickerModal'
import { AssetPickerListingPageProps, AssetPickerSidebarProps, AssetPickerTableProps } from '@complex/AssetPickerModal/Context/AssetPicker.context'
import { CustomSource, RenderSearchColumnsV2 } from '@complex/ListingPage/Context/ListingPageCommon.context'
import { getCustomRequestFilters } from '@components/AssetPickers/CRMSourcePickerModal/CRMSourcePickerModal.filters'
import { getInfoBanner, renderTableColumns } from '@components/AssetPickers/CRMSourcePickerModal/CRMSourcePickerModal.tables'
import { useCRMSourcePickerModalRequests } from '@components/AssetPickers/CRMSourcePickerModal/GraphQL/CRMSourcePickerModal.graphQL'
import {
  getMainFilterRequestResultsUtils,
  getRecommendedSourcesRequestUtils,
  getSearchRequestResultsUtils,
} from '@components/AssetPickers/CRMSourcePickerModal/Utils/CRMSourcePickerModal.utils'
import { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import { useTranslation } from '@const/globals'
import { ItemType } from '@utils/categorization'
import { CRMSourceOption } from '@utils/crm.utils'
import { FilterDefinition, FilterTypes } from '@utils/filter'
import { CRMConnectorType } from '@utils/hooks/useCRM'
import { replaceAll } from '@utils/strings'

interface CRMSourcePickerModalProps extends AssetPickerModalBaseProps {
  crmSourceOptions: CRMSourceOption[]
  connectorType: CRMConnectorType
}

const CRMSourcePickerModal: FC<CRMSourcePickerModalProps> = (props) => {
  const { crmSourceOptions, connectorType } = props

  const { t } = useTranslation()

  const { getRecommendedSourcesRequest, getSearchSegmentSourcesRequest, getSegmentSourcesRequest } = useCRMSourcePickerModalRequests()

  const customSources = useMemo(
    () =>
      crmSourceOptions.map((option) => {
        const { name = '', title = '' } = option
        const displayTitle = title.toLowerCase().replace('salesforce', 'Salesforce')
        const allItemFilter: FilterDefinition = {
          name: t(`All ${displayTitle}s`),
          svgSelected: SvgNames.segmentsSelected,
          svgUnselected: SvgNames.segmentsUnselected,
        }
        return {
          allItemFilter,
          customRequestFilters: getCustomRequestFilters({
            customAllItemsFilter: allItemFilter,
            mainFilterRequest: () => getMainFilterRequestResultsUtils(getSegmentSourcesRequest, connectorType, option, t),
            recommendedRequest: () => getRecommendedSourcesRequestUtils(getRecommendedSourcesRequest, option, t),
            searchRequest: (search: string) => getSearchRequestResultsUtils(getSearchSegmentSourcesRequest, connectorType, option, search, t),
            recommendedSearchRequest: (search: string) => getRecommendedSourcesRequestUtils(getRecommendedSourcesRequest, option, t, search),
          }),
          filterInfoBanner: (items, filterActive, t) => getInfoBanner(items, filterActive, t, option),
          itemName: title,
          searchPlaceholder: displayTitle,
          label: t(`${title}s`),
          mainColumnName: t(`${replaceAll(name, '_', ' ')} Name`),
          value: name,
          sidebarProps: {
            hideTags: true,
            hideFolders: true,
          },
        } as CustomSource
      }),
    [connectorType, crmSourceOptions, getRecommendedSourcesRequest, getSearchSegmentSourcesRequest, getSegmentSourcesRequest, t]
  )

  const [selectedCustomSource, setSelectedCustomSource] = React.useState<CustomSource>(customSources[0])

  const getCustomDefaultEmptyListing = useCallback(
    () => ({
      imgSrc: StaticImageNames.emptyContacts,
      headline: t('We couldn’t find any sources'),
      text: t('Create a compatible report in your CRM.'),
      size: EmptyListingSize.LARGE,
      withoutBorder: true,
      fullHeight: true,
    }),
    [t]
  )

  const renderSearchColumns: RenderSearchColumnsV2 = (
    _searchInAllItems: boolean | undefined,
    _currentFolder: FolderData,
    search: string,
    _folders: FolderData[],
    selectedCustomSource?: CustomSource
  ) => renderTableColumns(selectedCustomSource?.mainColumnName ?? '', search)

  const tableProps: AssetPickerTableProps = {
    columns: renderTableColumns(selectedCustomSource.mainColumnName ?? ''),
    renderSearchColumns,
    listPage: 'AddContactsFromCRM',
    hasDisabledRowStyles: true,
    enableLazyLoading: false,
    onLoading: () => undefined,
    sortingBy: [{ id: 'name', desc: false }],
  }

  const sidebarProps: AssetPickerSidebarProps = {
    allItemFilter: customSources[0].allItemFilter ?? {
      name: FilterTypes.ALL_SALESFORCE_CAMPAIGNS,
      svgUnselected: SvgNames.segmentsUnselected,
      svgSelected: SvgNames.segmentsSelected,
    },
    customSources,
    hasCreatedByMe: false,
    hasFavorites: false,
    hasRecent: false,
    hasRecommended: true,
    hideFilterCount: true,
    hideFolders: true,
  }

  const listingPageProps: AssetPickerListingPageProps = {
    hasCustomRequests: true,
    sidebarProps,
  }

  const assetPickerProps: AssetPickerModalProps = {
    listingPageProps,
    tableProps,
    enableSubmitWithoutSelection: true,
    getCustomDefaultEmptyListing,
    onSelectedCustomSourceChange: (source) => setSelectedCustomSource(source),
    hasCustomFilters: false,
    hasPreview: false,
    hasSidebar: true,
    hasTags: false,
    itemType: ItemType.SEGMENT,
    hideRecordsCount: true,
    restrictSelectionToOneSource: true,
    ...props,
  }

  return <AssetPickerModal {...assetPickerProps} />
}

export default CRMSourcePickerModal
