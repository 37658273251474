import { Dispatch, MutableRefObject, SetStateAction, useContext } from 'react'

import { ScheduleType } from '@complex/ScheduleSelector/utils/ScheduleSelector.utils'
import { Status } from '@components/StatusToast/StatusToast'
import { ListMaintenanceProgramInput } from '@graphql/types/mutation-types'
import { Program, ProgramStatus, ProgramStep } from '@graphql/types/query-types'
import { ProgramStepsAndDetailsContainerState } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/ProgramStepsAndDetailsContainer'
import { getModifiedSteps } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/utils/ListProgramSaveHandler.utils'
import { useProgramStepsAndDetailsQueries } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/utils/ProgramStepsAndDetails.graphQL'
import { setProgramErrors } from '@src/pages/listingPages/ListMaintenancePrograms/components/ProgramStepsAndDetails/utils/ProgramStepsAndDetails.utils'
import { ListMaintenanceProgramsContext } from '@src/pages/listingPages/ListMaintenancePrograms/context/ListMaintenancePrograms.context'
import { ListMaintenanceProgramDto } from '@utils/listingPage/listMaintenancePrograms.utils'
import { logNewRelicError } from '@utils/new-relic.utils'

interface UseListProgramSaveHandlerProps {
  programDetailsBackup?: Program
  deletedSteps: ProgramStep[]
  getProgramErrors: (program?: ListMaintenanceProgramInput) => Promise<ProgramStatus | undefined>
  loadProgram: (useCache?: boolean) => void
  setState: Dispatch<SetStateAction<ProgramStepsAndDetailsContainerState>>
  showSuccessToast: () => void
  stepsDeletedFieldChecked: MutableRefObject<boolean>
  stepsMissedListChecked: MutableRefObject<boolean>
}

export const useListProgramSaveHandler = (props: UseListProgramSaveHandlerProps) => {
  const {
    loadProgram,
    getProgramErrors,
    showSuccessToast,
    setState,
    deletedSteps,
    programDetailsBackup,
    stepsDeletedFieldChecked,
    stepsMissedListChecked,
  } = props

  const {
    update,
    values: { program, programDetails, hasMarketingSource = false },
  } = useContext(ListMaintenanceProgramsContext)

  const { saveProgramRequest } = useProgramStepsAndDetailsQueries()

  const getProgramInput = (): ListMaintenanceProgramInput => ({
    id: program?.externalId,
    name: programDetails?.name,
    description: programDetails?.description,
    sourceId: programDetails?.sourceList?.length ? programDetails.sourceList[0].id : undefined,
    schedule: programDetails?.schedule,
    scheduleChanged: programDetails?.schedule !== programDetailsBackup?.schedule,
    steps: getModifiedSteps(programDetails?.steps as ProgramStep[], hasMarketingSource),
    deletedSteps: deletedSteps.map(({ stepId }) => stepId),
    timeZoneId: programDetails?.timeZoneId,
  })

  const saveProgram = (programInputData: ListMaintenanceProgramInput, isValid?: boolean) => {
    setState((state) => ({ ...state, loading: true }))
    saveProgramRequest(programInputData)
      .then(() => {
        loadProgram(false)
        setState((state) => ({ ...state, deletedSteps: [] }))
        update({
          stepBeingEditedIndex: undefined,
          isEditing: false,
          program: {
            ...program,
            description: programInputData.description as string,
            scheduled: programInputData.schedule?.type !== ScheduleType.MANUALLY,
            name: programInputData.name as string,
            valid: isValid ?? program?.valid ?? false,
          } as ListMaintenanceProgramDto,
        })
        showSuccessToast()
      })
      .catch((error) => {
        logNewRelicError(error)
        setState((state) => ({ ...state, loading: false }))
        update({
          statusToast: {
            statusMessage: 'Sorry, an error occurred while trying to save your program.',
            status: Status.FAIL,
            showStatusToast: true,
          },
        })
      })
  }

  const onCancel = () => {
    update({
      isEditing: false,
      programDetails: programDetailsBackup,
      stepBeingEditedIndex: undefined,
    })
    setState((state) => ({ ...state, deletedSteps: [] }))
  }

  const onSave = (checkForErrors = true) => {
    if (program && programDetails) {
      const programInputData: ListMaintenanceProgramInput = getProgramInput()
      setState((state) => ({ ...state, loading: true }))
      if (checkForErrors) {
        getProgramErrors(programInputData)
          .then((programStatus) => {
            if (programStatus) {
              setProgramErrors(programStatus, hasMarketingSource, update, stepsDeletedFieldChecked, stepsMissedListChecked)
              if (programStatus.isRunning) {
                setState((state) => ({ ...state, loading: false }))
                update({
                  statusToast: {
                    statusMessage: 'The program is currently running. Please try again later.',
                    status: Status.WARNING,
                    showStatusToast: true,
                  },
                })
              } else {
                if (programStatus.valid) {
                  saveProgram(programInputData, true)
                } else {
                  setState((state) => ({ ...state, loading: false }))
                  update({ program: { ...program, valid: false }, showErrorsModal: true })
                }
              }
            }
          })
          .catch((error) => {
            logNewRelicError(error)
            setState((state) => ({ ...state, loading: false }))
            update({
              statusToast: {
                statusMessage: 'Sorry, an error occurred while trying to save your program.',
                status: Status.FAIL,
                showStatusToast: true,
              },
            })
          })
      } else {
        saveProgram(programInputData)
      }
    }
  }

  return {
    onCancel,
    onSave,
  }
}
