import React, { FC, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import FormRow from '@components/FormRow'
import InfoStatus, { InfoStatusTypes } from '@components/InfoStatus/InfoStatus'
import InputWithStatus, { REQUIRED_MESSAGE_KEY } from '@components/InputWithStatus/InputWithStatus'
import Label from '@components/Label'
import { SvgNames } from '@components/Svg'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { MutationUpdateUserPasswordArgs, UpdateUserPassword } from '@graphql/types/mutation-types'
import { PwdPolicyResponse, SecurityPageResponse } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import { useAccountSettings } from '@utils/account/account.utils'

import ComplexityValidationDisplay from './components/ComplexityValidationDisplay/ComplexityValidationDisplay'
import ShowHidePasswordButton from './components/ShowHIdeButton/ShowHidePasswordButton'
import { getComplexityValidators, getErrorMessages, PASSWORD_VALIDATION_MESSAGES, PasswordErrorTypesEnum } from './utils/security-validation.utils'
import { getExpirationPeriod, getExpirationWarningText, getPasswordCreationText } from './utils/security.utils'

import './Security.css'

export interface SecurityProps {
  dataTest?: string
  policy?: PwdPolicyResponse
  passwordData?: SecurityPageResponse
  setToastStatus: (value: StatusToastType) => void
  loading: boolean
  onSave: (data: MutationUpdateUserPasswordArgs) => Promise<any>
  onRefresh: () => Promise<any>
}

const rootClass = 'security'

const Security: FC<SecurityProps> = (props: SecurityProps) => {
  const { dataTest = rootClass, loading, policy, passwordData, onSave, setToastStatus, onRefresh } = props
  const [passwords, setPasswords] = useState<{ oldPassword: string; newPassword: string; confirmationPassword: string }>({
    oldPassword: '',
    newPassword: '',
    confirmationPassword: '',
  })
  const [initialInputState, setInitialInputState] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [errorTextNewPassword, setErrorTextNewPassword] = useState<string[]>([])
  const [errorTextOldPassword, setErrorTextOldPassword] = useState<string>('')
  const [showComplexityError, setShowComplexityError] = useState<boolean>(false)
  const [updateConfPassword, setUpdateConfPassword] = useState<boolean>(false)
  const [hideConfirmationStatus, setHideConfirmationStatus] = useState<boolean>(false)

  const accountSettings = useAccountSettings()

  const { t } = useTranslation()

  const onInputChange = (value: string, key: string) => {
    initialInputState && setInitialInputState(false)
    setPasswords({ ...passwords, [key]: value })
  }

  const confirmationPasswordValidator = (confirmationPassword = '') => {
    if (confirmationPassword !== passwords.newPassword) {
      return { errorMessageKey: 'confirmation' }
    }
  }

  const newPasswordValidator = (v: string) => {
    if (getComplexityValidators(policy?.complexity ?? '', +(policy?.length ?? 0)).some((validators) => !validators(v))) {
      return { errorMessageKey: 'complexity' }
    }
  }

  const getErrorTemplate = (messages: string[], isToast: boolean) => {
    return (
      <div
        className={classNames(`${rootClass}__error-container`, {
          [`${rootClass}__error-container-toast`]: isToast,
        })}
      >
        {isToast ? (
          <Typography text={t(messages[0])} key={messages[0]} type={TextType.BODY_TEXT} />
        ) : (
          messages.map((message) => <Typography text={t(message)} key={message} type={TextType.BODY_TEXT_WHITE} />)
        )}
      </div>
    )
  }

  const getButtonDisabled = () => {
    return (
      passwords.oldPassword.length < 1 ||
      getComplexityValidators(policy?.complexity ?? '', +(policy?.length ?? 0)).some((validators) => !validators(passwords.newPassword)) ||
      !!confirmationPasswordValidator(passwords?.confirmationPassword)
    )
  }

  const refresh = () => {
    setPasswords({
      oldPassword: '',
      newPassword: '',
      confirmationPassword: '',
    })
    setShowPassword(false)
    setShowComplexityError(false)
    setInitialInputState(true)
  }

  const handleSuccessResponse = () => {
    setToastStatus({
      showStatus: true,
      title: t('Success!'),
      statusMessage: t(`Your password has been changed.`),
      successStatus: true,
    })
    onRefresh().then(() => {
      refresh()
    })
  }

  const handleErrorResponse = (res: UpdateUserPassword) => {
    if (res.status === 'error' && res?.errorTypes && res?.errorTypes.includes(PasswordErrorTypesEnum.OLD_PASSWORD)) {
      const message = PASSWORD_VALIDATION_MESSAGES.get(PasswordErrorTypesEnum.OLD_PASSWORD)
      setErrorTextOldPassword(message?.validationMessage ?? '')
      setToastStatus({
        showStatus: true,
        title: t('Password not updated:'),
        statusMessage: t(message?.toasterMessage),
        successStatus: false,
      })
    }

    if (res.status === 'error' && res?.errorTypes && res?.errorTypes.length > 0) {
      const validationMessage = getErrorMessages(
        res?.errorTypes.filter((v) => v !== PasswordErrorTypesEnum.OLD_PASSWORD) as PasswordErrorTypesEnum[]
      ).map((p) => p.validationMessage)
      const toasterMessage = getErrorMessages(res?.errorTypes as PasswordErrorTypesEnum[]).map((p) => p.toasterMessage)
      setErrorTextNewPassword(validationMessage)
      setToastStatus({
        showStatus: true,
        title: t('Password not updated:'),
        statusMessage: getErrorTemplate(toasterMessage, true),
        successStatus: false,
      })
    }
  }

  const handleResponse = (res: UpdateUserPassword) => {
    res.status === 'ok' ? handleSuccessResponse() : handleErrorResponse(res)
  }

  const validateConfirmationPassword = (newPassword: string, confirmPassword: string, update = false) => {
    const passwordMatch = newPassword === confirmPassword
    const hasValue = confirmPassword.length > 0
    const complexityInvalid = getComplexityValidators(policy?.complexity ?? '', +(policy?.length ?? 0)).some((validators) => !validators(newPassword))
    const hide = complexityInvalid && passwordMatch && hasValue

    setHideConfirmationStatus(hide)
    if (update) {
      setUpdateConfPassword(!hide && hasValue)
    }
  }

  return (
    <>
      {!loading && policy && (
        <div className={rootClass} data-test={dataTest}>
          <Typography text={t('Change your password')} type={TextType.SECTION_HEADER} />
          {!accountSettings.hasExternalUserAuth ? (
            <>
              {passwordData?.passwordChangeDataMs && passwordData?.passwordChangeDataMs?.length > 0 && (
                <>
                  <Typography
                    className={`${rootClass}-description`}
                    dataTest={`${dataTest}-description`}
                    text={getPasswordCreationText(t, passwordData as SecurityPageResponse)}
                    type={TextType.BODY_TEXT_LIGHT}
                  />
                  {getExpirationPeriod(passwordData as SecurityPageResponse) >= 0 &&
                    getExpirationWarningText(passwordData as SecurityPageResponse, t) && (
                      <Caution message={getExpirationWarningText(passwordData as SecurityPageResponse, t)} />
                    )}
                  {getExpirationPeriod(passwordData as SecurityPageResponse) < 0 && (
                    <InfoStatus
                      status={InfoStatusTypes.Warning}
                      svgName={SvgNames.cautionError}
                      message={
                        <Typography
                          text={t(
                            'Your Act-On password has expired because you haven’t changed it in a while. You’ll need to create a new one to continue.'
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
              <form className={`${rootClass}-form`}>
                <FormRow className={`${rootClass}-form-row-with-action`}>
                  <div className={`${rootClass}__input-label-container`}>
                    <Label className={`${rootClass}__input-label`}>{t('Current password')}</Label>
                    <ShowHidePasswordButton
                      className={`${rootClass}__button-show`}
                      dataTest={dataTest}
                      value={showPassword}
                      onClick={(v) => setShowPassword(v)}
                    />
                  </div>
                  <InputWithStatus
                    placeholder={t('Enter your current password')}
                    value={passwords.oldPassword}
                    type={showPassword ? 'text' : 'password'}
                    dataTest={`${rootClass}-current-password-input`}
                    index={1}
                    autoComplete={'current-password'}
                    errorMessages={{ [REQUIRED_MESSAGE_KEY]: 'Please enter your current password.' }}
                    customErrorMessage={'The password you entered is incorrect. Please try again.'}
                    onChange={(event) => {
                      errorTextOldPassword.length > 0 && setErrorTextOldPassword('')
                      onInputChange(event.target.value, 'oldPassword')
                    }}
                    hasCustomError={!!errorTextOldPassword}
                    customTooltipErrorMessages={errorTextOldPassword}
                    onBlur={() => {
                      if (passwords.oldPassword.length < 1) {
                        setErrorTextOldPassword('Please enter your current password.')
                      }
                    }}
                    onFocus={() => {
                      setErrorTextOldPassword('')
                    }}
                    showStatus={!!errorTextOldPassword}
                    markPristine={initialInputState}
                    tooltipErrorMessages={{ [REQUIRED_MESSAGE_KEY]: 'Please enter your current password.' }}
                  />
                </FormRow>
                <FormRow>
                  <Label className={`${rootClass}__input-label`}>{t('New password')}</Label>
                  <InputWithStatus
                    placeholder={t('Enter a strong password')}
                    value={passwords.newPassword}
                    type={showPassword ? 'text' : 'password'}
                    dataTest={`${rootClass}-new-password-input`}
                    required
                    index={2}
                    autoComplete={'new-password'}
                    onChange={(event) => {
                      onInputChange(event.target.value, 'newPassword')
                      validateConfirmationPassword(event.target.value, passwords.confirmationPassword, true)
                    }}
                    tooltipErrorMessages={{
                      [REQUIRED_MESSAGE_KEY]: 'Your password is not strong enough. New passwords must meet the requirements listed below.',
                      complexity: 'Your password is not strong enough. New passwords must meet the requirements listed below.',
                    }}
                    customTooltipErrorMessages={getErrorTemplate(errorTextNewPassword, false)}
                    hasCustomError={errorTextNewPassword.length > 0}
                    onFocus={() => {
                      if (errorTextNewPassword.length > 0) {
                        setErrorTextNewPassword([])
                      }
                      setShowComplexityError(false)
                    }}
                    onBlur={() => {
                      setShowComplexityError(true)
                    }}
                    markPristine={initialInputState}
                    validityFunctions={[newPasswordValidator]}
                  />
                </FormRow>
                <ComplexityValidationDisplay
                  dataTest={`${dataTest}-validation-display`}
                  complexity={policy.complexity}
                  value={passwords?.newPassword ?? ''}
                  length={+policy.length}
                  showError={showComplexityError}
                />
                <FormRow>
                  <Label className={`${rootClass}__input-label`}>{t('Confirm password')}</Label>
                  <InputWithStatus
                    placeholder={t('Re-enter your new password')}
                    type={showPassword ? 'text' : 'password'}
                    value={passwords.confirmationPassword}
                    required
                    dataTest={`${rootClass}-confirm-password-input`}
                    showErrorOnChange
                    autoComplete={'new-password'}
                    index={3}
                    errorMessages={{
                      confirmation: 'Those passwords don’t match. Try again.',
                      [REQUIRED_MESSAGE_KEY]: 'Please confirm the new password.',
                    }}
                    onChange={(event) => {
                      onInputChange(event.target.value, 'confirmationPassword')
                      validateConfirmationPassword(passwords.newPassword, event.target.value)
                    }}
                    customTooltipErrorMessages={'This confirmation password doesn’t match the password you entered above'}
                    customErrorMessage={'Those passwords don’t match. Try again.'}
                    reload={updateConfPassword}
                    onReload={() => setUpdateConfPassword(false)}
                    showStatus={!hideConfirmationStatus}
                    tooltipErrorMessages={{
                      confirmation: 'This confirmation password doesn’t match the password you entered above',
                      [REQUIRED_MESSAGE_KEY]: 'Please confirm the new password you entered above.',
                    }}
                    markPristine={initialInputState}
                    validityFunctions={[confirmationPasswordValidator]}
                  />
                </FormRow>
              </form>
              <Button
                buttonType={ButtonType.PRIMARY}
                className={`${rootClass}__button`}
                dataTest={`${dataTest}-button-save`}
                disabled={getButtonDisabled()}
                onClick={() => {
                  onSave({ confirmationPassword: passwords.oldPassword, newPassword: passwords.newPassword, id: accountSettings.userId }).then(
                    (response: { data: { updateUserPassword: UpdateUserPassword } }) => {
                      const { updateUserPassword } = response.data
                      handleResponse(updateUserPassword)
                    }
                  )
                }}
              >
                {t('Update password')}
              </Button>
            </>
          ) : (
            <Typography
              className={`${rootClass}-description`}
              dataTest={`${dataTest}-description`}
              text={t('Please contact your company’s IT department to change password')}
              type={TextType.BODY_TEXT_LIGHT}
            />
          )}
        </div>
      )}
    </>
  )
}

export default Security
