import React, { FC } from 'react'

import classNames from 'classnames'

import { ButtonType } from '@components/Button'
import Button from '@components/Button/Button'
import Caution from '@components/Caution/Caution'
import { ModalBody } from '@components/Modal'
import { ModalFooter, ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeader, ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Modal from '@components/Modal/Modal'
import { SvgNames, SvgType } from '@components/Svg'
import Svg from '@components/Svg/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import ProgramStepIcon from '@src/pages/programs/edit/components/ProgramFlow/components/ProgramStepIcon/ProgramStepIcon'
import { ProgramErrors, ProgramStepError } from '@utils/program/program.constants'
import { ProgramStepType } from '@utils/program/program.constants'

import './ProgramErrorsModal.css'

export interface ProgramErrorsModalProps {
  errors: ProgramErrors
  isListMaintenanceProgram?: boolean
  isSaving: boolean
  fixErrors: () => void
  closeModal: () => void
  className?: string
  dataTest?: string
  disabled?: boolean
  hasStepPrefix?: boolean
}

const rootClass = 'program-errors-modal'

const ProgramErrorsModal: FC<ProgramErrorsModalProps> = (props: ProgramErrorsModalProps) => {
  const {
    errors,
    isListMaintenanceProgram = false,
    isSaving,
    fixErrors,
    closeModal,
    dataTest = rootClass,
    disabled,
    className = '',
    hasStepPrefix = true,
  } = props
  const { t } = useTranslation()

  const renderStepError = (err: ProgramStepError) =>
    renderError(`${hasStepPrefix ? 'Step ' : ''}${err.name}`, err.errors, err.stepType ?? ProgramStepType.START, err.showAOContactsIcon)

  const renderError = (errorTitle: string, errors: string[], stepType?: ProgramStepType, showAOContactsIcon = false) => (
    <div className={`${rootClass}__card-wrapper`} key={errorTitle.replace(/ /g, '_')}>
      <div className={`${rootClass}__card-title`} data-test={`${dataTest}-error-title`}>
        {stepType && <ProgramStepIcon stepType={stepType} className={`${rootClass}__svg`} aOContactsSource={showAOContactsIcon} />}
        {!stepType && <Svg name={SvgNames.error} type={SvgType.MEDIUM} className={`${rootClass}__svg`} />}
        <Typography text={t(errorTitle || '(undefined)')} weight={TextWeight.MEDIUM} />
      </div>
      <div className={`${rootClass}__card-body`}>
        {errors.map((error, index) => (
          <Typography className={`${rootClass}__card-message`} key={`${error}-${index}`} text={error} />
        ))}
      </div>
    </div>
  )

  return (
    <Modal
      isOpen
      header={<ModalHeader headerType={ModalHeaderType.Form}>{t('Your program has errors')}</ModalHeader>}
      className={classNames(rootClass, className)}
      data-test={dataTest}
    >
      <ModalBody>
        <Caution
          message={t(
            isSaving
              ? `We detected some problems in your ${
                  isListMaintenanceProgram ? 'list maintenance ' : ' '
                }program. You can save this program as a draft, but you won’t be able to run it until all of these errors are resolved.`
              : `You'll need to remove these errors before starting your program`
          )}
        />
        {errors.mainErrors.length > 0 && renderError('Main errors', errors.mainErrors)}
        {errors.exitErrors.length > 0 && renderError('Exit errors', errors.exitErrors)}
        {errors.stepErrors.length > 0 && errors.stepErrors.map((err) => renderStepError(err))}
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button dataTest={`${dataTest}-close-button`} buttonType={ButtonType.TERTIARY} onClick={closeModal}>
          {t(isSaving ? 'Resume editing' : 'Cancel')}
        </Button>
        <Tooltip
          hide={!disabled}
          triggerClassName={`${rootClass}__fix-errors-trigger`}
          trigger={
            <Button dataTest={`${dataTest}-fix-errors`} buttonType={ButtonType.PRIMARY} onClick={fixErrors} disabled={disabled}>
              {t(isSaving ? 'Save as draft' : 'Fix Errors')}
            </Button>
          }
        >
          {t('Ask your administrator for permission to do this')}
        </Tooltip>
      </ModalFooter>
    </Modal>
  )
}

export default ProgramErrorsModal
