import { ItemType } from '@utils/categorization'

export const getItemResponse = {
  authorId: '',
  createdTime: '',
  enabled: true,
  externalId: 'q-0001',
  folderId: null,
  id: 1,
  isFavorite: false,
  item: '{"id": "q-0001", "name": "Test Segment", "type": "Query", "parent": "", "isEditable": true, "recordsCount": 50, "lastCountDate": 1660762016846}',
  labels: [],
  parentId: null,
  position: 1,
  updatedTime: '',
  views: 1,
  __typename: 'ItemDTO',
}

export const getColumnIndexOrderResponse = {
  fixed: [1, 2],
  hidden: [],
  order: [0, 1, 2, 4],
  __typename: 'SegmentColumnSpecDTO',
}

export const getContactsResponse = {
  headers: ['Last Name', 'First name', 'E-mail Address', 'Act-On Primary Score'],
  contacts: {
    'l-unified-contacts:83': ['', '', '', '', '', '', '', '', '', 'Jan 10 2023 11:00 Chile/Continental', 'Jan 10 2023 11:00 Chile/Continental'],
    'l-unified-contacts:84': ['', '', '', '', '', '', '', '', '', 'Jan 10 2023 11:00 Chile/Continental', 'Jan 10 2023 11:00 Chile/Continental'],
    'l-unified-contacts:85': ['', '', '', '', '', '', '', '', '', 'Jan 10 2023 11:00 Chile/Continental', 'Jan 10 2023 11:00 Chile/Continental'],
    'l-unified-contacts:86': ['', '', '', '', '', '', '', '', '', 'Jan 10 2023 11:00 Chile/Continental', 'Jan 10 2023 11:00 Chile/Continental'],
  },
  totalCount: 20,
  rowIds: {
    'l-unified-contacts:8d': 141,
    'l-unified-contacts:9d': 157,
    'l-unified-contacts:9c': 156,
    'l-unified-contacts:9b': 155,
  },
  hasNextPage: false,
  __typename: 'Page',
}

export const contactDetailsMockProps = {
  segment: {
    id: 1,
    isEditable: true,
    isFavorite: false,
    externalId: 'q-0001',
    lastCountDate: '',
    name: 'Test Segment',
    parent: undefined,
    recordsCount: 10,
    tags: [],
    type: 'Segment',
    updatedTime: '',
    position: 1,
    itemType: ItemType.SEGMENT,
  },
  segmentContacts: {
    headers: ['Last Name', 'First Name', 'Email'],
    contacts: Object.fromEntries(
      [...Array(10).keys()].map((idx) => {
        return [`key-${idx}`, [`user ${idx} last name`, `user ${idx} first name`, `user${idx}@test.com`]]
      })
    ),
    totalCount: 10,
  },
  tags: [],
  movingItem: false,
}
