import { LabelDto } from '@graphql/types/microservice/categorization-types'

export enum SegmentSyncType {
  DYNAMIC = 'dynamic',
  STATIC = 'static',
}

export interface SegmentInput {
  createdBy?: string
  createdDate?: number
  description: string
  folderId?: number
  id?: string
  isDirectSelect: boolean
  labels: LabelDto[]
  lastModifiedBy?: string
  lastModifiedDate?: number
  name: string
  syncType?: SegmentSyncType
}
