import { useMemo } from 'react'

import { SvgNames } from '@components/Svg'
import { RowAction } from '@components/Table/Table'
import { useTranslation } from '@const/globals'
import { LogoItem } from '@graphql/types/query-types'

type RowActionClick = (logo: LogoItem) => void

export const useRowActions = (
  handlePreview: RowActionClick,
  handleEdit: (logo: LogoItem, isDuplicate: boolean) => void,
  handleDelete: RowActionClick,
  handleOpenWithEditor: RowActionClick,
  handleUpdateLogoDefault: RowActionClick
) => {
  const { t } = useTranslation()
  return useMemo<RowAction[]>(
    () => [
      {
        label: t('Preview'),
        icon: SvgNames.previewOn,
        onClick: (row) => handlePreview(row.original),
      },
      {
        label: t('Edit'),
        icon: SvgNames.pencil,
        onClick: (row) => handleEdit(row.original, false),
      },
      {
        label: t('Delete'),
        icon: SvgNames.delete,
        onClick: (row) => handleDelete(row.original),
      },
      {
        label: t('Duplicate logo'),
        icon: SvgNames.cloneSegment,
        onClick: (row) => handleEdit(row.original, true),
        inDropdown: true,
      },
      {
        label: t('Open with image editor'),
        icon: SvgNames.image,
        onClick: (row) => handleOpenWithEditor(row.original),
        inDropdown: true,
      },
      {
        label: (row) => (row.original.isDefault ? t('Remove as default') : t('Set as default')),
        onClick: (row) => handleUpdateLogoDefault(row.original),
        inDropdown: true,
        topSection: true,
      },
    ],
    [t]
  )
}
