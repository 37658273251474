import React, { ReactNode } from 'react'

import Tooltip from '@components/Tooltip/Tooltip'

export function getCountDisplay(count?: number): string {
  if (!count) return '0'
  if (count >= 1000000000) {
    const roundedBil = Math.round((count / 1000000000) * 1000) / 1000
    const isInt = roundedBil % 1 === 0
    return `${isInt ? roundedBil.toFixed(1) : roundedBil}b`
  } else if (count >= 1000000) {
    return `${(count / 1000000).toFixed(1)}m`
  } else if (count >= 1000) {
    return `${(count / 1000).toFixed(1)}k`
  }
  return `${count}`
}

export function getFormattedNumber(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatNumber = (num: number) => {
  return Intl.NumberFormat().format(Math.round(num))
}

export function getCountDisplayWithTooltip(count?: number): ReactNode {
  if (!count) return '0'
  const countToDisplay = getCountDisplay(count)

  if (countToDisplay.length < 4) {
    // less than 4 characters
    return countToDisplay
  }

  return <Tooltip trigger={<div>{countToDisplay}</div>}>{getFormattedNumber(count)}</Tooltip>
}

export const randomNumber = (max: number) => Math.floor(Math.random() * max)

export function getFormattedCount(count: number) {
  let formattedCount

  if (!count) return '0'
  if (count >= 1000000000) {
    formattedCount = `${(count / 1000000000).toFixed(1)}b`
  } else if (count >= 1000000) {
    formattedCount = `${(count / 1000000).toFixed(1)}m`
  } else {
    formattedCount = getFormattedNumber(count)
  }

  return `${formattedCount}`.replace('.', ',')
}

/** Returns the char that separates decimal numbers (ex: 1.1 returns '.') */
export const getLocaleDecimalChar = () => {
  const number = 1.1
  return number.toLocaleString()[1] ?? '.'
}

/** Returns the char that separates large numbers (ex: 1,000,000 returns ',') */
export const getLocaleRadixChar = () => {
  const number = 1000
  return number.toLocaleString()[1] ?? ','
}

export default {
  getCountDisplay,
  getCountDisplayWithTooltip,
  getFormattedNumber,
  formatNumber,
  randomNumber,
  getFormattedCount,
}
