import React, { FC, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'

import classNames from 'classnames'

import CustomPrompt from '@components/CustomPrompt/CustomPrompt'
import DropDown, { DropdownMenuItem, DropdownMenuLabel } from '@components/DropDown'
import Svg, { SvgColor } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Action } from '@interface/Action'
import { filterNotEmptyArray } from '@utils/array'
import { getUUID } from '@utils/const/globals'
import iframe from '@utils/iframe/iframe'

import { searchTypes, DEFAULT_SEARCH_OPTIONS, SearchOption, SearchType } from './TopBarSearch.utils'

import './TopBarSearch.css'

/**
 * Props for the TopBarSearch component
 *
 * @interface TopBarSearchProps
 * @property {Function} setIframeVisibility - Shows/hides the iframe
 * @property {Function} setPendingIFrameRequest - Indicates that a message is to be sent to the acton classic iframe
 * @property {boolean} hasContactSearch - Flag indicating whether the account has contact search available
 * @property {boolean} hasSearch - Flag indicating whether to show/hide search (sales users shouldn't see search bar)
 */
interface TopBarSearchProps {
  setPendingIFrameRequest: (iframeMessage?: unknown) => void
  setIframeVisibility: (visible: boolean) => Action<boolean>
  hasContactSearch: boolean
  hasSearch: boolean
  dataTest?: string
  className?: string
}

const IMPORT_CONTACTS_MAPPING_PATH = 'importContacts/mapping'

const rootClass = 'top-bar-search'

/**
 * Component for searching different types of assets and contacts globally through the TopBar
 *
 * @component
 * @param {TopBarSearchProps} props - The props for the TopBarSearch component.
 *
 * @example
 * <TopBarSearch
 *   setPendingIFrameRequest={setPendingIFrameRequest}
 *   setIframeVisibility={setIframeVisibility}
 *   hasContactSearch={true}
 *   hasSearch={true}
 * />
 */
export const TopBarSearch: FC<TopBarSearchProps> = (props: TopBarSearchProps) => {
  const { hasSearch, hasContactSearch, setPendingIFrameRequest, setIframeVisibility } = props

  const { t } = useTranslation()
  const location = useLocation()
  const prevPathname = useRef<string>()
  const inputRef = useRef<HTMLInputElement>(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [searchOptions, setSearchOptions] = useState<SearchOption[]>(DEFAULT_SEARCH_OPTIONS)
  const [customPromptId, setCustomPromptId] = useState<string>(getUUID())
  const [triggerCustomPrompt, setTriggerCustomPrompt] = useState<boolean>(false)
  const [currentSearch, setCurrentSearch] = useState<SearchOption>(
    hasContactSearch ? searchTypes[SearchType.CONTACTS] : searchTypes[SearchType.ASSETS]
  )

  useEffect(() => {
    const searchTypeFromPathname = (): SearchOption | undefined => {
      if (location.pathname.includes('classic')) {
        const classicSearch = location.pathname.split('/').pop()?.split('.').shift()

        return searchTypes[classicSearch as SearchType]
      }
    }

    if (prevPathname.current !== location.pathname) {
      const customSearchOption = searchTypeFromPathname()
      prevPathname.current = location.pathname
      const searchOptions = customSearchOption ? [customSearchOption, ...DEFAULT_SEARCH_OPTIONS] : DEFAULT_SEARCH_OPTIONS

      setCurrentSearch(customSearchOption ?? searchTypes[SearchType.CONTACTS])
      setSearchOptions(searchOptions)
    }
  }, [location.pathname])

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (window.location.pathname.includes(IMPORT_CONTACTS_MAPPING_PATH)) {
        setCustomPromptId(getUUID())
        setTriggerCustomPrompt(true)
      } else {
        postMessage()
      }
    }
  }

  const postMessage = () => {
    iframe.postMessage(
      {
        actonSearchQuery: `${inputRef.current?.value}`,
        actonSearchType: currentSearch.value,
        searchByEmitting: false,
      },
      setPendingIFrameRequest
    )

    setIframeVisibility(true)

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.value = ''

        if (currentSearch == searchTypes[SearchType.CONTACTS] || currentSearch == searchTypes[SearchType.ASSETS]) {
          setSearchOptions(DEFAULT_SEARCH_OPTIONS)
        }
      }
    }, 100)
  }

  const renderSearchOptions = () => {
    return searchOptions
      .map((searchType, i) => {
        if (searchType.value === SearchType.CONTACTS && !hasContactSearch) {
          return null
        }

        const isCurrent = currentSearch.value === searchType.value

        return (
          <DropdownMenuItem
            data-test={`search-drop-down-item-${i}`}
            key={searchType.value}
            className={classNames(`${rootClass}__option`, `${rootClass}__menu-item`, [
              {
                [`${rootClass}__option--selected`]: !!isCurrent,
              },
            ])}
            onSelect={() => {
              setCurrentSearch(searchType)
              setIsOpen(false)
            }}
          >
            {isCurrent && <Svg name={SvgNames.check} className={`${rootClass}__check`} />}
            <DropdownMenuLabel>
              <Typography text={t(searchType.label)} type={TextType.BODY_TEXT_GRAY} />
            </DropdownMenuLabel>
          </DropdownMenuItem>
        )
      })
      .filter(filterNotEmptyArray)
  }

  return (
    <div id="search-bar" data-test="search-bar" className={rootClass}>
      <CustomPrompt
        key={customPromptId}
        when={triggerCustomPrompt}
        title={t('TopBar.Search.CustomPrompt.Title')}
        body={t('TopBar.Search.CustomPrompt.Body')}
        okButtonText={t('TopBar.Search.CustomPrompt.OkButtonText')}
        searchAction={postMessage}
        searchPerformed={triggerCustomPrompt}
      />
      {hasSearch && (
        <div className={`${rootClass}__bar`}>
          <DropDown
            dataTest="search-drop-down"
            label={<Svg name={SvgNames.search} className={`${rootClass}__search-icon`} fill={SvgColor.LIGHT_GRAY} />}
            className={`${rootClass}__drop-down`}
            alignRight
            isOpen={isOpen}
            toggleOpen={setIsOpen}
            modal={false}
          >
            {renderSearchOptions()}
          </DropDown>
          <input
            type="text"
            placeholder={t(currentSearch.label ?? 'Search')}
            data-test="search-input"
            onKeyDown={handleKeyPress}
            ref={inputRef}
            className={`${rootClass}__input`}
          />
        </div>
      )}
    </div>
  )
}
