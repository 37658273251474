import React, { FC, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import DropDown from '@components/DropDown'
import { DropDownType } from '@components/DropDown/DropDown'
import DropDownActions, { MenuItem } from '@components/DropDownActions/DropDownActions'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { RowAction } from '@components/Table/Table'
import { ActionType } from '@components/TableV2/tableV2TS/enums'
import { splitActions } from '@components/TableV2/utils/tableV2Utils'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { toTrainCase } from '@utils/strings'

import './RowActions.css'

interface Props {
  className?: string
  dataTest?: string
  actions: RowAction[]
}

const rootClass = 'row-actions'

const RowActions: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '', actions = [] } = props
  const { t } = useTranslation()
  const [isCursorHovering, setIsCursorHovering] = useState<boolean>(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const [primaryRowActions, secondaryRowActions] = useMemo(() => splitActions(actions, undefined, ActionType.FolderOrTag), [actions])

  const dropdownActions = secondaryRowActions.map(({ icon, label, onClick }) => {
    return { icon, onClick, text: label } as MenuItem
  })
  const closeDropDown = () => setDropdownOpen(!dropdownOpen)
  return (
    <div className={classNames(rootClass, className)}>
      <div className={`${rootClass}__container`} data-test={dataTest}>
        {primaryRowActions.map((action, index) => {
          const iconIsSvgNames = typeof action.icon !== 'function'
          const buttonDataTest = `${dataTest}-${toTrainCase(action.label as string)}-button`
          return (
            <Tooltip
              triggerClassName={`${rootClass}__tooltip`}
              key={`${action.label}-${index}-action`}
              position={'top'}
              trigger={
                <Button
                  dataTest={buttonDataTest}
                  className={`${rootClass}__button`}
                  buttonType={ButtonType.TABLE_ACTION}
                  onClick={(e) => {
                    action.onClick && action.onClick(e as any)
                    e.stopPropagation()
                  }}
                  isLink={!!action.link}
                  to={action.link}
                >
                  {action.icon && iconIsSvgNames && <Svg name={action.icon as SvgNames} type={SvgType.LARGER_ICON} />}
                </Button>
              }
            >
              {t(action.label)}
            </Tooltip>
          )
        })}
        {secondaryRowActions.length > 0 && (
          <div onMouseEnter={() => setIsCursorHovering(true)} onMouseLeave={() => setIsCursorHovering(false)}>
            <DropDown
              className={classNames({
                [`${rootClass}__drop-down`]: !dropdownOpen,
                [`${rootClass}__drop-down-open`]: dropdownOpen,
                [`${rootClass}__drop-down-hovered`]: isCursorHovering,
              })}
              isOpen={dropdownOpen}
              toggleOpen={(open) => setDropdownOpen(open)}
              hasOverflowIcon
              type={DropDownType.STYLED}
            >
              <DropDownActions className={`${rootClass}__drop-down-actions`} menuItems={dropdownActions} closeDropDown={closeDropDown} />
            </DropDown>
          </div>
        )}
      </div>
    </div>
  )
}

export default RowActions
