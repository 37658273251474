import { useCallback, useEffect, useState } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import addRevenuePropertiesToUserSetting from '@graphql/mutations/addRevenuePropertiesToUserSetting'
import getRevenuePropertiesFromUserSetting from '@graphql/queries/getRevenuePropertiesFromUserSetting'
import {
  AddRevenuePropertiesToUserSettingMutation,
  AddRevenuePropertiesToUserSettingMutationVariables,
  UserRevenueInput,
} from '@graphql/types/mutation-types'
import { GetRevenuePropertiesFromUserSettingQuery, GetRevenuePropertiesFromUserSettingQueryVariables } from '@graphql/types/query-types'
import { revenueImpactNewMock } from '@src/pages/reports/revenueImpactNew/mocks/RevenueImpactNew.mock'
import { logNewRelicError } from '@utils/new-relic.utils'

const defaultPreservedData = revenueImpactNewMock.getRevenuePropertiesFromUserSetting

interface PreservedDataType {
  loading: boolean
  preservedData: UserRevenueInput | undefined
  handlePreserveDataChanges: (obj: Partial<UserRevenueInput>) => void
}

const useGetPreservedData = (): PreservedDataType => {
  const [preservedData, setPreservedData] = useState<UserRevenueInput>()
  const client = useApolloClient()

  const [addRevenueUserSettings] = useMutation<AddRevenuePropertiesToUserSettingMutation, AddRevenuePropertiesToUserSettingMutationVariables>(
    addRevenuePropertiesToUserSetting,
    {
      client,
      fetchPolicy: 'no-cache',
    }
  )

  const { loading, error, data } = useQuery<GetRevenuePropertiesFromUserSettingQuery, GetRevenuePropertiesFromUserSettingQueryVariables>(
    getRevenuePropertiesFromUserSetting,
    {
      client,
      fetchPolicy: 'network-only',
    }
  )

  const handlePreserveDataChanges = useCallback(
    (obj: Partial<UserRevenueInput>) => {
      addRevenueUserSettings({ variables: { userRevenueInput: obj } }).catch((error) => logNewRelicError(error))
    },
    [addRevenueUserSettings]
  )

  useEffect(() => {
    if (error) {
      logNewRelicError(error)
    }
    if (!loading) {
      const defaultSetting = { ...data?.getRevenuePropertiesFromUserSetting }
      setPreservedData({ ...defaultPreservedData, ...defaultSetting })
    }
  }, [error, loading, data])

  return { loading, preservedData, handlePreserveDataChanges }
}

export default useGetPreservedData
