import React, { FC, useEffect, useState } from 'react'

import { PreviewAsset } from '@graphql/types/query-types'
import {
  PreviewAndImportModalProps,
  PreviewAndImportModalState,
  PreviewAssetsByType,
} from '@src/pages/MarketingNetwork/Inbox/components/PreviewAndImportModal/PreviewAndImport.types'
import PreviewAndImportModal from '@src/pages/MarketingNetwork/Inbox/components/PreviewAndImportModal/PreviewAndImportModal'
import { PreviewAssetType } from '@src/pages/MarketingNetwork/Inbox/Inbox.constants'

const PreviewAndImportModalContainer: FC<PreviewAndImportModalProps> = (props: PreviewAndImportModalProps) => {
  const { onClose, onAction, isOpen, id, type, title, date, previewInfo, fromUser, fromOrg, changeOrReplace, assets, actionType, showAction } = props

  const [state, setState] = useState<PreviewAndImportModalState>(() => ({
    currentAsset: { id, type },
    expandedSections: [],
  }))
  const { currentAsset, expandedSections } = state

  const getAssetsByType = (assets: PreviewAsset[]) => {
    if (assets) {
      const updatedAssetsByType = {} as PreviewAssetsByType
      assets.forEach((asset) => {
        if (updatedAssetsByType[asset.type as PreviewAssetType]) {
          updatedAssetsByType[asset.type as PreviewAssetType].push(asset as PreviewAsset)
        } else {
          updatedAssetsByType[asset.type as PreviewAssetType] = [asset as PreviewAsset]
        }
      })
      return updatedAssetsByType
    } else {
      return false
    }
  }

  useEffect(() => {
    if (assets) {
      const assetsR = assets as PreviewAsset[]
      const assetsByType = getAssetsByType(assetsR)
      if (assetsByType) {
        setState({ ...state, assetsByType })
      }
    }
  }, [id])

  const toggleSection = (assetType: PreviewAssetType) => {
    const isExpanded = expandedSections.includes(assetType)
    let updatedSections
    if (isExpanded) {
      updatedSections = expandedSections.filter((section) => section !== assetType)
    } else {
      expandedSections.push(assetType)
      updatedSections = expandedSections
    }
    setState({ ...state, expandedSections: updatedSections })
  }

  const changeCurrentAsset = (id: string, type: string) => setState({ ...state, currentAsset: { id, type } })

  const doAction = () => {
    onAction && onAction(id)
  }

  return (
    <PreviewAndImportModal
      onClose={onClose}
      doAction={doAction}
      isOpen={isOpen}
      id={id}
      date={date}
      type={type}
      title={title}
      previewInfo={previewInfo}
      fromUser={fromUser}
      fromOrg={fromOrg}
      changeOrReplace={changeOrReplace}
      expandedSections={expandedSections}
      toggleSection={toggleSection}
      assets={assets}
      assetsByType={state.assetsByType}
      currentAsset={currentAsset}
      changeCurrentAsset={changeCurrentAsset}
      actionType={actionType}
      showAction={showAction}
    />
  )
}

export default PreviewAndImportModalContainer
