import React, { FC } from 'react'

import BulkActionsModal from '@components/BulkActionsModal/BulkActionsModal'
import { useTranslation } from '@const/globals'
import { BulkUsersDeleteErrorInfo } from '@src/pages/Settings/Users/hooks/useUsersDelete'

import './UsersBulkDeleteErrorModal.css'

interface ConfirmUsersDeleteModalProps {
  onClose: () => void
  usersInfo?: BulkUsersDeleteErrorInfo
  dataTest?: string
}

const rootClass = 'users-bulk-delete-error-modal'

const UsersBulkDeleteErrorModal: FC<ConfirmUsersDeleteModalProps> = (props: ConfirmUsersDeleteModalProps) => {
  const { onClose, usersInfo, dataTest = rootClass } = props
  const { t } = useTranslation()

  return (
    <BulkActionsModal
      isOpen={!!usersInfo}
      errorMessages={usersInfo?.notDeleted ?? []}
      successMessages={usersInfo?.deleted ?? []}
      title={t('Delete users')}
      warnings={t('Users.Bulk.Delete.Error.Caution')}
      onClose={onClose}
      successTitle={t('Successfully deleted')}
      errorTitle={t('Could not be deleted')}
      className={rootClass}
      dataTest={dataTest}
    />
  )
}

export default UsersBulkDeleteErrorModal
