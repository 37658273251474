import { createContext, MutableRefObject } from 'react'

import Bee from '@beefree.io/sdk'
import { GetLastPublishedMessageContentQuery, ImageAssetModelGq, LabelDto, LandingPageCssFile, LandingPageJsFile } from '@graphql/types/query-types'
import { CommonComposerAPI, CommonComposerState, CommonComposerTab } from '@utils/composer/commonComposer/CommonComposer.context'
import { isCustomerCareLogin } from '@utils/cookie'

import { DEFAULT_EMAIL_COMPOSER_TAB, EmailPreviewPlatform, EmailValidations, SendTimeType } from './EmailComposer.context'
import { beeLandingPageClientConfig } from '../beeEditor/constants'
import { defaultSendMethod } from '../emailComposer/constants'
import { messageConfigurationSettings, MessageConfigurationType } from '../emailComposer/types'
import { mockLandingPageBeeTemplate } from '../landingPage/templates/beeTemplate'

export const DEFAULT_LANDING_PAGE_TITLE = 'Untitled Landing Page'

export type LandingPageComposerProviderContext = {
  values: LandingPageComposerState
  api: LandingPageComposerAPI
}

export interface LandingPage {
  isDetectedChangesOnDesignTab?: boolean
  isPublishSaveCalled?: boolean
  isNewHtmlGenerated?: boolean
  lastPublishedTime?: number
  landingPageMetaTitle?: string
  landingPageMetaDescription?: string
  landingPageMetaImage?: ImageAssetModelGq
  landingPageId?: string
  landingPageIndividualUrl?: string
  hasLandingPageIndividualUrlError?: boolean
  landingPageVisibility?: string
  landingPageUrlType?: string
  lastStatusEdited?: number
  landingPagePreviewUrl?: string
  isLandingPageEnabled?: boolean
  isLandingPageStatusChanged?: boolean
  isLandingPage?: boolean
  globalRedirectUrl?: string
  showErrors?: boolean
  customJavaScript?: string
  customCss?: string
  hasUnpublishedChanges?: boolean
  isLandingPagePublished?: boolean
  selectedScripts?: (LandingPageJsFile & { index?: number })[]
  selectedCss?: (LandingPageCssFile & { index?: number })[]
  createLandingPageFields?: {
    name: string
    tags: LabelDto[]
    folderIdToClone: number | undefined
  }
  customCodeChanged?: boolean
  lastPublishedMessageData?: GetLastPublishedMessageContentQuery['getLastPublishedMessageContent']
}

export interface PublicUrlManager {
  landingPagePublicUrl?: string
  landingPageSeoFriendlyUrl?: string
}

export interface LandingPageComposerState extends CommonComposerState {}

export type LandingPageComposerAPI = Omit<CommonComposerAPI<LandingPageComposerState>, 'onSaveAsCopy'> & {
  onSaveAsCopy: (title?: string) => Promise<void>
}

export const defaultLandingPageComposerValidations: EmailValidations = {
  isEmailValidated: false,
  isValidationsLoading: false,
  isContentMissing: true,
  hasZeroRecipients: false,
  needToCheckValidation: false,
  contentValidations: {
    htmlSize: 0,
    isHtmlSizeValid: false,
    isClickthroughLoading: false,
    isClickthroughError: false,
    hasDefaultPlaceholderBlock: false,
    hasEmptyBlock: false,
    clickthroughLinks: [],
    invalidClickthroughLinks: [],
    prohibitedDataURIs: [],
    placeholderBlocks: {
      button: false,
      html: false,
      list: false,
      paragraph: false,
      title: false,
      tableHeader: false,
      tableRow: false,
    },
    emptyBlocks: {
      heading: 0,
      paragraph: 0,
      list: 0,
      button: 0,
      social: 0,
      html: 0,
      icons: 0,
      menu: 0,
    },
    emptyRows: [],
    isOnlyDynamicRows: false,
    hasRssBlock: false,
    svgUrls: [],
  },
  settingsValidations: {
    alwaysShowInlineValidations: {},
    isAnalyticsFieldsIncomplete: false,
    subjectEmojiCountExceeded: false,
    subjectLineContainsOnlyEmojis: false,
    subjectLengthExceeded: false,
    subjectMissing: false,
    subjectRequiredForETE: false,
    previewLengthExceeded: false,
    titleMissing: false,
    listsIncompatibleWithSalesforceOwner: [],
    listsIncompatibleWithPersonalizedFrom: [],
  },
  deliverabilityValidations: {},
  litmusSpamResults: {},
  personalization: {},
  recipients: {},
  validationSectionsStatus: {},
}

export enum LandingPageRedirectUrl {
  GLOBAL = 'ACCOUNT_DEFAULT',
  INDIVIDUAL = 'REMOTE_URL',
  LANDING_PAGE = 'LANDING_PAGE',
}

export enum LandingPageVisibilityStatus {
  SHOW = 'show',
  HIDE = 'hide',
}

export const defaultLandingPageComposerState: LandingPageComposerState = {
  landingPage: {
    landingPageMetaTitle: '',
    landingPageMetaDescription: '',
    landingPageMetaImage: undefined,
    landingPageIndividualUrl: '',
    landingPageVisibility: LandingPageVisibilityStatus.SHOW,
    landingPageUrlType: LandingPageRedirectUrl.GLOBAL,
    lastStatusEdited: undefined,
    landingPagePreviewUrl: 'https://a1832.ez-touch.net/acton/fs/blocks/',
    isLandingPageEnabled: false,
    isLandingPage: true,
    isLandingPageStatusChanged: false,
    customJavaScript: '',
    customCss: '',
    customCodeChanged: false,
  },
  publicUrlManager: {
    landingPagePublicUrl: '',
    landingPageSeoFriendlyUrl: '',
  },
  message: {
    id: '',
    title: DEFAULT_LANDING_PAGE_TITLE,
    subject: '',
    previewText: '',
    templateJson: mockLandingPageBeeTemplate,
    templateHtml: '',
    analyticsFields: [],
    sendMethod: defaultSendMethod,
    isCrmLaunch: false,
    subscriptionCategoryId: '',
    campaignId: '',
    sendto_lists: [],
    suppress_dups: true,
    individualRecipientsSourceIds: [],
    overrideFatigueRules: false,
    suppress_lists: [],
    isTransactional: false,
    isTriggered: false,
    messageType: 'DRAFT',
    isCrmSentNote: false,
    customTextPart: '',
    plainTextOnlyDynamicContent: [],
  },
  lastSavedTitle: DEFAULT_LANDING_PAGE_TITLE,
  isPreview: window.location.pathname.includes(CommonComposerTab.REVIEW),
  isTestSend: false,
  isSend: false,
  isClickthroughLinks: false,
  isCustomerCareSaveModal: false,
  isReviewAndSendModal: false,
  tab: DEFAULT_EMAIL_COMPOSER_TAB,
  loading: true,
  isBeeLoading: true,
  isPlainTextComposerLoading: false,
  isSaving: false,
  isIndicatingAutoSave: false,
  autoSaveFailure: false,
  autoSaveSuccess: undefined,
  beeEditorRef: { current: undefined } as unknown as MutableRefObject<Bee>,
  isCoeditingPrimaryUser: true,
  beeConfig: undefined,
  personalizations: undefined,
  emailSenders: [],
  analyticsDomains: [],
  subscriptionCategories: [],
  campaigns: [],
  hasFatigueRules: true,
  modalState: {
    statusToast: undefined,
    spinner: undefined,
    confirmation: undefined,
    deleteConfirmation: undefined,
    editPlainText: undefined,
    formTypeModal: undefined,
    bulkErrorModal: undefined,
    duplicateModal: undefined,
  },
  preview: {
    loadingPersonalization: false,
    loadingLitmusPreviews: false,
    loadingPlainText: false,
    html: '',
    plainText: '',
    formattedPlainText: '',
    formattedSubject: '',
    formattedPreviewText: '',
    isDarkMode: false,
    isPlainText: false,
    isCodeModalOpen: false,
    isPreviewPersonalizationOn: false,
    isPreviewDynamicContentOn: false,
    platform: EmailPreviewPlatform.DESKTOP,
    contactsList: [],
    dynamicContentList: [],
    selectedContact: null,
    litmusPreviews: [],
    selectedLitmusPreview: null,
  },
  validations: defaultLandingPageComposerValidations,
  launchApproval: 'allowed to launch',
  isCoEditing: false,
  editingUsernames: [],
  messageLimits: {
    monthlyLimit: 10,
    currentUsage: 9,
  },
  savedRowCategories: [],
  savedRows: [],
  savedRowDuplicateError: false,
  recipientsCount: 0,
  previousSendType: SendTimeType.STANDARD,
  lastEmailContentEditSession: undefined,
  isInitializeBeeEditor: false,
  showEmailTooLargeWarning: false,
  emailTooLargeWarningDismissed: false,
  disabledFeatures: {
    autoSave: isCustomerCareLogin(),
    editPlainText: false,
  },
  messageConfiguration: messageConfigurationSettings[MessageConfigurationType.DRAFT],
  detectedURLChanges: {
    hasStartId: false,
    hasToParam: false,
    isNewMessage: false,
    plainTextMode: false,
  },
  beeClientId: beeLandingPageClientConfig.CLIENT_ID,
  beeClientSecret: beeLandingPageClientConfig.CLIENT_SECRET,
  customTabFirstRender: true,
}

export const defaultLandingPageComposerAPI: LandingPageComposerAPI = {
  update: () => null,
  updateModal: () => null,
  updatePreview: () => null,
  updateValidations: () => null,
  onTest: () => null,
  onEditPlainText: () => null,
  onPreview: () => null,
  onTabChange: () => null,
  onStatusChange: () => null,
  onJoinSession: () => null,
  onCreateSubscriptionCategory: () => Promise.resolve(undefined),
  onCreateEmailContentEditSession: () => Promise.resolve(undefined),
  onCreateCampaign: () => Promise.resolve(undefined),
  onConvertToTemplate: (_discardDraft: boolean) => Promise.resolve({ errors: 'No handler defined' }),
  onClose: () => null,
  onSave: () => Promise.resolve(true),
  onAutoSave: () => Promise.resolve(),
  onSaveAndClose: () => Promise.resolve(),
  onSaveAsCopy: () => Promise.resolve(),
  onSaveAsDraft: () => Promise.resolve(),
  onSaveAsTemplate: () => Promise.resolve(),
  onCheckSubjectAndPreviewText: () => Promise.resolve(),
  onDiscardAndClose: () => Promise.resolve(),
  onGetRowsConfigurations: () => undefined,
  eventHooks: {},
}

export const defaultLandingPageComposerContext: LandingPageComposerProviderContext = {
  values: defaultLandingPageComposerState,
  api: defaultLandingPageComposerAPI,
}

export const LandingPageComposerContext = createContext<LandingPageComposerProviderContext>(defaultLandingPageComposerContext)
