import React, { FC, useMemo } from 'react'

import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import { renderLoader } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer/PageContainer'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import Tabs, { TabItemData, TabsType } from '@components/TabsAO/TabsAO'
import { rootContext, useTranslation } from '@const/globals'
import withLoadingAndError from '@hoc/withLoadingAndError/withLoadingAndError'
import LandingPageManagerClickMap from '@src/pages/Content/LandingPages/LandingPagesManager/components/LandingPageManagerClickMap/LandingPageManagerClickMap'
import ClickthroughLinksContainer from '@src/pages/Content/LandingPages/LandingPagesManager/components/LandingPageManagerClickthroughLinks/ClickthroughLinksContainer'
import LandingPageManagerDetails from '@src/pages/Content/LandingPages/LandingPagesManager/components/LandingPageManagerDetails/LandingPageManagerDetails'
import LandingPageManagerHeader from '@src/pages/Content/LandingPages/LandingPagesManager/components/LandingPageManagerHeader/LandingPageManagerHeader'
import LandingPageManagerReport from '@src/pages/Content/LandingPages/LandingPagesManager/components/LandingPageManagerReport/LandingPageManagerReport'
import { LandingPageManagerTab } from '@src/pages/Content/LandingPages/LandingPagesManager/LandingPageManager.utils'

import './LandingPageManager.css'

interface ListingPageManagerProps {
  currentTab: string
  setCurrentTab: (tab: string) => void
  showLinksAndMap: boolean
  notFound?: boolean
  updatingData?: boolean
  id: string
  className?: string
  dataTest?: string
}

const rootClass = 'landing-page-manager'

const LandingPageManager: FC<ListingPageManagerProps> = ({
  currentTab,
  setCurrentTab,
  showLinksAndMap,
  updatingData,
  notFound,
  id,
  dataTest = rootClass,
}) => {
  const { t } = useTranslation()

  const tabs = useMemo<TabItemData[]>(() => {
    const tabs = [
      { index: LandingPageManagerTab.DETAILS, label: t('Details'), content: <LandingPageManagerDetails /> },
      { index: LandingPageManagerTab.REPORT, label: t('Report'), content: showLinksAndMap ? null : <LandingPageManagerReport id={id} /> },
    ]
    if (showLinksAndMap) {
      return [
        ...tabs,
        ...[
          {
            index: LandingPageManagerTab.PERFORMANCE,
            label: '',
            parent: LandingPageManagerTab.REPORT,
            triggerButtonOption: {
              text: t('Landing page performance'),
              icon: SvgNames.graphBar,
              onClick: () => null,
            },
            content: <LandingPageManagerReport id={id} />,
          },
          {
            index: LandingPageManagerTab.CLICK_MAP,
            label: '',
            parent: LandingPageManagerTab.REPORT,
            triggerButtonOption: {
              text: t('Click map'),
              icon: SvgNames.tap,
              onClick: () => null,
            },
            content: <LandingPageManagerClickMap />,
          },
          {
            index: LandingPageManagerTab.LINKS,
            label: '',
            parent: LandingPageManagerTab.REPORT,
            triggerButtonOption: {
              text: t('Clickthrough links'),
              icon: SvgNames.url,
              onClick: () => null,
            },
            content: <ClickthroughLinksContainer />,
          },
        ],
      ]
    }
    return tabs
  }, [t, showLinksAndMap])

  return (
    <PageContainer noPadding noPrintClass className={rootClass} dataTest={dataTest}>
      {updatingData && renderLoader('loader--white-background')}
      {notFound ? (
        <PositionContainer>
          <EmptyListing
            headline={t('LandingPage.NotFound.Headline')}
            text={t(`Sorry, there's nothing to see here`)}
            imgSrc={StaticImageNames.errorNothingFound}
            buttonText={t('LandingPage.NotFound.Button.Label')}
            buttonURL={`${rootContext}/content/landingPages`}
            size={EmptyListingSize.LARGE}
            dataTest={`${dataTest}-empty-listing`}
          />
        </PositionContainer>
      ) : (
        <>
          <LandingPageManagerHeader dataTest={`${dataTest}-header`} />
          <Tabs defaultValue={currentTab} tabsType={TabsType.UNIFIED} onChange={setCurrentTab} childData={tabs} className={`${rootClass}__tabs`} />
        </>
      )}
    </PageContainer>
  )
}

export default withLoadingAndError(LandingPageManager)
