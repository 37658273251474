import React, { FC, ReactNode, ReactNodeArray, useCallback, useMemo, useState } from 'react'

import classNames from 'classnames'

import Svg, { SvgType } from '@components/Svg'
import { SvgColor } from '@components/Svg/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Tooltip from '@components/Tooltip/Tooltip'
import * as AccordionRadix from '@radix-ui/react-accordion'

import './Accordion.css'

export interface AccordionItemData {
  index: string
  header: string | ReactNode
  content: string | ReactNode | ReactNodeArray
  disabled?: boolean
  chevronTooltips?: { openTooltip: string; closeTooltip: string }
  contentClassName?: string
}

export type AccordionProps = {
  childData: AccordionItemData[]
  type?: 'single' | 'multiple'
  defaultValue?: string | string[]
  chevronAnimation?: 'down-up' | 'right-down'
  chevronLeft?: boolean
  // Need to clarify some behavior with Radix team
  // disabled?: boolean
  className?: string
  dataTest?: string
  chevronFillColor?: SvgColor
  triggerNoSidePadding?: boolean
}

const rootClass = 'accordion-radix'

const Accordion: FC<AccordionProps> = (props) => {
  const {
    childData,
    type = 'single',
    chevronLeft = false,
    defaultValue,
    className,
    dataTest = rootClass,
    chevronAnimation = 'down-up',
    chevronFillColor,
    triggerNoSidePadding,
  } = props
  const rootProps = useMemo<{ type: 'single'; defaultValue?: string; collapsible: boolean } | { type: 'multiple'; defaultValue?: string[] }>(() => {
    return type === 'single'
      ? { type, defaultValue: Array.isArray(defaultValue) ? defaultValue[0] : defaultValue, collapsible: true }
      : { type, defaultValue: Array.isArray(defaultValue) ? defaultValue : defaultValue === undefined ? undefined : [defaultValue as string] }
  }, [type, defaultValue])
  const chevron = useMemo(
    () => (
      <div className={classNames(`${rootClass}__caret-icon`, `${rootClass}__${chevronAnimation}`, { [`${className}__icon`]: !!className })}>
        <Svg name={chevronAnimation === 'down-up' ? SvgNames.caretDown : SvgNames.caretRight} type={SvgType.ICON} fill={chevronFillColor} />
      </div>
    ),
    [chevronAnimation, chevronFillColor, className]
  )

  return (
    <AccordionRadix.Root className={classNames(rootClass, className)} data-test={dataTest} {...rootProps}>
      {childData.map((item) => (
        <AccordionItem
          key={item.index}
          item={item}
          chevron={chevron}
          chevronLeft={chevronLeft}
          className={className}
          dataTest={dataTest}
          triggerNoSidePadding={triggerNoSidePadding}
        />
      ))}
    </AccordionRadix.Root>
  )
}

export default Accordion

type AccordionItemProps = {
  item: AccordionItemData
  chevron: ReactNode
  chevronLeft: boolean
  className?: string
  dataTest?: string
  triggerNoSidePadding?: boolean
}

const AccordionItem: FC<AccordionItemProps> = ({ item, chevron, chevronLeft, className, dataTest, triggerNoSidePadding }) => {
  const { index, disabled, header, chevronTooltips, content, contentClassName } = item

  const [open, setOpen] = useState<boolean>(false)
  const handleChange = useCallback(() => setOpen((o) => !o), [])

  return (
    <AccordionRadix.Item
      value={index}
      key={index}
      className={classNames(`${rootClass}__item`, { [`${className}__item`]: !!className })}
      disabled={disabled}
      data-test={`${dataTest}-item-${index}`}
    >
      <AccordionRadix.Header className={classNames(`${rootClass}__header`, { [`${className}__header`]: !!className })}>
        <AccordionRadix.Trigger
          className={classNames(`button ${rootClass}__button`, {
            [`${className}__button`]: !!className,
            [`${rootClass}__button-reverse`]: chevronLeft,
            [`${rootClass}__button--disabled`]: disabled,
            [`${rootClass}__button--noSidePadding`]: triggerNoSidePadding,
          })}
          data-test={`${dataTest}-trigger`}
          onClick={handleChange}
        >
          {header}
          {chevronTooltips && !disabled ? (
            <Tooltip trigger={chevron}>{open ? chevronTooltips.closeTooltip : chevronTooltips.openTooltip}</Tooltip>
          ) : !disabled ? (
            chevron
          ) : null}
        </AccordionRadix.Trigger>
      </AccordionRadix.Header>
      <AccordionRadix.Content className={classNames(`${rootClass}__panel`, contentClassName, { [`${className}__panel`]: !!className })}>
        {content}
      </AccordionRadix.Content>
    </AccordionRadix.Item>
  )
}
