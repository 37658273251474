import { SortDirection } from './common'

export function filterNotEmptyArray<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export const caseInsensitiveAlphabeticSort = (a = '', b = '', direction = SortDirection.ASC) => {
  const value = a.toLowerCase() < b.toLowerCase() ? -1 : 1
  return direction === SortDirection.ASC ? value : value * -1
}
