import { ApolloClient } from '@apollo/client'
import getAllItems from '@graphql/microservices/categorization/getAllItems'
import getFavoriteItems from '@graphql/microservices/categorization/getFavoriteItems'
import getItemsByAuthor from '@graphql/microservices/categorization/getItemsByAuthor'
import getRecentItems from '@graphql/microservices/categorization/getRecentItems'
import {
  GetAllItemsQuery,
  GetAllItemsQueryVariables,
  GetFavoriteItemsQuery,
  GetFavoriteItemsQueryVariables,
  GetItemsByAuthorQuery,
  GetItemsByAuthorQueryVariables,
  GetRecentItemsQuery,
  GetRecentItemsQueryVariables,
} from '@graphql/types/microservice/categorization-types'
import { AccountSettings, ItemDto } from '@graphql/types/query-types'
import { SetContainerValues } from '@interface/ListPage.context'
import { initSegmentLoading } from '@src/pages/ContactSegments/utils/ContactSegmentsContainerUtils'
import { CONTACT_SEGMENTS_CURRENT_SECTION } from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { filterNotEmptyArray } from '@utils/array'
import { doFilterBounce, doFilterSms, ItemSubType, ItemType } from '@utils/categorization'
import { ContactSegmentsSession, ContactSegmentsState } from '@utils/contactSegments/contactSegments.utils'
import { FilterDefinition } from '@utils/filter'
import { setItem } from '@utils/sessionStorage'

export const getAllSegmentsUtils = (
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  client: ApolloClient<any>,
  pageNumber: number,
  pageSize: number,
  options: { accountSettings: AccountSettings; type?: ItemType; needsReRender?: boolean; subTypes?: ItemSubType[] }
): Promise<ItemDto[]> => {
  const { accountSettings, type = ItemType.SEGMENT, needsReRender = false, subTypes = [] } = { ...options }
  const { hasPurchasedSMS, isUsingExternalSegmentationService } = accountSettings
  !needsReRender && initSegmentLoading(setContainerValues, pageNumber)
  return client
    .query<GetAllItemsQuery, GetAllItemsQueryVariables>({
      query: getAllItems,
      fetchPolicy: 'network-only',
      variables: {
        type,
        subTypes,
        pageNumber,
        pageSize,
      },
    })
    .then(({ data }) => {
      let items = data.getAllItems?.filter(filterNotEmptyArray) ?? []

      if (type === ItemType.BOUNCE) {
        if (!hasPurchasedSMS) {
          items = doFilterSms(items)
        }
        if (isUsingExternalSegmentationService) {
          items = doFilterBounce(items)
        }
      }

      return items
    })
}

const getFavoriteSegments = (client: ApolloClient<any>, pageNumber: number, pageSize: number, type: ItemType) =>
  client.query<GetFavoriteItemsQuery, GetFavoriteItemsQueryVariables>({
    query: getFavoriteItems,
    fetchPolicy: 'network-only',
    variables: {
      type,
      pageNumber,
      pageSize,
      orderBy: 'name',
    },
  })

export const getFavoriteSegmentsUtils = (
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  client: ApolloClient<any>,
  pageNumber: number,
  pageSize: number,
  needsLoading = true,
  types: ItemType[],
  needsReRender?: boolean
): Promise<ItemDto[]> => {
  !needsReRender && initSegmentLoading(setContainerValues, pageNumber, needsLoading)
  return Promise.all(types.map((type) => getFavoriteSegments(client, pageNumber, pageSize, type))).then((data) => {
    return data.reduce((items: any[], { data: { getFavoriteItems } }) => (getFavoriteItems ? [...items, ...getFavoriteItems] : items), [])
  })
}

export const getRecentSegmentsUtils = (
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  client: ApolloClient<any>,
  pageNumber: number,
  pageSize: number,
  needsReRender?: boolean
): Promise<ItemDto[]> => {
  !needsReRender && initSegmentLoading(setContainerValues, pageNumber)
  return client
    .query<GetRecentItemsQuery, GetRecentItemsQueryVariables>({
      query: getRecentItems,
      fetchPolicy: 'network-only',
      variables: {
        type: ItemType.SEGMENT,
        pageNumber,
        pageSize,
        orderBy: 'name',
      },
    })
    .then(({ data }) => data.getRecentItems?.filter(filterNotEmptyArray) ?? [])
}

export const getSegmentsCreatedByMeUtils = (
  setContainerValues: SetContainerValues<ContactSegmentsState>,
  client: ApolloClient<any>,
  pageNumber: number,
  pageSize: number,
  needsReRender?: boolean
): Promise<ItemDto[]> => {
  !needsReRender && initSegmentLoading(setContainerValues, pageNumber)
  return client
    .query<GetItemsByAuthorQuery, GetItemsByAuthorQueryVariables>({
      query: getItemsByAuthor,
      fetchPolicy: 'network-only',
      variables: {
        type: ItemType.SEGMENT,
        pageNumber,
        pageSize,
        orderBy: 'name',
      },
    })
    .then(({ data }) => data.getItemsByAuthor?.filter(filterNotEmptyArray) ?? [])
}

export const setFilterUtils = (filterActive: FilterDefinition, setContainerValues: SetContainerValues<ContactSegmentsState>) => {
  setContainerValues((containerValues) => ({
    ...containerValues,
    activeFolderId: undefined,
    folderPath: [],
    activeTagId: undefined,
    filterActive,
  }))
  setItem(ContactSegmentsSession.FILTER, JSON.stringify(filterActive))
  setItem(CONTACT_SEGMENTS_CURRENT_SECTION, ContactSegmentsSession.FILTER)
}
