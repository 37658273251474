export enum DEFINITION_TYPE {
  query = 'query',
  directSelect = 'directSelect',
  search = 'search',
  systemGenerated = 'systemGenerated',
}

export const expressionComparisons: { [key: string]: string } = {
  EQUALS: 'equals',
  NOT_EQUALS: 'does not equal',
  CONTAINS: 'contains',
  NOT_CONTAINS: 'does not contain',
  STARTS_WITH: 'starts with',
  ENDS_WITH: 'ends with',
  LESS_THAN: 'is less than',
  GREATER_THAN: 'is greater than',
  LESS_THAN_OR_EQUAL: 'is less or equal to',
  GREATER_THAN_OR_EQUAL: 'is greater or equal to',
  IS_BETWEEN_NUMBERS_INCLUSIVE: 'is between or equals',
  IS_BETWEEN_NUMBERS_EXCLUSIVE: 'is between, but does not equal',
  IS_EQUAL: 'equals',
  IS_BEFORE: 'is before',
  IS_AFTER: 'is after',
  IS_BETWEEN_DATES_EXCLUSIVE: 'is between, but does not equal',
  IS_BETWEEN_DATES_INCLUSIVE: 'is between or equals',
}

export const profileConditionEmptiness: { [key: string]: string } = {
  textEmpty: 'is empty',
  textNotEmpty: 'is not empty',
}

export const profileDateDirection: { [key: string]: string } = {
  FUTURE: 'ahead',
  PAST: 'ago',
}

export const profileDateRange: { [key: string]: string } = {
  days: 'days',
  weeks: 'weeks',
  months: 'months',
  sameDay: 'current day',
}

export const behaviorComparisons: { [key: string]: string } = {
  NONE: 'none',
  AT_LEAST: 'at least',
  AT_LEAST_ONE: 'at least one',
  AT_MOST: 'at most',
  IN_NONE: 'none of these',
  IN_SOME: 'at least one of these',
  IN_ALL: 'all of these',
  MULTIPLE_IN_ALL: 'at least this many of each',
}

export const behaviorTimePeriodOptions: { [key: string]: string } = {
  priorMonth: 'in the month',
  allTime: 'in all time',
  sinceDay: 'in the last',
}

export const behaviorTimeRange: { [key: string]: string } = {
  daysAgo: 'days',
  relativeMonth: 'months',
  absoluteMonth: '',
}

export const scoreComparisons: { [key: string]: string } = {
  LESS_THAN_OR_EQUAL: 'at most',
  GREATER_THAN_OR_EQUAL: 'at least',
}

export const systemFields: { [key: string]: string } = {
  CREATED_DATE: 'Record creation date',
  UPDATED_DATE: 'Record last changed date',
}

export const smsFields: { [key: string]: string } = {
  VALID_NUMBER: 'Has valid phone number',
  OPTED_IN: 'Is opted-in to SMS',
  OPTED_OUT: 'Is opted-out to SMS',
  PHONE_IN_COUNTRY: 'Phone number is in',
}

export const subscriptionFields: { [key: string]: string } = {
  OPTED_IN: 'opted in',
  OPTED_OUT: 'opted out',
  NOT_OPTED_IN: 'not opted in',
  NOT_OPTED_OUT: 'not opted out',
  SPECIFIED: 'preference specified',
  NOT_SPECIFIED: 'no preference specified',
}
