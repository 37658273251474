import { ApolloClient } from '@apollo/client'
import getAllItems from '@graphql/microservices/categorization/getAllItems'
import getItem from '@graphql/microservices/categorization/getItem'
import copyLandingPageOrTemplate from '@graphql/mutations/copyLandingPageOrTemplate'
import deleteLandingPages from '@graphql/mutations/deleteLandingPages'
import duplicateLandingPage from '@graphql/mutations/duplicateLandingPage'
import uploadLandingPageCode from '@graphql/mutations/uploadLandingPageCode'
import uploadLandingPageUrl from '@graphql/mutations/uploadLandingPageUrl'
import { GetItemQueryVariables, GetItemQuery, GetAllItemsQuery, GetAllItemsQueryVariables } from '@graphql/types/microservice/categorization-types'
import {
  DeleteLandingPagesMutationVariables,
  DeleteLandingPagesMutation,
  DuplicateLandingPageMutationVariables,
  DuplicateLandingPageMutation,
  UploadLandingPageCodeMutationVariables,
  UploadLandingPageCodeMutation,
  UploadLandingPageUrlMutationVariables,
  UploadLandingPageUrlMutation,
  CopyLandingPageOrTemplateMutationVariables,
  CopyLandingPageOrTemplateMutation,
} from '@graphql/types/mutation-types'
import { FetchPromise } from '@utils/types'

export interface LandingPagesListingPageRequests {
  deleteLandingPagesRequest: (variables: DeleteLandingPagesMutationVariables) => FetchPromise<DeleteLandingPagesMutation>
  createTemplateFromLandingPageRequest: (variables: CopyLandingPageOrTemplateMutationVariables) => FetchPromise<CopyLandingPageOrTemplateMutation>
  duplicateLandingPageRequest: (variables: DuplicateLandingPageMutationVariables) => FetchPromise<DuplicateLandingPageMutation>
  uploadLandingPageCodeRequest: (variables: UploadLandingPageCodeMutationVariables) => FetchPromise<UploadLandingPageCodeMutation>
  uploadLandingPageUrlRequest: (variables: UploadLandingPageUrlMutationVariables) => FetchPromise<UploadLandingPageUrlMutation>
  getItemRequest: (variables: GetItemQueryVariables) => FetchPromise<GetItemQuery>
  getLandingPageTemplatesRequest: () => FetchPromise<GetAllItemsQuery>
}

export const getLandingPagesListingPageRequests = (
  client: ApolloClient<object>,
  categorizationClient: ApolloClient<object>
): LandingPagesListingPageRequests => {
  const deleteLandingPagesRequest = async (variables: DeleteLandingPagesMutationVariables) => {
    return await client.mutate<DeleteLandingPagesMutation, DeleteLandingPagesMutationVariables>({
      mutation: deleteLandingPages,
      errorPolicy: 'all',
      variables,
    })
  }

  const createTemplateFromLandingPageRequest = async (variables: CopyLandingPageOrTemplateMutationVariables) => {
    return await client.mutate<CopyLandingPageOrTemplateMutation, CopyLandingPageOrTemplateMutationVariables>({
      mutation: copyLandingPageOrTemplate,
      errorPolicy: 'all',
      variables: {
        ...variables,
        toTemplate: true,
      },
    })
  }

  const duplicateLandingPageRequest = async (variables: DuplicateLandingPageMutationVariables) => {
    return await client.mutate<DuplicateLandingPageMutation, DuplicateLandingPageMutationVariables>({
      mutation: duplicateLandingPage,
      errorPolicy: 'all',
      variables,
    })
  }

  const uploadLandingPageCodeRequest = async (variables: UploadLandingPageCodeMutationVariables) => {
    return await client.mutate<UploadLandingPageCodeMutation, UploadLandingPageCodeMutationVariables>({
      mutation: uploadLandingPageCode,
      errorPolicy: 'all',
      variables,
    })
  }

  const uploadLandingPageUrlRequest = async (variables: UploadLandingPageUrlMutationVariables) => {
    return await client.mutate<UploadLandingPageUrlMutation, UploadLandingPageUrlMutationVariables>({
      mutation: uploadLandingPageUrl,
      errorPolicy: 'all',
      variables,
    })
  }

  const getItemRequest = async (variables: GetItemQueryVariables) => {
    return await categorizationClient.query<GetItemQuery, GetItemQueryVariables>({
      query: getItem,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables,
    })
  }

  const getLandingPageTemplatesRequest = async () => {
    return await categorizationClient.query<GetAllItemsQuery, GetAllItemsQueryVariables>({
      query: getAllItems,
      errorPolicy: 'all',
      variables: {
        type: 'LANDING_PAGE_TEMPLATE',
        field: 'name',
        fieldType: 'string',
        pageNumber: 0,
        pageSize: 50,
      },
    })
  }

  return {
    deleteLandingPagesRequest,
    createTemplateFromLandingPageRequest,
    duplicateLandingPageRequest,
    uploadLandingPageCodeRequest,
    uploadLandingPageUrlRequest,
    getItemRequest,
    getLandingPageTemplatesRequest,
  }
}
