import React, { FC, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import type { IAddOnResponseMixed } from '@beefree.io/sdk/dist/types/bee'
import { StatusToastProps } from '@complex/ListPageModals/ListPageModals'
import { PersonalizationContainer, PersonalizationContainerProps } from '@complex/Personalization/PersonalizationContainer'
import { getRecipientSrcIds } from '@complex/Personalization/utils/Personalization.utils'
import SelectAssetV2, { SelectAssetV2Props } from '@components/SelectAssetV2/SelectAssetV2'
import StatusToast from '@components/StatusToast/StatusToast'
import GenerativeEmailModal from '@src/pages/Content/Email/Editor/components/GenerativeEmailModal/GenerativeEmailModal'
import { GenerativeEmailModalProps } from '@src/pages/Content/Email/Editor/components/GenerativeEmailModal/GenerativeEmailModal.constants'
import { RowArgs } from '@src/pages/EmailComposer/BeeEditor/BeeEditorContainer'
import AssetLinkModalContainer from '@src/pages/EmailComposer/EmailModals/components/AssetLinkModal/AssetLinkModalContainer'
import DynamicContent from '@src/pages/EmailComposer/EmailModals/components/DynamicContent/DynamicContent'
import EmailLayoutsModalContainer from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/EmailLayoutsModalContainer'
import { EmailTemplateLayoutType } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/utils/EmailLayoutsModal.utils'
import InsertImageModalContainer from '@src/pages/EmailComposer/EmailModals/components/ImageFileModal/InsertImageModalContainer'
import MediaFileModalContainer from '@src/pages/EmailComposer/EmailModals/components/MediaFileModal/MediaFileModalContainer'
import SavedRows from '@src/pages/EmailComposer/EmailModals/components/SavedRows/SavedRows'
import { useAccountSettings } from '@utils/account/account.utils'
import { IPluginDisplayConditionExtended } from '@utils/composer/beeEditor/beeEditorTypes'
import { useComposerContext } from '@utils/composer/commonComposer/hooks/useComposerContext'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { AssetLinkTypes, getEmailComposerRows } from '@utils/composer/EmailModal.constants'
import { useTranslation } from '@utils/const/globals'
import { logNewRelicError } from '@utils/new-relic.utils'
import { DeepUpdateState } from '@utils/types'

import EventModalContainer from './components/EventModal/EventModalContainer'
import FormBlockModal from './components/FormBlockModal/FormBlockModal'
import { ManageWebinarDetailsModalContainer } from './components/ManageWebinarDetailsModal/ManageWebinarDetailsModalContainer'
import { MapBlockModalContainer } from './components/MapBlockModal/MapBlockModalContainer'
import { SESSION_MEDIA_SELECTED } from './components/MediaFileModal/MediaFileModal.constants'
import OptOutLinkModal, { OptOutLinkModalProps } from './components/OptOutLinkModal/OptOutLinkModal'
import { OptOutLinkType, useOptOutLinks } from './components/OptOutLinkModal/useOptOutLinks'
import { PollBlockContainer } from './components/PollBlockModal/PollBlockModalContainer'
import PollBlockRemovedModal from './components/PollBlockRemovedModal/PollBlockRemovedModal'
import RssModalContainer, { RssState } from './components/RSSModal/RssModalContainer'
import { SignaturesModalProps } from './components/SignaturesModal/SignaturesModal.context'
import SignaturesModalContainer from './components/SignaturesModal/SignaturesModalContainer'
import { CustomFields } from '../utils/Addons/customAddons.types'
import { BeeEditorActionDoneCallbacks } from '../utils/BeeEditor.types'
import { getDisplayConditionsCount } from '../utils/DisplayConditions.utils'
import { detectEmailType, detectWebinarType } from '../utils/EmailComposerDetector.utils'

const rootClass = 'email-modals'

export interface EmailModalsState {
  selectAsset: boolean
  formsPicker: boolean
  personalization: boolean
  generativeEmail: boolean
  saveRowModal: boolean
  eventModal: boolean
  rssModal: {
    isActive: boolean
    isPlainText: boolean
  }
  webinarModal: boolean
  optOutModal: boolean
  imageModal: boolean
  statusToast: StatusToastProps | false
  signaturesModal: boolean
  headersModal: boolean
  footersModal: boolean
  dynamicContent?: IPluginDisplayConditionExtended
  savedRowsEdit: boolean
  pollModal: boolean
  formBlockModal: boolean
  mapBlockModal: boolean
  pollBlockRemovedModal: boolean
  webinarBlockRemovedModal: boolean
}

export interface EmailEditModalState {
  selectedSignatureId?: string
  selectedImageUrl?: string
  selectedLayoutId?: string
}

export interface EmailModalsProps extends BeeEditorActionDoneCallbacks {
  modalsState: EmailModalsState
  editModalState: EmailEditModalState
  rowArgs: RowArgs
  metaData?: Record<string, string> | CustomFields
  excludeOptOutLink?: boolean
  sendPlainText?: boolean
  updateModalState?: DeepUpdateState<EmailModalsState>
  className?: string
  dataTest?: string
}

export const EmailModals: FC<EmailModalsProps> = (props: EmailModalsProps) => {
  const {
    modalsState,
    editModalState,
    onSpecialLinkDone,
    onSaveRowDone,
    onMergeTagDone,
    onAddOnDone,
    metaData,
    sendPlainText,
    excludeOptOutLink,
    onFilePickerDone,
    onEditRowDone,
    onDisplayConditionDone,
    updateModalState,
    dataTest = rootClass,
    className = '',
    rowArgs,
  } = props

  const [selectedAsset, setSelectedAsset] = useState<string>()
  const [initialSelectedAsset, setInitialSelectedAsset] = useState<string>(AssetLinkTypes.LANDING_PAGES)
  const [primaryButtonText, setPrimaryButtonText] = useState<string>()
  const { subscriptionManagementEnabled, useOnlyNewForms, subscriptionManagementIsLive } = useAccountSettings()

  const { getViewInBrowserUrlRequest } = useEmailComposerRequests()
  const [links, setLinks] = useState<string>()

  useEffect(() => {
    const fetchViewInBrowserUrl = async () => {
      try {
        const response = await getViewInBrowserUrlRequest()
        const data = response.data
        if (data) {
          setLinks(data.getViewInBrowserUrl)
        } else {
          setLinks(undefined)
        }
      } catch (e) {
        logNewRelicError(e, 'Error fetching view in browser links')
        setLinks(undefined)
      }
    }

    fetchViewInBrowserUrl()
  }, [modalsState.selectAsset])

  const { t } = useTranslation()

  const {
    values: {
      message,
      message: { sendto_contacts, sendto_lists, individualRecipientsSourceIds },
      landingPage: { isLandingPage },
      savedRows,
      savedRowCategories,
      savedRowDuplicateError,
      messageConfiguration: { messageType },
    },
    api: { update, onSave },
  } = useComposerContext()

  const optOutLinks = useOptOutLinks()
  const { isEmailBlankMessage, isEmailTemplate, isEmailABTest, isEmailProgram, isEmailForm } = detectEmailType(messageType)
  const { isAccepted, isPending, isRejected, isDeclined } = detectWebinarType(message.webinarConfig)

  const onDone = (callback: () => void) => {
    setInitialSelectedAsset(AssetLinkTypes.LANDING_PAGES)
    setSelectedAsset(undefined)
    callback()
  }

  const onOkRemovedPollBlock = () => {
    const templateJson = message.templateJson
    delete templateJson.hasPollBlock
    update({ message: { templateJson: templateJson } })
    onSave(true, true)
    updateModalState && updateModalState({ pollBlockRemovedModal: false })
  }

  const onOkRemovedWebinarBlock = () => {
    const templateJson = message.templateJson
    delete templateJson.hasWebinarBlock
    update({ message: { templateJson: templateJson } })
    onSave(true, true)
    updateModalState && updateModalState({ webinarBlockRemovedModal: false })
  }

  const onOkRemovedWebinarAndPollBlock = () => {
    const templateJson = message.templateJson
    delete templateJson.hasWebinarBlock
    delete templateJson.hasPollBlock
    update({ message: { templateJson: templateJson } })
    onSave(true, true)
    updateModalState && updateModalState({ webinarBlockRemovedModal: false, pollBlockRemovedModal: false })
  }

  const specificIdsFromEmailComposer = useMemo(() => getRecipientSrcIds(individualRecipientsSourceIds, sendto_lists), [sendto_contacts, sendto_lists])

  const isOptOutWithoutSubscription = (row: string) =>
    row === AssetLinkTypes.OPT_OUT && !(subscriptionManagementEnabled && subscriptionManagementIsLive)

  const selectAssetV2Props: SelectAssetV2Props = {
    onAction: (row: string) => {
      if (isOptOutWithoutSubscription(row)) {
        onDone(() => {
          onSpecialLinkDone(optOutLinks?.STANDARD)
        })
      } else if (row === AssetLinkTypes.VIEW_IN_BROWSER) {
        onDone(() =>
          onSpecialLinkDone({
            label: t('EmailComposer.AssetLinks.ViewInBrowser'),
            link: links ?? '',
            type: AssetLinkTypes.VIEW_IN_BROWSER,
          })
        )
      } else {
        setSelectedAsset(row)
      }
    },
    rowItems: getEmailComposerRows(subscriptionManagementEnabled, useOnlyNewForms, excludeOptOutLink, isLandingPage),
    headerText: t('Insert asset link'),
    subHeaderText: isLandingPage ? t('SelectTypeOfLinkToInsert', { name: 'landing page' }) : t('SelectTypeOfLinkToInsert', { name: 'email' }),
    onCancel: () => onDone(() => onSpecialLinkDone(undefined)),
    onChange: (row: string) => {
      setInitialSelectedAsset(row)
      const immediatelyInsert = isOptOutWithoutSubscription(row) || [`${AssetLinkTypes.VIEW_IN_BROWSER}`].includes(row)
      setPrimaryButtonText(immediatelyInsert ? t('Insert') : undefined)
    },
    primaryButtonText,
    isOpen: true,
    initialSelectedAsset,
  }

  const disableSegmentFields = isEmailABTest || isEmailForm || isEmailProgram || isAccepted || isPending || isRejected || isDeclined

  const personalizationProps: PersonalizationContainerProps = {
    isOpen: true,
    specificIds: specificIdsFromEmailComposer,
    disableAddRecipientsButton: !!sendto_contacts?.length,
    disableListOrSegmentFields: disableSegmentFields,
    disableListOrSegmentFieldsForTemplate: isEmailBlankMessage || isEmailTemplate,
    hideListOrSegmentFields: isLandingPage,
    hideSenderFieldsFilter: isLandingPage,
    isLandingPage: isLandingPage,
    closePersonalization: () => {
      onDone(() => onMergeTagDone(undefined))
    },
    doneCallback: (data) => {
      onDone(() => onMergeTagDone(data))
    },
  }

  const generativeEmailDone = (data: string) => {
    onDone(() => {
      onAddOnDone({ type: 'mixed', value: [{ type: 'paragraph', value: { html: `<p>${data}</p>` } as unknown as string }] } as IAddOnResponseMixed)
    })
  }

  const generativeEmailProps: GenerativeEmailModalProps = {
    isOpen: true,
    subject: 'generativeEmail',
    onClose: () => {
      onDone(() => onAddOnDone(undefined))
    },
    onAction: generativeEmailDone,
  }

  const optOutLinkModalProps: OptOutLinkModalProps = {
    onAction: (selectedOption: OptOutLinkType) => {
      onDone(() => onSpecialLinkDone(optOutLinks?.[selectedOption]))
    },
    onClose: (goBack: boolean) => {
      if (!goBack) {
        onDone(() => onSpecialLinkDone(undefined))
      } else {
        setSelectedAsset(undefined)
      }
    },
    isOpen: true,
  }

  const signaturesModalProps: SignaturesModalProps = {
    onAction: (data) => {
      onDone(() => onAddOnDone(data))
    },
    onClose: () => {
      onDone(() => onAddOnDone(undefined))
    },
    selectedSignatureId: editModalState.selectedSignatureId,
    isOpen: true,
  }

  const renderSelectedAssetModal = () => {
    switch (selectedAsset) {
      case AssetLinkTypes.OPT_OUT:
        return <OptOutLinkModal {...optOutLinkModalProps} />
      case AssetLinkTypes.LANDING_PAGES:
        return (
          <AssetLinkModalContainer
            isOpen={selectedAsset === AssetLinkTypes.LANDING_PAGES}
            handleBackClick={() => setSelectedAsset('')}
            handleCancel={() => onDone(() => onSpecialLinkDone(undefined))}
            handleInsert={(landingPage) =>
              onDone(() =>
                onSpecialLinkDone({
                  label: t(landingPage.name),
                  link: landingPage.url ?? '',
                  type: AssetLinkTypes.LANDING_PAGES,
                })
              )
            }
            assetType={AssetLinkTypes.LANDING_PAGES}
          />
        )
      case AssetLinkTypes.FORM:
        return (
          <AssetLinkModalContainer
            isOpen={selectedAsset === AssetLinkTypes.FORM}
            handleBackClick={() => setSelectedAsset('')}
            handleCancel={() => onDone(() => onSpecialLinkDone(undefined))}
            handleInsert={(form) =>
              onDone(() =>
                onSpecialLinkDone({
                  label: form.name ?? '',
                  link: form.url ?? '',
                  type: AssetLinkTypes.FORM,
                })
              )
            }
            assetType={AssetLinkTypes.FORM}
          />
        )
      case AssetLinkTypes.FILE:
        return (
          <MediaFileModalContainer
            handleInsert={(item) => {
              onDone(() =>
                onSpecialLinkDone({
                  label: item?.title ?? '',
                  link: item?.url ?? '',
                  type: AssetLinkTypes.FILE,
                })
              )
            }}
            isOpen={selectedAsset === AssetLinkTypes.FILE}
            handleClickBack={() => setSelectedAsset('')}
            handleCancel={() => onDone(() => onSpecialLinkDone(undefined))}
          />
        )
    }
  }

  useEffect(() => {
    if (!modalsState.selectAsset) {
      sessionStorage.removeItem(SESSION_MEDIA_SELECTED)
    }
  }, [modalsState.selectAsset])
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {modalsState.dynamicContent && (
        <DynamicContent
          numberOfExistingConditions={getDisplayConditionsCount(message)}
          displayConditions={modalsState.dynamicContent}
          onSubmit={(data) => {
            onDone(() => onDisplayConditionDone(data))
          }}
          onCancel={() => {
            onDone(() => onDisplayConditionDone(undefined))
          }}
        />
      )}
      {modalsState.selectAsset && !selectedAsset && <SelectAssetV2 {...selectAssetV2Props} />}
      {selectedAsset && renderSelectedAssetModal()}
      {modalsState.personalization && <PersonalizationContainer {...personalizationProps} />}
      {modalsState.generativeEmail && <GenerativeEmailModal {...generativeEmailProps} />}
      {modalsState.saveRowModal && (
        <SavedRows
          isEdit={false}
          savedRowCategories={savedRowCategories}
          savedRowDuplicateError={savedRowDuplicateError}
          savedRows={savedRows}
          onCancel={() => {
            onDone(() => onSaveRowDone(undefined))
            update({ savedRowDuplicateError: false })
          }}
          onSave={(data) => onSaveRowDone(data)}
          resetSavedRowDuplicateError={() => update({ savedRowDuplicateError: false })}
        />
      )}
      {modalsState.savedRowsEdit && (
        <SavedRows
          onCancel={() => onEditRowDone(undefined)}
          onSave={(data) => onEditRowDone(data)}
          resetSavedRowDuplicateError={() => update({ savedRowDuplicateError: false })}
          isEdit
          rowArgs={rowArgs}
          savedRowDuplicateError={savedRowDuplicateError}
          savedRowCategories={savedRowCategories}
          savedRows={savedRows}
        />
      )}
      {modalsState.imageModal && (
        <InsertImageModalContainer
          handleImagePickerDone={onFilePickerDone}
          imageUrlToReplace={editModalState.selectedImageUrl}
          allowSvg={isLandingPage}
        />
      )}
      {modalsState.statusToast && <StatusToast {...modalsState.statusToast} />}
      {modalsState.signaturesModal && <SignaturesModalContainer {...signaturesModalProps} />}
      {modalsState.eventModal && (
        <EventModalContainer onClose={() => onDone(() => onAddOnDone(undefined))} onAction={(data) => onAddOnDone(data)} defaultValues={metaData} />
      )}
      {modalsState.footersModal && (
        <EmailLayoutsModalContainer
          onAddOnDone={onAddOnDone}
          layoutsType={EmailTemplateLayoutType.FOOTER}
          layoutIdToReplace={editModalState.selectedLayoutId}
          sendPlainText={sendPlainText}
        />
      )}
      {modalsState.headersModal && (
        <EmailLayoutsModalContainer
          onAddOnDone={onAddOnDone}
          layoutsType={EmailTemplateLayoutType.HEADER}
          layoutIdToReplace={editModalState.selectedLayoutId}
          sendPlainText={sendPlainText}
        />
      )}
      {modalsState.rssModal.isActive && (
        <RssModalContainer
          onClose={() => onDone(() => onAddOnDone(undefined))}
          onAction={(data) => onAddOnDone(data)}
          customFieldValues={metaData?.rssCustomFields as RssState}
          isPlainText={modalsState.rssModal.isPlainText}
          isLandingPage={isLandingPage}
        />
      )}
      {modalsState.webinarModal && (
        <ManageWebinarDetailsModalContainer
          customFieldValues={metaData as unknown as CustomFields['webinarCustomFields']}
          onClose={() => onDone(() => onAddOnDone(undefined))}
          onAction={(data) => onAddOnDone(data)}
        />
      )}
      {modalsState.pollModal && (
        <PollBlockContainer
          customFieldValues={metaData as unknown as CustomFields['pollCustomFields']}
          onClose={() => onDone(() => onAddOnDone(undefined))}
          onAction={(data) => onAddOnDone(data)}
        />
      )}
      {modalsState.formBlockModal && (
        <FormBlockModal
          customFieldValues={metaData as unknown as CustomFields['formCustomFields']}
          onClose={() => onDone(() => onAddOnDone(undefined))}
          onAction={(data) => {
            onAddOnDone(data)
          }}
        />
      )}
      {modalsState.mapBlockModal && (
        <MapBlockModalContainer
          customFieldValues={metaData as unknown as CustomFields['mapCustomFields']}
          onClose={() => onDone(() => onAddOnDone(undefined))}
          onAction={(data) => {
            onAddOnDone(data)
          }}
        />
      )}
      {modalsState.pollBlockRemovedModal && modalsState.webinarBlockRemovedModal ? (
        <PollBlockRemovedModal
          text="EmailComposer.WebinarPollBlockRemovedModal.Description"
          title="EmailComposer.WebinarPollBlockRemovedModal.Title"
          onOk={onOkRemovedWebinarAndPollBlock}
          webinarAndPollRemoved={true}
          list={['Webinar', 'Poll Block']}
        />
      ) : modalsState.pollBlockRemovedModal ? (
        <PollBlockRemovedModal
          text="EmailComposer.PollBlockRemovedModal.Description"
          title="EmailComposer.PollBlockRemovedModal.Title"
          onOk={onOkRemovedPollBlock}
        />
      ) : modalsState.webinarBlockRemovedModal ? (
        <PollBlockRemovedModal
          text="EmailComposer.WebinarBlockRemovedModal.Description"
          title="EmailComposer.WebinarBlockRemovedModal.Title"
          onOk={onOkRemovedWebinarBlock}
        />
      ) : null}
    </div>
  )
}
