import React, { FC } from 'react'

import classNames from 'classnames'

import ComplexList from '@components/ComplexList/ComplexList'
import LineChart from '@components/LineChart/LineChart'
import MapChart from '@components/MapChart/MapChart'
import NumberedListContainer, { NumberedList } from '@components/NumberedList/NumberedListContainer'
import PageContainer from '@components/PageContainer/PageContainer'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import ReportHeader from '@components/ReportHeader/ReportHeader'
import StackedColumnChart from '@components/StackedColumnChart/StackedColumnChart'
import { ChartField, LegendLocation, MapTypes } from '@const/Chart.constants'
import { DAY_INTERVAL, useTranslation } from '@const/globals'
import withLoadingAndError from '@hoc/withLoadingAndError/withLoadingAndError'
import { daysOfWeek } from '@utils/date'
import { ReportProps } from '@utils/reports/reports.utils'

interface Props extends ReportProps {
  dailyVisitors?: { fields: ChartField[] }
  activityByDayOfWeek: { fields: ChartField[] }
  topVisitingComps?: NumberedList
  topSearchTerms?: NumberedList
  topReferringSites?: NumberedList
  referrals?: NumberedList
  usMap?: Map[]
  worldMap?: Map[]
}

interface Map {
  name?: string
  data: any[]
}

const rootClass = 'website-visitors'

const WebsiteVisitors: FC<Props> = (props: Props) => {
  const {
    updatedDate,
    startTime,
    endTime,
    onDateChange,
    nextDisabled,
    prevDisabled,
    refreshOnClick,
    dataTest = rootClass,
    className = '',
    activityByDayOfWeek,
    dailyVisitors,
    monthlyComparison,
    topVisitingComps,
    referrals,
    usMap,
    worldMap,
    topReferringSites,
    topSearchTerms,
  } = props

  const { t } = useTranslation()
  const days = daysOfWeek(t)
  return (
    <PageContainer className={classNames(rootClass, className)} data-test={dataTest}>
      <PositionContainer>
        <ReportHeader
          reportType={t('Website Visitors Summary Report')}
          startTime={startTime}
          endTime={endTime}
          prevDisabled={prevDisabled}
          nextDisabled={nextDisabled}
          updatedDate={updatedDate}
          handleChange={onDateChange}
          refreshOnClick={refreshOnClick}
        />
        <ComplexList title={t('Monthly Comparison')} columns={monthlyComparison.columns} rows={monthlyComparison.rows} isHoverEnabled />
        <LineChart fields={dailyVisitors?.fields ?? []} title={t('Daily Visitors')} tickInterval={14 * DAY_INTERVAL} isDateTime />
        <StackedColumnChart
          fields={activityByDayOfWeek.fields}
          categories={days}
          title={t('Visits by Day of Week')}
          legendLocation={LegendLocation.TOP}
        />
        <NumberedListContainer data={topVisitingComps} />
        <NumberedListContainer data={topSearchTerms} />
        <NumberedListContainer data={topReferringSites} />
        <NumberedListContainer data={referrals} />
        <MapChart mapType={MapTypes.US} fields={usMap ?? []} title={t('Visits From the United States')} className={`${rootClass}__list-container`} />
        <MapChart
          mapType={MapTypes.WORLD}
          fields={worldMap ?? []}
          title={t('Visits From Countries Around the World')}
          className={`${rootClass}__list-container`}
        />
      </PositionContainer>
    </PageContainer>
  )
}
export default withLoadingAndError(WebsiteVisitors)
