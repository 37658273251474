import { gql } from '@apollo/client'

export default gql`
  query syncedSegmentCountInfo {
    syncedSegmentCountInfo {
      querySyncLimit
      remainingSyncs
    }
  }
`
