import React, { FC } from 'react'

import classNames from 'classnames'

import DatePicker, { DatePickerProps } from '@components/DatePicker/DatePicker'
import DateRangePicker, { DateRangePickerProps } from '@components/DateRangePicker/DateRangePicker'
import { useTranslation } from '@const/globals'
import IgnoreYearPopover, {
  IgnoreYearPopoverProps,
} from '@src/pages/SegmentComposer/components/SegmentComposerBuild/components/IgnoreYearPopover/IgnoreYearPopover'

import './DateInput.css'

interface DateInputProps {
  className?: string
  dataTest?: string
  datePickerProps?: DatePickerProps
  dateRangePickerProps?: DateRangePickerProps
  ignoreYearProps?: IgnoreYearPopoverProps
}

const rootClass = 'date-input'
const DATE_FORMAT = 'MMM DD, YYYY'
const DATE_FORMAT_WITHOUT_YEAR = 'MMM DD'

const DateInput: FC<DateInputProps> = (props: DateInputProps) => {
  const { dataTest = rootClass, className = '', datePickerProps, dateRangePickerProps, ignoreYearProps } = props
  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {datePickerProps && (
        <DatePicker
          format={ignoreYearProps?.ignoreYear ? DATE_FORMAT_WITHOUT_YEAR : DATE_FORMAT}
          className={`${rootClass}__date-picker`}
          placeholder={t('Select a date')}
          {...datePickerProps}
        />
      )}
      {dateRangePickerProps && !datePickerProps && (
        <DateRangePicker
          format={ignoreYearProps?.ignoreYear ? DATE_FORMAT_WITHOUT_YEAR : DATE_FORMAT}
          className={`${rootClass}__date-range-picker`}
          {...dateRangePickerProps}
        />
      )}
      {ignoreYearProps && <IgnoreYearPopover {...ignoreYearProps} />}
    </div>
  )
}

export default DateInput
