import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import Accordion from '@components/Accordion/Accordion'
import Button, { ButtonIconPosition, ButtonType } from '@components/Button'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import Input from '@components/Input'
import Spinner from '@components/Spinner/Spinner'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextType, TextWeight, LineHeight } from '@components/Typography/Typography'
import { LITMUS_CLIENTS_URL, LitmusPreviewGroups } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { EmailComposerContext, LitmusPreview } from '@utils/composer/context/EmailComposer.context'
import { useTranslation } from '@utils/const/globals'

import { InboxSidebarEmptyState } from './components/InboxSidebarEmptyState/InboxSidebarEmptyState'
import InboxSidebarPreviewsList from './components/InboxSidebarPreviewsList/InboxSidebarPreviewsList'

import './EmailComposerPreviewInboxSidebar.css'

const rootClass = 'email-composer-preview-inbox-sidebar'

const EmailComposerPreviewInboxSidebar = ({
  loadLitmusPreviews,
  resetBlockedImage,
}: {
  loadLitmusPreviews: () => void
  resetBlockedImage: () => void
}) => {
  const {
    values: {
      preview: { loadingLitmusPreviews, selectedLitmusPreview, litmusPreviews },
    },
    api: { updatePreview },
  } = useContext(EmailComposerContext)

  const [filteredClients, setFilteredClients] = useState<LitmusPreview[]>([])
  const [searchValue, setSearchValue] = useState<string>('')

  const { t } = useTranslation()

  const desktopClients = useMemo(() => filteredClients.filter((client) => client.group === LitmusPreviewGroups.DESKTOP), [filteredClients])
  const mobileClients = useMemo(() => filteredClients.filter((client) => client.group === LitmusPreviewGroups.MOBILE_TABLET), [filteredClients])
  const webClients = useMemo(() => filteredClients.filter((client) => client.group === LitmusPreviewGroups.WEB_BASED), [filteredClients])

  const handleSelectPreview = useCallback((preview: LitmusPreview) => {
    updatePreview({ selectedLitmusPreview: preview })
    resetBlockedImage()
  }, [])

  const handleUpdatePreviews = useCallback(() => {
    setSearchValue('')
    loadLitmusPreviews()
    resetBlockedImage()
  }, [])

  const list = useMemo(
    () => [
      {
        index: LitmusPreviewGroups.DESKTOP,
        title: t('EmailComposer.Preview.Inbox.Sidebar.Desktop', { count: desktopClients.length }),
        content: (
          <InboxSidebarPreviewsList
            searchValue={searchValue}
            type={LitmusPreviewGroups.DESKTOP}
            previews={desktopClients}
            selectedPreview={selectedLitmusPreview}
            onSelect={handleSelectPreview}
          />
        ),
      },
      {
        index: LitmusPreviewGroups.MOBILE_TABLET,
        title: t('EmailComposer.Preview.Inbox.Sidebar.Mobile', { count: mobileClients.length }),
        content: (
          <InboxSidebarPreviewsList
            searchValue={searchValue}
            type={LitmusPreviewGroups.MOBILE_TABLET}
            previews={mobileClients}
            selectedPreview={selectedLitmusPreview}
            onSelect={handleSelectPreview}
          />
        ),
      },
      {
        index: LitmusPreviewGroups.WEB_BASED,
        title: t('EmailComposer.Preview.Inbox.Sidebar.Web', { count: webClients.length }),
        content: (
          <InboxSidebarPreviewsList
            searchValue={searchValue}
            type={LitmusPreviewGroups.WEB_BASED}
            previews={webClients}
            selectedPreview={selectedLitmusPreview}
            onSelect={handleSelectPreview}
          />
        ),
      },
    ],
    [t, desktopClients, searchValue, selectedLitmusPreview, handleSelectPreview, mobileClients, webClients]
  )

  useEffect(() => {
    setFilteredClients(litmusPreviews)
  }, [litmusPreviews])

  useEffect(() => {
    if (searchValue) {
      const searchedClients = litmusPreviews.filter((client) => client.title?.toLowerCase().includes(searchValue.toLocaleLowerCase()))
      setFilteredClients(searchedClients)
    } else {
      setFilteredClients(litmusPreviews)
    }
  }, [searchValue])

  return (
    <div className={rootClass} data-test={rootClass}>
      <div className={`${rootClass}__header`}>
        <div className={`${rootClass}__header-title`}>
          <Typography
            text={t('EmailComposer.Preview.Inbox.Sidebar.Title')}
            type={TextType.DATA_CARD_MODAL_HEADER}
            dataTest={`${rootClass}-header-title-typography`}
          />
          <InfoTooltip minimalPadding={false}>
            <Typography
              className={`${rootClass}__header-title-tooltip`}
              text={t(`EmailComposer.Preview.Inbox.Sidebar.Title.Tooltip`)}
              type={TextType.BODY_TEXT_WHITE}
              tagProps={{ bold: { weight: TextWeight.BOLD, inline: true } }}
            />
          </InfoTooltip>
        </div>
        <Input
          className={`${rootClass}__header-search`}
          icon={SvgNames.search}
          value={searchValue}
          placeholder="Search clients"
          onChange={(event) => setSearchValue(event.target.value)}
          dataTest={`${rootClass}-header-search`}
        />
      </div>
      <div
        className={classNames(`${rootClass}__content`, {
          [`${rootClass}__content-empty`]: !filteredClients.length && !!searchValue,
        })}
      >
        {loadingLitmusPreviews ? (
          <Spinner />
        ) : !filteredClients.length && !!searchValue ? (
          <InboxSidebarEmptyState isChild={false} onClearSearch={() => setSearchValue('')} />
        ) : (
          list.map((item) => (
            <Accordion
              key={item.index}
              defaultValue={list.map((item) => item.index) as unknown as string}
              className={`${rootClass}__content-accordion`}
              chevronLeft
              type={'multiple'}
              childData={[
                {
                  header: (
                    <Typography text={item.title} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_LARGE} />
                  ),
                  content: item.content,
                  index: item.index,
                },
              ]}
              dataTest={`${rootClass}-content-accordion`}
            />
          ))
        )}
      </div>
      <div className={`${rootClass}__footer`}>
        <Button
          className={`${rootClass}__footer-action`}
          buttonType={ButtonType.OUTLINE}
          iconPosition={ButtonIconPosition.LEFT}
          onClick={handleUpdatePreviews}
          dataTest={`${rootClass}-footer-action-update-button`}
        >
          <Svg name={SvgNames.reloadNoFill} type={SvgType.LARGER_ICON} />
          <Typography text={t('Update previews')} type={TextType.LINK} weight={TextWeight.MEDIUM} />
        </Button>
        <Button
          className={`${rootClass}__footer-action`}
          buttonType={ButtonType.OUTLINE}
          iconPosition={ButtonIconPosition.RIGHT}
          onClick={() => window.open(LITMUS_CLIENTS_URL, '_blank')}
          dataTest={`${rootClass}-footer-action-manage-button`}
        >
          <Typography text={t('Manage clients')} type={TextType.LINK} weight={TextWeight.MEDIUM} />
          <Svg name={SvgNames.externalLink} type={SvgType.LARGER_ICON} />
        </Button>
      </div>
    </div>
  )
}

export default EmailComposerPreviewInboxSidebar
