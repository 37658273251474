import * as HistoryInterface from 'history'

import { YesNo } from '@components/ConfirmationModal'
import { Status } from '@components/StatusToast/StatusToast'
import { SvgNames } from '@components/Svg'
import { GetCountQueryQuery } from '@graphql/types/microservice/categorization-types'
import { GetAssetAuditsQuery } from '@graphql/types/microservice/data-lake-query-types'
import { GetImportDetailsStatusQuery } from '@graphql/types/microservice/list-types'
import { GetCopySegmentCountQuery } from '@graphql/types/microservice/segment-types'
import { RestoreFormMutation, RestoreProgramFromBackupMutation, StopProgramMutation } from '@graphql/types/mutation-types'
import { GetAllContactsUpgradeCompletedQuery } from '@graphql/types/query-types'
import { AssetType, LogItem } from '@src/pages/UpgradeAssistant/components/UpgradeLogModal/UpgradeLogModal.constants'
import {
  BANNER_TYPE,
  COPY_SEGMENTS_URL,
  errorStatusToast,
  IMPORT_CONTACTS_FULL_URL,
  ItemState,
  UpgradeItem,
  UPGRADE_PROGRAMS_URL,
  UpgradeAssistantState,
  UpgradeItemType,
  FORM_RESTORE_FAILED,
} from '@src/pages/UpgradeAssistant/UpgradeAssistant.contants'
import { logNewRelicError } from '@utils/new-relic.utils'
import { ensureFirstLetterIsCapitalized } from '@utils/strings'
import { FetchPromise } from '@utils/types'

export const getItemValues = (type: UpgradeItemType, itemState: ItemState) => {
  const itemValues: {
    [key in UpgradeItemType]: {
      label: string
      svgName: SvgNames
      buttonLink?: string
      viewLogText?: string
      viewLogDisabledTooltip: string
      position: number
      hasViewLog: boolean
    }
  } = {
    [UpgradeItemType.CONTACTS]: {
      label: 'Import contacts',
      svgName: SvgNames.uploadCloud2,
      buttonLink: IMPORT_CONTACTS_FULL_URL,
      viewLogText: 'UpgradeAssistant.Type.Contacts.ViewLog.Text',
      viewLogDisabledTooltip: 'UpgradeAssistant.Type.Contacts.ViewLogDisabled.Tooltip',
      position: 1,
      hasViewLog: false,
    },
    [UpgradeItemType.SEGMENTS]: {
      label: 'Segments',
      svgName: SvgNames.segment,
      buttonLink: COPY_SEGMENTS_URL,
      viewLogText: 'UpgradeAssistant.Type.Segments.ViewLog.Text',
      viewLogDisabledTooltip: 'UpgradeAssistant.Type.Segments.ViewLogDisabled.Tooltip',
      position: 2,
      hasViewLog: false,
    },
    [UpgradeItemType.PROGRAMS]: {
      label: 'Automated programs',
      svgName: SvgNames.automationSelected,
      buttonLink: UPGRADE_PROGRAMS_URL,
      viewLogDisabledTooltip: 'UpgradeAssistant.Type.Programs.ViewLogDisabled.Tooltip',
      position: 3,
      hasViewLog: true,
    },
    [UpgradeItemType.FORMS]: {
      label: 'Forms',
      svgName: SvgNames.formSimpleTextTeal,
      viewLogDisabledTooltip: 'UpgradeAssistant.Type.Forms.ViewLogDisabled.Tooltip',
      position: 4,
      hasViewLog: true,
    },
  }

  return {
    ...itemValues[type],
    buttonText: `UpgradeAssistant.Type.${ensureFirstLetterIsCapitalized(type)}.Button${itemState !== ItemState.NOT_STARTED ? '.Continue' : ''}`,
  }
}

const checkItemState = (state: UpgradeAssistantState, type: UpgradeItemType) => {
  const itemCount: { [key in UpgradeItemType]: number } = {
    [UpgradeItemType.FORMS]: state.allContactsFormCount,
    [UpgradeItemType.CONTACTS]: state.allContactsImportCount,
    [UpgradeItemType.PROGRAMS]: state.allContactsProgramCount,
    [UpgradeItemType.SEGMENTS]: state.allContactsSegmentCount,
  }
  return itemCount[type] > 0 ? ItemState.IN_PROGRESS : ItemState.NOT_STARTED
}

export const onRemoveBannerAnswer = (answer: YesNo, setState: Function) => {
  if (answer === YesNo.YES) {
    setState((state: UpgradeAssistantState) => ({
      ...state,
      completedItems: Array.from(new Set([...(state.completedItems ?? []), BANNER_TYPE])),
    }))
  }
  setState((state: UpgradeAssistantState) => ({ ...state, showConfirmationModal: false }))
}

export const markAllAsComplete = (setState: Function) => {
  setState((state: UpgradeAssistantState) => ({
    ...state,
    items: state.items.map((item) => ({
      ...item,
      state: ItemState.MARKED_AS_COMPLETED,
    })),
    completedItems: Array.from(new Set([...(state.completedItems ?? []), ...state.items.map((item) => item.type)])),
  }))
}
export const onMarkAsComplete = (checked: boolean, type: UpgradeItemType, state: UpgradeAssistantState, setState: Function) => {
  const newSectionState = checked ? ItemState.MARKED_AS_COMPLETED : checkItemState(state, type)
  const itemIndex = state.items.findIndex((item) => item.type === type)
  const completedItems = state.completedItems ?? []
  setState((state: UpgradeAssistantState) => ({
    ...state,
    items: [...state.items.slice(0, itemIndex), { ...state.items[itemIndex], state: newSectionState }, ...state.items.slice(itemIndex + 1)],
    completedItems: checked ? [...completedItems, type] : completedItems.filter((item) => item !== type && item !== BANNER_TYPE),
  }))
}

export const getUpgradeCompletedStatus = (getAllContactsUpgrade: () => FetchPromise<GetAllContactsUpgradeCompletedQuery>, setState: Function) => {
  getAllContactsUpgrade()
    .then((response) => {
      const { data } = response
      const completedItems = data?.getAllContactsUpgradeCompleted.filter((item) => !!item) ?? []
      setState((state: UpgradeAssistantState) => ({
        ...state,
        completedItems,
        items: state.items.map((item) => ({
          ...item,
          state: completedItems.includes(item.type) ? ItemState.MARKED_AS_COMPLETED : item.state,
        })),
        loadingStatus: false,
      }))
    })
    .catch((e) => {
      logNewRelicError(e)
      setState((state: UpgradeAssistantState) => ({
        ...state,
        statusToast: errorStatusToast,
        loadingStatus: false,
      }))
    })
}

export const onItemButtonClick = (type: UpgradeItemType, setState: Function) => {
  const itemAction: { [key in UpgradeItemType]: Function } = {
    [UpgradeItemType.FORMS]: () => setState((state: UpgradeAssistantState) => ({ ...state, showFormsUpgradeModal: true, externalIdToUpgrade: '' })),
    [UpgradeItemType.CONTACTS]: () => undefined,
    [UpgradeItemType.PROGRAMS]: () => undefined,
    [UpgradeItemType.SEGMENTS]: () => undefined,
  }
  itemAction[type]()
}

export const onItemViewLogClick = (type: UpgradeItemType, setState: Function) => {
  setState((state: UpgradeAssistantState) => ({ ...state, showUpgradeLogModal: true, upgradeLogType: type }))
}

const onFormsUpgradeClick = (logItem: LogItem, setState: Function) => {
  setState((state: UpgradeAssistantState) => ({
    ...state,
    showFormsUpgradeModal: true,
    externalIdToUpgrade: logItem.assetId,
    externalIdToUpgradeType: logItem.assetType,
  }))
}

export const onLogUpgradeClick = (logItem: LogItem, setState: Function, history: HistoryInterface.History) => {
  const itemAction: { [key in AssetType]: Function } = {
    [AssetType.FORM]: () => onFormsUpgradeClick(logItem, setState),
    [AssetType.FORM_TEMPLATE]: () => onFormsUpgradeClick(logItem, setState),
    [AssetType.PROGRAM]: () => history.push(`${UPGRADE_PROGRAMS_URL}/${logItem.assetId}`),
  }
  itemAction[logItem.assetType]()
}

export const getFormsValues = (
  getFormToUpgradeCount: () => Promise<number>,
  getFormTemplatesCount: () => Promise<number>,
  getAllContactsFormCount: () => FetchPromise<GetCountQueryQuery>,
  getUpgradeLogRequest: (type: UpgradeItemType, page: number, pageSize: number) => FetchPromise<GetAssetAuditsQuery>,
  t: Function,
  setState: Function
) => {
  Promise.all([getFormToUpgradeCount(), getFormTemplatesCount(), getAllContactsFormCount(), getUpgradeLogRequest(UpgradeItemType.FORMS, 1, 1)])
    .then((responses) => {
      const [formsToUpgradeCount, formTemplatesCount, { data: dataAllContactsFormCount }, { data: dataUpgradeLod }] = responses
      const allContactsFormCount = dataAllContactsFormCount?.getCountQuery ? dataAllContactsFormCount?.getCountQuery[0]?.count ?? 0 : 0
      const hasUpgradeLog = dataUpgradeLod?.getAssetAudits?.totalCount && dataUpgradeLod?.getAssetAudits?.totalCount > 0

      setState((state: UpgradeAssistantState) => {
        const formsState = state.completedItems?.includes(UpgradeItemType.FORMS)
          ? ItemState.MARKED_AS_COMPLETED
          : allContactsFormCount > 0
          ? ItemState.IN_PROGRESS
          : ItemState.NOT_STARTED
        const itemIndex = state.items.findIndex((item) => item.type === UpgradeItemType.FORMS)
        const newItem: UpgradeItem = {
          type: UpgradeItemType.FORMS,
          title: t('UpgradeAssistant.Type.Forms.Title', { count: allContactsFormCount }),
          subtitle: t('UpgradeAssistant.Type.Forms.SubTitle', {
            formsToUpgradeCount,
            formTemplatesCount,
          }),
          state: formsState,
          viewLogDisabled: !hasUpgradeLog,
          ...getItemValues(UpgradeItemType.FORMS, formsState),
        }
        return {
          ...state,
          allContactsFormCount,
          items: itemIndex !== -1 ? [...state.items.slice(0, itemIndex), newItem, ...state.items.slice(itemIndex + 1)] : [...state.items, newItem],
        }
      })
    })
    .catch((e) => {
      logNewRelicError(e)
      setState((state: UpgradeAssistantState) => ({
        ...state,
        statusToast: errorStatusToast,
      }))
    })
}

export const getCopySegmentsValues = (getCopySegmentsCount: () => FetchPromise<GetCopySegmentCountQuery>, t: Function, setState: Function) => {
  getCopySegmentsCount()
    .then((response) => {
      const { data } = response
      const allContactsSegmentCount = data?.getCopySegmentCount ?? 0

      setState((state: UpgradeAssistantState) => {
        const segmentsState = state.completedItems?.includes(UpgradeItemType.SEGMENTS)
          ? ItemState.MARKED_AS_COMPLETED
          : allContactsSegmentCount > 0
          ? ItemState.IN_PROGRESS
          : ItemState.NOT_STARTED

        const itemIndex = state.items.findIndex((item) => item.type === UpgradeItemType.SEGMENTS)
        const newItem: UpgradeItem = {
          type: UpgradeItemType.SEGMENTS,
          title: t('UpgradeAssistant.Type.Segments.Title', { count: allContactsSegmentCount }),
          subtitle: t('UpgradeAssistant.Type.Segments.SubTitle'),
          state: segmentsState,
          ...getItemValues(UpgradeItemType.SEGMENTS, segmentsState),
        }
        return {
          ...state,
          allContactsSegmentCount,
          items: itemIndex !== -1 ? [...state.items.slice(0, itemIndex), newItem, ...state.items.slice(itemIndex + 1)] : [...state.items, newItem],
        }
      })
    })
    .catch((e) => {
      logNewRelicError(e)
      setState((state: UpgradeAssistantState) => ({
        ...state,
        statusToast: errorStatusToast,
      }))
    })
}

export const getImportValues = (getImportCount: () => FetchPromise<GetImportDetailsStatusQuery>, t: Function, setState: Function) => {
  getImportCount()
    .then((response) => {
      const { data } = response
      const allContactsImportCount = data?.importDetailsStatus?.count ?? 0

      setState((state: UpgradeAssistantState) => {
        const contactsState = state.completedItems?.includes(UpgradeItemType.CONTACTS)
          ? ItemState.MARKED_AS_COMPLETED
          : allContactsImportCount > 0
          ? ItemState.IN_PROGRESS
          : ItemState.NOT_STARTED

        const itemIndex = state.items.findIndex((item) => item.type === UpgradeItemType.CONTACTS)
        const newItem: UpgradeItem = {
          type: UpgradeItemType.CONTACTS,
          title: t('UpgradeAssistant.Type.Contacts.Title', { count: allContactsImportCount }),
          subtitle: t('UpgradeAssistant.Type.Contacts.SubTitle'),
          state: contactsState,
          ...getItemValues(UpgradeItemType.CONTACTS, contactsState),
        }
        return {
          ...state,
          allContactsImportCount,
          items: itemIndex !== -1 ? [...state.items.slice(0, itemIndex), newItem, ...state.items.slice(itemIndex + 1)] : [...state.items, newItem],
        }
      })
    })
    .catch((e) => {
      logNewRelicError(e)
      setState((state: UpgradeAssistantState) => ({
        ...state,
        statusToast: errorStatusToast,
      }))
    })
}

export const getProgramsValues = (
  getProgramsToUpgradeCount: () => Promise<number>,
  getAllContactsProgramCount: () => FetchPromise<GetCountQueryQuery>,
  getUpgradeLogRequest: (type: UpgradeItemType, page: number, pageSize: number) => FetchPromise<GetAssetAuditsQuery>,
  t: Function,
  setState: Function
) => {
  Promise.all([getProgramsToUpgradeCount(), getAllContactsProgramCount(), getUpgradeLogRequest(UpgradeItemType.PROGRAMS, 1, 1)])
    .then((responses) => {
      const [programsToUpgradeCount, { data: dataAllContactsProgramCount }, { data: dataUpgradeLod }] = responses
      const allContactsProgramCount = dataAllContactsProgramCount?.getCountQuery ? dataAllContactsProgramCount?.getCountQuery[0]?.count ?? 0 : 0
      const hasUpgradeLog = dataUpgradeLod?.getAssetAudits?.totalCount && dataUpgradeLod?.getAssetAudits?.totalCount > 0

      setState((state: UpgradeAssistantState) => {
        const programsState = state.completedItems?.includes(UpgradeItemType.PROGRAMS)
          ? ItemState.MARKED_AS_COMPLETED
          : allContactsProgramCount > 0
          ? ItemState.IN_PROGRESS
          : ItemState.NOT_STARTED

        const itemIndex = state.items.findIndex((item) => item.type === UpgradeItemType.PROGRAMS)
        const newItem: UpgradeItem = {
          type: UpgradeItemType.PROGRAMS,
          title: t('UpgradeAssistant.Type.Programs.Title', { count: allContactsProgramCount }),
          subtitle: t('UpgradeAssistant.Type.Programs.SubTitle', {
            programsToUpgradeCount,
          }),
          state: programsState,
          viewLogDisabled: !hasUpgradeLog,
          ...getItemValues(UpgradeItemType.PROGRAMS, programsState),
        }
        return {
          ...state,
          allContactsProgramCount,
          items: itemIndex !== -1 ? [...state.items.slice(0, itemIndex), newItem, ...state.items.slice(itemIndex + 1)] : [...state.items, newItem],
        }
      })
    })
    .catch((e) => {
      logNewRelicError(e)
      setState((state: UpgradeAssistantState) => ({
        ...state,
        statusToast: errorStatusToast,
      }))
    })
}

export const revertForm = (
  revertFormRequest: (srcFormId: string, backupId: string) => FetchPromise<RestoreFormMutation>,
  logItem: LogItem,
  setState: Function,
  t: Function
) => {
  revertFormRequest(logItem.assetId, logItem.extraInfo.backupId || '')
    .then(() => {
      setState((state: UpgradeAssistantState) => ({
        ...state,
        statusToast: {
          showStatusToast: true,
          status: Status.SUCCESS,
          statusMessage: t('UpgradeAssistant.Type.Forms.Revert.Toast', { name: logItem.name }),
        },
      }))
    })
    .catch((error) => {
      logNewRelicError(error)
      const graphQLError = error?.graphQLErrors ? error.graphQLErrors[0] : null
      if (graphQLError?.extensions?.errorCode === FORM_RESTORE_FAILED) {
        setState((state: UpgradeAssistantState) => ({
          ...state,
          statusToast: {
            ...errorStatusToast,
            statusMessage: t('UpgradeAssistant.Type.Forms.Revert.Toast.Error'),
          },
        }))
      } else {
        setState((state: UpgradeAssistantState) => ({
          ...state,
          statusToast: errorStatusToast,
        }))
      }
    })
}

const doRevertProgram = (
  revertProgramRequest: (srcProgramId: string, bckProgramId: string) => FetchPromise<RestoreProgramFromBackupMutation>,
  logItem: LogItem,
  setState: Function,
  t: Function
) => {
  revertProgramRequest(logItem.assetId, logItem.extraInfo.backupId || '')
    .then(() => {
      setState((state: UpgradeAssistantState) => ({
        ...state,
        statusToast: {
          showStatusToast: true,
          status: Status.SUCCESS,
          statusMessage: t('UpgradeAssistant.Type.Programs.Revert.Toast', { name: logItem.name }),
        },
      }))
    })
    .catch((e) => {
      logNewRelicError(e)
      setState((state: UpgradeAssistantState) => ({
        ...state,
        statusToast: errorStatusToast,
      }))
    })
}
export const revertProgram = (
  revertProgramRequest: (srcProgramId: string, bckProgramId: string) => FetchPromise<RestoreProgramFromBackupMutation>,
  pauseProgramRequest: (srcProgramId: string) => FetchPromise<StopProgramMutation>,
  logItem: LogItem,
  setState: Function,
  t: Function
) => {
  if (logItem.status === 'Running') {
    pauseProgramRequest(logItem.assetId).then(() => {
      doRevertProgram(revertProgramRequest, logItem, setState, t)
    })
  } else {
    doRevertProgram(revertProgramRequest, logItem, setState, t)
  }
}
