import { gql } from '@apollo/client'

export default gql`
  mutation createSenderDomainConfig($domain: String, $parentId: Long) {
    createSenderDomainConfig(domain: $domain, parentId: $parentId) {
      dkimIsSubdomain
      hasDkimCnameRecord
      hasDkimTxtRecord
      hasSpfTxtRecord
      parentId
      senderDomain
    }
  }
`
