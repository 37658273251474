import { gql } from '@apollo/client'

export default gql`
  query getContentFragmentPreview($id: String) {
    getContentFragmentPreview(id: $id) {
      fragmentHtml
      status
      message
    }
  }
`
