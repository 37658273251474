import React, { ChangeEvent, ChangeEventHandler, DragEvent, FC, useCallback, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import ImagePickerModalContainer from '@components/AssetPickers/ImagePickerModal/ImagePickerModalContainer'
import { ImagePickerModalInsertCallback } from '@components/AssetPickers/ImagePickerModal/utils/ImagePickerModal.utils'
import Button, { ButtonType } from '@components/Button'
import DropZone from '@components/DropZone/DropZone'
import { SvgNames } from '@components/Svg'
import Typography from '@components/Typography/Typography'
import InsertURLModal from '@components/UploadImage/components/InsertURLModal'
import { getImagePrimitiveTypeFormB64 } from '@components/UploadImage/components/utils'
import { FormatFile, useTranslation } from '@const/globals'

import './UploadImage.css'

export interface ImageWithType {
  imageBase64: string
  imageType: string
  size: number
  title: string
}

export interface ImageURL {
  id?: string
  title?: string
  url: string
  linkUrl?: string
}

interface Props {
  onImagePickerOpenChange?: (open: boolean) => void
  onImageChange: (image?: ImageWithType | ImageURL) => void
  image?: string
  withoutInsertFromURL?: boolean
  canSelectExistingImage?: boolean
  // maxSize in bytes
  maxSize?: number
  accept?: string
  isStory?: boolean
  withHeaderActions?: boolean
  hideUploadFromURL?: boolean
  allowSvg?: boolean
  className?: string
  dataTest?: string
  useRequirementsInImagePicker?: boolean
}

const defaultAccept = '.jpg, .jpeg, .gif, .png, .svg'
const checkImageB64 = (data?: string | ArrayBuffer | null): string | undefined =>
  typeof data === 'string' &&
  data.startsWith('data:image') &&
  defaultAccept.match(/(?<=\.)\w+/g)?.some((type) => type && data.startsWith(`data:image/${type}`))
    ? data
    : undefined

const rootClass = 'upload-image'

const UploadImage: FC<Props> = ({
  onImagePickerOpenChange,
  onImageChange,
  image,
  withoutInsertFromURL,
  canSelectExistingImage,
  maxSize = Math.pow(2, 30), // 1GB
  accept = defaultAccept,
  isStory,
  allowSvg,
  className,
  dataTest = rootClass,
  useRequirementsInImagePicker = false,
}) => {
  const { t } = useTranslation()
  const fileRef = useRef<HTMLInputElement | null>(null)
  const [img, setImg] = useState<string | undefined>(image)
  const [imageName, setImageName] = useState<string>('')
  const [progress, setProgress] = useState<number | undefined>(undefined)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openImagePicker, setOpenImagePicker] = useState<boolean>(false)
  const [invalidImg, setInvalidImg] = useState<boolean>(false)
  const [maxSizeError, setMaxSizeError] = useState<boolean>(false)
  const [typeNotSupportError, setTypeNotSupportError] = useState<boolean>(false)
  const imageRestrictionError = maxSizeError || typeNotSupportError
  const invalidImgOnEdit = !!img && invalidImg
  const showDropZone = (!img && progress === undefined) || invalidImgOnEdit

  const handleImageSelect = useCallback(
    (files: File[]) => {
      const imageFile = files[0]
      if (imageFile) {
        setImageName(imageFile.name)
        const reader = new FileReader()
        reader.addEventListener('loadstart', (e) => {
          setProgress(e.loaded / e.total)
          setInvalidImg(false)
          setMaxSizeError(false)
          setTypeNotSupportError(false)
        })
        reader.addEventListener('progress', (e) => {
          setProgress(e.loaded / e.total)
        })
        reader.addEventListener('load', (e) => {
          setProgress(undefined)
          const imageBase64 = checkImageB64(e.target?.result)
          const imageType = imageBase64 ? getImagePrimitiveTypeFormB64(imageBase64) : ''
          if (imageBase64 && imageType) {
            if (!accept?.includes(imageType)) {
              setTypeNotSupportError(true)
              setImg(undefined)
              onImageChange(undefined)
            } else if (maxSize && maxSize < imageFile.size) {
              setMaxSizeError(true)
              setImg(undefined)
              onImageChange(undefined)
            } else {
              setImg(imageBase64)
              onImageChange({ imageType, imageBase64, size: imageFile.size, title: imageFile.name.split('.')[0] })
            }
          } else {
            setInvalidImg(true)
            setImg(undefined)
            onImageChange(undefined)
          }
        })
        reader.readAsDataURL(imageFile)
      }
    },
    [onImageChange, maxSize, accept]
  )

  const stopEvent = useCallback((e: Event | ChangeEvent | DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleSelectImage = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      stopEvent(e)
      e.target.files && handleImageSelect(e.target.files as unknown as File[])
    },
    [handleImageSelect, stopEvent]
  )

  const handleImagePickerSelect = useCallback<ImagePickerModalInsertCallback>(
    (data) => {
      if (data) {
        const { url, id, title, linkUrl } = data
        setImg(url)
        setOpenImagePicker(false)
        setInvalidImg(false)
        setMaxSizeError(false)
        onImageChange({ url, id, title, linkUrl } as ImageURL)
      } else {
        setOpenImagePicker(false)
      }
    },
    [onImageChange]
  )

  const handleRemoveImage = useCallback(() => {
    setImg(undefined)
    onImageChange(undefined)
  }, [])

  const handleDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      stopEvent(e)
      handleImageSelect(e.dataTransfer.files as unknown as File[])
    },
    [handleImageSelect, stopEvent]
  )

  const handleChangeImage = useCallback(() => fileRef.current?.click(), [])

  const handleInsertFromImagePicker = useCallback(() => setOpenImagePicker(true), [])
  const handleInsertFromURL = useCallback(() => setOpenModal(true), [])
  const handleCloseModal = useCallback(() => setOpenModal(false), [])
  const handleSaveFromURL = useCallback(
    (url: string) => {
      setOpenModal(false)
      setImg(url)
      onImageChange({ url })
    },
    [onImageChange]
  )

  useEffect(() => {
    onImagePickerOpenChange && onImagePickerOpenChange(openImagePicker)
  })

  const maxSizeFormatted = FormatFile.readableBytes(maxSize).replace(/\s+/g, '')

  return (
    <div
      className={classNames(rootClass, className)}
      data-test={dataTest}
      onDragEnter={stopEvent}
      onDrop={handleDrop}
      onDragOver={stopEvent}
      onDragLeave={stopEvent}
      draggable
    >
      <>
        {showDropZone ? (
          <DropZone
            selectFileText={t('Upload an image')}
            onFileSelected={handleImageSelect}
            altUpload={
              withoutInsertFromURL
                ? undefined
                : canSelectExistingImage
                ? { text: 'Browse Images', onClick: handleInsertFromImagePicker, asFloatButton: true }
                : { text: 'Insert from URL', onClick: handleInsertFromURL, asButton: true }
            }
            accept={accept}
            className={`${rootClass}__drop-zone`}
            dataTest={`${rootClass}-drop-zone`}
            maxSize={maxSizeFormatted}
            isError={imageRestrictionError || invalidImg}
            errorTitle={imageRestrictionError ? 'Image.MaxSize.Error.Title' : invalidImgOnEdit ? 'Invalid.Image.Error.Title' : undefined}
            errorSvg={invalidImgOnEdit ? SvgNames.noImage : undefined}
            errorStateSelectFileText={imageRestrictionError ? 'Upload a different image' : undefined}
            errorMsg={
              <Typography
                text={
                  typeNotSupportError
                    ? 'Image.TypeSupport.Error.Message'
                    : maxSizeError
                    ? 'Image.MaxSize.Error.Message'
                    : invalidImgOnEdit
                    ? 'Invalid.Image.Error.Msg'
                    : 'Upload.Image.Error.Msg'
                }
                values={{ imageName, maxSize: maxSizeFormatted }}
                tagComponents={{ name: <span className={`${rootClass}__error-name`} /> }}
              />
            }
          />
        ) : (
          <div
            className={classNames(`${rootClass}-holder`, {
              [`${rootClass}-holder-image`]: !!img,
              [`${rootClass}-holder-progress`]: progress !== undefined,
            })}
            data-test={`${dataTest}-body-holder`}
          >
            {img ? (
              <img
                alt="uploaded"
                className={`${rootClass}-image`}
                data-test={`${dataTest}-body-image`}
                src={img}
                onError={({ currentTarget }) => {
                  const fileName = img.substring(img.lastIndexOf('/') + 1).split('?')[0]
                  currentTarget.onerror = null
                  setImageName(fileName || 'image')
                  setInvalidImg(true)
                }}
              />
            ) : (
              <div className={`${rootClass}-progress`}>
                <progress className={`${rootClass}-progress-bar`} value={progress} max="1" />
                <Typography text={t('Progress.Uploading.File', { fileName: imageName })} />
              </div>
            )}
          </div>
        )}
        {!!img && (
          <div className={`${rootClass}-hover`} data-test={`${dataTest}-body-hover`}>
            <div className={`${rootClass}-hover-content`}>
              <Button
                buttonType={ButtonType.TRANSPARENT}
                onClick={canSelectExistingImage ? () => setOpenImagePicker(true) : handleChangeImage}
                className={`${rootClass}-hover-change-button`}
                dataTest={`${dataTest}-body-hover-change-button`}
              >
                {t('Change image')}
                <input id="selectFile" accept={accept} type="file" hidden onChange={handleSelectImage} ref={fileRef} />
              </Button>
              {canSelectExistingImage && (
                <Button
                  buttonType={ButtonType.DELETE}
                  className={`${rootClass}-hover-remove-button`}
                  onClick={handleRemoveImage}
                  dataTest={`${dataTest}-body-hover-remove-button`}
                >
                  {t('Remove image')}
                </Button>
              )}
            </div>
          </div>
        )}
      </>
      {!withoutInsertFromURL && (
        <InsertURLModal isOpen={openModal} onClose={handleCloseModal} onSave={handleSaveFromURL} dataTest={`${dataTest}-modal`} />
      )}
      {canSelectExistingImage && openImagePicker && (
        <ImagePickerModalContainer
          onImageInsert={handleImagePickerSelect}
          isStory={isStory}
          allowSvg={allowSvg}
          {...(useRequirementsInImagePicker && { accept, imageMaxSize: maxSize })}
        />
      )}
    </div>
  )
}

export default UploadImage
