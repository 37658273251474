import React, { FC } from 'react'

import classNames from 'classnames'

import { ApolloError } from '@apollo/client'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { useTranslation } from '@const/globals'
import { logNewRelicError } from '@utils/new-relic.utils'
import { EmptyState } from '@utils/program/program.constants'

import '../../ProgramPerformanceDetailTableContainer.css'

interface Props {
  emptyState: EmptyState
  emptySearch: boolean
  error?: ApolloError
  className?: string
  dataTest?: string
}

const rootClass = 'program-performance-detail-empty-table'

const ProgramPerformanceDetailEmptyTable: FC<Props> = (props: Props) => {
  const { emptyState, error, emptySearch, className, dataTest = rootClass } = props
  const { t } = useTranslation()

  const renderError = () => {
    logNewRelicError(error)
    return (
      <EmptyListing
        headline={`${t('Program contacts could not be retrieved at this time.')}`}
        imgSrc={StaticImageNames.errorStateAllContacts}
        size={EmptyListingSize.MEDIUM}
        text={t(`Engineering has been alerted.`)}
      />
    )
  }

  const renderEmptySearch = () => {
    return (
      <EmptyListing
        headline={t(`No results found`)}
        imgSrc={StaticImageNames.emptySearch}
        size={EmptyListingSize.MEDIUM}
        text={t(`There were no contacts matching your search.`)}
      />
    )
  }

  const renderEmptyTable = () => {
    return (
      <EmptyListing
        headline={emptyState.headline}
        imgSrc={emptyState.imgSrc}
        size={EmptyListingSize.MEDIUM}
        text={emptyState.text}
        linkText={emptyState.linkText}
        link={emptyState.link}
        hideIcon={false}
        className={`${rootClass}__empty-listing`}
      />
    )
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {error ? renderError() : emptySearch ? renderEmptySearch() : renderEmptyTable()}
    </div>
  )
}

export default ProgramPerformanceDetailEmptyTable
