import { gql } from '@apollo/client'

export default gql`
  mutation setFolder($folder: FolderDTOInput, $type: String) {
    setFolder(folder: $folder, type: $type) {
      accountId
      createdTime
      depth
      enabled
      id
      itemCount
      name
      parentId
      position
      subFolders {
        accountId
        createdTime
        depth
        enabled
        id
        itemCount
        name
        parentId
        position
        subFolders {
          accountId
          createdTime
          depth
          enabled
          id
          itemCount
          name
          parentId
          position
          subTypeDTOS {
            count
            itemType
            name
          }
          updatedTime
          views
        }
        updatedTime
        views
      }
      subTypeDTOS {
        count
        itemType
        name
      }
      updatedTime
      views
    }
  }
`
