export const BASE_IFRAME_PATH = '/acton/ng-ui/'
export const IFRAME_PATH = `${BASE_IFRAME_PATH}if/`

export enum EXTERNAL_WINDOW_FEATURES {
  CRM_INTEGRATION_MESSAGE = 'crmIntegrationMessage',
  DATA_STUDIO = 'dataStudio',
  EMAIL_MESSAGE = 'emailMessage',
  FORMS = 'forms',
  LANDING_PAGES = 'lp',
  SALESFORCE_EMAIL_MESSAGE = 'salesForceEmailMessage',
  WEBINAR_AUTO_RESPONSE_EDIT = 'webinarAutoResponseEdit',
  WEBINAR_COMPOSE_FOLLOWUP = 'webinarComposeFollowup',
  WEBINAR_COMPOSE_PROMOTION = 'webinarComposePromotion',
  WEBINAR_COMPOSE_REMINDER = 'webinarComposeReminder',
  ZOOM_AUTO_RESPONSE_EDIT = 'zoomAutoResponseEdit',
  ZOOM_COMPOSE_FOLLOWUP = 'zoomComposeFollowup',
  ZOOM_COMPOSE_PROMOTION = 'zoomComposePromotion',
  ZOOM_COMPOSE_REMINDER = 'zoomComposeReminder',
  WEBEX_AUTO_RESPONSE_EDIT = 'webexAutoResponseEdit',
  WEBEX_COMPOSE_FOLLOWUP = 'webexComposeFollowup',
  WEBEX_COMPOSE_PROMOTION = 'webexComposePromotion',
  WEBEX_COMPOSE_REMINDER = 'webexComposeReminder',
}

export const CLASSIC_WINDOW_URLS: { [key in EXTERNAL_WINDOW_FEATURES]: string } = {
  [EXTERNAL_WINDOW_FEATURES.CRM_INTEGRATION_MESSAGE]: 'compose/m.jsp',
  [EXTERNAL_WINDOW_FEATURES.DATA_STUDIO]: '_export',
  [EXTERNAL_WINDOW_FEATURES.EMAIL_MESSAGE]: '_compose/emailMessage.jsp',
  [EXTERNAL_WINDOW_FEATURES.FORMS]: 'editor/#!/form-builder',
  [EXTERNAL_WINDOW_FEATURES.LANDING_PAGES]: 'editor/#!/lp-editor',
  [EXTERNAL_WINDOW_FEATURES.SALESFORCE_EMAIL_MESSAGE]: 'compose/m.jsp',
  [EXTERNAL_WINDOW_FEATURES.WEBINAR_AUTO_RESPONSE_EDIT]: 'webinar/autoResponseMessageEdit.jsp',
  [EXTERNAL_WINDOW_FEATURES.WEBINAR_COMPOSE_FOLLOWUP]: 'webinar/composeFollowup.jsp',
  [EXTERNAL_WINDOW_FEATURES.WEBINAR_COMPOSE_PROMOTION]: 'webinar/composePromotion.jsp',
  [EXTERNAL_WINDOW_FEATURES.WEBINAR_COMPOSE_REMINDER]: 'webinar/composeReminder.jsp',
  [EXTERNAL_WINDOW_FEATURES.ZOOM_AUTO_RESPONSE_EDIT]: 'zoomui/autoResponseMessageEdit.jsp',
  [EXTERNAL_WINDOW_FEATURES.ZOOM_COMPOSE_FOLLOWUP]: 'zoomui/composeFollowup.jsp',
  [EXTERNAL_WINDOW_FEATURES.ZOOM_COMPOSE_PROMOTION]: 'zoomui/composePromotion.jsp',
  [EXTERNAL_WINDOW_FEATURES.ZOOM_COMPOSE_REMINDER]: 'zoomui/composeReminder.jsp',
  [EXTERNAL_WINDOW_FEATURES.WEBEX_AUTO_RESPONSE_EDIT]: 'webex/autoResponseMessageEdit.jsp',
  [EXTERNAL_WINDOW_FEATURES.WEBEX_COMPOSE_FOLLOWUP]: 'webex/composeFollowup.jsp',
  [EXTERNAL_WINDOW_FEATURES.WEBEX_COMPOSE_PROMOTION]: 'webex/composePromotion.jsp',
  [EXTERNAL_WINDOW_FEATURES.WEBEX_COMPOSE_REMINDER]: 'webex/composeReminder.jsp',
}

const MESSAGE_COMPOSER_TITLE = 'Message Composer'
const MARKETING_AUTOMATION_TITLE = ' :: Marketing Automation'

export const EXTERNAL_WINDOW_TITLES: { [key in EXTERNAL_WINDOW_FEATURES]: string } = {
  [EXTERNAL_WINDOW_FEATURES.CRM_INTEGRATION_MESSAGE]: MESSAGE_COMPOSER_TITLE,
  [EXTERNAL_WINDOW_FEATURES.DATA_STUDIO]: 'Data Studio :: Exports',
  [EXTERNAL_WINDOW_FEATURES.EMAIL_MESSAGE]: MESSAGE_COMPOSER_TITLE,
  [EXTERNAL_WINDOW_FEATURES.FORMS]: MARKETING_AUTOMATION_TITLE,
  [EXTERNAL_WINDOW_FEATURES.LANDING_PAGES]: MARKETING_AUTOMATION_TITLE,
  [EXTERNAL_WINDOW_FEATURES.SALESFORCE_EMAIL_MESSAGE]: MESSAGE_COMPOSER_TITLE,
  [EXTERNAL_WINDOW_FEATURES.WEBINAR_AUTO_RESPONSE_EDIT]: MESSAGE_COMPOSER_TITLE,
  [EXTERNAL_WINDOW_FEATURES.WEBINAR_COMPOSE_FOLLOWUP]: MESSAGE_COMPOSER_TITLE,
  [EXTERNAL_WINDOW_FEATURES.WEBINAR_COMPOSE_PROMOTION]: MESSAGE_COMPOSER_TITLE,
  [EXTERNAL_WINDOW_FEATURES.WEBINAR_COMPOSE_REMINDER]: MESSAGE_COMPOSER_TITLE,
  [EXTERNAL_WINDOW_FEATURES.ZOOM_AUTO_RESPONSE_EDIT]: MESSAGE_COMPOSER_TITLE,
  [EXTERNAL_WINDOW_FEATURES.ZOOM_COMPOSE_FOLLOWUP]: MESSAGE_COMPOSER_TITLE,
  [EXTERNAL_WINDOW_FEATURES.ZOOM_COMPOSE_PROMOTION]: MESSAGE_COMPOSER_TITLE,
  [EXTERNAL_WINDOW_FEATURES.ZOOM_COMPOSE_REMINDER]: MESSAGE_COMPOSER_TITLE,
  [EXTERNAL_WINDOW_FEATURES.WEBEX_AUTO_RESPONSE_EDIT]: MESSAGE_COMPOSER_TITLE,
  [EXTERNAL_WINDOW_FEATURES.WEBEX_COMPOSE_FOLLOWUP]: MESSAGE_COMPOSER_TITLE,
  [EXTERNAL_WINDOW_FEATURES.WEBEX_COMPOSE_PROMOTION]: MESSAGE_COMPOSER_TITLE,
  [EXTERNAL_WINDOW_FEATURES.WEBEX_COMPOSE_REMINDER]: MESSAGE_COMPOSER_TITLE,
}
