import { useMemo } from 'react'

import { useApolloClient } from '@apollo/client'
import getWebPageCount from '@graphql/queries/getWebPageCount'
import getWebPages from '@graphql/queries/getWebPages'
import getWebPagesDomains from '@graphql/queries/getWebPagesDomains'
import getWebPagesDomainsCount from '@graphql/queries/getWebPagesDomainsCount'
import getWebPagesDomainsFiltered from '@graphql/queries/getWebPagesDomainsFiltered'
import getWebPagesFiltered from '@graphql/queries/getWebPagesFiltered'
import {
  GetWebPagesFilteredQuery,
  GetWebPagesFilteredQueryVariables,
  GetWebPagesDomainsQuery,
  GetWebPagesDomainsQueryVariables,
  GetWebPagesQuery,
  GetWebPagesQueryVariables,
  GetWebPageCountQuery,
  GetWebPageCountQueryVariables,
  GetWebPagesDomainsCountQuery,
  GetWebPagesDomainsCountQueryVariables,
  GetWebPagesDomainsFilteredQuery,
  GetWebPagesDomainsFilteredQueryVariables,
} from '@graphql/types/query-types'
import { createGraphQLQuery } from '@utils/apollo'

export const useWebPageRequests = () => {
  const actonClient = useApolloClient()

  return useMemo(
    () => ({
      getWebPagesRequest: createGraphQLQuery<GetWebPagesQuery, GetWebPagesQueryVariables>(actonClient, getWebPages),
      getWebPagesFilteredRequest: createGraphQLQuery<GetWebPagesFilteredQuery, GetWebPagesFilteredQueryVariables>(actonClient, getWebPagesFiltered),
      getWebPageCountRequest: createGraphQLQuery<GetWebPageCountQuery, GetWebPageCountQueryVariables>(actonClient, getWebPageCount),
      getWebPagesDomainsRequest: createGraphQLQuery<GetWebPagesDomainsQuery, GetWebPagesDomainsQueryVariables>(actonClient, getWebPagesDomains),
      getWebPagesDomainsCountRequest: createGraphQLQuery<GetWebPagesDomainsCountQuery, GetWebPagesDomainsCountQueryVariables>(
        actonClient,
        getWebPagesDomainsCount
      ),
      getWebPagesDomainsFilteredRequest: createGraphQLQuery<GetWebPagesDomainsFilteredQuery, GetWebPagesDomainsFilteredQueryVariables>(
        actonClient,
        getWebPagesDomainsFiltered
      ),
    }),
    [actonClient]
  )
}
