import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import { SegmentSaveModal } from '@components/SegmentSaveModal/SegmentSaveModal'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { Folder } from '@interface/Folder'
import { SegmentComposerContext } from '@src/pages/SegmentComposer/SegmentComposer.context'

interface FirstSegmentSaveModalProps {
  className?: string
  dataTest?: string
  isOpen: boolean
  onClose: VoidFunction
}

const rootClass = 'first-segment-save-modal'

const FirstSegmentSaveModal: FC<FirstSegmentSaveModalProps> = (props: FirstSegmentSaveModalProps) => {
  const { dataTest = rootClass, className = '', isOpen, onClose } = props

  const {
    onSaveClick,
    update,
    values: { errors, folders, segment, tags },
  } = useContext(SegmentComposerContext)

  const { t } = useTranslation()

  const onSave = async (name: string, folder: Folder | undefined, tags: LabelDto[]) => {
    await onSaveClick({
      segmentInput: { ...segment, name, folderId: folder?.id, labels: tags },
      isSavingFromModal: true,
    })
  }

  const onCreateTag = (newTag: LabelDto) => {
    update({ tags: [...tags, newTag] })
  }

  return (
    <SegmentSaveModal
      dataTest={dataTest}
      className={classNames(rootClass, className)}
      folders={folders}
      isOpen={isOpen}
      onClose={onClose}
      onSave={onSave}
      onCreateTag={onCreateTag}
      tags={tags}
      defaultErrors={errors}
      segment={segment}
      title={t('Save segment')}
    />
  )
}

export default FirstSegmentSaveModal
