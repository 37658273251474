import React, { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row } from 'react-table'

import classNames from 'classnames'

import CRMSourcesDefinition from '@complex/ContactSegments/SegmentInfoHoverCard/components/CRMSourcesDefinition'
import SegmentInfoHoverCardSegmentDefinition from '@complex/ContactSegments/SegmentInfoHoverCardSegmentDefinition/SegmentInfoHoverCardSegmentDefinition'
import { DEFINITION_TYPE } from '@complex/ContactSegments/SegmentInfoHoverCardSegmentDefinition/SegmentInfoHoverCardSegmentDefinition.constants'
import { getSegmentHierarchyUtils } from '@complex/ListPickerModalV2/components/ListInfoHoverCard/GraphQL/SegmentActivity.graphQL'
import Breadcrumbs, { BreadcrumbsItemType } from '@components/Breadcrumbs/Breadcrumbs'
import { getSegmentActivities } from '@components/ItemActivity/ItemActivityUtils'
import MoveToFolderModal from '@components/MoveToFolderModal/MoveToFolderModal'
import { SvgType } from '@components/Svg'
import { renderColWithTitle } from '@components/Table/components/tableColumns'
import { TableColumn, TableColumnAlign } from '@components/Table/Table'
import TableWithLoader from '@components/TableWithLoader/TableWithLoader'
import TagManager from '@components/TagManager/TagManager'
import TagManagerTriggerWithText from '@components/TagManagerTriggerWithText/TagManagerTriggerWithText'
import TagWithName from '@components/TagWithName/TagWithName'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { SegmentActivityDto } from '@graphql/types/microservice/segment-types'
import SegmentActivityDetailsSection from '@src/pages/ContactSegments/components/SegmentActivity/components/SegmentActivityDetailsSection/SegmentActivityDetailsSection'
import SegmentPerformance from '@src/pages/ContactSegments/components/SegmentActivity/components/SegmentPerformance/SegmentPerformance'
import { getAllActivityUtils } from '@src/pages/ContactSegments/components/SegmentActivity/SegmentActivityUtils'
import { useSegmentDetailsRequests } from '@src/pages/ContactSegments/components/SegmentDetails/utils/SegmentDetails.graphQL'
import { SEGMENT_ACTIVITY_URL } from '@src/pages/ContactSegments/utils/ContactSegmentsSession.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { ItemSubType, ItemType } from '@utils/categorization'
import {
  EXPRESSION_EVALUATION_TYPE,
  getSegmentPath,
  goEditCRMSourcedSegment,
  goEditSegment,
  Segment,
  systemSegments,
} from '@utils/contactSegments/contactSegments.utils'
import { ContactSegmentsContext } from '@utils/contactSegments/context/ContactSegmentsContext'
import { getSegmentTypeLabel } from '@utils/contactSegments/SegmentInfoHoverCardSegmentDefinitionUtils'
import { formatSendTSDate } from '@utils/date'
import { buildHeader } from '@utils/folderUtils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { replaceAll } from '@utils/strings'
import { NO_COLOR, sortTagsByName } from '@utils/tags'

import './SegmentActivity.css'

interface Props {
  className?: string
  dataTest?: string
  segment: Segment
}

type SectionType = {
  action?: Function
  actionText?: string
  children: ReactNode
  title: string
}

export enum ActivityTypes {
  AUTOMATED_PROGRAMS = 'AUTOMATED_PROGRAMS',
  CREATED = 'CREATED',
  EDITED = 'EDITED',
  FORM = 'FORM',
  IMPORT = 'IMPORT',
  LIST_MAINTENANCE_PROGRAMS = 'LIST_MAINTENANCE_PROGRAM',
  MESSAGE = 'MESSAGE',
  SMS = 'SMS',
  SMS_SCHEDULED = 'SMS_SCHEDULED',
  WEBINAR = 'WEBINAR',
}

export interface SegmentActivityState {
  allActivity: SegmentActivityDto[]
  crmSourceName?: string
  crmSources?: string[]
  loadingAllActivity: boolean
  changeFolder: boolean
  moveToFolderId?: number
}

const initialState = {
  changeFolder: false,
  allActivity: [],
  loadingAllActivity: false,
}

const rootClass = 'segment-activity'

const SegmentActivity: FC<Props> = (props: Props) => {
  const { segment, dataTest = rootClass, className = '' } = props

  const {
    values: { crmSourceOptions = [], folders, items: segments, tags, movingItem: movingSegment, segmentDefinition, search, searchAllItems },
    values,
    applyAndRemoveTags,
    createTag,
    moveItemsIntoFolder: moveSegmentsIntoFolder,
    getCRMSourcedSegmentDetails,
    getSegmentDefinition,
    update,
  } = useContext(ContactSegmentsContext)

  const [state, setState] = useState<SegmentActivityState>(initialState)
  const { allActivity, crmSourceName, crmSources, loadingAllActivity, changeFolder, moveToFolderId } = state

  const { id, tags: appliedTags, isEditable, folderId, externalId, parent, subTypes } = segment

  const isCRMSourcedParentSegment = subTypes?.includes(ItemSubType.CRM_SOURCED) && !parent

  const history = useHistory()

  const { t } = useTranslation()

  const segmentActivities = useMemo(() => getSegmentActivities(t, !!segment.parent, ItemType.SEGMENT), [segment.parent, t])

  const { client: categorizationClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CATEGORIZATION })

  const { disableSegmentModifications } = useAccountSettings()

  const { getActivitiesRequest } = useSegmentDetailsRequests()

  useEffect(() => {
    if (segments.length === 0) {
      getSegmentHierarchyUtils(id, categorizationClient).then((segments) => update({ items: segments }))
    }
  }, [])

  useEffect(() => {
    getAllActivityUtils(setState, getActivitiesRequest, externalId)
    if (isEditable) {
      if (isCRMSourcedParentSegment) {
        getCRMSourcedSegmentDetails([externalId]).then((segmentDetails) => {
          if (!!segmentDetails) {
            const { sourceType, syncedSegmentEntityFactors, syncedSegmentQueryFactors } = segmentDetails[0] || {}
            const sources = !!syncedSegmentEntityFactors
              ? syncedSegmentEntityFactors.map((factor) => factor?.label)
              : syncedSegmentQueryFactors?.map((factor) => factor?.label)
            setState((state) => ({ ...state, crmSourceName: sourceType, crmSources: sources as string[] }))
          }
        })
      } else {
        getSegmentDefinition(externalId)
      }
    } else {
      const systemSegmentsContent = systemSegments[segment.name]
      update({
        segmentDefinition: {
          type: DEFINITION_TYPE.systemGenerated,
          customBooleanExpression: null,
          expressions: [],
          expressionEvaluationType: 'SYSTEM' as EXPRESSION_EVALUATION_TYPE,
          patterns: [systemSegmentsContent],
        },
        infoHoverLoading: false,
      })
    }

    if (!location.pathname.includes(SEGMENT_ACTIVITY_URL)) {
      history.push(SEGMENT_ACTIVITY_URL, { showDetails: segment })
    } else {
      history.replace(location.pathname, { showDetails: segment })
    }
  }, [])

  useEffect(() => {
    if (!movingSegment && moveToFolderId) {
      update({ showDetails: { ...segment, folderId: moveToFolderId } })
    }
  }, [movingSegment])

  const onApplyAndRemoveTags = async (segmentId: number, tagsToApply: LabelDto[], tagsToRemove: number[]) => {
    const result = await applyAndRemoveTags([segmentId], tagsToApply, tagsToRemove)
    if (result) {
      const updatedTags = sortTagsByName([...appliedTags.filter(({ id }) => !tagsToRemove.includes(id)), ...tagsToApply])
      update({ showDetails: { ...segment, tags: updatedTags } })
    }
  }

  const renderActivityCol = ({ original: activity }: Row<SegmentActivityDto>) => {
    const activityType = (activity?.type || '') as ActivityTypes
    return segmentActivities.hasOwnProperty(activityType) ? segmentActivities[activityType](activity) : ''
  }

  const onChangeFolder = (folderId: number) => {
    moveSegmentsIntoFolder([segment], folderId, !folderId)
    setState({ ...state, moveToFolderId: folderId, changeFolder: false })
  }

  const columns: TableColumn[] = useMemo(() => {
    return [
      {
        Header: 'Activity',
        accessor: 'activityName',
        align: 'left' as TableColumnAlign,
        Cell: ({ cell: { row } }) => renderActivityCol(row),
      },
      {
        Header: 'By',
        accessor: 'author',
        align: 'left' as TableColumnAlign,
        minWidth: 150,
        maxWidth: 150,
        Cell: renderColWithTitle,
      },
      {
        Header: 'Time',
        accessor: 'date',
        align: 'right' as TableColumnAlign,
        minWidth: 220,
        maxWidth: 220,
        Cell: (row: any) => formatSendTSDate(row.cell.value, { dateFormat: 'MMM d, yyyy', includeTime: true }),
        sortType: 'statusDateTime',
      },
    ]
  }, [])

  const renderTags = () => (
    <div className={`${rootClass}__details-tags`}>
      {appliedTags.map(({ color, name }: LabelDto) => (
        <TagWithName className={`${rootClass}__details-tag`} key={name} color={color ?? NO_COLOR} name={name ?? ''} />
      ))}
      <TagManager
        trigger={<TagManagerTriggerWithText className={`${rootClass}__details-tag-manager-trigger`} text={'manage tags'} svgType={SvgType.ICON} />}
        appliedTags={appliedTags}
        onApplyAndRemove={(tagsToApply: LabelDto[], tagsToRemove: number[]) => onApplyAndRemoveTags(id, tagsToApply, tagsToRemove)}
        onCreate={(color, name) => createTag({ color, name } as LabelDto)}
        tags={tags}
        title={'Manage Tags'}
      />
    </div>
  )

  const folderTreeBreadcrumbs: BreadcrumbsItemType[] = useMemo(
    () => buildHeader(segment.folderId as number, folders).map((value) => ({ text: value, hasTooltip: true })),
    [segment, folders]
  )

  const segmentTreeBreadcrumbs: BreadcrumbsItemType[] = useMemo(
    () =>
      getSegmentPath(externalId, segments)
        .map(({ name }) => ({ text: name, hasTooltip: true }))
        .reverse(),
    [segment, segments]
  )

  const detailsSections: SectionType[] = [
    {
      children: <Breadcrumbs breadcrumbs={folderTreeBreadcrumbs} wrap />,
      title: 'Folder',
      action: () => setState({ ...state, changeFolder: true }),
      actionText: 'Change folder',
    },
    { children: renderTags(), title: 'Tags' },
    { children: <Breadcrumbs breadcrumbs={segmentTreeBreadcrumbs} wrap />, title: 'Segment Tree' },
    {
      children:
        isCRMSourcedParentSegment && segment.isEditable ? (
          <CRMSourcesDefinition crmSources={crmSources as string[]} />
        ) : (
          <SegmentInfoHoverCardSegmentDefinition textType={TextType.BODY_TEXT} data={segmentDefinition} contextValues={values} />
        ),
      title: `${t('Segment Type')}: ${
        isCRMSourcedParentSegment
          ? `CRM sourced ${replaceAll(crmSourceOptions.find(({ name }) => name === crmSourceName)?.name ?? '', '_', ' ')}`
          : segmentDefinition?.type
          ? getSegmentTypeLabel(segmentDefinition).toLowerCase()
          : ''
      }`,
      action:
        isEditable && !disableSegmentModifications
          ? () =>
              isCRMSourcedParentSegment
                ? goEditCRMSourcedSegment(segment)
                : goEditSegment(segment, SEGMENT_ACTIVITY_URL, { search, searchAll: searchAllItems })
          : undefined,
      actionText: 'Edit',
    },
  ]

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {changeFolder && (
        <MoveToFolderModal
          movableItems={[segment]}
          addToFolder={!folderId}
          onMoveClick={onChangeFolder}
          folders={folders}
          onClose={() => setState({ ...state, changeFolder: false })}
          isOpen
        />
      )}
      <SegmentPerformance className={`${rootClass}__performance`} segment={segment} />
      <div className={`${rootClass}__details`}>
        <div className={`${rootClass}__details-content`}>
          <Typography className={`${rootClass}__details-header`} text={t('Details')} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} />
          <div className={`${rootClass}__sections-wrapper`}>
            {detailsSections.map(({ children, ...rest }) => (
              <SegmentActivityDetailsSection key={rest.title} {...rest}>
                {children}
              </SegmentActivityDetailsSection>
            ))}
          </div>
        </div>
      </div>
      <div className={`${rootClass}__activity`}>
        <div className={`${rootClass}__activity-content`}>
          <Typography
            className={`${rootClass}__activity-content-title`}
            text={t('Activity')}
            type={TextType.SECTION_HEADER}
            weight={TextWeight.MEDIUM}
          />
          <TableWithLoader
            initialState={{ sortBy: [{ id: 'date', desc: true }] }}
            className={`${rootClass}__table`}
            data={allActivity}
            columns={columns}
            loading={loadingAllActivity}
          />
        </div>
      </div>
    </div>
  )
}

export default SegmentActivity
