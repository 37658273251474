import React from 'react'

import { t } from 'i18next'

import PillLabel from '@components/PillLabel/PillLabel'
import { SvgNames } from '@components/Svg'
import { ColumnDefWithAdditionalProps } from '@components/TableV2/tableV2TS/types'
import Typography from '@components/Typography/Typography'
import { MarketingNetworkAccount, MarketingNetworkManager } from '@src/pages/MarketingNetwork/Managers/Managers'
import { CellContext, Row } from '@tanstack/react-table'

const renderName = (row: Row<MarketingNetworkManager>) => {
  const name = row.original.name.trim() === '' ? row.original.email : row.original.name

  return (
    <div className={'managers__manager-name'}>
      <Typography text={name} inline />
      {row.original.isAdministrator && <PillLabel text={t('Admin')} />}
    </div>
  )
}

const renderAccountsCell = (accounts: MarketingNetworkAccount[]) => {
  const count = accounts.length
  return <Typography text={t('Managers.AccountColumn', { count })} dataTest={`managers-manages`} />
}

const managerSorting = (a: Row<MarketingNetworkManager>, b: Row<MarketingNetworkManager>) => {
  return a.original.name.toLocaleLowerCase() > b.original.name.toLocaleLowerCase() ? 1 : -1
}

const accountSorting = (a: Row<MarketingNetworkManager>, b: Row<MarketingNetworkManager>) => {
  return a.original.accounts.length - b.original.accounts.length > 0 ? 1 : -1
}

export const managerColumns: ColumnDefWithAdditionalProps<MarketingNetworkManager, any>[] = [
  {
    accessorKey: 'name',
    header: () => 'Name',
    cell: (cell: CellContext<MarketingNetworkManager, any>) => renderName(cell.row),
    sortingFn: managerSorting,
    textAlign: 'left',
    minSize: 300,
  },
  {
    accessorKey: 'accounts',
    header: () => 'Accounts Managed',
    cell: (cell: CellContext<MarketingNetworkManager, any>) => renderAccountsCell(cell.getValue()),
    sortingFn: accountSorting,
    textAlign: 'left',
    minSize: 100,
    maxSize: 100,
  },
]

interface ManagerRowActionsProps {
  onToggleAssignModal: (manager: MarketingNetworkManager) => void
}

export const managerRowActions = ({ onToggleAssignModal }: ManagerRowActionsProps) => {
  return [
    {
      label: 'Assign Accounts',
      icon: SvgNames.addContacts,
      onClick: (row: Row<MarketingNetworkManager>) => onToggleAssignModal(row.original),
    },
  ]
}

export const assignAccountColumns: ColumnDefWithAdditionalProps<MarketingNetworkAccount>[] = [
  {
    accessorKey: 'name',
    header: () => 'Accounts Managed',
    sortingFn: 'alphanumeric',
    textAlign: 'left',
  },
]
