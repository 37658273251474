import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import { crmSyncOptions } from '@complex/ContactSegments/SegmentNameWithCrmSyncAndInfoHover/SegmentNameWithCrmSyncAndInfoHover.constants'
import SegmentNameWithInfoHover from '@complex/ContactSegments/SegmentNameWithInfoHover/SegmentNameWithInfoHover'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import { useTranslation } from '@const/globals'
import { Segment } from '@utils/contactSegments/contactSegments.utils'

import './SegmentNameWithCrmSyncAndInfoHover.css'

interface SegmentNameWithCrmSyncAndInfoHoverProps {
  className?: string
  dataTest?: string
  segmentData: Segment
  cardBody: ReactNode
}

export interface CRMSyncDisplay {
  tooltipText: string
  icon: SvgNames
  tooltipData?: {
    link: string
    linkText: string
  }
}

const rootClass = 'segment-name-with-crm-sync-and-info-hover'

const SegmentNameWithCrmSyncAndInfoHover: FC<SegmentNameWithCrmSyncAndInfoHoverProps> = (props: SegmentNameWithCrmSyncAndInfoHoverProps) => {
  const { dataTest = rootClass, className = '', segmentData, cardBody } = props
  const { t } = useTranslation()
  const crmSyncDisplayData = segmentData.crmStatus ? crmSyncOptions[segmentData.crmStatus] : { tooltipText: '', icon: SvgNames.fail }
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <div className={`${rootClass}__content`}>
        <Tooltip
          className={`${rootClass}__crm-status-tooltip`}
          trigger={
            <Svg
              name={crmSyncDisplayData.icon}
              type={SvgType.ICON}
              className={classNames(`${rootClass}__icon`, `${rootClass}__icon-${crmSyncDisplayData.icon}`)}
              dataTest={`${rootClass}__icon-${crmSyncDisplayData.icon}`}
            />
          }
          align={'center'}
          {...crmSyncDisplayData.tooltipData}
        >
          {t(crmSyncDisplayData.tooltipText)}
        </Tooltip>
        <Tooltip position={'top'} trigger={segmentData.name} ellipOnTrigger>
          {segmentData.name}
        </Tooltip>
        <SegmentNameWithInfoHover segmentData={segmentData} cardBody={cardBody} />
      </div>
    </div>
  )
}

export default SegmentNameWithCrmSyncAndInfoHover
