import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'

import Svg from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'

import './LinkTextButton.css'

export enum LinkTextButtonSize {
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

export enum LinkTextButtonColor {
  TEAL = 'TEAL',
  GRAY = 'GRAY',
  WHITE = 'WHITE',
}

export interface LinkTextButtonProps {
  text?: string
  link?: string
  target?: string
  dataTest?: string
  disabled?: boolean
  className?: string
  hideIcons?: boolean
  inline?: boolean
  children?: ReactNode
  hideIconLeft?: boolean
  iconNameLeft?: SvgNames
  hideIconRight?: boolean
  iconNameRight?: SvgNames
  size?: LinkTextButtonSize
  color?: LinkTextButtonColor
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  onMouseDown?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  weight?: TextWeight
  maxWidth?: number | string
  noBorder?: boolean
}

const rootClass = 'link'

export const LinkTextButton: FC<LinkTextButtonProps> = (props: LinkTextButtonProps) => {
  const {
    text,
    link,
    inline,
    onClick,
    onMouseDown,
    children,
    disabled,
    hideIcons,
    hideIconLeft,
    hideIconRight,
    className = '',
    target = '_blank',
    dataTest = rootClass,
    size = LinkTextButtonSize.MEDIUM,
    color = LinkTextButtonColor.TEAL,
    iconNameLeft = SvgNames.generate,
    iconNameRight = SvgNames.externalLink,
    weight = TextWeight.MEDIUM,
    maxWidth = 'unset',
    noBorder = false,
  } = props

  const onClickProp = onClick ? { onClick } : { target }
  const onMouseDownProp = onMouseDown ? { onMouseDown } : {}
  const href = link ? { href: link } : {}

  const icon = (position: 'left' | 'right') => {
    const name = position === 'left' ? iconNameLeft : iconNameRight
    return (
      <Svg
        className={classNames(`${rootClass}__icon`, `${rootClass}__icon--${position}`, {
          [`${rootClass}__icon-large`]: size === LinkTextButtonSize.LARGE,
          [`${rootClass}__icon-small`]: size === LinkTextButtonSize.SMALL,
          [`${rootClass}__icon-teal`]: color === LinkTextButtonColor.TEAL,
          [`${rootClass}__icon-white`]: color === LinkTextButtonColor.WHITE,
          [`${rootClass}__icon-gray`]: color === LinkTextButtonColor.GRAY,
          [`${rootClass}__icon-white-disabled`]: color === LinkTextButtonColor.WHITE && disabled,
          [`${rootClass}__icon-disabled`]: color !== LinkTextButtonColor.WHITE && disabled,
          [`${rootClass}__icon-inline`]: inline,
        })}
        name={name}
      />
    )
  }

  const getLinkType = () => {
    switch (true) {
      case color === LinkTextButtonColor.TEAL && size === LinkTextButtonSize.SMALL:
        return TextType.LINK_SMALL
      case color === LinkTextButtonColor.TEAL && size === LinkTextButtonSize.LARGE:
        return TextType.LINK_LARGER
      case color === LinkTextButtonColor.WHITE && size === LinkTextButtonSize.MEDIUM:
        return TextType.LINK_WHITE
      case color === LinkTextButtonColor.WHITE && size === LinkTextButtonSize.SMALL:
        return TextType.LINK_WHITE_SMALL
      case color === LinkTextButtonColor.WHITE && size === LinkTextButtonSize.LARGE:
        return TextType.LINK_WHITE_LARGER
      case color === LinkTextButtonColor.GRAY && size === LinkTextButtonSize.MEDIUM:
        return TextType.BODY_TEXT
      case color === LinkTextButtonColor.GRAY && size === LinkTextButtonSize.SMALL:
        return TextType.BODY_TEXT_SMALL
      case color === LinkTextButtonColor.GRAY && size === LinkTextButtonSize.LARGE:
        return TextType.BODY_TEXT_LARGE
      default:
        return TextType.LINK
    }
  }

  return (
    <a
      className={classNames(rootClass, className, {
        [`${rootClass}__large`]: size === LinkTextButtonSize.LARGE,
        [`${rootClass}__small`]: size === LinkTextButtonSize.SMALL,
        [`${rootClass}__disabled`]: disabled,
        [`${rootClass}__inline`]: inline,
        [`${rootClass}__noBorder`]: noBorder,
      })}
      {...onClickProp}
      {...onMouseDownProp}
      rel="noreferrer"
      data-test={dataTest}
      {...href}
    >
      {!hideIconLeft && !hideIcons && icon('left')}
      <span
        style={{ maxWidth: typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth }}
        className={classNames(`${rootClass}__text`, {
          [`${rootClass}__text-large`]: size === LinkTextButtonSize.LARGE,
          [`${rootClass}__text-small`]: size === LinkTextButtonSize.SMALL,
          [`${rootClass}__text-teal`]: color === LinkTextButtonColor.TEAL,
          [`${rootClass}__text-white`]: color === LinkTextButtonColor.WHITE,
          [`${rootClass}__text-gray`]: color === LinkTextButtonColor.GRAY,
          [`${rootClass}__text-white-disabled`]: color === LinkTextButtonColor.WHITE && disabled,
          [`${rootClass}__text-disabled`]: color != LinkTextButtonColor.WHITE && disabled,
        })}
      >
        {<Typography text={children ?? text} type={getLinkType()} weight={weight} inline />}
      </span>
      {!hideIconRight && !hideIcons && icon('right')}
    </a>
  )
}
