import React, { FC, useState } from 'react'

import classNames from 'classnames'

import ButtonIcon, { ButtonIconType } from '@components/ButtonIcon/ButtonIcon'
import { SvgNames, SvgType } from '@components/Svg'
import Typography from '@components/Typography/Typography'
import { useTranslation } from '@utils/const/globals'

import './Paginator.css'

interface PaginatorProps {
  className?: string
  dataTest?: string
  defaultCurrent?: number
  total: number
  onBack: (current: number) => void
  onNext: (current: number) => void
}

const rootClass = 'common-paginator'

const Paginator: FC<PaginatorProps> = (props: PaginatorProps) => {
  const { dataTest = rootClass, className = '', defaultCurrent, total, onBack, onNext } = props
  const [current, setCurrent] = useState(defaultCurrent ?? 1)
  const { t } = useTranslation()

  const handleBack = () => {
    setCurrent((current) => current - 1)
    onBack(current - 1)
  }

  const handleNext = () => {
    setCurrent((current) => current + 1)
    onNext(current + 1)
  }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <ButtonIcon
        className={`${rootClass}__button-back`}
        onClick={handleBack}
        disabled={current <= 1}
        icon={SvgNames.caretLeft}
        type={ButtonIconType.TEAL_ON_HOVER}
        iconSize={SvgType.LARGER_ICON}
      />
      <Typography text={t('Paginator.Text', { current, total })} />
      <ButtonIcon
        className={`${rootClass}__button-next`}
        onClick={handleNext}
        disabled={current === total}
        icon={SvgNames.caretRight}
        type={ButtonIconType.TEAL_ON_HOVER}
        iconSize={SvgType.LARGER_ICON}
      />
    </div>
  )
}

export default Paginator
