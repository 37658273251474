import React, { ComponentProps, FC, MouseEventHandler, ReactNode, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'

import './GroupHeader.css'

interface GroupHeaderProps {
  className?: string
  dataTest?: string
  label: string
  children: ReactNode
  onReload?: () => void
  onDelete?: () => void
  reloadTooltip?: string
  defaultExpanded?: boolean
  hasBoldLabel?: boolean
  readOnly?: boolean
}

const rootClass = 'group-header'

const GroupHeader: FC<GroupHeaderProps> = (props: GroupHeaderProps) => {
  const {
    dataTest = rootClass,
    className = '',
    label,
    children,
    onReload,
    onDelete,
    reloadTooltip,
    defaultExpanded = false,
    hasBoldLabel = false,
    readOnly,
  } = props

  const [isExpanded, setExpanded] = useState(defaultExpanded)

  const onReloadCallback: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    onReload && onReload()
  }

  const labelProps: Partial<ComponentProps<typeof Typography>> = hasBoldLabel
    ? {
        type: TextType.BODY_TEXT_SMALL,
        weight: TextWeight.BOLD,
        lineHeight: LineHeight.MEDIUM_SMALL,
      }
    : {
        type: TextType.BODY_TEXT,
        weight: TextWeight.MEDIUM,
        lineHeight: LineHeight.MEDIUM,
      }

  return (
    <>
      <div className={classNames(rootClass, className, { [`${className}-expanded`]: className && isExpanded })} data-test={dataTest}>
        <Button
          buttonType={ButtonType.TEXT}
          className={classNames(`${rootClass}__header`, {
            [`${rootClass}__header-expanded`]: isExpanded,
          })}
          dataTest={`${dataTest}-show-content`}
          onClick={() => setExpanded(!isExpanded)}
        >
          <div className={`${rootClass}__header-expand-section`}>
            <div className={classNames(`${rootClass}__button`, `${rootClass}__header-chevron`)}>
              <Svg name={SvgNames.chevronRight} type={SvgType.ICON_MEDIUM} />
            </div>
            <Typography {...labelProps} className={`${rootClass}__header-label`} text={label} />
          </div>
          {onReload && !readOnly && (
            <Tooltip
              trigger={
                <Button buttonType={ButtonType.ICON} className={`${rootClass}__header-reload`} onClick={onReloadCallback}>
                  <Svg name={SvgNames.reloadNoFill} type={SvgType.ICON} className={`${rootClass}__header-reload-icon`} />
                </Button>
              }
            >
              {reloadTooltip}
            </Tooltip>
          )}
        </Button>
        {onDelete && !readOnly && (
          <Button buttonType={ButtonType.ICON} className={classNames(`${rootClass}__button`, `${rootClass}__action`)} onClick={onDelete}>
            <Svg name={SvgNames.close} type={SvgType.ICON} className={`${rootClass}__button-icon`} />
          </Button>
        )}
      </div>
      {isExpanded && children}
    </>
  )
}

export default GroupHeader
