import { gql } from '@apollo/client'

export default gql`
  mutation importInboxAssets($inboxAssets: [InboxAsset]) {
    importInboxAssets(inboxAssets: $inboxAssets) {
      successImportedIds
      failedImportedIds
      sharedAccountId
    }
  }
`
