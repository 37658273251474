import React, { ReactNode } from 'react'

import { AccordionItemData } from '@components/Accordion/Accordion'
import { Checkbox } from '@components/Checkbox/Checkbox'
import LabelWithSvgTooltip from '@components/LabelWithTooltipIcon/LabelWithSvgTooltip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { PermissionsGroupItem, PermissionsGroupName } from '@src/pages/Settings/Users/components/AllPermissionsForm/allPermissionsData'
import UserLaunchApprovalSelect from '@src/pages/Settings/Users/components/UserLaunchApprovalSelect/UserLaunchApprovalSelect'

interface Props {
  permissionGroup: PermissionsGroupItem
  handleCheckboxClick: (groupName: PermissionsGroupName, key: string, check: boolean) => void
  t: (s: any, options?: any) => any
  handleSelectApproval: (approvalOrAdminId?: string) => void
  launchChecked: boolean
  launchApproval?: string
  launchApprovalId?: string
  excludeUserIds?: string[]
}

const rootClass = 'permissions-group'

const getPermissionsGroup = ({
  permissionGroup,
  handleCheckboxClick,
  t,
  handleSelectApproval,
  launchChecked,
  ...restLaunchProps
}: Props): AccordionItemData => {
  const { name, titleKey, tooltipKey, options, headerKey } = permissionGroup
  const isEMail = name === 'accountWideEmails'
  const all: number = Object.keys(options).length + (isEMail ? 1 : 0)
  const selected: number = Object.values(options).filter(({ value }) => value).length + (isEMail && launchChecked ? 1 : 0)
  const context: string = selected === all ? 'all' : ''
  const subheaderOptions = { context, selected, all }

  const header: ReactNode = (
    <div className={`${rootClass}__trigger__header`}>
      <Typography text={t(headerKey)} weight={TextWeight.BOLD} lineHeight={LineHeight.TINY} inline />
      <Typography text={t('Permission.Group.SubHeader', subheaderOptions)} type={TextType.BODY_TEXT_SMALL_LIGHT} lineHeight={LineHeight.SMALL} />
    </div>
  )

  const content: ReactNode = (
    <div className={rootClass}>
      <div className={`${rootClass}__content`}>
        <LabelWithSvgTooltip label={t(titleKey)} tooltipContent={t(tooltipKey)} className={`${rootClass}__content-title`} alignOffset={-6} />
        {Object.entries(options)
          .sort((a, b) => a[1].order - b[1].order)
          .map(([key, { value }]) => (
            <div className={`${rootClass}__content-option`} key={key}>
              <Typography text={t('Permission.Group.Option.Title', { context: key })} lineHeight={LineHeight.MEDIUM_LARGE} />
              <Checkbox checked={value} onChange={(check) => handleCheckboxClick(name, key, check)} />
            </div>
          ))}
      </div>
      {name === 'accountWideEmails' && (
        <div className={`${rootClass}__content`}>
          <LabelWithSvgTooltip
            label={t('Allowed to launch')}
            tooltipContent={t('Allowed.To.Launch.Tooltip')}
            className={`${rootClass}__content-title`}
            alignTextCenter
          />
          <UserLaunchApprovalSelect onSubmit={handleSelectApproval} {...restLaunchProps} />
        </div>
      )}
    </div>
  )
  return { index: name, header, content }
}

export default getPermissionsGroup
