import { gql } from '@apollo/client'

export default gql`
  query getWebinarRecentActivities($webinarId: String, $days: Int, $webinarType: WebinarType) {
    getWebinarRecentActivities(webinarId: $webinarId, days: $days, webinarType: $webinarType) {
      amountOfRegistrations
      amountOfRegistrationsByDate
      author
      authorId
      firstRegistration
      mostRecentRegistration
      webinarId
    }
  }
`
