import { gql } from '@apollo/client'

export default gql`
  query getFormFieldsUsage($formIds: [String]) {
    getFormFieldsUsage(formIds: $formIds) {
      fieldName
      dataType
      formsInfo {
        id
        name
        description
      }
    }
  }
`
