import React, { FC, useContext } from 'react'

import Container from '@components/Container'
import Input from '@components/Input/Input'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { MAX_SPLITS, MIN_SPLITS } from '@src/pages/ContactSegments/components/ABSplit/ABSplit.constants'
import { ABSplitContext } from '@src/pages/ContactSegments/components/ABSplit/ABSplitContext'

import './ABSplitFirstSection.css'

interface ABSplitFirstSectionProps {
  className?: string
  dataTest?: string
}

const rootClass = 'ab-split-first-section'

const ABSplitFirstSection: FC<ABSplitFirstSectionProps> = (props: ABSplitFirstSectionProps) => {
  const { dataTest = rootClass, className } = props
  const { t } = useTranslation()
  const {
    values: { inputError, numberOfSplits },
    update,
  } = useContext(ABSplitContext)

  return (
    <Container dataTest={dataTest} className={className}>
      <Typography
        className={`${rootClass}__header`}
        text={t('1. Enter split name and number of splits')}
        type={TextType.BODY_TEXT_LARGE}
        weight={TextWeight.MEDIUM}
      />
      <div className={`${rootClass}__content`}>
        <Input
          className={`${rootClass}__name`}
          label={t('A/B Split Name (required)')}
          onChange={(event) => update('splitName', event.currentTarget.value)}
        />
        <InputWithStatus
          className={`${rootClass}__number`}
          type={'number'}
          min={MIN_SPLITS}
          max={MAX_SPLITS}
          value={numberOfSplits.toString()}
          label={t('No. of Splits')}
          onChange={(event) => update('numberOfSplits', Number(event.currentTarget.value))}
          hasCustomError={numberOfSplits < MIN_SPLITS || numberOfSplits > MAX_SPLITS}
          customTooltipErrorMessages={
            numberOfSplits < MIN_SPLITS
              ? t('Requires {{number}} or more splits', { number: MIN_SPLITS })
              : numberOfSplits > MAX_SPLITS
              ? t('Limited to {{number}} splits', { number: MAX_SPLITS })
              : ''
          }
          tooltipProps={{ position: 'top', align: 'start', sideOffset: -30, alignOffset: 86 }}
          showIconOnValid={false}
          onChangeDebounce={500}
          disabled={inputError}
        />
      </div>
    </Container>
  )
}

export default ABSplitFirstSection
