import React, { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import FTPSyncSegmentDefinition from '@complex/ContactSegments/SegmentInfoHoverCard/components/FTPSyncSegmentDefinition/FTPSyncSegmentDefinition'
import { ListingPageCommonContext } from '@complex/ListingPage/Context/ListingPageCommon.context'
import ExpandableBreadCrumbs from '@components/ExpandableBreadCrumbs/ExpandableBreadCrumbs'
import { CLOSE_INFO_HOVER_EVENT } from '@components/InfoHoverCard/InfoHoverCard.constants'
import Loader from '@components/Loader'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AuditDto, AuditListQuery } from '@graphql/types/microservice/categorization-types'
import { FormSubmissionActivityDto, FormSubmissionSummaryDto } from '@graphql/types/microservice/entity-join-types'
import { FormActivities } from '@src/pages/listingPages/FormsJoinView/components/FormSubmissionInfoHoverCard/FormSubmissionInfoHoverCard.constants'
import {
  activityByDescription,
  renderField,
} from '@src/pages/listingPages/FormsJoinView/components/FormSubmissionInfoHoverCard/utils/FormSubmissionInfoHoverCard.utils'
import RelatedFormsList from '@src/pages/listingPages/FormsJoinView/components/RelatedFormsList/RelatedFormsList'
import { useFormsJoinViewListingPageEntityJoinRequests } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.entityJoin.graphQL'
import { useFormsJoinViewListingPageRequests } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.graphQL'
import { FormsJoinViewCustomModals } from '@src/pages/listingPages/FormsJoinView/utils/FormsJoinViewListingPage.tables'
import { useAccountSettings } from '@utils/account/account.utils'
import { Segment } from '@utils/contactSegments/contactSegments.utils'
import { buildHeader } from '@utils/folderUtils'

import './FormSubmissionInfoHoverCard.css'

interface FormSubmissionInfoHoverCardProps {
  className?: string
  dataTest?: string
  segment: Segment
  syncsWithFtp?: boolean
  hasExport?: boolean
  onFtpViewLogAction?: (target: string | undefined) => void
}

interface FormSubmissionInfoHoverCardState {
  relatedForms?: FormSubmissionSummaryDto[]
  recentBaseActivities?: FormSubmissionActivityDto
  recentActivitiesAudits?: AuditListQuery
  recentActivities?: FormActivity[]
}

interface FormActivity {
  activity: ReactNode
  timeStamp: number
}

export const formSubmissionInfoHoverCardRootClass = 'form-submission-info-hover-card'

const FormSubmissionInfoHoverCard: FC<FormSubmissionInfoHoverCardProps> = (props: FormSubmissionInfoHoverCardProps) => {
  const { dataTest = formSubmissionInfoHoverCardRootClass, className = '', segment, syncsWithFtp, hasExport = false, onFtpViewLogAction } = props
  const { t } = useTranslation()
  const { userName: loggedUser } = useAccountSettings()
  const [state, setState] = useState<FormSubmissionInfoHoverCardState>({})
  const { relatedForms, recentBaseActivities, recentActivitiesAudits, recentActivities } = state
  const {
    values: { folders },
    update,
  } = useContext(ListingPageCommonContext)
  const folderTreeBreadcrumbs: string[] = useMemo(() => buildHeader(segment.folderId as number, folders).reverse(), [segment, folders])
  const { getActivitiesAudits } = useFormsJoinViewListingPageRequests()
  const { getRelatedForms, getRecentActivities } = useFormsJoinViewListingPageEntityJoinRequests()
  useEffect(() => {
    getRelatedForms(segment.externalId).then((relatedForms) => setState((state) => ({ ...state, relatedForms: relatedForms })))
    getRecentActivities(segment.externalId).then((recentActivities) => setState((state) => ({ ...state, recentBaseActivities: recentActivities })))
    getActivitiesAudits(segment.externalId).then((activitiesAudits) =>
      setState((state) => ({ ...state, recentActivitiesAudits: activitiesAudits as AuditListQuery }))
    )
  }, [])

  useEffect(() => {
    if (recentBaseActivities && recentActivitiesAudits) {
      const activitiesArray: FormActivity[] = [
        ...(recentBaseActivities.mostRecentSubmission
          ? [{ activity: activityByDescription[FormActivities.LAST_SUBMISSION]('', t), timeStamp: recentBaseActivities.mostRecentSubmission }]
          : []),
        ...(recentBaseActivities.firstSubmission
          ? [{ activity: activityByDescription[FormActivities.FIRST_SUBMISSION]('', t), timeStamp: recentBaseActivities.firstSubmission }]
          : []),
        ...recentBaseActivities.amountOfSubmissionsByDate?.reduce((result: FormActivity[], submission) => {
          return [
            ...result,
            {
              activity: (
                <Typography
                  text={t(`FormSubmissions.InfoHover.RecentActivities.NewSubmissions`)}
                  values={{ amountOfSubmissions: submission.amount }}
                  weight={TextWeight.BOLD}
                  type={TextType.BODY_TEXT_SMALL}
                />
              ),
              timeStamp: submission.date,
            },
          ]
        }, []),
        ...(recentActivitiesAudits as AuditDto[]).reduce((result: FormActivity[], audit) => {
          const userName = audit.username === loggedUser ? 'you' : audit.username?.substring(0, audit.username?.indexOf(' ') + 2)
          return ['CUSTOM', 'CREATED'].includes(audit.auditType || '')
            ? [
                ...result,
                {
                  activity: activityByDescription[audit.auditType === 'CUSTOM' ? (audit?.description as FormActivities) : FormActivities.CREATED](
                    userName ?? '',
                    t
                  ),
                  timeStamp: audit?.createdTime,
                },
              ]
            : result
        }, []),
      ]
      setState((state) => ({
        ...state,
        recentActivities: activitiesArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1)).slice(0, 5),
      }))
    }
  }, [recentBaseActivities, recentActivitiesAudits])

  const renderSelector = () => {
    const event = new CustomEvent(CLOSE_INFO_HOVER_EVENT)
    document.dispatchEvent(event)
    update({
      showCustomModal: true,
      customTableAction: FormsJoinViewCustomModals.VIEW_FORM_REPORT,
      selectedRows: [{ externalId: segment.externalId }],
    })
  }

  return (
    <div className={classNames(formSubmissionInfoHoverCardRootClass, className)} data-test={dataTest}>
      <div className={`${formSubmissionInfoHoverCardRootClass}__section-tree`}>
        {syncsWithFtp && <FTPSyncSegmentDefinition onFtpViewLogAction={onFtpViewLogAction} hasExport={hasExport} segment={segment} />}
        {folderTreeBreadcrumbs.length !== 0 && (
          <>
            <Typography
              text={t('FormSubmissions.InfoHover.Location')}
              type={TextType.BODY_TEXT_LIGHT_TINY}
              weight={TextWeight.MEDIUM}
              inline
              className={`${formSubmissionInfoHoverCardRootClass}__section-title`}
            />
            <ExpandableBreadCrumbs crumbs={folderTreeBreadcrumbs} baseCrumb={t('FormSubmissions.InfoHover.BaseFolderCrumb')} />
            <div className={`${formSubmissionInfoHoverCardRootClass}__divider`} />
          </>
        )}
        <div className={`${formSubmissionInfoHoverCardRootClass}__section-related`}>
          <Typography
            text={t('FormSubmissions.InfoHover.RelatedForms')}
            type={TextType.BODY_TEXT_LIGHT_TINY}
            tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
            className={`${formSubmissionInfoHoverCardRootClass}__section-title`}
          />
        </div>
        {!relatedForms ? (
          <Loader className={`${formSubmissionInfoHoverCardRootClass}__loader`} />
        ) : (
          <RelatedFormsList relatedForms={relatedForms} nameMaxWidth={24} maxFormsToDisplay={3} />
        )}
        <div className={`${formSubmissionInfoHoverCardRootClass}__divider`} />
        <div className={`${formSubmissionInfoHoverCardRootClass}__section-recent`}>
          <Typography
            text={t('FormSubmissions.InfoHover.RecentActivities')}
            type={TextType.BODY_TEXT_LIGHT_TINY}
            tagProps={{ medium: { weight: TextWeight.MEDIUM } }}
            inline
            className={`${formSubmissionInfoHoverCardRootClass}__section-title`}
          />
          <TextLink text={t('View all')} size={TextLinkSize.SMALL} hideIcon onClick={() => renderSelector()} />
        </div>
        {recentActivities && (
          <div className={`${formSubmissionInfoHoverCardRootClass}__activities`}>
            {recentActivities.map(({ activity, timeStamp }, i) => renderField(activity, timeStamp, i))}
          </div>
        )}
      </div>
    </div>
  )
}

export default FormSubmissionInfoHoverCard
