import { gql } from '@apollo/client'

export default gql`
  query loadAccountSchema {
    loadAccountSchema {
      columns {
        name
        mapping
      }
    }
  }
`
