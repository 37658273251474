import { gql } from '@apollo/client'

export default gql`
  query segment($accountId: ID, $segmentId: String, $segmentStatus: SegmentStatus) {
    segment(accountId: $accountId, segmentId: $segmentId, segmentStatus: $segmentStatus) {
      id
      accountId
      configId
      jobId
      segmentId
      status
      name
      factors
      memberEstimate
      insertTime
      updateTime
      parentSegmentId
      type
      feedback
      selectionReason
    }
  }
`
