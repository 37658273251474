import { useEffect, useRef, MutableRefObject } from 'react'

import { CommonComposerState } from '@utils/composer/commonComposer/CommonComposer.context'

export const useMayLoseChanges = (containerValuesRef: MutableRefObject<CommonComposerState>) => {
  const offlineRef = useRef<boolean>(false)

  useEffect(() => {
    const setOnline = () => (offlineRef.current = false)
    const setOffline = () => (offlineRef.current = true)

    window.addEventListener('online', setOnline)
    window.addEventListener('offline', setOffline)

    return () => {
      window.removeEventListener('online', setOnline)
      window.removeEventListener('offline', setOffline)
    }
  }, [])

  useEffect(() => {
    window.onbeforeunload = () => {
      const { haveUnsavedChanges, isSaveFailed } = containerValuesRef.current
      return offlineRef.current || haveUnsavedChanges || isSaveFailed ? true : null
    }
    return () => {
      window.onbeforeunload = () => null
    }
  }, [containerValuesRef])
}
