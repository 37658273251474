import React, { FC, Fragment } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramSource } from '@graphql/types/query-types'

import './StartStepDetail.css'

interface StartStepDetailProps {
  sourceLists: ProgramSource[]
  className?: string
  dataTest?: string
}

const rootClass = 'start-step-detail'

const StartStepDetail: FC<StartStepDetailProps> = (props: StartStepDetailProps) => {
  const { sourceLists, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()
  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography text={`${t('Contact Sources')}:`} />
      <div className={`${rootClass}__list-container`}>
        {sourceLists.map((list, idx) => (
          <Fragment key={list.id}>
            <Svg name={SvgNames.listsAndSegments} type={SvgType.ICON} className={`${rootClass}__icon`} />
            <Typography text={idx < sourceLists.length - 1 ? `${list.name}, ` : list.name} weight={TextWeight.MEDIUM} inline />
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default StartStepDetail
