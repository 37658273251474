import React, { ChangeEvent, FC, useState } from 'react'

import classNames from 'classnames'

import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import FormRow from '@components/FormRow'
import InputV2 from '@components/InputV2/InputV2'
import { LabelV2 } from '@components/LabelV2/LabelV2'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import NestedDropDown from '@components/NestedDropDown/NestedDropDown'
import RemovableTag from '@components/RemovableTag/RemovableTag'
import Spinner from '@components/Spinner/Spinner'
import { SvgNames } from '@components/Svg'
import TagManager from '@components/TagManager/TagManager'
import TagManagerTriggerWithText from '@components/TagManagerTriggerWithText/TagManagerTriggerWithText'
import TextArea from '@components/TextArea/TextArea'
import { useTranslation } from '@const/globals'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { Folder } from '@interface/Folder'
import { SegmentInput } from '@interface/Segment'
import { ItemType } from '@utils/categorization'
import { getFolderDropDownOptions } from '@utils/folderUtils'
import { useCategorizationService } from '@utils/hooks/microservices/useCategorizationService'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import { NO_COLOR } from '@utils/tags'
import { removeElement } from '@utils/utils'

import './SegmentSaveModal.css'

export const getSegmentInputError = (errors: Set<SegmentError>) => {
  if (errors.size > 0) {
    return Array.from(errors).find((error) => [SegmentError.NAME_REQUIRED, SegmentError.DUPLICATE_NAME].includes(error))
  }
}

interface SegmentSaveModalProps {
  className?: string
  dataTest?: string
  defaultErrors?: Set<SegmentError>
  folders: Folder[]
  hasDescription?: boolean
  isOpen: boolean
  onClose: VoidFunction
  onSave: (name: string, folder: Folder | undefined, tags: LabelDto[], description?: string) => Promise<void>
  onCreateTag: (tag: LabelDto) => void
  segment?: SegmentInput
  tags: LabelDto[]
  title?: string
}

interface SegmentSaveModalState {
  description: string
  inputError?: SegmentError
  loading: boolean
  name: string
  selectedFolder: Folder | undefined
  selectedTags: LabelDto[]
}

export enum SegmentError {
  NAME_REQUIRED = 'SegmentSaveModal.Error.EmptyName',
  DUPLICATE_NAME = 'SegmentSaveModal.Error.DuplicateName',
}

const rootClass = 'segment-save-modal'
const MAX_DESCRIPTION_LENGTH = 600

export const SegmentSaveModal: FC<SegmentSaveModalProps> = (props: SegmentSaveModalProps) => {
  const { t } = useTranslation()

  const {
    className = '',
    dataTest = rootClass,
    defaultErrors,
    folders,
    hasDescription = false,
    isOpen,
    onClose,
    onCreateTag: onCreateTagProp,
    onSave,
    segment,
    tags,
    title = t('Create a new segment'),
  } = props

  const [state, setState] = useState<SegmentSaveModalState>({
    inputError: defaultErrors ? getSegmentInputError(defaultErrors) : undefined,
    loading: false,
    name: segment?.name || '',
    description: segment?.description || '',
    selectedFolder: segment?.folderId ? getFolderById(segment.folderId, folders) : undefined,
    selectedTags: segment?.labels ?? [],
  })
  const { inputError, loading, name, description, selectedFolder, selectedTags } = state

  const modalUpdate = useDeepUpdate(setState)

  const { createTags } = useCategorizationService()

  const onSaveCallback = async () => {
    if (!inputError) {
      modalUpdate({ loading: true })
      await onSave(name, selectedFolder, selectedTags, description)
      modalUpdate({ loading: false })
    }
  }

  const onSegmentNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newSegmentName = event.target.value.trim()
    modalUpdate({
      name: newSegmentName,
      ...(inputError && newSegmentName && { inputError: undefined }),
    })
  }

  const onSegmentNameBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value.trim()) {
      modalUpdate({ inputError: SegmentError.NAME_REQUIRED })
    }
  }

  const onSegmentDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newSegmentName = event.target.value.trim()
    modalUpdate({ description: newSegmentName })
  }

  const onRemoveTag = (tag: string) => {
    const index = selectedTags.findIndex(({ name }) => name === tag)
    const updatedAppliedTags = removeElement(index, selectedTags)
    modalUpdate({ selectedTags: updatedAppliedTags })
  }

  const onApplyAndRemoveTags = async (tagsToApply: LabelDto[], tagsToRemove: number[]) => {
    const newTags = tagsToApply.filter(({ id }) => !tags.some((tag) => tag.id === id))
    if (newTags.length > 0) {
      const { color = NO_COLOR, name = '' } = newTags[0]
      await onCreateTags(color, name)
    }
    const filteredTags = selectedTags.filter(({ id }) => !tagsToRemove.includes(id))
    modalUpdate({ selectedTags: filteredTags.length > 0 ? [...filteredTags, ...tagsToApply] : [...tagsToApply] })
  }

  const onCreateTags = async (color: string, name: string) => {
    modalUpdate({ loading: true })
    try {
      const newTags = await createTags({ type: ItemType.SEGMENT, tag: { name, color } })
      if (newTags) {
        onCreateTagProp(newTags[0])
      }
    } finally {
      modalUpdate({ loading: false })
    }
  }

  const onFolderSelect = (folderId: string | undefined) => {
    const folder = folderId !== undefined ? getFolderById(parseInt(folderId), folders) : undefined
    modalUpdate({ selectedFolder: folder })
  }

  return (
    <Modal
      className={classNames(rootClass, className)}
      dataTest={dataTest}
      isOpen={isOpen}
      paddingV2
      header={<ModalHeaderV2 className={`${rootClass}__modal-header`} headerType={ModalHeaderType.Form} headerText={title} />}
    >
      <ModalBody className={`${rootClass}__body`}>
        {loading ? (
          <Spinner dataTest={`${dataTest}-spinner`} />
        ) : (
          <>
            <FormRow className={`${rootClass}__form-row`}>
              <InputV2
                labelProps={{ label: t('Segment name'), required: true }}
                inputInfo={{
                  enabled: true,
                  helperText: t('SegmentSaveModal.Input.Helper'),
                  errorText: t(inputError),
                  hasIcon: true,
                }}
                error={!!inputError}
                className={`${rootClass}__input`}
                dataTest={`${dataTest}-segment-name-input`}
                value={name}
                placeholder={''}
                onChange={onSegmentNameChange}
                onBlur={onSegmentNameBlur}
                required
              />
            </FormRow>
            {hasDescription ? (
              <FormRow className={`${rootClass}__form-row`}>
                <TextArea
                  className={`${rootClass}__description`}
                  dataTest={`${dataTest}-description`}
                  name={'description'}
                  label={t('Description')}
                  placeholder={t('SegmentSaveModal.Input.Description.Placeholder')}
                  defaultValue={description}
                  maxCharacterProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
                  onChange={onSegmentDescriptionChange}
                />
              </FormRow>
            ) : (
              <></>
            )}
            <FormRow className={`${rootClass}__form-row`}>
              <LabelV2>{t('Location')}</LabelV2>
              <NestedDropDown
                dataTest={`${dataTest}-folder-drop-down`}
                options={getFolderDropDownOptions(folders, true)}
                defaultSelected={selectedFolder?.id.toString()}
                footerPrimaryButtonText={t('Add here')}
                onSubmit={onFolderSelect}
                placeholderIcon={SvgNames.moveFolder}
                title={t('Select a folder')}
                withTitle
                withFooter
                className={`${rootClass}__folder-drop-down`}
                asChild
                isRequired={false}
              />
            </FormRow>
            <FormRow className={`${rootClass}__form-row`}>
              <LabelV2>{t('Tags')}</LabelV2>
              <div className={`${rootClass}__tags`}>
                {selectedTags.map(({ name, color }) => (
                  <RemovableTag key={name} onRemove={onRemoveTag} color={color ?? NO_COLOR} name={name ?? 'No Name'} />
                ))}
                <TagManager
                  appliedTags={selectedTags}
                  tags={tags}
                  trigger={<TagManagerTriggerWithText />}
                  onApplyAndRemove={onApplyAndRemoveTags}
                  onCreate={onCreateTags}
                  title={'Manage Tags'}
                  dropDownContentProps={{
                    align: 'start',
                    side: 'top',
                    avoidCollisions: false,
                  }}
                />
              </div>
            </FormRow>
          </>
        )}
      </ModalBody>
      <ModalFooterV2
        dataTest={`${dataTest}-footer`}
        footerType={ModalFooterType.Form}
        onClose={onClose}
        buttons={{
          actionButtonDisabled: !!inputError || loading || !name,
          actionButtonLabel: t('Save'),
          actionButtonOnClick: onSaveCallback,
          cancelButtonLabel: t('Cancel'),
        }}
      />
    </Modal>
  )
}
