import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import classNames from 'classnames'

import { ApolloError, useApolloClient } from '@apollo/client'
import { TabItemData } from '@components/TabsAO/TabsAO'
import { useTranslation } from '@const/globals'
import { GetSummaryByLaunchIdQuery, GetSummaryByMessageIdQuery } from '@graphql/types/microservice/sms-management-types'
import ReportDetail from '@src/pages/sms/report/components/ReportDetail/ReportDetail'
import SummaryReport from '@src/pages/sms/report/components/SummaryReport/SummaryReport'
import { FAILED_FIELDS, MessageDetailsType, reportTabData, ReportTabData, ReportTabs, tabsMockData } from '@src/pages/sms/report/SMSReport.constants'
import {
  fetchAutomatedProgramMessageDetails,
  fetchLaunch,
  fetchLaunchDetails,
  fetchSummaryByMessageIdQuery,
  fetchWelcomeSendMessageDetails,
} from '@src/pages/sms/report/smsReportUtils'
import { useAccountSettings } from '@utils/account/account.utils'
import { getColorValue } from '@utils/document'
import useMicroserviceClient, { getAuthenticatedPromise, MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { MatchParams } from '@utils/types'

import SMSReport from './SMSReport'

interface SummaryCount {
  name: string
  totalCount: number
  uniqueCount: number
  uniquePercent?: number
  tooltip?: string
  color?: string
}

interface LocationParams {
  search: string
  state?: {
    tab: string
  }
}

interface Props {
  dataTest?: string
  location: LocationParams
}

type AllProps = RouteComponentProps<MatchParams> & Props

interface DataState {
  data?: GetSummaryByLaunchIdQuery | GetSummaryByMessageIdQuery
  loading: boolean
  pageError?: ApolloError
}

interface DetailsState {
  messageDetails?: MessageDetailsType
  messageLoading: boolean
}

export const DEFAULT_SMS_REPORT_DATA = {
  smsAveRate: {
    aveClickRate: 0,
    aveDeliveryRate: 0,
    aveOptOutRate: 0,
    aveReplyRate: 0,
  },
  smsFailedDetails: {
    failedReasonToCount: {},
    failedTotal: 0,
  },
  smsTotalCount: {
    clicked: 0,
    replied: 0,
    sent: 0,
  },
  smsUniqueCount: {
    clicked: 0,
    delivered: 0,
    failed: 0,
    optedOut: 0,
    replied: 0,
    sent: 0,
  },
} as GetSummaryByLaunchIdQuery

const DEFAULT_DATA_STATE: DataState = {
  loading: true,
  pageError: undefined,
  data: DEFAULT_SMS_REPORT_DATA,
}

export const renderInfoText = (text: string, theme: ReportTabs, count?: number) => {
  const textParts = text.split('**')
  const countText = count ? ` (${count})` : ''
  return (
    <span>
      {textParts[0]}
      <span className={classNames('report-detail__info-text', `report-detail__info-text-${theme}`)}>{textParts[1]}</span>
      {textParts[2]}
      {countText}
    </span>
  )
}

const SMSReportContainer: FC<AllProps> = (props: AllProps) => {
  const {
    match,
    location: { search },
  } = props
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [tabs, setTabs] = useState<TabItemData[]>(tabsMockData)
  const [tab, setTab] = useState<string>(ReportTabs.SUMMARY)
  const [dataState, setDataState] = useState<DataState>(DEFAULT_DATA_STATE)
  const [detailsState, setDetailsState] = useState<DetailsState>({
    messageLoading: false,
  })
  const [launchFetched, setLaunchFetched] = useState(false)

  const { accountId } = useAccountSettings()
  const { client, token, aoAccountContext } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_REPORTING })
  const { t } = useTranslation()
  const history = useHistory()
  const launchId = match.params.id
  const isLaunch = launchId.indexOf('-') > -1
  const isWelcomeMessage = !isLaunch && search.indexOf('programId') === -1
  const programId = search.indexOf('programId') > -1 ? search.split('=')[1] : !isLaunch ? launchId : undefined

  const responseUpdateData = (response: DataState) => {
    setDataState({ ...dataState, ...response })
  }

  const responseUpdateDetails = (response: DetailsState) => {
    setDetailsState({ ...detailsState, ...response })
  }

  const apolloClient = useApolloClient()

  useEffect(() => {
    if (!detailsState.messageLoading && !dataState.loading) {
      setIsDataLoading(false)
    }
  }, [detailsState?.messageLoading, dataState?.loading])

  const getDownloadUrl = (): Promise<string> => {
    return getAuthenticatedPromise(MicroserviceClients.SMS_REPORTING, `/sms-report/${accountId}/${launchId}`, token, aoAccountContext).then(
      (response) => {
        return response
      }
    )
  }

  const getResponseData = () =>
    isLaunch
      ? (dataState.data as GetSummaryByLaunchIdQuery)?.getSummaryByLaunchId
      : (dataState.data as GetSummaryByMessageIdQuery)?.getSummaryByMessageId

  const fetchData = () => {
    if (isLaunch) {
      if (!launchFetched) {
        setLaunchFetched(true)
        fetchLaunch(launchId, accountId, client, responseUpdateData)
      }
      fetchLaunchDetails(launchId, apolloClient, responseUpdateDetails)
    } else {
      fetchSummaryByMessageIdQuery(launchId, accountId, client, responseUpdateData)
      if (programId && !isWelcomeMessage) {
        fetchAutomatedProgramMessageDetails(Number(launchId), programId as string, apolloClient, responseUpdateDetails)
      }
      if (programId && isWelcomeMessage) {
        fetchWelcomeSendMessageDetails(launchId as unknown as number, apolloClient, responseUpdateDetails)
      }
    }
  }

  useEffect(() => {
    const responseData = getResponseData()
    if (responseData) {
      const smsUniqueCount = responseData?.smsUniqueCount ?? {}
      const smsTotalCount = responseData?.smsTotalCount ?? {}
      const smsFailedTotal = responseData.smsFailedDetails?.failedTotal ?? 0
      const reportTotal = smsUniqueCount.sent ?? 0
      const deliveredTotal = smsUniqueCount.delivered ?? 0

      // Delivered tab
      const sentPrimary = reportTabData.delivered.fields.filter((field) => field.isPrimary)[0]
      sentPrimary.data = smsTotalCount.sent ?? 0
      sentPrimary.uniques = smsUniqueCount.delivered ?? 0
      const sentRemainder = reportTabData.delivered.fields.filter((field) => !field.isPrimary)[0]
      sentRemainder.data = reportTotal - (sentPrimary.uniques ?? 0)

      // Clicked
      const clickedPrimary = reportTabData.clicked.fields.filter((field) => field.isPrimary)[0]
      clickedPrimary.data = smsTotalCount.clicked ?? 0
      clickedPrimary.uniques = smsUniqueCount.clicked ?? 0
      const clickedRemainder = reportTabData.clicked.fields.filter((field) => !field.isPrimary)[0]
      clickedRemainder.data = deliveredTotal - (clickedPrimary.uniques ?? 0)

      // Replied
      const repliedPrimary = reportTabData.replied.fields.filter((field) => field.isPrimary)[0]
      repliedPrimary.data = smsTotalCount.replied ?? 0
      repliedPrimary.uniques = smsUniqueCount.replied ?? 0
      const repliedRemainder = reportTabData.replied.fields.filter((field) => !field.isPrimary)[0]
      repliedRemainder.data = deliveredTotal - (repliedPrimary.uniques ?? 0)

      // Opt-out
      const optedOutPrimary = reportTabData.optedout.fields.filter((field) => field.isPrimary)[0]
      optedOutPrimary.data = smsUniqueCount?.optedOut ?? 0
      optedOutPrimary.uniques = smsUniqueCount?.optedOut ?? 0
      const optedOutRemainder = reportTabData.optedout.fields.filter((field) => !field.isPrimary)[0]
      optedOutRemainder.data = deliveredTotal - (optedOutPrimary.uniques ?? 0)

      // Failed
      if (smsFailedTotal > 0) {
        const failedReasons = responseData.smsFailedDetails?.failedReasonToCount
        if (failedReasons) {
          const uniqueFailures = smsUniqueCount?.failed ?? 0
          const updatedFields = FAILED_FIELDS.filter((field) => Object.keys(failedReasons).includes(field?.label ?? '')).map((field, index) => {
            field.data = failedReasons[field?.label ?? '']
            if (index === 0) {
              field.isPrimary = true
              field.uniques = uniqueFailures
            } else {
              field.isPrimary = false
              field.uniques = 0
            }
            return field
          })
          reportTabData.failed.fields = [...updatedFields]
        }
      } else {
        reportTabData.failed.fields = []
      }

      updateTabData()
    }
    fetchData()
  }, [dataState.data])

  const summaryCounts = (reportTabData: ReportTabData) => {
    const failedUnique = reportTabData.failed.fields.reduce((acc, curr) => acc + (curr.uniques || 0), 0)
    const deliveredUnique = reportTabData.delivered.fields.filter((field) => field.isPrimary)[0].uniques || 0
    const sentUnique = failedUnique + deliveredUnique
    const clickedUnique = reportTabData.clicked.fields.filter((field) => field.isPrimary)[0].uniques || 0
    const repliedUnique = reportTabData.replied.fields.filter((field) => field.isPrimary)[0].uniques || 0
    const optOutUnique = reportTabData.optedout.fields.filter((field) => field.isPrimary)[0].uniques || 0

    return {
      sent: {
        name: t('Sent'),
        totalCount: reportTabData.delivered.fields.filter((field) => field.isPrimary)[0].data || 0,
        uniqueCount: sentUnique,
        color: getColorValue('--border-sent'),
      },
      failed: {
        name: t('Failed'),
        totalCount: reportTabData.failed.fields.reduce((acc, curr) => acc + (curr.data as number), 0),
        uniqueCount: failedUnique,
        uniquePercent: sentUnique ? Math.floor((failedUnique / sentUnique) * 100) : 0,
        tooltip: t('Failed / Sent * 100'),
        color: getColorValue('--border-failed'),
      },
      delivered: {
        name: t('Delivered'),
        totalCount: 0,
        uniqueCount: deliveredUnique,
        uniquePercent: sentUnique ? Math.floor((deliveredUnique / sentUnique) * 100) : 0,
        tooltip: t('Delivered / Sent * 100'),
        color: getColorValue('--border-delivered'),
      },
      clicked: {
        name: t('Clicked'),
        totalCount: reportTabData.clicked.fields.filter((field) => field.isPrimary)[0].data || 0,
        uniqueCount: clickedUnique,
        uniquePercent: deliveredUnique ? Math.floor((clickedUnique / deliveredUnique) * 100) : 0,
        tooltip: t('Clicked / Delivered * 100'),
        color: getColorValue('--border-clicked'),
      },
      replied: {
        name: t('Replied'),
        totalCount: reportTabData.replied.fields.filter((field) => field.isPrimary)[0].data || 0,
        uniqueCount: repliedUnique,
        uniquePercent: deliveredUnique ? Math.floor((repliedUnique / deliveredUnique) * 100) : 0,
        tooltip: t('Replied / Delivered * 100'),
        color: getColorValue('--border-replied'),
      },
      optedout: {
        name: t('Opted Out'),
        totalCount: reportTabData.optedout.fields.filter((field) => field.isPrimary)[0].data || 0,
        uniqueCount: optOutUnique,
        uniquePercent: deliveredUnique ? Math.floor((optOutUnique / deliveredUnique) * 100) : 0,
        tooltip: t('Opt-Outs / Delivered * 100'),
        color: getColorValue('--border-optedout'),
      },
    }
  }

  const summaryChartData = (count: SummaryCount) => {
    return {
      name: t(count.name),
      data: count.uniqueCount,
      color: count.color,
    }
  }

  const summaryTableData = (count: SummaryCount, indented = false, showTotal = false, showUniquePercent = false) => {
    return {
      action: count.name,
      color: count.color,
      indented,
      tooltip: count.tooltip,
      uniqueCount: count.uniqueCount,
      ...(showUniquePercent && {
        uniquePercent: `${count.uniquePercent}%`,
      }),
      ...(showTotal && { totalCount: count.totalCount }),
    }
  }

  const updateTabData = () => {
    const failedUnique = reportTabData.failed.fields.reduce((acc, curr) => acc + (curr.uniques || 0), 0)
    const deliveredUnique = reportTabData.delivered.fields.filter((field) => field.isPrimary)[0].uniques || 0
    const sentUnique = failedUnique + deliveredUnique

    const counts: { [key: string]: any } = summaryCounts(reportTabData)
    const updatedTabs = tabs.map((tab) => {
      if (tab.index === ReportTabs.SUMMARY) {
        if (tab.labelContent) {
          tab.labelContent.primary = sentUnique
        }
        tab.content = (
          <SummaryReport
            chartDetails={[
              summaryChartData(counts.sent),
              summaryChartData(counts.delivered),
              summaryChartData(counts.clicked),
              summaryChartData(counts.replied),
              summaryChartData(counts.failed),
              summaryChartData(counts.optedout),
            ]}
            tableData={[
              summaryTableData(counts.sent, false, false),
              summaryTableData(counts.failed, true, false, true),
              summaryTableData(counts.delivered, false, false, true),
              summaryTableData(counts.clicked, true, true, true),
              summaryTableData(counts.replied, true, true, true),
              summaryTableData(counts.optedout, true, false, true),
            ]}
          />
        )
        return tab
      }
      const tabData = reportTabData[tab.index]
      const responseData = getResponseData()

      // Forgive me
      if (tabData.average) {
        if (tab.index === 'delivered') {
          tabData.average = { ...tabData.average, percentage: responseData?.smsAveRate?.aveDeliveryRate ?? 0 }
        } else if (tab.index === 'clicked') {
          tabData.average = { ...tabData.average, percentage: responseData?.smsAveRate?.aveClickRate ?? 0 }
        } else if (tab.index === 'replied') {
          tabData.average = { ...tabData.average, percentage: responseData?.smsAveRate?.aveReplyRate ?? 0 }
        } else if (tab.index === 'optedout') {
          tabData.average = { ...tabData.average, percentage: responseData?.smsAveRate?.aveOptOutRate ?? 0 }
        }
      }
      const tabFields = tabData.fields.map((field) => ({ ...field, label: t(field.label) }))
      const count = tab.index === ReportTabs.DELIVERED ? sentUnique : counts[tab.index].totalCount
      const countInfo = tab.index === ReportTabs.CLICKED || tab.index === ReportTabs.REPLIED ? count : undefined

      tab.content = (
        <ReportDetail
          launchId={launchId}
          isLaunch={isLaunch}
          themeName={tab.themeName as ReportTabs}
          chartDetails={{
            title: t(tabData.title),
            fields: tabFields,
            average: tabData.average,
          }}
          tableDetails={{
            columns: tabData.columns,
            infoText: renderInfoText(tabData.infoText, tab.themeName as ReportTabs, countInfo),
          }}
          emptyState={tabData.emptyState}
          count={count}
        />
      )
      const primaryField = tabData.fields.filter((field) => field.isPrimary)
      let tabPrimary = 0
      let tabPercent
      if (tab.index === ReportTabs.FAILED) {
        tabPrimary = tabData.fields.reduce((acc, curr) => acc + (curr.data as number), 0)
        tabPercent = sentUnique ? Math.floor((tabPrimary / sentUnique) * 100) : 0
      } else if (tab.index === ReportTabs.DELIVERED) {
        tabPrimary = deliveredUnique
        tabPercent = deliveredUnique ? Math.floor((tabPrimary / sentUnique) * 100) : 0
      } else {
        tabPrimary = primaryField[0].uniques ?? 0
        tabPercent = sentUnique ? Math.floor((tabPrimary / sentUnique) * 100) : 0
      }

      tab.tooltip = counts[tab.index].tooltip
      if (tab.labelContent) {
        tab.labelContent.primary = tabPrimary
        tab.labelContent.secondary = tabPercent !== undefined ? `${tabPercent}%` : ''
      }
      return tab
    })
    setTabs(updatedTabs)
  }

  const onTabChange = (tab: string) => {
    const state = { ...(history.location.state as Object), tab: tab }
    history.replace(location.pathname, state)
    setTab(tab)
  }

  const messageTitle = detailsState.messageDetails?.messageTitle ?? ''

  return (
    <>
      <SMSReport
        messageName={messageTitle ?? ''}
        messageDetails={detailsState.messageDetails}
        tabs={tabs}
        currentTab={tab}
        onTabChange={onTabChange}
        getDownloadUrl={getDownloadUrl}
        loading={isDataLoading}
        isLaunch={isLaunch}
        pageError={dataState.pageError}
      />
    </>
  )
}

SMSReportContainer.displayName = 'SMSReportContainer'
export default SMSReportContainer
