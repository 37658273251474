import { SvgNames, SvgType } from '@components/Svg'

const getHeaderActions = (
  t: Function,
  onDeleteUsersAction: () => void,
  onResendVerificationAction: () => void,
  onCopyPermissionAction: () => void,
  rowsCount: number
) => [
  {
    label: t('Copy permissions from another user'),
    icon: SvgNames.cloneSegment,
    iconSize: SvgType.LARGER_ICON,
    hasTooltip: true,
    onClick: () => onCopyPermissionAction(),
  },
  {
    label: t('Resend verification email'),
    icon: SvgNames.letter,
    iconSize: SvgType.LARGER_ICON,
    hasTooltip: true,
    hidden: (rows: any) => rowsCount !== rows.length && rows.every((row: any) => row.original.isVerified),
    onClick: () => onResendVerificationAction(),
  },
  {
    label: t('Delete'),
    icon: SvgNames.delete,
    hasTooltip: true,
    iconSize: SvgType.LARGER_ICON,
    onClick: () => onDeleteUsersAction(),
  },
]

export default getHeaderActions
