import unifiedListFieldMappings from '@graphql/microservices/list/getUnifiedListFieldMappings'
import scoreSheets from '@graphql/microservices/list/scoreSheets'
import {
  GetUnifiedListFieldMappingsQuery,
  GetUnifiedListFieldMappingsQueryVariables,
  ScoreSheetsQuery,
  ScoreSheetsQueryVariables,
} from '@graphql/types/microservice/list-types'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import { FetchPromise } from '@utils/types'

export interface SegmentComposerListRequests {
  getScoreSheetsRequest: () => FetchPromise<ScoreSheetsQuery>
  getUnifiedFieldsRequest: () => FetchPromise<GetUnifiedListFieldMappingsQuery>
}

export const useSegmentComposerListRequests = (): SegmentComposerListRequests => {
  const { client: listClient } = useMicroserviceClient({ serviceName: MicroserviceClients.LIST })

  const getScoreSheetsRequest = () =>
    listClient.query<ScoreSheetsQuery, ScoreSheetsQueryVariables>({
      query: scoreSheets,
      fetchPolicy: 'network-only',
    })

  const getUnifiedFieldsRequest = () =>
    listClient.query<GetUnifiedListFieldMappingsQuery, GetUnifiedListFieldMappingsQueryVariables>({
      query: unifiedListFieldMappings,
      fetchPolicy: 'network-only',
    })

  return {
    getScoreSheetsRequest,
    getUnifiedFieldsRequest,
  }
}
