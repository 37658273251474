import React, { MutableRefObject, FC, useCallback, useState } from 'react'

import classNames from 'classnames'

import EmptyListing from '@components/EmptyListing/EmptyListing'
import LazyLoadOnScrollContainer from '@components/LazyLoadOnScrollContainer/LazyLoadOnScrollContainer'
import SortDropDown from '@components/SortDropDown/SortDropDown'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import SwitchButtons from '@components/SwitchButtons/SwitchButtons'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import EmailLayoutsModalGridView from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/components/EmailLayoutsModalContent/EmailLayoutsModalGridView'
import EmailLayoutsModalTable from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/components/EmailLayoutsModalContent/EmailLayoutsModalTable'
import { useEmailLayoutsModalContentContext } from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/context/EmailLayoutsModalContent.context'
import {
  emailLayoutsModalSortOptions,
  EmailLayoutsModalView,
} from '@src/pages/EmailComposer/EmailModals/components/EmailLayoutsModal/utils/EmailLayoutsModal.utils'

import './EmailLayoutsModalContent.css'

interface EmailLayoutsModalContentProps {
  viewTypeRef: MutableRefObject<EmailLayoutsModalView>
  onLoadNext: () => Promise<void>
  allLoaded: boolean
  saveScrollSessionKey?: string
  dataTest?: string
}

const icons = [
  { icon: SvgNames.gridView, key: EmailLayoutsModalView.GRID },
  { icon: SvgNames.listView, key: EmailLayoutsModalView.LIST },
]

const rootClass = 'email-layouts-modal-content'

const EmailLayoutsModalContent: FC<EmailLayoutsModalContentProps> = ({
  viewTypeRef,
  onLoadNext,
  allLoaded,
  saveScrollSessionKey,
  dataTest = rootClass,
}) => {
  const { searchTerm, loading, layouts, sortBy, onSortColumnChange } = useEmailLayoutsModalContentContext()
  const { t } = useTranslation()
  const [view, setView] = useState<EmailLayoutsModalView>(viewTypeRef.current)
  const isListView = view === EmailLayoutsModalView.LIST

  const handleViewChange = useCallback(
    (view: EmailLayoutsModalView) => {
      viewTypeRef.current = view
      setView(view)
    },
    [viewTypeRef]
  )

  const count = layouts.length

  return (
    <>
      {!loading && searchTerm && (
        <Typography
          className={classNames('inset-x4 inset', `${rootClass}__search-results`)}
          text="AssetPicker.Search.Results"
          values={{ count, searchTerm }}
          type={TextType.SECTION_HEADER}
          weight={TextWeight.BOLD}
          dataTest={`${dataTest}-search-results`}
        />
      )}
      <div className={`${rootClass}__filters`} data-test={`${dataTest}-filters`}>
        <SortDropDown options={emailLayoutsModalSortOptions} onSelect={onSortColumnChange} selectedOption={sortBy} dataTest={`${dataTest}-sort`} />
        <SwitchButtons onChange={handleViewChange} icons={icons} defaultActive={view} dataTest={`${dataTest}-switch`} />
      </div>
      <LazyLoadOnScrollContainer
        className={`${rootClass}__lazy-load-container`}
        onLoadMoreData={onLoadNext}
        hideSpinner={isListView}
        allLoaded={allLoaded}
        saveScrollSessionKey={saveScrollSessionKey}
      >
        {!loading && !count ? (
          <EmptyListing
            imgSrc={StaticImageNames.emptySearch}
            text="Empty.Listing.No.Results.Text"
            headline={t('Empty.Listing.No.Results.Headline', { term: searchTerm })}
            withoutBorder
            dataTest={`${dataTest}-empty-listing`}
          />
        ) : isListView ? (
          <EmailLayoutsModalTable />
        ) : (
          <EmailLayoutsModalGridView rootClass={`${rootClass}__grid`} dataTest={`${dataTest}-grid`} />
        )}
      </LazyLoadOnScrollContainer>
    </>
  )
}

export default EmailLayoutsModalContent
