import { getMenuItems, GetMenuItemsParams, renderMoreFiltersItems } from '@utils/menuItems'

export const getContactSegmentsMenuItems = (
  {
    filterActive,
    filterAction,
    rootClass,
    customFilters,
    folders,
    defaultOpenedFolders,
    activeFolderId,
    activeTagId,
    tags,
    t,
    ...menuItemProps
  }: GetMenuItemsParams,
  hideCrmSynced: boolean
) => {
  const [commonFilters, ...tagsAndFolders] = getMenuItems(
    {
      rootClass,
      customFilters,
      filterActive,
      folders,
      defaultOpenedFolders,
      activeFolderId,
      tags,
      activeTagId,
      t,
      filterAction,
      ...menuItemProps,
    },
    true,
    true
  )

  return [commonFilters, renderMoreFiltersItems(filterAction, rootClass, t, filterActive, hideCrmSynced), ...tagsAndFolders]
}
