import { useEffect, useState } from 'react'

import dataManagementPageLoad from '@graphql/microservices/crm/dataManagementPageLoad'
import { DataManagementPageLoadQuery, DataManagementPageLoadQueryVariables, SyncSchedule } from '@graphql/types/microservice/crm-types'
import { useAccountSettings } from '@utils/account/account.utils'
import { useTranslation } from '@utils/const/globals'
import { dayOfWeek, dayWithOrdinal } from '@utils/date'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { logNewRelicError } from '@utils/new-relic.utils'

interface SyncCRMData {
  isSyncing?: boolean
  isSyncingError: boolean
  lastSyncedDate: string
  syncConfiguration: string
}

const CRMConvertedTime = (date?: Date, accountTimeZone?: string) => {
  if (date && accountTimeZone) {
    const time = date
      .toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true, timeZone: accountTimeZone, timeZoneName: 'short' })
      .toLocaleUpperCase()
    const dateString = date.toLocaleDateString([], { month: '2-digit', day: '2-digit', year: '2-digit' })
    return `${time} ${dateString}`
  }
}

const formattedSyncConfigurationInfo = (t: Function, schedule?: SyncSchedule, timeZone?: string) => {
  const date = new Date()
  date.setHours(Number(schedule?.hour), schedule?.minute)
  const time = `${date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })} ${schedule?.ampm?.toUpperCase()} ${timeZone}`

  if (schedule?.automaticSchedule) {
    return 'every hour'
  } else {
    switch (schedule?.mode) {
      case 'Hourly':
        return `every ${schedule?.hourInterval} hours starting at ${time}`
      case 'Daily':
        return `every day at ${time}`
      case 'Weekly':
        return `every ${dayOfWeek(t, schedule?.dayOfWeek)} at ${time}`
      case 'Monthly':
        return `every ${dayWithOrdinal(schedule?.dayOfMonth)} of the month at ${time}`
      default:
        return 'manually'
    }
  }
}

export const useGetCRMSyncing = (): SyncCRMData => {
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })
  const { accountTimeZoneId } = useAccountSettings()

  const { t } = useTranslation()

  const { data, error, loading } = useQueryOnMount<DataManagementPageLoadQuery, DataManagementPageLoadQueryVariables>(dataManagementPageLoad, {
    client,
    fetchPolicy: 'network-only',
  })

  const isSyncing = data?.syncStatus !== 'Idle'
  const date = new Date(data?.lastSync?.ended)
  const timeZone = date.toLocaleTimeString([], { timeZone: accountTimeZoneId, timeZoneName: 'short' }).split(' ')[2]

  const syncData = {
    isSyncing,
    isSyncingError: !!error,
    lastSyncedDate: CRMConvertedTime(date, accountTimeZoneId) ?? '',
    syncConfiguration: formattedSyncConfigurationInfo(t, data?.syncSchedule as SyncSchedule, timeZone),
  }
  const [syncState, setSyncState] = useState<SyncCRMData>(syncData)

  if (error) {
    logNewRelicError(error)
  }

  useEffect(() => {
    if (!loading) {
      setSyncState(syncData)
    }
  }, [data, loading])

  return syncState
}
