export const MARKETING_USERS_MORE_INFO =
  'https://connect.act-on.com/hc/en-us/articles/360023939513-Difference-Between-Marketing-Users-and-Sales-Users'

export const PERMISSION_TITLES_MAP = new Map<string, string>([
  ['contactLists', 'Contact List'],
  ['allContent', 'All Content'],
  ['accountWideEmails', 'Account-wide Emails'],
  ['automatedPrograms', 'Automated Programs'],
])

export const PERMISSION_TITLES = ['VIEW', 'EDIT', 'DELETE', 'DOWNLOAD']
