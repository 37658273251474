import { MutableRefObject, SetStateAction, useCallback } from 'react'

import _ from 'lodash'

import { DeepPartial, DeepUpdateState } from '../types'

const UNDEFINED_PLACEHOLDER = '__UNDEFINED__'

const deepCloneMerge = <T extends {}>(objValue: DeepPartial<T>, srcValue: DeepPartial<T>): unknown => {
  if (_.isUndefined(srcValue)) {
    // mergeWith ignores explicitly undefined values
    // so we need to replace this afterward with enforceUndefined
    return UNDEFINED_PLACEHOLDER
  }
  if (_.isNull(srcValue)) {
    return null
  }
  if (_.isArray(objValue) && _.isArray(srcValue)) {
    return srcValue
  }
  if (_.isPlainObject(objValue)) {
    return _.mergeWith(_.clone(objValue), srcValue, deepCloneMerge)
  }
}
const enforceUndefined = (state: any) => {
  if (state instanceof Object) {
    Object.keys(state).forEach((key) => {
      try {
        state[key] = enforceUndefined(state[key])
      } catch (e) {
        // This can fail in Cypress tests if the object is a React component
        return state[key]
      }
    })
  } else if (state === UNDEFINED_PLACEHOLDER) {
    return undefined
  }
  return state
}

export const useDeepUpdate = <T extends {}>(setState: (values: SetStateAction<T>) => void, refObject?: MutableRefObject<DeepPartial<T>>) => {
  const setDeepState: DeepUpdateState<T> = useCallback(
    (fields) =>
      setState((state) => {
        const newState = enforceUndefined(deepCloneMerge(state, fields)) as T
        if (refObject) {
          refObject.current = newState
        }
        return newState
      }),
    [setState, refObject]
  )

  return setDeepState
}
