import React, { CSSProperties, FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import Loader from '@components/Loader'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { WebinarType } from '@src/pages/listingPages/FormsJoinView/components/DetailsCardWrapper/utils/DetailsCardWrapper.utils'
import { useAttendanceAndConversionRateLineChartQueries } from '@src/pages/listingPages/FormsJoinView/components/FormSubmissionInfoHoverCard/components/AttendanceAndConversionRateLineChart/AttendanceAndConversionRateLineChart.graphQL'
import { Segment } from '@utils/contactSegments/contactSegments.utils'

import './AttendanceAndConversionRateLineChart.css'

interface AttendanceAndConversionRateLineChartProps {
  className?: string
  dataTest?: string
  segment: Segment
  isWebinarRegistration?: boolean
}

const rootClass = 'attendance-and-conversion-rate-line-chart'

const AttendanceAndConversionRateLineChart: FC<AttendanceAndConversionRateLineChartProps> = (props: AttendanceAndConversionRateLineChartProps) => {
  const { dataTest = rootClass, className = '', segment, isWebinarRegistration } = props

  const [rate, setRate] = useState<number>()
  const [loadingRate, setLoadingRate] = useState<boolean>(true)

  const { t } = useTranslation()

  const { getConversionRateRequest, getWebinarAttendanceRequest } = useAttendanceAndConversionRateLineChartQueries()

  useEffect(() => {
    setLoadingRate(true)
    if (isWebinarRegistration) {
      const webinarId = 'webinarId' in segment ? (segment.webinarId as string) : ''
      const webinarType = segment.subTypeDTO && segment.subTypeDTO[0] && ((segment.subTypeDTO[0].name as string).toUpperCase() as WebinarType)
      if (webinarType) {
        setLoadingRate(true)
        getWebinarAttendanceRequest(webinarId, webinarType).then((attendance) => {
          setLoadingRate(false)
          setRate(attendance ?? 0)
        })
      }
    } else {
      getConversionRateRequest(segment.externalId).then(({ data }) => {
        data && setRate(data.getConversionRate)
        setLoadingRate(false)
      })
    }
  }, [])

  const lineLength: CSSProperties = { width: `${rate}%` }

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      {!loadingRate ? (
        <>
          <div className={`${rootClass}__values`}>
            <Typography
              text={isWebinarRegistration ? t('Attendance') : t('Conversion rate')}
              weight={TextWeight.MEDIUM}
              lineHeight={LineHeight.MEDIUM_LARGE}
              inline
            />
            <Typography text={`${rate}%`} type={TextType.BODY_TEXT_LIGHT} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_LARGE} inline />
          </div>
          <div className={`${rootClass}__bar`}>
            <div className={`${rootClass}__bar-total`} />
            <div style={lineLength} className={`${rootClass}__bar-actual-wrapper`}>
              <div
                className={classNames(`${rootClass}__bar-actual`, {
                  [`${rootClass}__bar-actual-hidden`]: rate === 0,
                })}
              />
            </div>
          </div>
        </>
      ) : (
        <Loader className={`${rootClass}__loader`} />
      )}
    </div>
  )
}

export default AttendanceAndConversionRateLineChart
