import React, { FC, useContext } from 'react'

import classNames from 'classnames'

import Loader from '@components/Loader'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ImportContactsContext } from '@src/pages/importcontacts/context/ImportContactsContext'

import './ImportSummary.css'

const rootClass = 'import-summary'

const ImportSummary: FC = () => {
  const {
    values: {
      importingList,
      summary: { mapped, unmapped, newFields, warnings },
      creatingSegmentLoading,
    },
  } = useContext(ImportContactsContext)
  const { t } = useTranslation()

  const renderSummary = () => {
    const summary = [
      { label: 'FIELDS MAPPED', value: mapped },
      { label: 'UNMAPPED', value: unmapped },
      { label: 'NEW FIELDS', value: newFields },
      { label: 'WARNINGS', value: warnings },
    ]
    return summary.map(({ label, value }) => (
      <div key={label}>
        <Typography className={`${rootClass}__title`} text={t(label)} type={TextType.FIELD_MAPPING_SUB_HEADER} weight={TextWeight.BOLD} />
        <Typography className={`${rootClass}__value`} text={value} type={TextType.BODY_TEXT_LARGE} weight={TextWeight.BOLD} />
      </div>
    ))
  }

  return (
    <div className={classNames(rootClass, { [`${rootClass}__add-contact-preference`]: !importingList })} data-testid={rootClass}>
      {creatingSegmentLoading ? (
        <Loader center />
      ) : (
        <>
          <div className={`${rootClass}__rectangle`}>{renderSummary()}</div>
        </>
      )}
    </div>
  )
}

export default ImportSummary
