import { gql } from '@apollo/client'

export default gql`
  query getAllFavoriteSentMessages {
    getAllFavoriteSentMessages {
      id
      msgTitle
      sentCount
      senderName
      sentTime
      previewUrl
    }
  }
`
