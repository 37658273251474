import { gql } from '@apollo/client'

export default gql`
  query getSubscriptionManagementVersions {
    getSubscriptionManagementVersions {
      name
      description
      language
      isEnabled
      needsChanges
      isDefault
      createdTime
    }
  }
`
