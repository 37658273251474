import React, { FC, RefObject, useEffect, useMemo, useRef } from 'react'

import { useDebouncedCallback } from 'use-debounce'

import ActionableNestedTable, { ActionableNestedTableProps } from '@components/ActionableNestedTable/ActionableNestedTable'
import Button, { ButtonType } from '@components/Button'
import Loader from '@components/Loader'
import { useTranslation } from '@const/globals'

import './ActionableNestedTableWithLazyLoading.css'

export interface ActionableNestedTableWithLazyLoadingProps extends ActionableNestedTableProps {
  dataTest?: string
  scrollableElement?: RefObject<HTMLDivElement>
  onLoading: () => void
  allLoaded: boolean
}

const rootClass = 'actionable-nested-table-with-lazy-loading'

/**
 * @deprecated use <TableV2 instead
 */
const ActionableNestedTableWithLazyLoading: FC<ActionableNestedTableWithLazyLoadingProps> = (props: ActionableNestedTableWithLazyLoadingProps) => {
  const { dataTest = rootClass, onLoading, scrollableElement, allLoaded, loading, data, scrollFromPageContainer, ...tableProps } = props
  const { t } = useTranslation()
  const lazyLoading = useMemo(() => loading && data.length > 0 && !allLoaded, [loading, allLoaded])
  const debouncedLoading = useDebouncedCallback(onLoading, 1000, { leading: true, trailing: false })
  const tableRef = useRef<HTMLDivElement>(null)

  const pageContainerScroll = () => {
    const pageContainer = document.getElementsByClassName('page-container')[0]
    const scrollTop = pageContainer.scrollTop
    const scrollHeight = pageContainer.scrollHeight
    const clientHeight = pageContainer.clientHeight
    const scrolledToBottom = scrollTop + clientHeight >= scrollHeight

    if (scrolledToBottom && !allLoaded) {
      debouncedLoading()
    }
  }

  useEffect(() => {
    const scrollArea = scrollableElement?.current ?? tableRef.current
    const pageContainer = document.getElementsByClassName('page-container')[0]

    if (scrollFromPageContainer && pageContainer) {
      pageContainer.addEventListener('scroll', pageContainerScroll)
      return () => {
        pageContainer.removeEventListener('scroll', pageContainerScroll)
      }
    } else {
      if (scrollArea && tableRef) {
        scrollArea.addEventListener('scroll', handleScroll)
        return () => scrollArea.removeEventListener('scroll', handleScroll)
      }
    }
  }, [scrollableElement?.current, allLoaded, lazyLoading, loading])

  const handleScroll = (ev: Event) => {
    const { scrollHeight, scrollTop, offsetHeight } = ev.target as HTMLDivElement
    if (scrollTop >= scrollHeight - offsetHeight) {
      !loading && !lazyLoading && !allLoaded && debouncedLoading()
    }
  }

  return (
    <div className={rootClass} data-test={dataTest}>
      <ActionableNestedTable data={data} loading={loading && !lazyLoading} reference={tableRef} {...tableProps} />
      {lazyLoading && <Loader className={`${rootClass}__loader`} />}
      {!allLoaded && !loading && (
        <div className={`${rootClass}__show-more`}>
          <Button buttonType={ButtonType.TEXT} onClick={onLoading}>
            {t('Show more...')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default ActionableNestedTableWithLazyLoading
