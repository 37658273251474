import StaticImageNames from '@components/StaticImage/StaticImageNames'

export enum CheckCardStatus {
  DEFAULT = 'default',
  SUCCESS = 'success',
  FAILURE = 'failure',
  TIMEOUT = 'timeout',
  ERROR = 'error',
  LOADING = 'loading',
  NO_CONTENT = 'noContent',
  WARNING = 'warning',
}

export enum CheckCardType {
  SPAM = 'Spam',
  PERSONALIZATION = 'Personalization',
}

const imageMappings = new Map<CheckCardType, Map<CheckCardStatus, StaticImageNames>>([
  [
    CheckCardType.SPAM,
    new Map<CheckCardStatus, StaticImageNames>([
      [CheckCardStatus.DEFAULT, StaticImageNames.spamCheckDefault],
      [CheckCardStatus.ERROR, StaticImageNames.spamCheckError],
      [CheckCardStatus.TIMEOUT, StaticImageNames.spamCheckError],
      [CheckCardStatus.FAILURE, StaticImageNames.spamCheckFailure],
      [CheckCardStatus.SUCCESS, StaticImageNames.spamCheckSuccess],
    ]),
  ],
  [
    CheckCardType.PERSONALIZATION,
    new Map<CheckCardStatus, StaticImageNames>([
      [CheckCardStatus.DEFAULT, StaticImageNames.personalizationCheckDefault],
      [CheckCardStatus.ERROR, StaticImageNames.personalizationCheckError],
      [CheckCardStatus.FAILURE, StaticImageNames.personalizationCheckFailure],
      [CheckCardStatus.SUCCESS, StaticImageNames.personalizationCheckSuccess],
      [CheckCardStatus.NO_CONTENT, StaticImageNames.personalizationCheckError],
      [CheckCardStatus.WARNING, StaticImageNames.personalizationCheckError],
    ]),
  ],
])

export const getCheckCardImage = (type: CheckCardType, status: CheckCardStatus): StaticImageNames => {
  return (
    imageMappings.get(type)?.get(status) ??
    (type === CheckCardType.SPAM ? StaticImageNames.spamCheckDefault : StaticImageNames.personalizationCheckDefault)
  )
}
