import React, { FC, ReactNode, useContext, useMemo, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Caution from '@components/Caution/Caution'
import Modal, { ModalBody, ModalHeader } from '@components/Modal'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import { RowAction } from '@components/TableV2/tableV2TS/interfaces'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Tooltip from '@components/Tooltip/Tooltip'
import Typography, { ModalHeaderStyle, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  renderInfoTooltip,
  renderShowButton,
} from '@src/pages/Webhooks/IncomingWebhooks/components/CreateIncomingWebhookModal/utils/CreateIncomingWebhookModal.utils'
import InputWithCopy from '@src/pages/Webhooks/IncomingWebhooks/components/InputWithCopy/InputWithCopy'
import { AuthenticationType, IncomingWebhook, IncomingWebhooksContext, Source } from '@src/pages/Webhooks/IncomingWebhooks/IncomingWebhooksContext'
import { Row } from '@tanstack/react-table'
import { isFunction } from '@utils/utils'

import './WebhookInfoModal.css'

interface WebhookInfoModalProps {
  className?: string
  dataTest?: string
  onClose: () => void
  isOpen: boolean
  webhook: Row<IncomingWebhook>
  rowActions?: RowAction<IncomingWebhook>[]
}

const rootClass = 'webhook-info-modal'

const WebhookInfoModal: FC<WebhookInfoModalProps> = (props: WebhookInfoModalProps) => {
  const { dataTest = rootClass, className = '', onClose, isOpen, rowActions = [], webhook: webhookRow } = props

  const { update } = useContext(IncomingWebhooksContext)

  const [showPassword, setShowPassword] = useState(false)

  const webhook = webhookRow.original

  const { t } = useTranslation()

  const headerBySource: { [key in Source]: any } = {
    [Source.Custom]: { icon: SvgNames.webhooks, header: t(`Custom webhook`) },
    [Source.Calendly]: { icon: SvgNames.calendlyLogo, header: t(`Calendly webhook`) },
  }

  const { icon, header } = headerBySource[webhook.source]

  const showStatusToast = (statusMessage: string) => {
    update({ statusToast: { status: Status.SUCCESS, statusMessage } })
  }

  const renderLabelWithTooltip = (label: string, tooltip: string) => (
    <div className={`${rootClass}__input-label`}>
      <Typography text={t(label)} weight={TextWeight.MEDIUM} inline />
      {renderInfoTooltip(t(tooltip))}
    </div>
  )

  const renderActionButton = ({ icon, onClick, label }: RowAction<IncomingWebhook>) =>
    icon && (
      <Tooltip
        key={`action-${icon}`}
        position={'top'}
        trigger={
          <Button tabIndex={-1} buttonType={ButtonType.ICON} onClick={() => onClick(webhookRow)}>
            {isFunction(icon) ? icon(webhookRow) : <Svg name={icon} type={SvgType.LARGER_ICON} />}
          </Button>
        }
      >
        {t(label)}
      </Tooltip>
    )

  const actions = useMemo(() => {
    const closeAction: RowAction<IncomingWebhook> = {
      label: 'Close',
      icon: SvgNames.close,
      onClick: () => onClose(),
    }
    return [...rowActions, closeAction]
      .filter(({ disabled = false }) => (isFunction(disabled) ? !disabled(webhookRow) : !disabled))
      .map(renderActionButton)
  }, [rowActions, webhookRow])

  const contentByAuthentication: { [key in AuthenticationType]: ReactNode } = {
    [AuthenticationType.None]: undefined,
    [AuthenticationType.Basic]: (
      <div data-test={`${dataTest}-basic-authentication`}>
        <InputWithCopy
          dataTest={`${dataTest}-basic-authentication-username`}
          label={renderLabelWithTooltip('Username', 'Incoming.Webhooks.CreateModal.Authentication.Basic.Username.Tooltip')}
          value={webhook.authentication.username}
          onCopy={() => showStatusToast('Username copied to your clipboard.')}
          copyButtonTooltip={t('Copy username to clipboard')}
        />
        <InputWithCopy
          dataTest={`${dataTest}-basic-authentication-password`}
          className={`${rootClass}__password`}
          label={
            <div className={`${rootClass}__password-label`}>
              {renderLabelWithTooltip('Password', 'Incoming.Webhooks.CreateModal.Authentication.Basic.Password.Tooltip')}
              {renderShowButton(showPassword, () => setShowPassword(!showPassword), t)}
            </div>
          }
          type={showPassword ? 'text' : 'password'}
          value={webhook.authentication.passwordHash}
          onCopy={() => showStatusToast('Password copied to your clipboard.')}
          copyButtonTooltip={t('Copy password to clipboard')}
        />
      </div>
    ),
    [AuthenticationType.Bearer]: (
      <div data-test={`${dataTest}-token-authentication`}>
        {renderLabelWithTooltip('Bearer token', 'Incoming.Webhooks.CreateModal.Authentication.Token.Input.Tooltip')}
        <Typography text={t('Incoming.Webhooks.Info.Bearer')} type={TextType.BODY_TEXT_LIGHT} />
      </div>
    ),
    [AuthenticationType.Signature]: (
      <div data-test={`${dataTest}-signature-authentication`}>
        {renderLabelWithTooltip('HMAC', 'Incoming.Webhooks.CreateModal.Authentication.HMAC.Input.Tooltip')}
        <Typography text={t('Incoming.Webhooks.Info.HMAC')} type={TextType.BODY_TEXT_LIGHT} />
      </div>
    ),
  }

  const modalHeader = (
    <ModalHeader headerType={ModalHeaderType.List} className={`${rootClass}__header`}>
      <div className={`${rootClass}__header-name`}>
        <Svg type={SvgType.VERY_LARGE_ICON} name={icon} />
        <Typography text={header} {...ModalHeaderStyle} />
      </div>
      <div className={`${rootClass}__header-actions`}>{actions}</div>
    </ModalHeader>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={modalHeader}>
      <ModalBody className={`${rootClass}__body`}>
        <div className={`${rootClass}__webhook-info`}>
          <div className={`${rootClass}__title`}>
            <TextWithTooltipOnEllip
              typographyProps={{
                text: `${webhook.name}`,
                type: TextType.SECTION_HEADER,
                weight: TextWeight.MEDIUM,
                tagProps: { bold: { weight: TextWeight.MEDIUM, type: TextType.SECTION_HEADER } },
              }}
            />
            {!webhook.enabled && <Typography text={t(`(Disabled)`)} type={TextType.SECTION_HEADER_LIGHT} inline />}
          </div>
          {webhook.description && <Typography text={webhook.description} type={TextType.BODY_TEXT_LIGHT} />}
        </div>
        <InputWithCopy
          dataTest={`${dataTest}-webhook-url`}
          label={renderLabelWithTooltip('Webhook URL', 'Incoming.Webhooks.Info.Tooltip.WebhookURL')}
          value={webhook.url}
          onCopy={() => showStatusToast('Incoming.Webhooks.Toast.CopyUrl')}
          copyButtonTooltip={t('Copy URL to clipboard')}
        />
        <Caution className={`${rootClass}__caution`}>
          <Typography text={`Incoming.Webhooks.Test.Caution`} tagProps={{ bold: { weight: TextWeight.BOLD } }} inline />
        </Caution>
        {contentByAuthentication[webhook.authentication.type]}
      </ModalBody>
    </Modal>
  )
}

export default WebhookInfoModal
