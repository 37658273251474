import React, { FC, useState, useEffect } from 'react'

import PageContainer from '@components/PageContainer'
import PageError from '@components/PageError/PageError'
import getOptInMarketingList from '@graphql/microservices/sms-management/getOptInMarketingList'
import toggleMultipleSendWelcomeMessage from '@graphql/microservices/sms-management/toggleMultipleSendWelcomeMessage'
import {
  MutationToggleMultipleSendWelcomeMessagesArgs,
  Mutation,
  Query,
  QueryGetOptInMarketingListArgs,
} from '@graphql/types/microservice/sms-management-types'
import MapContactsContainer from '@src/pages/sms/marketingListOptIns/components/MapContacts/MapContactsContainer'
import { OptInsSourceList } from '@src/pages/sms/marketingListOptIns/components/OptInsSourceLists/OptInsSourceLists.constants'
import { useAccountSettings } from '@utils/account/account.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'
import { logNewRelicError } from '@utils/new-relic.utils'
import { SortByState } from '@utils/sms.utils'

import OptInsSourceLists from './OptInsSourceLists'

export enum AddListStatus {
  none,
  success,
  error,
}

export interface State {
  lists: OptInsSourceList[]
  totalCount: number
  pageSize: number
  pageIndex: number
  sortBy: SortByState[]
  isAddOpen: boolean
  [key: string]: any
}

interface Props {
  setStatus: (status: AddListStatus) => void
  updateSendWelcomeMessageChange: (sendWelcomeMessageChange: AddListStatus) => void
  dataTest?: string
}

const defaultSortBy: SortByState = {
  id: 'latestOptInDate',
  desc: true,
}

const defaultState: State = {
  lists: [],
  totalCount: 0,
  pageSize: 10,
  pageIndex: 0,
  sortBy: [defaultSortBy],
  isAddOpen: false,
}

const rootClass = 'opt-ins-source-lists'

const convertAccessorToSort = (id: string) => {
  switch (id) {
    case 'listType':
      return 'LIST_TYPE'
    case 'listId':
      return 'LIST_ID'
    case 'listDisplayName':
      return 'LIST_NAME'
    case 'optInCount':
      return 'OPT_IN_COUNT'
    case 'latestOptInDate':
      return 'LATEST_OPT_IN_DATE'
    case 'welcome': // backend doesn't have a sort on column since this is purely a frontend construct
      return 'LIST_NAME'
    default:
      throw new Error(`Unknown accessor: ${id}`)
  }
}

const OptInsSourceListsContainer: FC<Props> = (props: Props) => {
  const { setStatus, updateSendWelcomeMessageChange, dataTest = rootClass } = props
  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.SMS_MANAGEMENT })
  const { accountId } = useAccountSettings()
  const [state, setState] = useState<State>({ ...defaultState })
  const [loading, setLoading] = useState(true)

  const {
    data,
    error: pageError,
    loading: optInMarketingListLoading,
    refetch,
  } = useQueryOnMount<Query, QueryGetOptInMarketingListArgs>(getOptInMarketingList, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      page: state.pageIndex,
      size: state.pageSize,
      sortDirection: state.sortBy[0].desc ? 'DESC' : ('ASC' as any),
      sortColumn: convertAccessorToSort(state.sortBy[0].id) as any,
    },
  })

  useEffect(() => {
    setLoading(optInMarketingListLoading)
  }, [optInMarketingListLoading])

  useEffect(() => {
    const lists = data?.getOptInMarketingList?.optInMarketingLists
    const totalCount = data?.getOptInMarketingList.totalCount ?? 0
    if (lists && lists.length > 0) {
      setState({ ...state, lists, totalCount })
    }
  }, [data])

  useEffect(() => {
    if (pageError) {
      logNewRelicError(pageError)
    }
  }, [pageError])

  if (pageError) {
    return (
      <PageContainer>
        <PageError center />
      </PageContainer>
    )
  }

  const onAddList = async (statusVal?: AddListStatus) => {
    const status = statusVal ?? AddListStatus.none
    setState({
      ...state,
      isAddOpen: false,
    })
    if (statusVal === AddListStatus.success) {
      await refetch()
    }
    setStatus(status)
  }

  const onOpenAddList = () => {
    setState({
      ...state,
      isAddOpen: !state.isAddOpen,
    })
  }

  const onChangeSetSendWelcomeMessage = async (sendWelcomeMessage: boolean, listIds: [string]) => {
    await client.mutate<Mutation, MutationToggleMultipleSendWelcomeMessagesArgs>({
      mutation: toggleMultipleSendWelcomeMessage,
      variables: {
        listIds,
        accountId,
        sendWelcomeMessage,
      },
    })
    await refetch()
    updateSendWelcomeMessageChange(AddListStatus.success)
  }

  const fetchData = (pageIndex: number, pageSize: number) => {
    setState({ ...state, pageIndex, pageSize })
  }

  const onChangeSort = (sortBy: Array<any>) => {
    const validSortBy = sortBy.length > 0 ? sortBy : [defaultSortBy]
    setState({ ...state, sortBy: validSortBy })
  }

  return (
    <>
      {state.isAddOpen && <MapContactsContainer closeModal={onAddList} />}
      <OptInsSourceLists
        lists={state.lists}
        totalCount={state.totalCount}
        pageSize={state.pageSize}
        pageIndex={state.pageIndex}
        fetchData={fetchData}
        sortBy={state.sortBy}
        onChangeSort={onChangeSort}
        loading={loading}
        onOpenAddList={onOpenAddList}
        onChangeSetSendWelcomeMessage={onChangeSetSendWelcomeMessage}
        data-test={`${dataTest}`}
      />
    </>
  )
}

export default OptInsSourceListsContainer
