import { jsPlumb, Connection, ConnectorSpec, jsPlumbInstance } from 'jsplumb'

import { ProgramStep } from '@graphql/types/query-types'
import { getLetter } from '@src/pages/programs/dashboard/ProgramSteps.utils'
import { getStepTemplate } from '@utils/program/program'
import { Direction, ProgramBranchStepExt, ProgramFlowTree, Step, Track, ProgramStepType } from '@utils/program/program.constants'
const ZOOM_PERCENT = 0.2

enum PlusDirection {
  DOWN,
  LEFT,
  RIGHT,
  LEFT_HALF,
  RIGHT_HALF,
}

export interface Plus {
  id: string
  top: number
  left: number
  toStepId: string
  direction: PlusDirection
  branchDirection?: Direction
  yes: boolean
}

export interface ProgramFlowStepInterface {
  left: number
  top: number
  width: number
  height: number
  id: string
  name: string
  step: Step
  pluses?: Plus[]
  first: boolean
}

export interface ProgramCanvasSize {
  columnWidth: number
  rowHeight: number
}

export interface SaveStepResults {
  steps?: ProgramFlowStepInterface[]
  tracks: Track[]
}

export enum EditStepPhase {
  ADDING,
  EDITING,
}

export interface EditStep {
  step?: Step
  new?: boolean
  move?: boolean
  phase: EditStepPhase
  direction?: Direction
  parentStep?: Step
}

export function getNumberOfColumns(tree: ProgramFlowTree): number {
  return -1 * tree.gridSize.left + tree.gridSize.right + 1
}

export function getZoomScale(zoom: number) {
  let zoomScale = zoom * ZOOM_PERCENT
  if (zoomScale < ZOOM_PERCENT) {
    zoomScale = ZOOM_PERCENT
  }
  return zoomScale
}

export function getProgramCanvasSize(): ProgramCanvasSize {
  const columnWidth = 224
  const rowHeight = 110
  return {
    columnWidth,
    rowHeight,
  }
}

export function getNodeCenter(node: ProgramFlowStepInterface) {
  return {
    horizontal: Math.ceil(node.left + node.width / 2),
    vertical: Math.ceil(node.top + node.height / 2),
  }
}

export function getPlusPosition(
  id: string,
  yes: boolean,
  parent: ProgramFlowStepInterface,
  child: ProgramFlowStepInterface,
  branchDirection?: Direction,
  halfStep?: boolean,
  verticalOffest?: number
): Plus {
  const parentCenter = getNodeCenter(parent)
  const childCenter = getNodeCenter(child)

  let left
  let direction
  if (parentCenter.horizontal === childCenter.horizontal) {
    left = parentCenter.horizontal
    direction = PlusDirection.DOWN
  } else if (parentCenter.horizontal > childCenter.horizontal) {
    left = parentCenter.horizontal + Math.ceil((childCenter.horizontal - parentCenter.horizontal) / 2)
    direction = halfStep ? PlusDirection.LEFT_HALF : PlusDirection.LEFT
  } else {
    left = childCenter.horizontal + Math.ceil((parentCenter.horizontal - childCenter.horizontal) / 2)
    direction = halfStep ? PlusDirection.RIGHT_HALF : PlusDirection.RIGHT
  }

  let top = parentCenter.vertical + Math.ceil((childCenter.vertical - parentCenter.vertical) / 2)
  if (verticalOffest) {
    top += verticalOffest
  }
  return {
    id,
    yes,
    toStepId: child.id,
    left,
    top,
    direction,
    branchDirection,
  }
}

export function isStepGoToExitAndOneNot(step1: ProgramFlowStepInterface, step2: ProgramFlowStepInterface) {
  return (step1.step.stepType === 'goto' || step1.step.stepType === 'exit') && step2.step.stepType !== 'goto' && step2.step.stepType !== 'exit'
}

export function addStepPluses(nodes: { [key: string]: ProgramFlowStepInterface }, rowHeight: number) {
  const nodeKeys = Object.keys(nodes)
  return nodeKeys.map((nodeKey) => {
    const node = nodes[nodeKey]
    if (node.step.child) {
      node.pluses = [getPlusPosition(`${node.id}-plus`, false, node, nodes[`${node.step.child.trackIndex}.${node.step.child.stepIndex}`])]
    } else if (node.step.children) {
      const leftNode = nodes[`${node.step.children.left.trackIndex}.${node.step.children.left.stepIndex}`]
      const rightNode = nodes[`${node.step.children.right.trackIndex}.${node.step.children.right.stepIndex}`]
      if (isStepGoToExitAndOneNot(leftNode, rightNode)) {
        node.pluses = [
          getPlusPosition(`${node.id}-plus-left`, false, node, leftNode, Direction.left, true),
          getPlusPosition(`${node.id}-plus-right`, true, node, rightNode, Direction.right, false, rowHeight / 2),
        ]
      } else if (isStepGoToExitAndOneNot(rightNode, leftNode)) {
        node.pluses = [
          getPlusPosition(`${node.id}-plus-left`, false, node, leftNode, Direction.left, false, rowHeight / 2),
          getPlusPosition(`${node.id}-plus-right`, true, node, rightNode, Direction.right, true),
        ]
      } else {
        node.pluses = [
          getPlusPosition(`${node.id}-plus-left`, false, node, leftNode, Direction.left),
          getPlusPosition(`${node.id}-plus-right`, true, node, rightNode, Direction.right),
        ]
      }
    }
    return node
  })
}

export function getProgramFlowSteps(tree: ProgramFlowTree, canvasSize: ProgramCanvasSize) {
  const leftOffset = -1 * tree.gridSize.left
  const nodes: { [key: string]: ProgramFlowStepInterface } = {}
  // add nodes
  for (let row = 0; row <= tree.gridSize.bottom; row++) {
    for (let column = tree.gridSize.left; column <= tree.gridSize.right; column++) {
      const trackStep = tree.nodePositions[`${column}.${row}`]

      if (trackStep) {
        const step = tree.tracks[trackStep.trackIndex].steps[trackStep.stepIndex]
        let offset = 0
        if (step.offset === Direction.right) {
          offset = canvasSize.columnWidth / 2
        } else if (step.offset === Direction.left) {
          offset = -1 * (canvasSize.columnWidth / 2)
        }
        const correctedColumn = column + leftOffset
        nodes[`${trackStep.trackIndex}.${trackStep.stepIndex}`] = {
          left: correctedColumn * canvasSize.columnWidth + offset + canvasSize.columnWidth,
          top: row * canvasSize.rowHeight,
          width: canvasSize.columnWidth,
          height: canvasSize.rowHeight,
          id: `program-flow-node-${step.stepId}`,
          name: step.displayName ?? '',
          step,
          first: step.first ?? false,
        }
      }
    }
  }

  return addStepPluses(nodes, canvasSize.rowHeight)
}

export function getGoToAnchorPositionsCurve(stepEl: HTMLElement, destEl: HTMLElement) {
  if (stepEl.offsetTop === destEl.offsetTop) {
    if (stepEl.offsetLeft < destEl.offsetLeft) {
      return {
        source: 'RightMiddle',
        target: 'LeftMiddle',
        curviness: 0,
      }
    } else {
      return {
        source: 'LeftMiddle',
        target: 'RightMiddle',
        curviness: 0,
      }
    }
  } else if (stepEl.offsetLeft === destEl.offsetLeft) {
    if (stepEl.offsetLeft > 300) {
      return {
        source: 'LeftMiddle',
        target: 'LeftMiddle',
        curviness: 150,
      }
    } else {
      return {
        source: 'RightMiddle',
        target: 'RightMiddle',
        curviness: 150,
      }
    }
  } else {
    if (destEl.offsetLeft < stepEl.offsetLeft && destEl.offsetLeft + destEl.offsetWidth > stepEl.offsetLeft) {
      return {
        source: 'RightMiddle',
        target: 'RightMiddle',
        curviness: 150,
      }
    } else if (destEl.offsetLeft > stepEl.offsetLeft && destEl.offsetLeft - destEl.offsetWidth < stepEl.offsetLeft) {
      return {
        source: 'LeftMiddle',
        target: 'LeftMiddle',
        curviness: 150,
      }
    }
    if (stepEl.offsetLeft < destEl.offsetLeft) {
      return {
        source: 'RightMiddle',
        target: 'LeftMiddle',
        curviness: 150,
      }
    } else {
      return {
        source: 'LeftMiddle',
        target: 'RightMiddle',
        curviness: 150,
      }
    }
  }
}

export function centerFirstStep(steps: ProgramFlowStepInterface[], chart: HTMLDivElement | null) {
  if (!chart) return
  const firstStep = steps.find((step) => step.first)
  if (firstStep) {
    const firstStepDom = document.getElementById(firstStep.id)
    if (firstStepDom?.parentElement) {
      const center = firstStepDom.parentElement.offsetLeft + firstStepDom.parentElement.offsetWidth / 2
      const containerCenter = chart.offsetWidth / 2
      try {
        chart.scrollTo(center - containerCenter, 0)
      } catch (e) {
        chart.scrollLeft = center - containerCenter
      }
    }
  }
}

export function centerStep(steps: ProgramFlowStepInterface[], chart: HTMLDivElement | null, stepId: string) {
  if (!chart) return
  const step = steps.find((step) => step.step.stepId === stepId)
  if (step) {
    const firstStepDom = document.getElementById(step.id)
    if (firstStepDom?.parentElement) {
      const centerX = firstStepDom.parentElement.offsetLeft + firstStepDom.parentElement.offsetWidth / 2
      const containerCenterX = chart.offsetWidth / 2
      const centerY = firstStepDom.parentElement.offsetTop + firstStepDom.parentElement.offsetHeight / 2
      const containerCenterY = chart.offsetHeight / 2
      try {
        chart.scrollTo(centerX - containerCenterX, centerY - containerCenterY)
      } catch (e) {
        chart.scrollLeft = centerX - containerCenterX
        chart.scrollTop = centerY - containerCenterY
      }
    }
  }
}

export function clearAllConnections(chart: HTMLDivElement | null) {
  if (chart) {
    chart.querySelectorAll('.jtk-connector').forEach((connector) => {
      connector.parentNode?.removeChild(connector)
    })
  }
}

export function getConnectorLine(chart: HTMLDivElement | null, isViewOnly = false) {
  return jsPlumb.getInstance({
    PaintStyle: {
      strokeWidth: 2,
      stroke: isViewOnly ? '#C9C9C9' : '#EDC7A7',
      // @ts-ignore
      'stroke-dasharray': '4 3',
    },
    Overlays: [['Arrow', { width: 9, length: 8, location: 1 }]],
    Container: chart,
    Endpoint: 'Blank',
  })
}

export function drawGoTo(from: string, to: string, chart: HTMLDivElement | null): Connection | undefined {
  const endpointOptions = { isSource: true, isTarget: true, maxConnections: 1 }
  const connectorLine = getConnectorLine(chart)
  const stepEl = document.getElementById(from)
  const destEl = document.getElementById(to)
  if (stepEl?.parentElement && destEl?.parentElement) {
    const anchorCurves = getGoToAnchorPositionsCurve(stepEl.parentElement, destEl.parentElement)
    const src = connectorLine.addEndpoint(
      stepEl,
      {
        anchor: anchorCurves.source as any,
        maxConnections: 1,
        connector: ['Bezier', { curviness: anchorCurves.curviness }],
      },
      endpointOptions
    )
    const target = connectorLine.addEndpoint(
      destEl,
      {
        anchor: anchorCurves.target as any,
        maxConnections: 1,
        connector: ['Bezier', { curviness: anchorCurves.curviness }],
      },
      endpointOptions
    )
    if (!Array.isArray(src) && !Array.isArray(target)) {
      return connectorLine.connect({
        source: src,
        target: target,
      })
    }
  }
  return undefined
}

export function deleteConnection(connection: Connection, chart: HTMLDivElement | null) {
  jsPlumb
    .getInstance({
      Container: chart,
    })
    .deleteConnection(connection)
}

export function connectSteps(steps: ProgramFlowStepInterface[], chart: HTMLDivElement | null, isViewOnly: boolean) {
  const endpointOptions = { isSource: true, isTarget: true, maxConnections: 1 }
  const defaultLine = jsPlumb.getInstance({
    PaintStyle: {
      strokeWidth: 2,
    },
    Container: chart,
    Connector: ['Straight', { curviness: 0 }],
    Endpoint: 'Blank',
  })
  const connectorLine = getConnectorLine(chart, isViewOnly)
  const regularConnections: any = []
  const goToConnections: any = []
  steps.forEach((step) => {
    if (step.step.goToStepId) {
      const stepEl = document.getElementById(step.id)
      const destEl = document.getElementById(`program-flow-node-${step.step.goToStepId}`)
      if (stepEl?.parentElement && destEl?.parentElement) {
        const anchorCurves = getGoToAnchorPositionsCurve(stepEl.parentElement, destEl.parentElement)
        goToConnections.push({
          src: {
            step: stepEl,
            options: {
              anchor: anchorCurves.source as any,
              maxConnections: 1,
              connector: ['Bezier', { curviness: anchorCurves.curviness }],
            },
          },
          target: {
            step: destEl,
            options: {
              anchor: anchorCurves.target as any,
              maxConnections: 1,
              connector: ['Bezier', { curviness: anchorCurves.curviness }],
            },
          },
        })
      }
    }
    if (step.pluses) {
      step.pluses.forEach((plus) => {
        const stepEl = document.getElementById(step.id)
        const plusEl = document.getElementById(plus.id)
        const destStepEl = document.getElementById(plus.toStepId)
        if (stepEl && plusEl && destStepEl) {
          const connectorStyle = { stroke: plus.yes ? '#08A2A5' : '#C9C9C9', strokeWidth: 2 }

          let connector: ConnectorSpec = ['Straight', { curviness: 0 }]
          if (plus.direction !== PlusDirection.DOWN) {
            connector = ['Flowchart', { stub: 1, alwaysRespectStubs: true, cornerRadius: 40 }]
          }

          regularConnections.push({
            src: {
              step: stepEl,
              options: { anchor: 'BottomCenter', maxConnections: 1, connectorStyle, connector },
            },
            target: {
              step: destStepEl,
              options: { anchor: 'TopCenter', maxConnections: 1, connectorStyle, connector },
            },
          })
        }
      })
    }
  })
  const batchConnections = (instance: jsPlumbInstance, connections: any) => {
    instance.batch(() => {
      for (const connection of connections) {
        const src = instance.addEndpoint(connection.src.step, connection.src.options, endpointOptions)
        const target = instance.addEndpoint(connection.target.step, connection.target.options, endpointOptions)
        if (!Array.isArray(src) && !Array.isArray(target)) {
          instance.connect({
            source: src,
            target: target,
          })
        }
      }
    })
  }
  batchConnections(defaultLine, regularConnections)
  batchConnections(connectorLine, goToConnections)
}

export const getNewTrackNumber = (tracks: Track[]) => {
  const trackIds = tracks.map(({ id }) => (id ? parseInt(id.replace('t', '')) : tracks.length))
  const sortedIdsDescending = trackIds.sort((a, b) => b - a)
  const trackNumber = trackIds.length ? sortedIdsDescending[0] + 1 : 0
  return trackNumber
}

export function processBranchStep(step: Step, tracks: Track[], templates: ProgramStep[]) {
  const exitStep = getStepTemplate(templates, ProgramStepType.EXIT)
  if (exitStep) {
    const trackNumber = getNewTrackNumber(tracks)
    const newTrackId = `t${trackNumber}`
    const newTrack: Track = {
      id: newTrackId,
      letter: 'Test',
      steps: [
        {
          ...exitStep,
          letter: `${getLetter(trackNumber)}-1`,
          stepId: `exit_t${trackNumber}`,
        },
      ],
    }
    const branchStep = step as ProgramBranchStepExt
    branchStep.goToStepId = newTrackId
    return [...tracks, newTrack]
  }
}

export function deleteStep(step: Step, tree: ProgramFlowTree): Track[] | undefined {
  let newTracks: Track[] | undefined = undefined
  for (let i = 0; i < tree.tracks.length; i++) {
    const curTrack = tree.tracks[i]
    for (let j = 0; j < curTrack.steps.length; j++) {
      const curStep = curTrack.steps[j]
      curStep.isNew = false
      if (curStep?.stepId === step.stepId) {
        const newSteps = [...curTrack.steps.slice(0, j), ...curTrack.steps.slice(j + 1)]
        const updatedTrack = {
          ...curTrack,
          steps: newSteps,
        }
        newTracks = [...tree.tracks.slice(0, i), updatedTrack, ...tree.tracks.slice(i + 1)]

        if (step.stepType == ProgramStepType.BRANCH) {
          const trackIdsToDelete = step.goToStepId ? getChainedBranchTracks(step.goToStepId, tree) : []
          if (trackIdsToDelete.length) {
            newTracks = newTracks.filter((track) => track.id && !trackIdsToDelete.includes(track.id))
          }
        }
      } else if (curStep.stepType === ProgramStepType.GOTO && curStep.goToStepId === step.stepId) {
        curStep.stepType = ProgramStepType.EXIT
        curStep.goToStepId = undefined
        curStep.displayName = 'Exit the Program'
      }
    }
  }
  return newTracks
}

function getChainedBranchTracks(trackId: string, tree: ProgramFlowTree): string[] {
  const track = tree.tracks.find((track) => track.id === trackId)
  if (!track) return []
  const stepBranches = track.steps.filter((step) => step.stepType === ProgramStepType.BRANCH)
  if (!stepBranches.length) return [trackId]
  const tracks = stepBranches.reduce((acc, stepBranch) => {
    if (stepBranch.goToStepId) {
      acc.add(stepBranch.goToStepId)
      getChainedBranchTracks(stepBranch.goToStepId, tree).forEach((trackId) => acc.add(trackId))
    }
    return acc
  }, new Set<string>([trackId]))
  return Array.from(tracks)
}

const filterBranches = (tracks: Track[], stepId: string, move = false) =>
  move
    ? tracks.map((track) => ({
        ...track,
        steps: track.steps.filter((step) => stepId !== step.stepId && `program-flow-node-${stepId}` !== step.stepId),
      }))
    : [...tracks]
export function getUpdatedStepsAndTracks(
  newStep: Step,
  tree: ProgramFlowTree,
  steps: ProgramFlowStepInterface[],
  templates: ProgramStep[],
  editStep?: EditStep,
  newStepId?: number
): SaveStepResults | undefined {
  if (editStep?.new || editStep?.move) {
    newStep.isNew = true
  }
  steps.forEach((step) => {
    step.step.isNew = false
  })

  if ((editStep?.new || editStep?.move) && editStep?.direction === Direction.right) {
    const trackIndex = tree.tracks.findIndex((track) => track.id === editStep.parentStep?.goToStepId)
    if (trackIndex) {
      const track = tree.tracks[trackIndex]
      let newTracks = filterBranches(tree.tracks, newStep.stepId, editStep?.move)
      newTracks.splice(trackIndex, 1, {
        ...track,
        steps: [newStep, ...track.steps],
      })
      newTracks.splice(trackIndex, 1, { ...track, steps: [newStep, ...track.steps] }).forEach((track) => {
        track.steps.forEach((step, index) => {
          step.letter = `${track.letter}-${index + 1}`
        })
      })
      if (editStep.step?.stepType == ProgramStepType.BRANCH) {
        newTracks = processBranchStep(newStep, newTracks, templates) ?? newTracks
      }
      return {
        tracks: newTracks,
      }
    }
  }
  const stepId = editStep?.new || editStep?.move ? editStep?.parentStep?.stepId : newStep.stepId
  let newTracks = filterBranches(tree.tracks, newStep.stepId, editStep?.move)
  for (let i = 0; i < tree.tracks.length; i++) {
    const curTrack = newTracks[i]
    for (let j = 0; j < curTrack.steps.length; j++) {
      const step = curTrack.steps[j]
      if (step?.stepId === stepId) {
        const newSteps = [...curTrack.steps]
        if (editStep?.new || editStep?.move) {
          newSteps.splice(j + 1, 0, newStep)
        } else {
          if (newStep.stepType === ProgramStepType.GOTO && stepId?.includes('exit')) {
            newSteps.splice(j, 1, { ...newStep, stepId: `newstep${newStepId}` })
            newStep.stepId = `newstep${newStepId}`
          } else {
            newSteps.splice(j, 1, newStep)
          }
        }

        if (editStep?.new) {
          const hasStartStep = newSteps.some((step) => {
            return step.stepType === ProgramStepType.START
          })
          newSteps.forEach((step, index) => {
            if (!step.first && hasStartStep) {
              step.letter = `${curTrack.letter}-${index}`
            } else if (!hasStartStep) {
              step.letter = `${curTrack.letter}-${index + 1}`
            }
          })
        }

        const updatedTrack = {
          ...curTrack,
          steps: newSteps,
        }
        newTracks.splice(i, 1, updatedTrack)

        if (editStep?.new && editStep.step?.stepType == ProgramStepType.BRANCH) {
          newTracks = processBranchStep(newStep, newTracks, templates) ?? newTracks
        }

        return {
          steps:
            editStep?.new || editStep?.move
              ? steps
              : steps.map((step) => {
                  if (step.step.stepId == newStep.stepId || step.step.stepId == stepId) {
                    return {
                      ...step,
                      step: newStep,
                    }
                  }
                  return step
                }),
          tracks: newTracks,
        }
      }
    }
  }
}

export default {
  centerFirstStep,
  drawGoTo,
  deleteConnection,
  getUpdatedStepsAndTracks,
  deleteStep,
  getZoomScale,
  getProgramFlowSteps,
  getProgramCanvasSize,
  connectSteps,
  centerStep,
  clearAllConnections,
  getNumberOfColumns,
}
