import { gql } from '@apollo/client'

export default gql`
  query syncedSegmentDetails($segmentIdentifiers: [String]) {
    syncedSegmentDetails(segmentIdentifiers: $segmentIdentifiers) {
      accountId
      connectedCrm
      hadSuccessfulSync
      ongoingSync
      segmentIdentifier
      segmentType
      sourceType
      syncedSegmentEntityFactors {
        accountId
        label
        segmentCriteriaIdentifier
        uclSyncedSegmentEntityFactorId
        uclSyncedSegmentId
      }
      syncedSegmentError
      syncedSegmentId
      syncedSegmentQueryFactors {
        accountId
        label
        lastError
        lastWarningCode
        queryIdentifier
        uclSyncedSegmentId
        uclSyncedSegmentQueryFactorId
      }
      syncing
    }
  }
`
