import React, { FC, useEffect, useState } from 'react'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import addLogo from '@graphql/mutations/addLogo'
import deleteLogo from '@graphql/mutations/deleteLogo'
import duplicateLogo from '@graphql/mutations/duplicateLogo'
import updateLogoAttributes from '@graphql/mutations/updateLogoAttributes'
import updateLogoDefault from '@graphql/mutations/updateLogoDefault'
import getAllLogos from '@graphql/queries/getAllLogos'
import {
  AddLogoMutation,
  AddLogoMutationVariables,
  DeleteLogoMutation,
  DeleteLogoMutationVariables,
  DuplicateLogoMutation,
  DuplicateLogoMutationVariables,
  UpdateLogoAttributesMutation,
  UpdateLogoAttributesMutationVariables,
  UpdateLogoDefaultMutation,
  UpdateLogoDefaultMutationVariables,
} from '@graphql/types/mutation-types'
import { GetAllLogosQuery, GetAllLogosQueryVariables, LogoItem } from '@graphql/types/query-types'
import { StatusToastType } from '@interface/StatusToast'
import Logos from '@src/pages/Content/Branding/tabs/Logos/Logos'
import { filterNotEmptyArray } from '@utils/array'

interface Props {
  setToastStatus: (value: StatusToastType) => void
}

const LogosContainer: FC<Props> = ({ setToastStatus }) => {
  const [logos, setLogos] = useState<LogoItem[]>([])
  const client = useApolloClient()
  const { data, loading, error, refetch } = useQuery<GetAllLogosQuery, GetAllLogosQueryVariables>(getAllLogos, {
    client,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [deleteBrandingLogo, { loading: deleteLogoLoading }] = useMutation<DeleteLogoMutation, DeleteLogoMutationVariables>(deleteLogo, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [addBrandingLogo, { loading: addLogoLoading }] = useMutation<AddLogoMutation, AddLogoMutationVariables>(addLogo, {
    client,
    fetchPolicy: 'no-cache',
  })

  const [duplicateBrandingLogo, { loading: duplicateLogoLoading }] = useMutation<DuplicateLogoMutation, DuplicateLogoMutationVariables>(
    duplicateLogo,
    {
      client,
      fetchPolicy: 'no-cache',
    }
  )

  const [editLogo, { loading: editLogoLoading }] = useMutation<UpdateLogoAttributesMutation, UpdateLogoAttributesMutationVariables>(
    updateLogoAttributes,
    {
      client,
      fetchPolicy: 'no-cache',
    }
  )

  const [updateBrandingLogoDefault] = useMutation<UpdateLogoDefaultMutation, UpdateLogoDefaultMutationVariables>(updateLogoDefault, {
    client,
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!loading && data) {
      const sortedLogos =
        data?.getAllLogos?.items
          ?.filter(filterNotEmptyArray)
          .sort((a, b) => ((a.title as string).toLowerCase() > (b.title as string).toLowerCase() ? 1 : -1)) || []
      const filteredLogos = [...sortedLogos.filter(({ isDefault }) => isDefault), ...sortedLogos.filter(({ isDefault }) => !isDefault)]
      setLogos(filteredLogos)
    }
  }, [data, loading, error])

  return (
    <Logos
      data={logos}
      refetch={refetch}
      setLogos={setLogos}
      loading={loading}
      addLogoLoading={addLogoLoading}
      duplicateLogoLoading={duplicateLogoLoading}
      editLogoLoading={editLogoLoading}
      deleteLogoLoading={deleteLogoLoading}
      deleteLogo={deleteBrandingLogo}
      addLogo={addBrandingLogo}
      duplicateLogo={duplicateBrandingLogo}
      editLogo={editLogo}
      updateLogoDefault={updateBrandingLogoDefault}
      setToastStatus={setToastStatus}
    />
  )
}

export default LogosContainer
