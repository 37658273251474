import { createContext } from 'react'

import { IAddOnResponseHTML } from '@beefree.io/sdk/dist/types/bee'
import { SignatureItem } from '@graphql/types/query-types'
import { ColumnSort } from '@tanstack/react-table'
import { FilterTypes } from '@utils/filter'

import { initialSelectedSort } from './SignaturesModal.utils'

export interface SignaturesModalProps {
  className?: string
  dataTest?: string
  isOpen: boolean
  selectedSignatureId?: string
  onClose: () => void
  onAction: (signature: IAddOnResponseHTML) => void
}

export interface SignaturesModalContainerState {
  data: SignatureItem[]
  selectedSignature?: SignatureItem
  previewSignature?: SignatureItem
  activeFilter: string
  filterCounts?: { [key in string]: number | undefined }
  selectedSort: ColumnSort
  currentPage: number
  loading: boolean
  nextPageLoading: boolean
}

export const SignaturesModalContainerInitialState: SignaturesModalContainerState = {
  data: [],
  selectedSignature: undefined,
  previewSignature: undefined,
  activeFilter: FilterTypes.ALL_SIGNATURES,
  selectedSort: initialSelectedSort,
  currentPage: 0,
  loading: false,
  nextPageLoading: false,
}

type Update = (fields: Partial<SignaturesModalContainerState>) => void
type LoadNextPage = (page: number) => void

export interface SignaturesModalAPI {
  values: SignaturesModalContainerState
  update: Update
  loadNextPage: LoadNextPage
}

export const SignaturesModalContext = createContext<SignaturesModalAPI>({ values: SignaturesModalContainerInitialState } as any)
