import React, { ChangeEvent, FC, useState } from 'react'

import classNames from 'classnames'

import AssetSelect from '@components/AssetSelect/AssetSelect'
import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button'
import { Checkbox } from '@components/Checkbox/Checkbox'
import InfoTooltip from '@components/InfoTooltip/InfoTooltip'
import Input from '@components/Input/Input'
import InputWithStatus, { ValidityReturnType } from '@components/InputWithStatus/InputWithStatus'
import Label from '@components/Label'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import SelectV2 from '@components/SelectV2/SelectV2'
import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { SvgNames } from '@components/Svg'
import Typography, { ModalHeaderStyle } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { AddMediaLinkMutation, AddMediaLinkMutationVariables } from '@graphql/types/mutation-types'
import ExpirationDate from '@src/pages/EmailComposer/EmailModals/components/MediaFileModal/ExpirationDate/ExpirationDate'
import { checkURLWithoutHTTPValidity } from '@utils/formUtils'

import './MediaLink.css'

export interface MediaLinkInsertArgs {
  url: string
  title: string
}

interface MediaLinkProps {
  className?: string
  dataTest?: string
  isOpen: boolean
  handleBack: () => void
  handleCancel: () => void
  folders: SelectV2SingleOption[]
  handleAddLink: (variables: AddMediaLinkMutationVariables) => Promise<AddMediaLinkMutation | undefined>
  handleInsert: (data: MediaLinkInsertArgs) => void
}

interface MediaLinkState {
  url: string
  linkName: string
  isValid: boolean | undefined
  isInitialOptions: boolean
  isLinkedToLibrary: boolean
  selectedFolderId: string | undefined
  isSvgHovered: boolean
}

const rootClass = 'media-link'

const initialState = {
  url: '',
  linkName: '',
  isValid: undefined,
  isLinkedToLibrary: true,
  isInitialOptions: true,
  isSvgHovered: false,
  selectedFolderId: '-1',
}

const MediaLink: FC<MediaLinkProps> = (props: MediaLinkProps) => {
  const { dataTest = rootClass, className = '', isOpen, handleBack, handleCancel, folders, handleAddLink, handleInsert } = props
  const [state, setState] = useState<MediaLinkState>(initialState)
  const { t } = useTranslation()

  const onUrlChange =
    (validity: (url: string) => undefined | ValidityReturnType) =>
    (url: string): undefined | ValidityReturnType => {
      if (url) {
        return validity(url)
      }
    }

  const onLinkNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, linkName: e.target.value })
  }

  const header = (
    <ModalHeader headerType={ModalHeaderType.Form} className={`${rootClass}__header`}>
      <BackButton
        onClick={() => {
          setState({ ...initialState })
          handleBack()
        }}
      />
      <Typography text={t('Add media link')} {...ModalHeaderStyle} />
    </ModalHeader>
  )

  const renderInitialOptions = () => {
    return (
      <>
        <Label required={true}>{t('Source URL')}</Label>
        <InputWithStatus
          value={state.url}
          placeholder={'https://example.com'}
          onChange={(e) => {
            setState({ ...state, url: e.target.value, isValid: !checkURLWithoutHTTPValidity(e.target.value) })
          }}
          tooltipErrorMessages={{
            invalidUrl: 'Provide valid URL',
          }}
          validityFunctions={[onUrlChange(checkURLWithoutHTTPValidity)]}
          errorText={t('You must enter a valid https URL')}
          dataTest={`${dataTest}-url-input`}
        />
        <div className={`${rootClass}__divider`} />
        <Label required={true} className={`${rootClass}__link-name-label`} display={'marginTop: 10px'}>
          {t('Link name')}
        </Label>
        <Input className={`${rootClass}__link-name-input`} onChange={onLinkNameChange} dataTest={`${dataTest}-name-input`} />
        <Checkbox
          checked={state.isLinkedToLibrary}
          label={t('Add link to my media library')}
          value={'linkToLibary'}
          onChange={(checked) => setState({ ...state, isLinkedToLibrary: checked })}
        />
        {state.isLinkedToLibrary && (
          <div className={`${rootClass}__selectV2-container`}>
            <SelectV2
              defaultValue={folders[0]}
              inputIcon={SvgNames.folder}
              options={folders}
              isClearable={false}
              isSearchable
              insideModal
              onChange={(e) => setState({ ...state, selectedFolderId: e?.value })}
            />
          </div>
        )}
        <ExpirationDate />
      </>
    )
  }

  const renderLinkAddedOptions = () => {
    return (
      <>
        <AssetSelect
          isButtonLaunch
          labelProps={{ label: t('Media link') }}
          svgIcon={SvgNames.page}
          onClick={() => setState(initialState)}
          selectedAssets={state.linkName}
          placeholder={'Select a media file or link'}
        />
        <div className={`${rootClass}__bottom-options`}>
          <Checkbox
            disabled={true}
            label={t(`Download file on click`)}
            value={'linkToLibary'}
            onChange={(isChecked) => setState({ ...state, isLinkedToLibrary: isChecked })}
          />
          <InfoTooltip position={'right'}>{t('Downloading the file is not available for media links')}</InfoTooltip>
        </div>
      </>
    )
  }

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody>{state.isInitialOptions ? renderInitialOptions() : renderLinkAddedOptions()}</ModalBody>
      <ModalFooter footerType={ModalFooterType.Form} className={`${rootClass}__footer`}>
        <Button
          onClick={handleCancel}
          buttonType={ButtonType.TERTIARY}
          dataTest={`${dataTest}-button-tertiary`}
          className={`${rootClass}__footer-button-cancel`}
        >
          {t('Cancel')}
        </Button>
        <Button
          className={`${rootClass}__footer-button-add`}
          buttonType={ButtonType.PRIMARY}
          onClick={() => {
            if (state.isInitialOptions) {
              const mutationVars = { sourceUrl: state.url, linkName: state.linkName, folderId: parseInt(state.selectedFolderId ?? '-1') }
              setState({ ...state, isInitialOptions: !state.isInitialOptions })
              if (state.isLinkedToLibrary) {
                handleAddLink(mutationVars)
              }
            } else {
              const insertArgs = { url: state.url, title: state.linkName }
              handleInsert(insertArgs)
            }
          }}
          disabled={!state.isValid || !state.linkName}
          dataTest={`${dataTest}-button-primary`}
        >
          {t('Insert')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default MediaLink
