import { ExpressionGroup, ExpressionRow } from '@src/pages/SegmentComposer/SegmentComposer.interfaces'

const ANIMATION_DURATION = 1000
export const ITEM_DROPPED_STATE_TIMEOUT = ANIMATION_DURATION + 500
export const RENDERER_DROPPED_STATE_TIMEOUT = 500

const deepCloneGroup = (group: ExpressionGroup): ExpressionGroup => ({
  ...group,
  rows: [...group.rows],
})

/**
 * Updates a specific group in the root group with new values.
 *
 * @param targetGroup - The ExpressionGroup to locate.
 * @param newValues - The updated values for the target group.
 * @param rootGroup - The root ExpressionGroup containing all groups and rows.
 * @returns A new ExpressionGroup with the updated group values.
 */
export const updateGroup = (rootGroup: ExpressionGroup, targetGroup: ExpressionGroup, newValues: Partial<ExpressionGroup>): ExpressionGroup => {
  const clonedRootGroup = deepCloneGroup(rootGroup)
  const updateGroupRecursively = (group: ExpressionGroup): ExpressionGroup => {
    if (group.id === targetGroup.id) {
      return { ...group, ...newValues }
    }
    if ('rows' in group) {
      return {
        ...group,
        rows: group.rows.map((group) => updateGroupRecursively(group as ExpressionGroup)),
      }
    }
    return group
  }
  return updateGroupRecursively(clonedRootGroup)
}

/**
 * Moves a row from one group to another within a hierarchical structure of expression groups.
 *
 * @param {number[]} from - The path of indices representing the source location of the row to be moved.
 * @param {number[]} to - The path of indices representing the target location where the row should be inserted.
 * @param {ExpressionGroup} rootGroup - The root expression group that contains the entire tree structure.
 * @param {boolean} [isOverTop] - Optional flag indicating whether the row should be inserted at the top of the target group.
 *                                If true, the row is inserted above the target index.
 *                                If false or omitted, the row is inserted below the target index.
 * @returns {ExpressionGroup} A new root expression group with the updated structure after the row has been moved.
 * @throws {Error} If the source or target path is invalid, or if the row to be moved is not found.
 */
export const moveRow = (from: number[], to: number[], rootGroup: ExpressionGroup, isOverTop?: boolean): ExpressionGroup => {
  const updatedRoot = deepCloneGroup(rootGroup)

  // Helper function to find a group by path of indices
  const findGroup = (group: ExpressionGroup, path: number[]): ExpressionGroup => {
    return path.reduce((currentGroup, index) => {
      if (!currentGroup.rows || !currentGroup.rows[index]) {
        throw new Error('Invalid group path')
      }
      return currentGroup.rows[index] as ExpressionGroup
    }, group)
  }

  const sourceGroup = findGroup(updatedRoot, from.slice(0, -1))
  const sourceIndex = from[from.length - 1]

  const targetGroup = findGroup(updatedRoot, to.slice(0, -1))
  const targetIndex = to[to.length - 1] + (isOverTop ? 0 : 1)

  // Remove row from source group
  const [movedRow] = sourceGroup.rows.splice(sourceIndex, 1)

  if (!movedRow) {
    throw new Error('Row not found in source group')
  }

  // Add row to target group at the specified position
  targetGroup.rows.splice(targetIndex, 0, movedRow)

  return updatedRoot
}

/**
 * Retrieves the coordinates of a specific ExpressionRow or ExpressionGroup
 * within a nested ExpressionGroup structure.
 *
 * This function searches for the provided target (row or group) in the given
 * root ExpressionGroup, including its subgroups, and returns the path as an
 * array of indices.
 *
 * @param targetId - The id of the ExpressionRow or ExpressionGroup to locate.
 * @param rootGroup - An ExpressionGroup object containing rows and subGroups.
 * @returns An array of numbers representing the hierarchical path to the target,
 *          or null if the target is not found.
 */
export const getCoordinates = (targetId: string, rootGroup: ExpressionGroup): number[] | null => {
  const { rows } = rootGroup
  for (let index = 0; index < rows.length; ++index) {
    const currentItem = rows[index]
    // Check if the target is a row
    if ('factor' in currentItem) {
      const currentRow = currentItem as ExpressionRow
      if (currentRow.factor.id === targetId) {
        return [index]
      }
    } else {
      const currentGroup = currentItem as ExpressionGroup
      if (currentGroup.id === targetId) {
        return [index]
      }
      // Recursively search in subgroups
      const childPath = getCoordinates(targetId, currentGroup)
      if (childPath) {
        return [index, ...childPath]
      }
    }
  }

  return null
}
