import { gql } from '@apollo/client'

export default gql`
  mutation copyLandingPageOrTemplate(
    $folderId: Int
    $id: String
    $title: String
    $toTemplate: Boolean
    $tags: [LabelDto]
    $includeUnPublishedChanges: Boolean
  ) {
    copyLandingPageOrTemplate(
      folderId: $folderId
      id: $id
      title: $title
      toTemplate: $toTemplate
      tags: $tags
      includeUnPublishedChanges: $includeUnPublishedChanges
    )
  }
`
