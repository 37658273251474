import { gql } from '@apollo/client'

export default gql`
  query getCrmSchema($crmType: String!) {
    getCrmSchema(crmType: $crmType) {
      columns {
        name
        mapping
      }
      status
      message
    }
  }
`
