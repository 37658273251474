import React, { FC } from 'react'

import Container from '@components/Container'
import Input from '@components/Input/Input'
import Label from '@components/Label'
import Typography, { TextType } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import { SMSContextAPI } from '../../../context/SMSContext'

import './MessageOverview.css'

export interface SMSMessage {
  title: string
  senderCode: string
  description: string
  recipients: any
  messageText: string
  personalizations: any
}

const rootClass = 'message-overview'

interface Props {
  context: SMSContextAPI
}

const MessageOverview: FC<Props> = ({ context }: Props) => {
  const {
    values: { smsMessage, senderCode },
    saveMessage,
  } = context

  const { t } = useTranslation()

  return (
    <Container data-text={rootClass} title={'Message Overview'}>
      <div className={`${rootClass}__title-line`}>
        <div className={`${rootClass}__title-input`}>
          <Input
            required
            label={t('Act-On Message Title')}
            dataTest={`${rootClass}-input-title`}
            defaultValue={smsMessage.title}
            onBlur={(e) => saveMessage({ ...smsMessage, title: e.target.value })}
          />
        </div>
        <div className={`${rootClass}__sender-code`}>
          <Label title={'Sender Code'}>
            {t('Sender Code')}
            <div className={`${rootClass}__sender-code-number`}>
              <Typography text={senderCode} type={TextType.BODY_TEXT_INPUT} dataTest={`${rootClass}-input-sender-code`} />
            </div>
          </Label>
        </div>
      </div>
      <div>
        <Input
          label={t('Act-On Description (Optional)')}
          dataTest={`${rootClass}-input-description`}
          defaultValue={smsMessage.description}
          onBlur={(e) => saveMessage({ ...smsMessage, description: e.target.value })}
        />
      </div>
    </Container>
  )
}

export default MessageOverview
