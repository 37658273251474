export const getAuthenticatedPromise = (url: string, token: string, AOAccountContext: string) => {
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      Authorization: token,
      AOAccountContext,
    },
  })
    .then((response: any) => response.blob())
    .then((blob: any) => URL.createObjectURL(blob))
}
