import { gql } from '@apollo/client'

export default gql`
  query getItem($itemId: Long, $externalId: String, $type: String) {
    getItem(itemId: $itemId, externalId: $externalId, type: $type) {
      accountId
      authorId
      createdTime
      enabled
      externalId
      favoriteUsers
      folderId
      hasToAudit
      id
      isFavorite
      item
      itemLevel
      labels {
        accountId
        color
        createdTime
        enabled
        id
        itemCount
        name
        updatedTime
        views
      }
      parentId
      position
      rootParent
      subTypeDTO {
        count
        itemType
        name
      }
      updatedTime
      username
      views
    }
  }
`
