import React, { FC, useEffect, useState } from 'react'

import classNames from 'classnames'

import BackButton from '@components/BackButton/BackButton'
import Button, { ButtonType } from '@components/Button'
import Checkbox from '@components/Checkbox/Checkbox'
import EmptyList from '@components/EmptyList/EmptyList'
import FormGroup from '@components/FormGroup'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import Select from '@components/Select/Select'
import Typography, { ModalBodyStyle, ModalHeaderListStyle, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import {
  ColumnWithPreview,
  ListProperties,
  MarketingListMapping,
} from '@src/pages/sms/marketingListOptIns/components/MapContacts/MapContactsContainer'
import { DateFormats, datePickerFormats } from '@utils/date'

import './mapContacts.css'

const rootClass = 'map-contacts'

enum MapFields {
  PHONE = 'phoneColumn',
  SOURCE = 'sourceColumn',
  DATE = 'dateColumn',
  DATEFORMAT1 = 'dateFormat1',
  DATEFORMAT2 = 'dateFormat2',
  DATEFORMAT3 = 'dateFormat3',
}

interface MapContactsProps {
  listSchemas: ListProperties[]
  onNextMapping: (mappings: MarketingListMapping[]) => void
  backToPicker: () => void
  onClose: () => void
  dataTest?: string
}

interface listObject {
  id: string
  displayName: string
  selected: boolean
  phoneColumn: string
  sourceColumn: string
  dateColumn: string
  dateFormat1: string
  dateFormat2: string
  dateFormat3: string
  previewData: {
    phone: string
    date: string
    source: string
    [key: string]: string
  }
  headersWithPreview: ColumnWithPreview[]
}

interface State {
  mappingComplete: boolean
  lists: listObject[]
}

const defaultPreviewData = {
  phone: '',
  source: '',
  date: '',
}

const MapContacts: FC<MapContactsProps> = (props: MapContactsProps) => {
  const { dataTest = 'dataTest', backToPicker, onClose, listSchemas, onNextMapping } = props
  const [state, setState] = useState<State>({
    mappingComplete: false,
    lists: listSchemas.map((list) => ({
      id: list.id,
      displayName: list.name,
      selected: true,
      phoneColumn: '',
      sourceColumn: '',
      dateColumn: '',
      dateFormat1: '',
      dateFormat2: '',
      dateFormat3: '',
      previewData: defaultPreviewData,
      headersWithPreview: list.headersWithPreviews,
    })),
  })
  const { t } = useTranslation()

  //Make sure at least one list is selected and all columns have a value selected for lists that are selected
  useEffect(() => {
    setState({
      ...state,
      mappingComplete:
        state.lists.every(
          (el) =>
            (el.selected && el.phoneColumn && el.sourceColumn && el.dateColumn && el.dateFormat1 && el.dateFormat2 && el.dateFormat3) || !el.selected
        ) && state.lists.some((el) => el.selected),
    })
  }, [state.lists])

  const toggleSelected = (listId: string) => {
    setState({
      ...state,
      lists: state.lists.map((el) => (el.id === listId ? { ...el, selected: !el.selected } : el)),
    })
  }

  const getPreviewData = (listId: string, field: string, value: string) => {
    const list = state.lists.find((x) => x.id === listId)
    if (list) {
      const previewData = list.headersWithPreview.find((header) => header.columnName === value)?.previewRows.join(', ') ?? ''
      switch (field) {
        case MapFields.PHONE:
          return { ...list.previewData, phone: previewData }
        case MapFields.DATE:
          return { ...list.previewData, date: previewData }
        case MapFields.SOURCE:
          return { ...list.previewData, source: previewData }
        default:
          return { ...list.previewData }
      }
    } else {
      return defaultPreviewData
    }
  }

  const updateState = (field: MapFields, listId: string, value: string) => {
    const previewData = getPreviewData(listId, field, value)
    setState({
      ...state,
      lists: state.lists.map((el) => (el.id === listId ? { ...el, [field]: value, previewData } : el)),
    })
  }

  const getDateFormat = (list: listObject) => {
    return `${list.dateFormat1}[-][/][.]${list.dateFormat2}[-][/][.]${list.dateFormat3}`
  }
  const getColNum = (list: listObject, matchField: string) => {
    return list.headersWithPreview.find((header) => header.columnName === matchField)?.columnIndex ?? -1
  }

  const submit = () => {
    const mappings: MarketingListMapping[] = state.lists.map((list) => ({
      id: list.id,
      displayName: list.displayName,
      cellPhoneColNum: getColNum(list, list.phoneColumn),
      optInSourceColNum: getColNum(list, list.sourceColumn),
      optInDateColNum: getColNum(list, list.dateColumn),
      optInDateFormat: getDateFormat(list),
    }))
    return onNextMapping(mappings)
  }

  const renderPreview = (field: string, listId: string) => {
    const preview = state.lists.find((x) => x.id === listId)?.previewData[field] ?? []
    return preview.length === 0 ? null : (
      <div className={`${rootClass}__preview`}>
        <Typography text={`${t('Preview')}: ${preview}`} {...ModalBodyStyle} />
      </div>
    )
  }

  const filterByGroup = (list: listObject, groupOptions: DateFormats[], field: MapFields) => {
    return (
      groupOptions.filter(
        (option) =>
          (field !== MapFields.DATEFORMAT1 && list.dateFormat1 === option.format) ||
          (field !== MapFields.DATEFORMAT2 && list.dateFormat2 === option.format) ||
          (field !== MapFields.DATEFORMAT3 && list.dateFormat3 === option.format)
      ).length > 0
    )
  }

  const isOptionUsed = (option: DateFormats, groupOptions: DateFormats[], listId: string, field: MapFields): boolean => {
    const list = state.lists.find((x) => x.id === listId)
    if (list) {
      const filterGroup = filterByGroup(list, groupOptions, field)
      switch (field) {
        case MapFields.DATEFORMAT1:
          return list.dateFormat1 === option.format || filterGroup
        case MapFields.DATEFORMAT2:
          return list.dateFormat2 === option.format || filterGroup
        case MapFields.DATEFORMAT3:
          return list.dateFormat3 === option.format || filterGroup
      }
    }
    return false
  }

  const renderDateFormatOptions = (listId: string, field: MapFields) => {
    return datePickerFormats(t).map((group) => (
      <optgroup key={group.title} label={group.title}>
        {group.options.map((option: DateFormats) => (
          <option key={option.format} value={option.format} disabled={isOptionUsed(option, group.options, listId, field)}>
            {option.name}
          </option>
        ))}
      </optgroup>
    ))
  }
  return (
    <Modal
      className={rootClass}
      isOpen
      dataTest={dataTest}
      header={
        <ModalHeader headerType={ModalHeaderType.Form} closeButton={onClose}>
          <div className={`${rootClass}__header-title`}>
            <BackButton onClick={backToPicker} />
            <Typography text={t('Map Fields')} {...ModalHeaderListStyle} inline />
          </div>
        </ModalHeader>
      }
    >
      <ModalBody>
        <div className={`${rootClass}__body`}>
          <div className={`${rootClass}__info-text`}>
            <Typography
              text={t('Map.Fields.Info.Text')}
              {...ModalBodyStyle}
              inline
              tagComponents={{
                Mobile: <Typography text={'Mobile Phone Number'} weight={TextWeight.MEDIUM} inline />,
                Source: <Typography text={'Opt-In Source'} weight={TextWeight.MEDIUM} inline />,
                Date: <Typography text={'Opt-In Date'} weight={TextWeight.MEDIUM} inline />,
              }}
            />
          </div>
          {listSchemas.map((list) => (
            <div key={list.id}>
              <div className={`${rootClass}__list`}>
                <Checkbox label={list.name} checked={state.lists.find((x) => x.id === list.id)?.selected} onChange={() => toggleSelected(list.id)} />
              </div>
              {state.lists.find((x) => x.id === list.id)?.selected ? (
                <FormGroup>
                  <div className={`${rootClass}__row`}>
                    <div className={`${rootClass}__col`}>
                      <Select
                        dataTest={`${dataTest}-phone-select`}
                        label={t('Mobile Phone Number')}
                        defaultValue={''}
                        onChange={(event) => updateState(MapFields.PHONE, list.id, event.target.value)}
                      >
                        <option value="" disabled hidden>
                          {t('Select Phone Number field')}&hellip;
                        </option>
                        {list.headers.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Select>
                    </div>
                    {renderPreview('phone', list.id)}
                  </div>
                  <div className={`${rootClass}__row`}>
                    <div className={`${rootClass}__col`}>
                      <Select
                        dataTest={`${dataTest}-optsource-select`}
                        label={t('Opt-In Source')}
                        defaultValue={''}
                        onChange={(event) => updateState(MapFields.SOURCE, list.id, event.target.value)}
                      >
                        <option value="" disabled hidden>
                          {t('Select Opt-In Source field')}&hellip;
                        </option>
                        {list.headers.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Select>
                    </div>
                    {renderPreview('source', list.id)}
                  </div>
                  <div className={`${rootClass}__row`}>
                    <div className={`${rootClass}__col`}>
                      <Select
                        dataTest={`${dataTest}-optdate-select`}
                        label={t('Opt-In Date')}
                        defaultValue={''}
                        onChange={(event) => updateState(MapFields.DATE, list.id, event.target.value)}
                      >
                        <option value="" disabled hidden>
                          {t('Select Opt-In Date field')}&hellip;
                        </option>
                        {list.headers.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Select>
                    </div>
                    {renderPreview('date', list.id)}
                  </div>
                  <div>
                    <div>
                      <Typography text={t('Date Format')} weight={TextWeight.BOLD} />
                    </div>
                    <div className={`${rootClass}__info-text`}>
                      <Typography text={t('Please input how your date field looks so we can map it correctly.')} {...ModalBodyStyle} inline />
                    </div>
                  </div>
                  <div className={classNames(`${rootClass}__row`, `${rootClass}__row-date-format`)}>
                    <div className={`${rootClass}__col-date`}>
                      <Select
                        dataTest={`${dataTest}-date1-select`}
                        defaultValue={''}
                        onChange={(event) => updateState(MapFields.DATEFORMAT1, list.id, event.target.value)}
                      >
                        <option value="" disabled hidden>
                          --
                        </option>
                        {renderDateFormatOptions(list.id, MapFields.DATEFORMAT1)}
                      </Select>
                    </div>
                    <div className={`${rootClass}__col-date`}>
                      <Select
                        dataTest={`${dataTest}-date2-select`}
                        defaultValue={''}
                        onChange={(event) => updateState(MapFields.DATEFORMAT2, list.id, event.target.value)}
                      >
                        <option value="" disabled hidden>
                          --
                        </option>
                        {renderDateFormatOptions(list.id, MapFields.DATEFORMAT2)}
                      </Select>
                    </div>
                    <div className={`${rootClass}__col-date-last`}>
                      <Select
                        dataTest={`${dataTest}-date3-select`}
                        defaultValue={''}
                        onChange={(event) => updateState(MapFields.DATEFORMAT3, list.id, event.target.value)}
                      >
                        <option value="" disabled hidden>
                          --
                        </option>
                        {renderDateFormatOptions(list.id, MapFields.DATEFORMAT3)}
                      </Select>
                    </div>
                  </div>
                </FormGroup>
              ) : (
                <div className={`${rootClass}__row`}>
                  <EmptyList message={t('This list has been removed from selection.')} />
                </div>
              )}
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter footerType={ModalFooterType.Form}>
        <Button dataTest={`${dataTest}-no-button`} buttonType={ButtonType.TERTIARY} onClick={backToPicker}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.PRIMARY} onClick={submit} disabled={!state.mappingComplete}>
          {t('Next')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default MapContacts
