import React, { ChangeEvent, FC, useRef, useState } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button'
import Container from '@components/Container'
import CustomPrompt from '@components/CustomPrompt/CustomPrompt'
import DirtyBanner from '@components/DirtyBanner/DirtyBanner'
import InputWithMaxLength from '@components/InputWithMaxLength/InputWithMaxLength'
import InputWithStatus from '@components/InputWithStatus/InputWithStatus'
import PageContainer from '@components/PageContainer/PageContainer'
import PageHeader from '@components/PageHeader'
import { PageHeaderType } from '@components/PageHeadline/PageHeadline'
import PositionContainer, { PositionContainerTypes } from '@components/PositionContainer/PositionContainer'
import StatusToast, { Status } from '@components/StatusToast/StatusToast'
import Svg, { SvgNames, SvgType } from '@components/Svg'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import withLoadingAndError from '@hoc/withLoadingAndError/withLoadingAndError'
import CustomizeModals from '@src/pages/MarketingNetwork/Customize/components/CustomizeModals/CustomizeModals'
import CustomizePreview from '@src/pages/MarketingNetwork/Customize/components/CustomizePreview/CustomizePreview'
import CustomizeSection from '@src/pages/MarketingNetwork/Customize/components/CustomizeSection/CustomizeSection'
import { CustomizeToastState } from '@src/pages/MarketingNetwork/Customize/CustomizeContainer'
import {
  CustomizeState,
  defaultCustomAlertAddress,
  defaultCustomLogoState,
  defaultCustomTitleState,
  defaultState,
  getCustomizeModalProps,
  MAX_IMAGE_SIZE,
} from '@src/pages/MarketingNetwork/Customize/utils/Customize.utils'
import { checkEmailValidity } from '@utils/formUtils'

import './Customize.css'

export interface CustomizeProps {
  logo: string
  applicationName: string
  alertAddress: string
  isDefault: boolean
  toastState: CustomizeToastState
  setStatusToast: (message: string, status?: Status) => void
  onSave: (customTitle?: string, customLogo?: string, customAlertAddress?: string) => void
  className?: string
  dataTest?: string
}

const rootClass = 'customize'

const Customize: FC<CustomizeProps> = (props: CustomizeProps) => {
  const { logo = '', onSave, isDefault, applicationName, alertAddress, toastState, setStatusToast, dataTest = rootClass, className = '' } = props

  const [state, setState] = useState<CustomizeState>({ ...defaultState })
  const fileRef = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()

  const handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const files = e.target.files

    if (files && files[0]) {
      const uploadedFile = files[0]

      if (uploadedFile.size < MAX_IMAGE_SIZE) {
        const reader = new FileReader()
        reader.onloadend = () => {
          setState({
            ...state,
            customLogo: { ...state.customLogo, editingLogo: reader.result as string },
          })
        }

        reader.readAsDataURL(uploadedFile)
      } else {
        setStatusToast(t('Marketing.Customize.Appearance.Toast.Error.Size'), Status.FAIL)
      }
    }
  }

  const renderResetButton = () => {
    return !isDefault ? (
      <Button
        buttonType={ButtonType.TEXT}
        dataTest={`${dataTest}__button-reset`}
        onClick={() => setState({ ...state, showResetModal: true })}
        className={`${rootClass}__button-reset`}
      >
        <Svg name={SvgNames.reset} type={SvgType.LARGER_ICON} />
        <Typography text={t('Marketing.Customize.Reset.Button.Text.Plural')} weight={TextWeight.MEDIUM} />
      </Button>
    ) : undefined
  }

  const renderLogo = () => (
    <div className={`${rootClass}__logo`}>
      <Typography text={t('Logo')} type={TextType.BODY_TEXT_SMALL} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM_SMALL} />
      <div
        onMouseOver={() => setState({ ...state, imageButtonActive: true })}
        onMouseOut={() => setState({ ...state, imageButtonActive: false })}
        onFocus={() => setState({ ...state, imageButtonActive: true })}
        onBlur={() => setState({ ...state, imageButtonActive: false })}
        role={'button'}
        data-test={`${rootClass}-logo`}
        onKeyDown={(keyDownEvent) => (keyDownEvent.key === '' || keyDownEvent.key === 'Enter' ? fileRef.current?.click() : undefined)}
        tabIndex={0}
        className={classNames(`${rootClass}__logo-body`, { [`${rootClass}__logo-body-hover-visible`]: state.imageButtonActive })}
      >
        <img className={`${rootClass}__img`} src={state.customLogo.editingLogo ?? state.customLogo.appliedLogo ?? logo} alt={'logo'} />
        <div
          className={classNames(`${rootClass}__logo-hover`, {
            [`${rootClass}__logo-hover-visible`]: state.imageButtonActive,
          })}
        >
          <div className={`${rootClass}__logo-hover-content`}>
            <Button
              tabIndex={-1}
              buttonType={ButtonType.TRANSPARENT}
              className={`${rootClass}__logo-hover-button`}
              dataTest={`${rootClass}-logo-button`}
              onClick={(e) => {
                e.stopPropagation()
                fileRef.current?.click()
              }}
            >
              {t('Change')}
              <input
                data-test={`${rootClass}-logo-input`}
                accept={'image/png, image/jpeg, image/gif'}
                type="file"
                hidden
                onChange={handleSelectFile}
                ref={fileRef}
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )

  const renderAppearanceSection = () => (
    <>
      {state.isEditingAppearance ? (
        <>
          <div className={`${rootClass}__logo-container`}>
            {renderLogo()}
            <div className={classNames(`${rootClass}__info`, 'flex-justify-center')}>
              <Typography text={t('Marketing.Customize.Appearance.Info.Max.Size')} tagProps={{ light: { type: TextType.BODY_TEXT_LIGHT } }} inline />
              <Typography
                text={t('Marketing.Customize.Appearance.Info.Allowed.Type')}
                tagProps={{ light: { type: TextType.BODY_TEXT_LIGHT } }}
                inline
              />
            </div>
          </div>
          <div className={`${rootClass}__input`}>
            <InputWithMaxLength
              inputProps={{
                label: t('Page Title'),
                defaultValue: state.customTitle.appliedTitle ?? applicationName,
                onChange: (e) => {
                  setState({
                    ...state,
                    customTitle: { ...state.customTitle, editingTitle: e.target.value },
                  })
                },
              }}
              maxLength={200}
            />
          </div>
        </>
      ) : (
        <CustomizePreview pageTitle={state.customTitle.appliedTitle ?? applicationName} logo={state.customLogo.appliedLogo ?? logo} />
      )}
    </>
  )

  const renderAlertsSection = () => (
    <>
      <div className={`${rootClass}__alerts`}>
        {state.isEditingAlerts ? (
          <InputWithStatus
            defaultValue={state.customAlertAddress.appliedAddress ?? alertAddress}
            value={state.customAlertAddress.appliedAddress ?? alertAddress}
            validityFunctions={[checkEmailValidity]}
            tooltipErrorMessages={{ emailInvalid: t('Invalid email address'), emailExist: t('Email.Tag.Existence.Error.Msg') }}
            onChange={(e) => {
              setState({
                ...state,
                customAlertAddress: { ...state.customAlertAddress, editingAddress: e.target.value },
              })
            }}
          />
        ) : (
          <Typography text={state.customAlertAddress.appliedAddress ?? alertAddress} type={TextType.BODY_TEXT_LIGHT} />
        )}
      </div>
    </>
  )

  return (
    <>
      <CustomPrompt
        when={state.showSaveBanner}
        title={t('You have unpublished changes?')}
        body={t(`Leave.And.Lose.Changes.Message`)}
        okButtonText={t('Discard Changes')}
        cancelButtonText={t('Continue Editing')}
        onConfirm={() => setState(defaultState)}
      />
      <PageContainer className={classNames(rootClass, className)} dataTest={dataTest}>
        {toastState.message && (
          <StatusToast
            {...toastState}
            className={classNames({
              [`${rootClass}__toast-failure`]: toastState.status === Status.FAIL,
              [`${rootClass}__toast-success`]: toastState.status === Status.SUCCESS,
            })}
            closeStatus={() => setStatusToast('', undefined)}
          />
        )}
        <CustomizeModals {...getCustomizeModalProps(state, setState, onSave)} />
        {state.showSaveBanner && (
          <DirtyBanner
            expanded
            fullWidth
            dataTest={`${rootClass}-dirty-banner`}
            saveButtonText={t('Publish changes')}
            onSave={() => setState({ ...state, showPublishModal: true })}
            onDiscard={() => setState({ ...state, showDiscardModal: true })}
          />
        )}
        <PositionContainer displayType={PositionContainerTypes.flex}>
          <div className={`${rootClass}__header`}>
            <PageHeader
              primaryText={t('Marketing.Customize.Title')}
              headlineType={PageHeaderType.SUB_HEADER}
              leftContent
              className={`${rootClass}__page-header`}
            />
            {renderResetButton()}
          </div>
          <Container className={classNames(rootClass, className)} dataTest={dataTest}>
            <CustomizeSection
              title={'Marketing.Customize.Appearance.Title'}
              description={'Marketing.Customize.Appearance.Description'}
              isEditing={state.isEditingAppearance}
              setIsEditing={() => setState({ ...state, isEditingAppearance: true })}
              handleApply={() =>
                setState({
                  ...state,
                  isEditingAppearance: false,
                  customTitle: { ...defaultCustomTitleState, appliedTitle: state.customTitle.editingTitle ?? state.customTitle.appliedTitle },
                  customLogo: { ...defaultCustomLogoState, appliedLogo: state.customLogo.editingLogo ?? state.customLogo.appliedLogo },
                  showSaveBanner: state.customTitle.editingTitle !== applicationName || state.customLogo.editingLogo !== logo,
                })
              }
              handleCancel={() =>
                setState({
                  ...state,
                  isEditingAppearance: false,
                  customTitle: { ...state.customTitle, editingTitle: undefined },
                  customLogo: { ...state.customLogo, editingLogo: undefined },
                })
              }
              applyDisabled={!state.customTitle.editingTitle && !state.customLogo.editingLogo}
            >
              {renderAppearanceSection()}
            </CustomizeSection>
            <CustomizeSection
              title={'Marketing.Customize.Alerts.Title'}
              description={'Marketing.Customize.Alerts.Description'}
              isEditing={state.isEditingAlerts}
              setIsEditing={() => setState({ ...state, isEditingAlerts: true })}
              handleApply={() =>
                setState({
                  ...state,
                  isEditingAlerts: false,
                  customAlertAddress: { ...defaultCustomAlertAddress, appliedAddress: state.customAlertAddress.editingAddress },
                  showSaveBanner: state.customAlertAddress.editingAddress !== alertAddress,
                })
              }
              handleCancel={() =>
                setState({ ...state, isEditingAlerts: false, customAlertAddress: { ...state.customAlertAddress, editingAddress: undefined } })
              }
              applyDisabled={!state.customAlertAddress || checkEmailValidity(state.customAlertAddress.editingAddress as string) !== undefined}
              borderTop
            >
              {renderAlertsSection()}
            </CustomizeSection>
          </Container>
        </PositionContainer>
      </PageContainer>
    </>
  )
}

export default withLoadingAndError(Customize)
