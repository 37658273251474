import React, { FC } from 'react'

import classNames from 'classnames'

import Button, { ButtonType } from '@components/Button/Button'
import Container from '@components/Container/Container'
import StaticImage from '@components/StaticImage/StaticImage'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import TextLink, { TextLinkSize } from '@components/TextLink/TextLink'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './AdvertisingBanner.css'

interface AdvertisingBannerProps {
  headline: string
  info?: string
  learnMore?: {
    text: string
    url: string
  }
  actionButton: {
    text: string
    onClick: () => void
  }
  bannerImage: StaticImageNames
  onDismiss: () => void
  className?: string
  dataTest?: string
}

const rootClass = 'advertising-banner'

const AdvertisingBanner: FC<AdvertisingBannerProps> = (props: AdvertisingBannerProps) => {
  const { headline, info, learnMore, actionButton, onDismiss, bannerImage, dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  return (
    <Container className={classNames(rootClass, className)} data-test={dataTest}>
      <Typography text={t(headline)} type={TextType.MODAL_HEADLINE_LARGE} weight={TextWeight.EXTRA_BOLD} />
      {info && <Typography text={t(info)} type={TextType.SECTION_HEADER} weight={TextWeight.MEDIUM} className={`${rootClass}__sub-text`} />}
      {learnMore && <TextLink text={t(learnMore.text)} onClick={() => window.open(learnMore?.url, `_blank`)} size={TextLinkSize.LARGE} />}
      <div className={`${rootClass}__buttons`}>
        <Button buttonType={ButtonType.PRIMARY} onClick={actionButton.onClick}>
          {t(actionButton.text)}
        </Button>
        <TextLink text={t('Dismiss')} onClick={onDismiss} hideIcon />
      </div>
      <StaticImage name={bannerImage} className={`${rootClass}__image`} dataTest={`${dataTest}-${bannerImage}`} />
    </Container>
  )
}

export default AdvertisingBanner
