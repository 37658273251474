import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import * as Papa from 'papaparse'

import { ContactStatus } from '@complex/ImportContacts/ImportContacts.constants'
import PageContainer from '@components/PageContainer'
import PageError from '@components/PageError/PageError'
import { Status } from '@components/StatusToast/StatusToast'
import { FormatFile, useTranslation } from '@const/globals'
import { List } from '@interface/foldersLists/List'
import ErrorMessage, { ErrorType } from '@src/pages/importcontacts/components/ErrorMessage/ErrorMessage'
import { getDuplicates } from '@src/pages/importcontacts/components/MappingScreen/utils/MappingScreen.utils'
import { useFieldMappingValidator } from '@src/pages/importcontacts/components/MappingScreen/utils/useFieldMappingValidator'
import Entity from '@src/pages/importcontacts/context/EntityUploadServiceAxios'
import {
  ColumnStateType,
  defaultMappingPreviewField,
  defaultState,
  ImportContactsContainerValues,
  ImportContactsContext,
  VALIDATION_MESSAGE,
} from '@src/pages/importcontacts/context/ImportContactsContext'
import { useImportContactsCategorizationRequests } from '@src/pages/importcontacts/GraphQL/ImportContactsRequests.categorization.graphQL'
import { useAddContactsFromCRMClassicRequests } from '@src/pages/importcontacts/GraphQL/ImportContactsRequests.classic.graphQL'
import { useImportContactsEntityUploadRequests } from '@src/pages/importcontacts/GraphQL/ImportContactsRequests.entityUpload.graphQL'
import { useImportContactsListRequests } from '@src/pages/importcontacts/GraphQL/ImportContactsRequests.list.graphQL'
import ImportContacts from '@src/pages/importcontacts/ImportContacts'
import {
  convertDelimiter,
  convertQuoteChar,
  createSegmentUtils,
  getImportContactsSteps,
  getInProgressImportsUtils,
  getListSchemaUtils,
  getMappingValidationMessageUtils,
  getTopDirectSelectSegmentOptionsUtils,
  getUpdatedMappingField,
  importEntityUtils,
  importListUtils,
  isAValidSegmentNameUtils,
  loadStandardFieldsUtils,
  matchFieldsWithHeadersUtil,
  searchSegmentUtils,
} from '@src/pages/importcontacts/utils/ImportContactsContainerUtils'
import { ImportContactsSteps } from '@src/pages/ImportContactsV2/utils/ImportContactsV2.constants'
import { useDeepUpdate } from '@utils/hooks/useDeepUpdate'
import { MicroserviceClients, useMicroserviceUrl } from '@utils/hooks/useMicroserviceClient'

const rootClass = 'contacts-import'
const previewCount = 21

const ImportContactsContainer: FC = () => {
  const { t } = useTranslation()
  const [containerValues, setContainerValues] = useState<ImportContactsContainerValues>({ ...defaultState, steps: getImportContactsSteps(t, false) })
  const { token, aoAccountContext, accountSettings } = useSelector((state: any) => state.account.account)
  const { createSegmentRequest, isAValidSegmentNameRequest, getListSchemaRequest } = useAddContactsFromCRMClassicRequests()
  const { getTopDirectSelectSegmentsRequest, searchDirectSelectSegmentsRequest } = useImportContactsCategorizationRequests()
  const { getInProgressImportsRequest, getStandardFieldsRequest } = useImportContactsListRequests()
  const { importListRequest, importEntityRequest } = useImportContactsEntityUploadRequests()
  const microserviceUrl = useMicroserviceUrl({ serviceName: MicroserviceClients.ENTITY_UPLOAD })
  const { mappingPreview, pageError, validateAllTrigger, visibleFields, nonDeletedFields, previewHeader } = containerValues
  // Ref to handle background uploading, the state keeps old values while the file is uploading
  // therefore it's necessary to maintain the values updated during the mapping
  const uploadingFileStateRef = useRef({ ...containerValues })
  const validationProcessRef = useRef({ inProgress: false, reviewClicked: false })
  const { steps } = containerValues

  const update = useDeepUpdate(setContainerValues)

  const closeModal = () => {
    update({
      error: {
        displayError: false,
        errorTitle: '',
        errorMessage: '',
      },
    })
  }

  const { validateAllFields, validateColumn } = useFieldMappingValidator<ImportContactsContainerValues>({
    containerValues,
    validationProcessRef,
    setStatusToast: (statusToast) => update({ statusToast }),
    setContainerValues,
  })

  const isAValidSegmentName = async (segmentName: string) => await isAValidSegmentNameUtils(isAValidSegmentNameRequest, segmentName, update)
  const currentStep = useMemo(() => steps.find(({ isActive }) => isActive), [steps])

  const resetState = () => {
    const { visibleFields, standardFields, selectOptions } = containerValues
    const initState = { ...defaultState, steps: getImportContactsSteps(t, false), visibleFields, standardFields, selectOptions }
    uploadingFileStateRef.current = initState
    update(initState)
  }

  const closeStatusToast = () => {
    update({ statusToast: { ...containerValues.statusToast, statusMessage: undefined, showStatusToast: false } })
  }

  const handleOnSectionChange = (newTab: string, currentStepCompleted = false) => {
    update({
      steps: steps.map((step) => {
        if (step.key === currentStep?.key) {
          return { ...step, isActive: false, isCompleted: currentStepCompleted || step.isCompleted }
        } else if (step.key === newTab) {
          return { ...step, isActive: true }
        } else {
          return step
        }
      }),
    })
  }

  const startUpload = () => {
    update({ fileUploading: true })
    if (containerValues.fileSelected) {
      handleOnSectionChange(ImportContactsSteps.IMPORT_OPTIONS, true)
      const formData = new FormData()

      formData.append('file', containerValues.fileSelected)
      Entity.uploadCSV(
        microserviceUrl ?? '',
        token,
        aoAccountContext,
        formData,
        (progress: any) => {
          // Once the file is completed upload we still need to wait for the server response
          // To avoid showing 100% on the progress bar for a long time we put 99% instead, and then move to the next screen once it is done
          const csvUploadProgress = progress.loaded === progress.total ? 99 : Math.round((progress.loaded * 100) / progress.total)

          if (uploadingFileStateRef.current.showProgress) {
            update({
              uploadingProgress: csvUploadProgress > 0 ? csvUploadProgress : 0,
            })
          } else {
            uploadingFileStateRef.current = { ...uploadingFileStateRef.current, fileUploading: true, uploadingProgress: csvUploadProgress }
          }
        },
        (response: any) => {
          if (response.status === 200) {
            if (uploadingFileStateRef.current.showProgress) {
              update({
                fileUploading: false,
                uploadCompleted: true,
                importStarted: true,
                mappingId: response.data.id,
              })
            } else {
              uploadingFileStateRef.current = {
                ...uploadingFileStateRef.current,
                uploadCompleted: true,
                importStarted: true,
                mappingId: response.data.id,
              }
            }
          } else {
            update({
              fileUploading: false,
              statusToast: { statusMessage: 'Error connecting to the service when uploading the file', showStatusToast: true, status: Status.FAIL },
            })
          }
        },
        () => {
          update({
            fileUploading: false,
            statusToast: { statusMessage: 'Error connecting to the service when uploading the file', showStatusToast: true, status: Status.FAIL },
          })
        }
      )
    }
  }

  const removeFormData = () => update({ fileSelected: null })

  const goToImportOptions = () => handleOnSectionChange(ImportContactsSteps.IMPORT_OPTIONS, true)
  const onSubmitLists = (lists: List[]) => {
    const listIds = lists.map((list) => list.id)
    const count = previewCount - 1
    getListSchemaUtils(getListSchemaRequest, listIds, count, update, goToImportOptions)
  }

  const onFileSelected = (files: File[]) => {
    if (files.length > 1) {
      update({
        error: {
          displayError: true,
          errorTitle: 'Data overload',
          errorMessage: <ErrorMessage type={ErrorType.SINGLE_FILE} />,
        },
      })
      return false
    }
    const fileSelected: File = files[0]
    const maxFileSize = 1024 * 1024 * 1024 // 1 Gig max file size
    const extension = fileSelected.name.split('.').pop()
    if (fileSelected.size > maxFileSize) {
      update({
        error: {
          displayError: true,
          errorTitle: 'Data overload',
          errorMessage: <ErrorMessage type={ErrorType.SIZE} fileName={fileSelected.name} />,
        },
      })
      return false
    }

    if (fileSelected.size === 0) {
      update({
        error: {
          displayError: true,
          errorTitle: 'No data found',
          errorMessage: <ErrorMessage type={ErrorType.EMPTY_FILE} fileName={fileSelected.name} />,
        },
      })
      return false
    }

    if (extension !== 'csv') {
      update({
        error: {
          displayError: true,
          errorTitle: "Let's try this again",
          errorMessage: <ErrorMessage type={ErrorType.EXTENSION} fileName={fileSelected.name} />,
        },
      })
      return false
    }
    update({ fileSelected })
  }

  const getNewCustomFieldsInUse = () => {
    const customFields = mappingPreview.filter(({ isCustom }) => isCustom).map(({ friendlyName }) => friendlyName)
    const currentFieldsNames = nonDeletedFields.map(({ displayName }) => displayName)
    return customFields.filter((customField) => currentFieldsNames.includes(customField))
  }

  const updatePreviewData = (file: any) => {
    const quoteChar = convertQuoteChar(containerValues.quoteChar)
    Papa.parse(file, {
      preview: 21,
      delimiter: convertDelimiter(containerValues.delimiter),
      quoteChar: quoteChar,
      escapeChar: quoteChar,
      chunk: (_results, parser) => {
        parser.abort() // The first megabyte should be all we need to get 20 rows from the csv.
      },
      chunkSize: 1024 * 1024, // 1MB
      error: () => {
        update({
          statusToast: {
            statusMessage: 'Error while trying to parse the file for preview.',
            showStatusToast: true,
            status: Status.FAIL,
          },
        })
      },
      complete: (result) => showPreviewData(result),
    })
  }

  const showPreviewData = (result: any) => {
    const mappingPreview = result.data[0]?.map((_fieldName: string, index: number) => {
      return {
        index,
        ...defaultMappingPreviewField,
      }
    })

    const { delimiter, hasHeaders } = containerValues

    const previewHeader = result.data[0]?.map((fieldName: string, index: number) => {
      if (hasHeaders) {
        return fieldName === '' ? `(${t('Untitled')})` : fieldName
      }
      return `${t('Column')} ${FormatFile.columnToLetter(index)}`
    })

    const previewRecords = result.data
      .slice(hasHeaders ? 1 : 0, hasHeaders ? previewCount : previewCount - 1)
      .filter((record: any) => record.length >= previewHeader.length)
      .map((record: any) => {
        return record.map((column: any) => {
          return {
            value: column,
            hasError: false,
          }
        })
      })

    update({
      columnCount: result.data.length ? result.data[0].length : 0,
      previewHeader,
      previewRecords,
      mappingPreview,
      delimiter: delimiter,
    })
  }

  const createSegment = (segmentName: string) => createSegmentUtils(createSegmentRequest, segmentName, setContainerValues)

  const closeSummary = () => {
    validationProcessRef.current = { inProgress: false, reviewClicked: false }
    handleOnSectionChange(ImportContactsSteps.MAP_FIELDS, false)
  }

  const reviewSummary = () => {
    const { mappingPreview } = containerValues
    const { inProgress } = validationProcessRef.current
    const statusMessage = getMappingValidationMessageUtils(mappingPreview, inProgress, getDuplicates, getNewCustomFieldsInUse)
    if (statusMessage !== VALIDATION_MESSAGE.VALIDATION_SUCCESFUL) {
      const validationInProgress = statusMessage === VALIDATION_MESSAGE.VALIDATION_IN_PROGRESS
      validationProcessRef.current = { inProgress: validationInProgress, reviewClicked: validationInProgress }

      update({
        statusToast: {
          statusMessage,
          status: Status.FAIL,
          showStatusToast: true,
        },
      })
      return
    }

    const newFields = mappingPreview.filter(({ isCustom }) => isCustom).length

    const warnings = mappingPreview.filter(({ state }) => state === ColumnStateType.WARNING).length

    const mapped =
      mappingPreview.filter(({ state }) => {
        return state === ColumnStateType.SUCCESS
      }).length + warnings

    const unmapped = mappingPreview.length - mapped

    update({
      summary: {
        mapped,
        unmapped,
        newFields,
        warnings,
      },
    })
    handleOnSectionChange(ImportContactsSteps.REVIEW, true)
  }

  const doImport = () => {
    if (containerValues.importingList) {
      update({
        doImportList: true,
        importStarted: true,
      })
    } else if (uploadingFileStateRef.current.uploadCompleted) {
      update({
        fileUploading: false,
        uploadCompleted: true,
        importStarted: true,
        mappingId: uploadingFileStateRef.current.mappingId,
      })
    } else {
      uploadingFileStateRef.current = {
        ...containerValues,
        showProgress: true,
        uploadingProgress: uploadingFileStateRef.current.uploadingProgress,
      }
      update({
        showProgress: true,
        uploadingProgress: uploadingFileStateRef.current.uploadingProgress,
      })
      handleOnSectionChange(ImportContactsSteps.UPLOAD_IN_PROGRESS, true)
    }
  }

  const importContacts = () => {
    const {
      listName,
      importingList,
      mappingPreview,
      segmentSelectedOption,
      selectSegmentChecked,
      delimiter,
      fileSelected,
      hasHeaders,
      mappingId,
      quoteChar,
      contactPreference,
    } = containerValues
    const columnAttributes: any = {
      current: {},
      added: {},
    }

    const segmentId = selectSegmentChecked ? segmentSelectedOption?.value : undefined

    mappingPreview
      .filter(({ isCustom, mapping, mappingIndex }) => isCustom || (mapping !== '' && mappingIndex !== undefined))
      .forEach(({ index, mapping, mappingIndex, merge, isCustom, friendlyName, dataType, dataFormat = null, marketingColumnName }) => {
        if (!isCustom) {
          columnAttributes.current[index] = {
            columnId: mappingIndex,
            dataFormat: dataFormat || null,
            mapping,
            merge,
            marketingColumnName,
          }
        } else {
          columnAttributes.added[index] = {
            friendlyName,
            dataType,
            dataFormat: dataFormat || null,
            additional: {
              hidden: 'false',
            },
            marketingColumnName,
          }
        }
      })

    handleOnSectionChange(ImportContactsSteps.IMPORT_IN_PROGRESS, true)

    if (importingList) {
      const listVariables = {
        importListData: {
          listId: mappingId,
          listName,
          userId: accountSettings.userId,
          userName: accountSettings.userName,
          importColumnAttributes: columnAttributes,
          segmentId,
        },
      }
      importListUtils(importListRequest, listVariables, update)
    } else {
      const entityVariable = {
        importEntityData: {
          fileId: mappingId,
          fileName: fileSelected?.name,
          fieldSeparator: convertDelimiter(delimiter),
          entityType: 'actontact',
          quoteCharacter: convertQuoteChar(quoteChar),
          hasHeader: hasHeaders,
          userId: accountSettings.userId,
          userName: accountSettings.userName,
          importColumnAttributes: columnAttributes,
          segmentId,
          ...(contactPreference ? { contactStatus: contactPreference } : {}),
        },
      }
      importEntityUtils(importEntityRequest, entityVariable, update)
    }
  }

  const loadStandardFields = () => loadStandardFieldsUtils(getStandardFieldsRequest, update)
  const getInProgressImports = () => getInProgressImportsUtils(getInProgressImportsRequest, update, t)
  const updateHasHeaders = (hasHeaders: boolean) => update({ hasHeaders })

  const matchFieldsWithHeaders = () => {
    update({
      mappingPreview: matchFieldsWithHeadersUtil(mappingPreview, previewHeader, visibleFields, getUpdatedMappingField),
      validateAllTrigger: true,
    })
  }

  const setContactPreference = (contactPreference: ContactStatus) => update({ contactPreference })
  const enableContactPreference = (contactPreferenceEnabled: boolean) => update({ contactPreferenceEnabled })

  const getTopDirectSelectSegments = async (currentPage = 0) =>
    await getTopDirectSelectSegmentOptionsUtils(getTopDirectSelectSegmentsRequest, currentPage)

  const searchTopDirectSelectSegments = async (search: string) => await searchSegmentUtils(searchDirectSelectSegmentsRequest, search)

  useEffect(() => {
    const setDefaultOptions = async () => {
      const options = await getTopDirectSelectSegments()
      update({ selectOptions: options })
    }
    loadStandardFields()
    getInProgressImports()
    setDefaultOptions()
  }, [])

  useEffect(() => {
    if (validateAllTrigger) {
      validateAllFields()
    }
  }, [validateAllTrigger])

  if (pageError) {
    return (
      <PageContainer>
        <PageError center />
      </PageContainer>
    )
  }

  return (
    <ImportContactsContext.Provider
      value={{
        values: containerValues,
        startUpload,
        handleOnSectionChange,
        removeFormData,
        onFileSelected,
        validateColumn,
        closeModal,
        updatePreviewData,
        doImport,
        importContacts,
        updateHasHeaders,
        reviewSummary,
        closeSummary,
        createSegment,
        onSubmitLists,
        closeStatusToast,
        resetState,
        matchFieldsWithHeaders,
        setContactPreference,
        enableContactPreference,
        getTopDirectSelectSegments,
        searchTopDirectSelectSegments,
        isAValidSegmentName,
        update,
      }}
    >
      <ImportContacts loading={false} dataTest={rootClass} currentStep={currentStep ?? steps[0]} />
    </ImportContactsContext.Provider>
  )
}

export default ImportContactsContainer
