import { useEffect, useRef, useState } from 'react'

import { useTranslation } from '@const/globals'
import getAvailableFields from '@graphql/microservices/crm-sync/getAvailableFields'
import { AvailableFieldsKeyValue, GetAvailableFieldsQuery, GetAvailableFieldsQueryVariables } from '@graphql/types/microservice/crm-sync-types'
import {
  ColumnType,
  DynamicListItems,
  DynamicListRowData,
  OptionType,
} from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.interfaces'
import {
  buildEditItem,
  buildOptionItem,
  buildStaticItem,
} from '@src/pages/datamanagement/components/CrmContacts/components/DynamicList/utils/DynamicList.utils'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'

export type BaseRow = {
  standard: DynamicListRowData
  custom: DynamicListRowData
  required: DynamicListRowData
}

type Props = {
  entityTypeList: string[]
  standardFields: OptionType[]
}

const usePopulateRows = ({ entityTypeList, standardFields }: Props): BaseRow => {
  const [availableFields, setAvailableFields] = useState<AvailableFieldsKeyValue[]>()
  const [loadingAvailableFields, setLoadingAvailableFields] = useState<boolean>()
  const [baseRow, setBaseRow] = useState<BaseRow>({
    custom: { id: 0, columns: [], key: '0' },
    standard: { id: 0, columns: [], key: '0' },
    required: { id: 0, columns: [], key: '0' },
  })

  const availableFieldsRequested = useRef(false)

  const { client: crmSyncClient } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM_SYNC })

  const { t } = useTranslation()

  // these fields shouldn't be localized
  const customDataTypes: OptionType[] = [
    { display: t('Data Type'), disabled: true, value: '-1' },
    { display: 'BOOLEAN', value: 'BOOLEAN', dataType: 'BOOLEAN' },
    { display: 'DATE', value: 'DATE', dataType: 'DATE' },
    { display: 'DATETIME', value: 'DATETIME', dataType: 'DATETIME' },
    { display: 'EMAIL', value: 'EMAIL', dataType: 'EMAIL' },
    { display: 'NUMBER', value: 'NUMBER', dataType: 'NUMBER' },
    { display: 'SCORE', value: 'SCORE', dataType: 'SCORE' },
    { display: 'TEXT', value: 'TEXT', dataType: 'TEXT' },
    { display: 'MULTI_TEXT', value: 'MULTI_TEXT', dataType: 'MULTI_STRING' },
  ]

  const loadAvailableFields = () => {
    setLoadingAvailableFields(true)
    crmSyncClient
      .query<GetAvailableFieldsQuery, GetAvailableFieldsQueryVariables>({
        query: getAvailableFields,
        fetchPolicy: 'network-only',
        variables: {
          entityTypes: entityTypeList,
        },
      })
      .then(({ data }) => setAvailableFields(data.availableFields as AvailableFieldsKeyValue[]))
      .finally(() => setLoadingAvailableFields(false))
  }

  useEffect(() => {
    if (entityTypeList.length > 0 && !availableFieldsRequested.current) {
      loadAvailableFields()
      availableFieldsRequested.current = true
    }
  }, [entityTypeList])

  useEffect(() => {
    if (!loadingAvailableFields && availableFields) {
      const columns = availableFields?.reduce((fieldsAcc: DynamicListItems[], availableFields) => {
        const fields: OptionType[] =
          availableFields?.fieldDefinitions?.map((fieldData) => ({
            display: fieldData?.displayName ?? '',
            disabled: false,
            key: fieldData?.fieldName,
            dataType: fieldData?.dataType,
          })) ?? []

        fields
          .sort((a: OptionType, b: OptionType) => (a.display < b.display ? -1 : 1))
          .push({ display: t('Select Source'), disabled: false, value: '-1' })
        return [...fieldsAcc, buildOptionItem(availableFields?.entityType ?? t('undefined'), fields, '-1')]
      }, [])

      if (columns) {
        const baseColumns: DynamicListItems[] = [{ type: ColumnType.BLANK }, ...columns, { type: ColumnType.ALLOW_PUSH, column: t('ALLOW PUSH') }]
        setBaseRow({
          ...baseRow,
          standard: {
            columns: [...baseColumns, buildOptionItem(t('ACT-ON CONTACTS'), standardFields, '-1')],
            id: -1,
            key: '0',
          },
          custom: {
            columns: [...baseColumns, buildEditItem(t('ACT-ON CONTACTS'), ''), buildOptionItem(t('DATA TYPE'), customDataTypes, '-1')],
            id: -1,
            key: '0',
          },
          required: {
            columns: [...baseColumns, buildStaticItem(t('ACT-ON CONTACTS'), '')],
            id: -1,
            key: '0',
          },
        })
      }
    }
  }, [loadingAvailableFields, availableFields])

  return baseRow
}

export default usePopulateRows
