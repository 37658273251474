import React, { FC } from 'react'

import classNames from 'classnames'

import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { useTranslation } from '@const/globals'

interface Props {
  className?: string
  dataTest?: string
  helpMessage?: {
    text: string
    url: string
  }
}

const rootClass = 'email-sms-empty'

const EmailSMSEmpty: FC<Props> = (props: Props) => {
  const { className, dataTest = rootClass, helpMessage } = props
  const { t } = useTranslation()

  return (
    <div className={classNames(rootClass, className)} data-test={dataTest}>
      <EmptyListing
        headline={t(`You haven't added any messages!`)}
        imgSrc={StaticImageNames.emptyMessages}
        size={EmptyListingSize.MEDIUM}
        text={t(`You'll see message performance here once you add messages to your program and start it up.`)}
        linkText={helpMessage?.text}
        link={helpMessage?.url}
        hideIcon={false}
      />
    </div>
  )
}

export default EmailSMSEmpty
