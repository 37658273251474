import React, { FC, ReactNode } from 'react'

import ScrollArea from '@components/ScrollArea/ScrollArea'
import TextBubble from '@components/TextBubble/TextBubble'
import Typography, { TextAlign, TextType } from '@components/Typography/Typography'
import { getUUID, useTranslation } from '@const/globals'

import { MessageType } from './MessagePreview'
import { SMSContextAPI } from '../../../context/SMSContext'
import { replacePersonalizationWithX } from '../../utils/personalizationReplacement'
import SegmentedMessage from '../../utils/segmentationCalculator'

import './messagePreviewVisual.css'

interface Props {
  context: SMSContextAPI
  dataTest?: string
  rootClass?: string
  messageType?: MessageType
  personalization?: string
}

type Segment = {
  raw: string
}

const linkPattern = /(http[s]?:\/\/[\w.\/~_\-]+)/
const newLinePattern = /\r\n|\r|\n/

export const displayMessage = (message: string, messageType?: MessageType, personalization?: string, dataTest?: string, rootClass?: string) => {
  const addNewLinesAndHighlight = (message: string) => {
    return message.split(newLinePattern).map((str) => (
      <p key={getUUID(true)} data-test={`${dataTest}__inner`}>
        {str.split(linkPattern).map((str, i) => {
          if (linkPattern.test(str)) {
            return (
              <span key={i} className={`${rootClass}__highlight`} data-test={`${dataTest}__highlight`}>
                {str}
              </span>
            )
          } else {
            return str
          }
        })}
      </p>
    ))
  }

  const formatMessageText = (message: string, key = 'empty') => {
    return <TextBubble key={key} text={addNewLinesAndHighlight(message)} dataTest={`${dataTest}__sms-text`} className={`${rootClass}__sms-block`} />
  }

  const reduceSegmentedMessage = (segmentedMessageToReduce: SegmentedMessage) => {
    return segmentedMessageToReduce.segments.reduce((acc: ReactNode[], curr: [Segment]) => {
      return [
        ...acc,
        formatMessageText(
          curr
            .filter((current) => current)
            .map((current) => current.raw)
            .join(''),
          getUUID(true)
        ),
      ]
    }, [])
  }

  const maxLengthSingleMessage = 160
  const displayMessage = personalization ? personalization : replacePersonalizationWithX(message)
  if (messageType === MessageType.CONCATENATED || displayMessage.length < maxLengthSingleMessage) {
    return formatMessageText(displayMessage)
  }

  const newSegment = new SegmentedMessage(displayMessage)
  return reduceSegmentedMessage(newSegment)
}

export const MessagePreviewVisual: FC<Props> = (props: Props) => {
  const {
    context,
    rootClass = 'message-preview-visual',
    dataTest = 'message-preview-visual',
    messageType = MessageType.INDIVIDUAL,
    personalization,
  } = props

  const { t } = useTranslation()

  const {
    values: {
      smsMessage: { messageText },
    },
  } = context

  const getEmptyContainer = () => (
    <div className={`${rootClass}__empty`}>
      <Typography
        text={t('Create text in composer to preview message')}
        type={TextType.BODY_TEXT_LIGHT}
        textAlign={TextAlign.CENTER}
        data-test={`${dataTest}__empty`}
      />
    </div>
  )

  return (
    <div className={`${rootClass}`}>
      <ScrollArea className={`${rootClass}__overflow`}>
        {messageText.length ? displayMessage(messageText, messageType, personalization, dataTest, rootClass) : getEmptyContainer()}
      </ScrollArea>
    </div>
  )
}

export default MessagePreviewVisual
