import { TFunction } from 'i18next'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'

export const getScoreTypeOptions = (t: TFunction): SelectV2SingleOption[] => [
  {
    label: t('Total score'),
    value: 'TOTAL_SCORE',
    extraOptions: { nextConditionType: 'SCORE_SHEET' },
  },
  {
    label: t('Behavior score'),
    value: 'BEHAVIOR_SCORE',
    extraOptions: { nextConditionType: 'SCORE_SHEET' },
  },
  {
    label: t('Campaign score'),
    value: 'CAMPAIGN_SCORE',
    extraOptions: { nextConditionType: 'SCORE_SHEET' },
  },
  {
    label: t('Predictive score'),
    value: 'PREDICTIVE_SCORE',
    extraOptions: { nextConditionType: 'NUMERIC' },
  },
]
