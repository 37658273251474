import React, { useMemo } from 'react'

import classNames from 'classnames'

import { getFolderById } from '@complex/ListingPage/Components/Sidebar/Utils/Sidebar.utils'
import {
  GetCRMSegmentsInfoRequestType,
  SyncedSegmentDetailsRequestType,
} from '@complex/ListPickerModalV2/utils/graphql/ListPickerModalCRMRequests.graphQL'
import { PreProcessedList } from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalInterfaces'
import {
  GetMenuItemsParams,
  RenderFiltersParams,
  RenderFirstSectionParams,
  RenderFoldersSectionParams,
  RenderSelectParams,
  RenderTagsSectionParams,
} from '@complex/ListPickerModalV2/utils/interfaces/ListPickerModalMenuUtilsInterfaces'
import { legacyListTypes } from '@complex/ListPickerModalV2/utils/ListPickerModalConstants'
import { listChangedUtils, setFilter, setFolder, setTag } from '@complex/ListPickerModalV2/utils/ListPickerModalUtils'
import EmptyListing, { EmptyListingSize } from '@components/EmptyListing/EmptyListing'
import FilterContainer from '@components/FilterContainer/FilterContainer'
import Loader from '@components/Loader'
import SelectV2 from '@components/SelectV2/SelectV2'
import { FolderData } from '@components/SortableFolders/components/Folder/Folder'
import SortableFolders from '@components/SortableFolders/SortableFolders'
import StaticImageNames from '@components/StaticImage/StaticImageNames'
import { SvgNames } from '@components/Svg'
import TagsList from '@components/TagsList/TagsList'
import { TextType } from '@components/Typography/Typography'
import { LabelDto } from '@graphql/types/microservice/categorization-types'
import { SyncedSegment } from '@graphql/types/microservice/crm-types'
import { Folder } from '@interface/Folder'
import { ItemSubType, ItemType } from '@utils/categorization'
import { CRM_STATUS } from '@utils/crm.utils'
import { availableListsFilter, commonFilters, favoriteFilter, FilterDefinition } from '@utils/filter'
import { getFolderCount } from '@utils/folderUtils'
import { GET_LIST_CATEGORIES, LIST_CATEGORY_FILTERS, ListCategory } from '@utils/lists'
import { renderMoreFiltersItems } from '@utils/menuItems'
import { logNewRelicError } from '@utils/new-relic.utils'

export const getMainFilter = (listType: string): FilterDefinition => ({
  name: LIST_CATEGORY_FILTERS[listType as ListCategory],
  svgSelected: SvgNames.segmentsSelected,
  svgUnselected: SvgNames.segmentsUnselected,
})

export const getFilters = (listType: string): FilterDefinition[] => {
  const mainFilter = getMainFilter(listType)
  const filters: { [key: string]: FilterDefinition[] } = {
    [ListCategory.ACCOUNTS]: [mainFilter],
    [ListCategory.MARKETING]: [mainFilter, favoriteFilter],
    [ListCategory.EXTENSION]: [mainFilter, favoriteFilter],
    [ListCategory.FORM_SUBMISSIONS]: [mainFilter, favoriteFilter],
    [ListCategory.FORM_SUBMISSIONS_UCL]: [mainFilter, favoriteFilter],
    [ListCategory.WEBINAR]: [mainFilter, favoriteFilter],
    [ListCategory.WEBINAR_UCL]: [mainFilter, favoriteFilter],
    [ListCategory.UNIFIED_LIST]: [mainFilter, ...commonFilters],
  }
  return filters[listType]
}

const renderSelect = ({
  rootClass,
  setState,
  clearSearch,
  exclusivelyActOnContacts,
  hasLegacyLists,
  hasUnifiedLists,
  isLegacyForms,
  isLegacyWebinars,
  hideAllUCLListTypes,
  phase3FormSubmission,
  listType,
  t,
}: RenderSelectParams) => {
  const options = GET_LIST_CATEGORIES().reduce((options: { label: string; value: ListCategory }[], { text, type }) => {
    const hideUnifiedList = (!hasUnifiedLists || hideAllUCLListTypes) && type === ListCategory.UNIFIED_LIST
    const hideUnifiedFormSubmissions = (isLegacyForms || hideAllUCLListTypes) && type === ListCategory.FORM_SUBMISSIONS_UCL
    const hideClassicFormSubmissions =
      (!isLegacyForms || (phase3FormSubmission && exclusivelyActOnContacts)) && type === ListCategory.FORM_SUBMISSIONS
    const hideUnifiedWebinarRegistrations = (isLegacyWebinars || hideAllUCLListTypes) && type === ListCategory.WEBINAR_UCL
    const hideClassicWebinarRegistrations = (!isLegacyWebinars || (phase3FormSubmission && exclusivelyActOnContacts)) && type === ListCategory.WEBINAR
    const hideAllLegacyLists = !hasLegacyLists && legacyListTypes.includes(type)
    const hideMarketingList = exclusivelyActOnContacts && type === ListCategory.MARKETING
    return hideUnifiedList ||
      hideAllLegacyLists ||
      hideMarketingList ||
      hideUnifiedFormSubmissions ||
      hideClassicFormSubmissions ||
      hideUnifiedWebinarRegistrations ||
      hideClassicWebinarRegistrations
      ? options
      : [...options, { label: t(text), value: type }]
  }, [])

  const selectedOption = options?.find(({ value }) => value === listType)

  return (
    <SelectV2
      key={`${options?.length}-${selectedOption}`}
      value={selectedOption}
      defaultValue={selectedOption}
      className={`${rootClass}__menu-select`}
      dataTest={`${rootClass}-select-field`}
      insideModal
      isSearchable
      isClearable={false}
      itemType={ItemType.SEGMENT}
      onChange={(option) => {
        if (!!option && option.value !== listType) {
          listChangedUtils(setState, option?.value, clearSearch)
        }
      }}
      options={options}
      placeholder={t('Search...')}
    />
  )
}

const renderFilters = ({ rootClass, setState, listType, currentFilter, isRestrictedToLegacyLists }: RenderFiltersParams) => (
  <FilterContainer
    className={`${rootClass}-filter-container`}
    dataTest={`${rootClass}-filter-container`}
    filters={isRestrictedToLegacyLists ? [availableListsFilter] : getFilters(listType ?? '')}
    activeFilter={currentFilter}
    filterAction={(filter) => filter.name !== currentFilter?.name && setFilter(setState, filter as FilterDefinition)}
  />
)

const renderFirstSection = ({
  rootClass,
  setState,
  clearSearch,
  currentFilter,
  exclusivelyActOnContacts,
  hasLegacyLists,
  hasUnifiedLists,
  isLegacyForms,
  isLegacyWebinars,
  hideAllUCLListTypes,
  phase3FormSubmission,
  listType,
  t,
  isRestrictedToLegacyLists,
}: RenderFirstSectionParams) => {
  return {
    content: (
      <section className={`${rootClass}__menu-first-section`}>
        {!isRestrictedToLegacyLists &&
          renderSelect({
            rootClass,
            setState,
            clearSearch,
            exclusivelyActOnContacts,
            hasLegacyLists,
            hasUnifiedLists,
            isLegacyForms,
            isLegacyWebinars,
            hideAllUCLListTypes,
            phase3FormSubmission,
            listType,
            t,
          })}
        {renderFilters({ rootClass, setState, listType, currentFilter, isRestrictedToLegacyLists })}
      </section>
    ),
  }
}

const renderFoldersSection = ({
  rootClass,
  setState,
  currentFolder,
  folders,
  foldersCount,
  foldersLoading,
  listType,
  hideLegacyListToggle,
  t,
}: RenderFoldersSectionParams) => {
  return {
    header: (() => {
      if (hideLegacyListToggle) {
        if (foldersCount) {
          return t(`Folders ({{count}})`, { count: foldersCount })
        } else {
          return t('Folders')
        }
      } else if (listType === ListCategory.FORM_SUBMISSIONS_UCL) {
        if (foldersCount) {
          return t(`All Contacts Folders ({{count}})`, { count: foldersCount })
        } else {
          return t('All Contacts Folders')
        }
      } else if (listType === ListCategory.FORM_SUBMISSIONS) {
        if (foldersCount) {
          return t(`Legacy Folders ({{count}})`, { count: foldersCount })
        } else {
          return t('Legacy Folders')
        }
      } else {
        return t('Folders')
      }
    })(),
    content: foldersLoading ? (
      <Loader className={`${rootClass}__loader`} />
    ) : folders?.length > 0 ? (
      <SortableFolders
        className={classNames(`${rootClass}__menu-folders`, `${rootClass}__menu-folders${listType !== ListCategory.UNIFIED_LIST ? '-non' : ''}-ucl`)}
        dataTest={`${rootClass}-folders`}
        activeFolder={currentFolder?.id}
        folders={folders as FolderData[]}
        onClick={(folderId) => setFolder(setState, getFolderById(folderId, folders) as Folder)}
      />
    ) : (
      <EmptyListing
        imgSrc={StaticImageNames.aocNoFolders}
        text={t('You haven’t created any folders.')}
        size={EmptyListingSize.SMALL}
        textType={TextType.BODY_TEXT_SMALL_LIGHT}
        withoutBorder
      />
    ),
    isOpen: true,
  }
}

const renderTagsSection = ({ rootClass, setState, currentTag, tags, tagsLoading, t }: RenderTagsSectionParams) => {
  return {
    header: tags.length > 0 ? t(`Tags ({{count}})`, { count: tags.length }) : t('Tags'),
    content: tagsLoading ? (
      <Loader className={`${rootClass}__loader`} />
    ) : tags.length > 0 ? (
      <TagsList
        className={`${rootClass}__menu-tags`}
        dataTest={`${rootClass}-tags-list`}
        selectedId={currentTag?.id}
        onClick={(tag) => setTag(setState, tag)}
        tags={tags}
      />
    ) : (
      <EmptyListing
        imgSrc={StaticImageNames.aocNoTags}
        text={t('Keep track of your segments with tags.')}
        size={EmptyListingSize.SMALL}
        textType={TextType.BODY_TEXT_SMALL_LIGHT}
        withoutBorder
      />
    ),
    isOpen: true,
  }
}

export const useMenuItems = ({
  rootClass,
  state,
  setState,
  clearSearch,
  exclusivelyActOnContacts,
  hasLegacyLists,
  hasUnifiedLists,
  isLegacyForms,
  isLegacyWebinars,
  hideAllUCLListTypes,
  isRestrictedToLegacyLists,
  t,
  hideCrmSynced,
  hideContactPreferences,
  hideLegacyListToggle,
  phase3FormSubmission,
}: GetMenuItemsParams) => {
  const {
    listType,
    currentFilter,
    currentFolder,
    currentTag,
    foldersState: { folders, foldersLoading },
    tagsState: { tags, tagsLoading },
  } = state

  const foldersCount = useMemo(() => (listType !== ListCategory.ACCOUNTS ? getFolderCount(folders) : 0), [folders])

  const foldersSection =
    listType !== ListCategory.ACCOUNTS
      ? [
          renderFoldersSection({
            rootClass,
            setState,
            currentFolder: currentFolder as Folder,
            folders,
            foldersCount,
            foldersLoading,
            listType,
            hideLegacyListToggle,
            t,
          }),
        ]
      : []

  return useMemo(
    () => [
      renderFirstSection({
        rootClass,
        setState,
        clearSearch,
        currentFilter,
        exclusivelyActOnContacts,
        hasLegacyLists,
        hasUnifiedLists,
        isLegacyForms,
        isLegacyWebinars,
        hideAllUCLListTypes,
        phase3FormSubmission,
        listType,
        t,
        isRestrictedToLegacyLists,
      }),
      ...(isRestrictedToLegacyLists
        ? []
        : listType === ListCategory.UNIFIED_LIST && (!hideCrmSynced || !hideContactPreferences)
        ? [
            renderMoreFiltersItems(
              (filter) => filter.name !== currentFilter?.name && setFilter(setState, filter),
              rootClass,
              t,
              currentFilter,
              hideCrmSynced,
              hideContactPreferences
            ),
            ...foldersSection,
            renderTagsSection({ rootClass, setState, currentTag: currentTag as LabelDto, tags, tagsLoading, t }),
          ]
        : listType === ListCategory.FORM_SUBMISSIONS_UCL ||
          listType === ListCategory.WEBINAR_UCL ||
          (listType === ListCategory.UNIFIED_LIST && hideCrmSynced && hideContactPreferences)
        ? [...foldersSection, renderTagsSection({ rootClass, setState, currentTag: currentTag as LabelDto, tags, tagsLoading, t })]
        : foldersSection),
    ],
    [listType, currentFilter, currentFolder, currentTag, folders, foldersLoading, tags, tagsLoading, isLegacyForms, t]
  )
}

const setCRMErrorStatus = (crmSegmentsInfo: any, lists: PreProcessedList[]): PreProcessedList[] =>
  lists.map((list) => {
    const { crmStatus, id } = list
    if (!!crmSegmentsInfo[id]) {
      const { syncedSegment, syncWarning } = crmSegmentsInfo[id]
      return {
        ...list,
        crmStatus: syncedSegment && !!syncWarning?.length ? syncWarning[0] : crmStatus || null,
        crmErrorDetails: undefined,
      }
    }
    return {
      ...list,
      crmStatus: crmStatus || null,
      crmErrorDetails: undefined,
    }
  })

const setCRMErrorDetails = (errorMessage: string, lists: PreProcessedList[]): PreProcessedList[] =>
  lists
    .filter(({ parent }) => !parent)
    .map((list) => {
      const { crmStatus, subTypes, parent } = list
      const isCRMSourced = subTypes?.includes(ItemSubType.CRM_SOURCED) && !parent
      return {
        ...list,
        crmStatus: isCRMSourced ? CRM_STATUS.CRM_SYNC_FAILED : crmStatus,
        crmErrorDetails: isCRMSourced ? errorMessage : undefined,
      }
    })

export const getCRMListsInfoUtils = async (lists: PreProcessedList[], getCRMSegmentsInfoRequest: GetCRMSegmentsInfoRequestType) => {
  try {
    const listIdentifiers = lists.filter(({ parent }) => !parent).map(({ id }) => id)
    if (!!listIdentifiers.length) {
      const { data, errors } = await getCRMSegmentsInfoRequest({ segmentIdentifiers: listIdentifiers })
      if (data?.crmSegmentsInfo) {
        return setCRMErrorStatus(data?.crmSegmentsInfo, lists)
      } else if (errors) {
        logNewRelicError(errors[0].message)
        return setCRMErrorDetails(errors[0].message, lists as PreProcessedList[])
      }
    }
    return lists
  } catch (e) {
    logNewRelicError(e)
    return setCRMErrorDetails(typeof e === 'string' ? e : e instanceof Error ? e.message : '', lists as PreProcessedList[])
  }
}

const setOngoingSyncStatus = (syncedSegmentDetails: SyncedSegment[], lists: PreProcessedList[]): PreProcessedList[] =>
  lists.map((list) => {
    const { id, isEditable, subTypes } = list
    const { ongoingSync } = syncedSegmentDetails.find(({ segmentIdentifier }) => segmentIdentifier === id) ?? {}
    return {
      ...list,
      crmStatus: ongoingSync || (!isEditable && subTypes?.includes(ItemSubType.CRM_SOURCED)) ? CRM_STATUS.ON_GOING_SYNC : undefined,
    }
  })

export const setOngoingSyncStatusUtils = async (lists: PreProcessedList[], syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType) => {
  try {
    const segmentIdentifiers = lists.filter(({ parent }) => !parent).map(({ id }) => id)
    if (!!segmentIdentifiers.length) {
      const { data, errors } = await syncedSegmentDetailsRequest({ segmentIdentifiers })
      if (!!data?.syncedSegmentDetails) {
        return setOngoingSyncStatus(data?.syncedSegmentDetails as SyncedSegment[], lists)
      } else if (errors) {
        logNewRelicError(errors[0].message)
        return setCRMErrorDetails(errors[0].message, lists as PreProcessedList[])
      }
    }
    return lists
  } catch (e) {
    logNewRelicError(e)
    return setCRMErrorDetails(typeof e === 'string' ? e : e instanceof Error ? e.message : '', lists as PreProcessedList[])
  }
}

export const getCRMSourcedSegmentDetailsUtils = async (
  segmentIdentifiers: string[],
  syncedSegmentDetailsRequest: SyncedSegmentDetailsRequestType
): Promise<SyncedSegment[] | undefined> => {
  try {
    if (segmentIdentifiers.length > 0) {
      const { data } = await syncedSegmentDetailsRequest({ segmentIdentifiers })
      if (data?.syncedSegmentDetails) {
        return data.syncedSegmentDetails as SyncedSegment[]
      }
    } else {
      return []
    }
  } catch (e) {
    logNewRelicError(e)
  }
}
