import React, { FC, useContext, useState } from 'react'

import { renderLoader } from '@components/Loader/Loader'
import PageContainer from '@components/PageContainer'
import PageHeader from '@components/PageHeader'
import PositionContainer from '@components/PositionContainer/PositionContainer'
import StatusToast from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import { SMSContext } from '@src/pages/sms/context/SMSContext'
import CreditsSummary from '@src/pages/sms/edit/components/CreditsSummary/CreditsSummary'
import MessagePreview from '@src/pages/sms/edit/components/MessagePreview/MessagePreview'
import SendabilityContainer from '@src/pages/sms/edit/components/Sendability/SendabilityContainer'
import InitialMessageEditor from '@src/pages/sms/initialMessage/components/InitialMessageEditor/InitialMessageEditor'
import InitialMessageSending from '@src/pages/sms/initialMessage/components/InitialMessageSending/InitialMessageSending'

import './InitialMessage.css'

interface Props {
  loading?: string
  sending: boolean
}

interface State {
  showError: boolean
}

const defaultState: State = {
  showError: false,
}

const rootClass = 'initial-message'

const InitialMessage: FC<Props> = (props: Props) => {
  const { loading = false, sending } = props
  const context = useContext(SMSContext)
  const [state, setState] = useState<State>(defaultState)
  const { t } = useTranslation()

  const closeError = () => setState({ ...state, showError: false })

  return (
    <PageContainer className={rootClass}>
      {loading && renderLoader()}
      {sending && <InitialMessageSending />}
      {state.showError && (
        <StatusToast
          isSuccess={false}
          message={t('Unable to add the selected recipients. Only contact lists with a mobile number field can be used.')}
          closeStatus={closeError}
        />
      )}
      <PositionContainer>
        <PageHeader primaryText={t('SMS Opt-In Confirmation')} leftContent dataTest={rootClass} />
        <div className={`${rootClass}__main-container`}>
          <div className={`${rootClass}__left-container`}>
            <InitialMessageEditor context={context} />
            <MessagePreview context={context} />
          </div>
          <div className={`${rootClass}__right-container`}>
            <CreditsSummary context={context} />
            <SendabilityContainer context={context} isInitialMessage />
          </div>
        </div>
      </PositionContainer>
    </PageContainer>
  )
}

export default InitialMessage
