import { FieldError, FieldErrors } from 'react-hook-form'

import { InputDefinition } from '@graphql/types/microservice/webhooks-incoming-management-types'

export enum HMACAuthenticationField {
  ALGORITHM = 'Algorithm',
  ENCODING = 'Encoding',
  SIGNATURE_HEADER = 'SignatureHeader',
  SIGNING_SECRET = 'SigningSecret',
}

export enum HMACAuthenticationFieldNames {
  AUTH_ALGORITHM = 'authentication.signature.hashAlgorithm',
  AUTH_ENCODING = 'authentication.signature.keyEncoding',
  AUTH_SIGNATURE_HEADER = 'authentication.signature.headerName',
  AUTH_SIGNING_SECRET = 'authentication.signature.key',
}
interface FieldProps {
  error?: FieldError
  field: string
  isPassword?: boolean
  name: HMACAuthenticationFieldNames
  placeholder: string
}

export const getFieldsRelatedData = (field: HMACAuthenticationField, errors: FieldErrors<InputDefinition>): FieldProps => {
  const fieldRelatedData: { [key in HMACAuthenticationField]: Omit<FieldProps, 'field'> } = {
    [HMACAuthenticationField.ALGORITHM]: {
      error: errors?.authentication?.signature?.hashAlgorithm,
      name: HMACAuthenticationFieldNames.AUTH_ALGORITHM,
      placeholder: 'Select an algorithm',
    },
    [HMACAuthenticationField.ENCODING]: {
      error: errors?.authentication?.signature?.keyEncoding,
      name: HMACAuthenticationFieldNames.AUTH_ENCODING,
      placeholder: 'Select encoding format',
    },
    [HMACAuthenticationField.SIGNATURE_HEADER]: {
      error: errors?.authentication?.signature?.headerName,
      name: HMACAuthenticationFieldNames.AUTH_SIGNATURE_HEADER,
      placeholder: 'X-My-Digital-Signature',
    },
    [HMACAuthenticationField.SIGNING_SECRET]: {
      error: errors?.authentication?.signature?.key,
      isPassword: true,
      name: HMACAuthenticationFieldNames.AUTH_SIGNING_SECRET,
      placeholder: 'Enter a secret key',
    },
  }
  return { ...fieldRelatedData[field], field }
}
