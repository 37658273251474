import React, { FC, useCallback } from 'react'
import { useForm } from 'react-hook-form'

import * as yup from 'yup'

import MessageSelect from '@complex/MessageSelect'
import Button, { ButtonType } from '@components/Button'
import FormGroup from '@components/FormGroup'
import FormRow from '@components/FormRow'
import Input from '@components/Input'
import Label from '@components/Label'
import { ModalBody } from '@components/Modal'
import Radio from '@components/Radio'
import RadioGroup from '@components/RadioGroup'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { TextType, TextWeight } from '@components/Typography/Typography'
import { getUUID, OnComposeAutoResponse, useTranslation } from '@const/globals'
import globals from '@const/globals'
import { Program, ProgramMessage, ProgramSendChoice, ProgramSource } from '@graphql/types/query-types'
import { yupResolver } from '@hookform/resolvers/yup'
import SendEmailStepDetail, {
  SendEmailStepMode,
} from '@src/pages/programs/dashboard/components/ProgramSteps/components/SendEmailStepDetail/SendEmailStepDetail'
import { getDisplayedSendChoices } from '@src/pages/programs/dashboard/components/ProgramSteps/ProgramSteps.utils'
import ConditionalSelect from '@src/pages/programs/edit/components/ProgramFlow/components/ConditionalSelect'
import {
  getIsListSegment,
  getNewSegment,
  getNewSegmentName,
  getProgramMessageName,
  getSegmentAndNotValue,
  getUpdatedProgramMessages,
  getUpdatedProgramSources,
} from '@utils/program/program'
import { ProgramSendStepExt, Step } from '@utils/program/program.constants'

import './editSendStep.css'

const rootClass = 'edit-send-step'

export enum SendMode {
  CONDITIONAL = 'Conditional',
  AB_TEST = 'ABTest',
}

interface SendChoice {
  not?: boolean
  srcId?: string
  msgId?: string
  id: string
}

export interface State {
  step: ProgramSendStepExt
  sendChoices: SendChoice[]
}

interface Props {
  step: Step
  program: Program
  isRunning: boolean
  saveStepAndProgram(step: Step | null, program?: Program): void
  submitId: string
  dataTest?: string
}

const schema = yup.object().shape({
  displayName: yup.string().required('Step Name is required.'),
})

export function removeSendChoice(index: number, sendChoices: SendChoice[], newSendChoice?: SendChoice) {
  if (newSendChoice) {
    return [...sendChoices.slice(0, index), newSendChoice, ...sendChoices.slice(index + 1)]
  } else {
    return [...sendChoices.slice(0, index), ...sendChoices.slice(index + 1)]
  }
}

export function onSubmitHandler(data: any, props: Props, state: State) {
  const newSendChoices: ProgramSendChoice[] = []
  const localSegmentDetails = Array.isArray(props.program.localSegmentDetails) ? [...props.program.localSegmentDetails] : []

  if (state.step.sendMode === SendMode.AB_TEST) {
    const msgIds = Array.isArray(state.sendChoices) ? state.sendChoices : []
    msgIds.forEach((currentMsgId) => {
      newSendChoices.push({
        not: false,
        msgId: data.msgIds[currentMsgId.id],
        srcId: '',
      })
    })
  } else {
    const conditions = Array.isArray(state.sendChoices) ? state.sendChoices : []
    conditions.forEach((currentCondition) => {
      const condition = data.conditions[currentCondition.id]
      const { segmentMode, not } = getSegmentAndNotValue(condition.segmentMode)
      const isListSegment = getIsListSegment(segmentMode)
      let srcId = condition.srcId
      if (!isListSegment && condition.newSegment === 'true') {
        srcId = getNewSegmentName(localSegmentDetails)
        localSegmentDetails.push(getNewSegment(srcId, condition.messageIds, segmentMode))
      }
      newSendChoices.push({
        not,
        srcId,
        msgId: condition.defaultMsgId,
      })
    })
  }

  const newStep = {
    ...state.step,
    displayName: data.displayName,
    defaultMsgId: data.defaultMsgId,
    sendMode: state.step.sendMode,
    abThreshold: data.abThreshold,
    sendChoices: newSendChoices,
  }
  props.saveStepAndProgram(newStep, {
    ...props.program,
    localSegmentDetails,
  })
}

let globalProgram: Program
const EditSendStep: FC<Props> = (props: Props) => {
  const { step: baseStep, program, isRunning, saveStepAndProgram, submitId, dataTest = 'edit-send-step' } = props

  const sendStep = baseStep as ProgramSendStepExt
  const [state, setState] = React.useState<State>({
    step: sendStep,
    sendChoices: sendStep.sendChoices.map((choice) => ({
      ...choice,
      id: getUUID(),
    })),
  })
  const { step, sendChoices } = state
  globalProgram = program

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })
  const { t } = useTranslation()

  const onMessageSave = (response: OnComposeAutoResponse) => {
    const messageIndex = globalProgram.messages.findIndex((message) => message.id === response.msgid)
    if (messageIndex >= 0 && response.title) {
      saveStepAndProgram(null, {
        ...globalProgram,
        messages: [
          ...globalProgram.messages.slice(0, messageIndex),
          {
            ...globalProgram.messages[messageIndex],
            name: getProgramMessageName(response),
          },
          ...globalProgram.messages.slice(messageIndex + 1),
        ],
      })
    }
  }

  React.useEffect(() => {
    globals.listenToComposerResponse('ProgramMessages', onMessageSave)

    return () => {
      globals.removeComposerResponseListener('ProgramMessages')
    }
  }, [])

  const onProgramSourcesUpdate = (sources: ProgramSource[]) => {
    saveStepAndProgram(null, getUpdatedProgramSources(program, sources))
  }

  const onMessageUpdate = (message: ProgramMessage) => {
    saveStepAndProgram(null, getUpdatedProgramMessages(program, [message]))
  }

  const onSendModeChange = useCallback(
    (sendMode: SendMode) => () => {
      setState((state) => {
        const { step } = state
        return {
          ...state,
          step: {
            ...step,
            sendMode: sendMode,
          },
          sendChoices: [
            {
              not: false,
              srcId: '',
              msgId: step.defaultMsgId,
              id: getUUID(),
            },
          ],
        }
      })
    },
    []
  )

  const onSubmit = (data: any) => {
    onSubmitHandler(data, props, state)
  }

  const sendMode = step?.sendChoices?.length > 0 ? step.sendMode : 'NoMode'
  const renderView = () => (
    <>
      <Typography text={step.displayName} weight={TextWeight.MEDIUM} type={TextType.SECTION_HEADER} />
      <SendEmailStepDetail
        programId={program.id}
        sendMode={sendMode as SendEmailStepMode}
        sendChoices={getDisplayedSendChoices(program, step, step.defaultMsgId ?? '')}
        abThreshold={step.abThreshold}
        abWinner={step.abWinner ?? ''}
        stepId={step.stepId}
      />
    </>
  )

  const renderForm = () => (
    <form data-test={dataTest} onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <Input label={t('Step Name')} defaultValue={step.displayName} name="displayName" register={register('displayName')} />
        {errors?.displayName && <span className="error">{t(errors.displayName.message)}</span>}
      </FormRow>
      {sendChoices.length > 0 && (
        <>
          <div className={`${rootClass}__radio-row`}>
            <RadioGroup>
              <Radio
                dataTest={`${dataTest}-mode-conditional`}
                register={register('sendMode')}
                name="sendMode"
                value={SendMode.CONDITIONAL}
                label={t('Conditional')}
                checked={step.sendMode === SendMode.CONDITIONAL}
                onClick={onSendModeChange(SendMode.CONDITIONAL)}
              />
              <Radio
                dataTest={`${dataTest}-mode-ab-test`}
                register={register('sendMode')}
                name="sendMode"
                value={SendMode.AB_TEST}
                label={t('A/B Test')}
                checked={step.sendMode === SendMode.AB_TEST}
                onClick={onSendModeChange(SendMode.AB_TEST)}
              />
            </RadioGroup>
            <Button
              data-test={`${dataTest}-header-delete`}
              buttonType={ButtonType.REMOVE}
              className={`${rootClass}__delete-button`}
              onClick={() => {
                const sendChoicesUpdated = removeSendChoice(0, sendChoices)
                const stepUpdate = sendChoicesUpdated.length === 0 ? { ...step, sendMode: SendMode.CONDITIONAL } : { ...step }
                setState({
                  ...state,
                  step: stepUpdate,
                  sendChoices: sendChoicesUpdated,
                })
              }}
            >
              <Svg name={SvgNames.delete} />
              {t('Delete')}
            </Button>
            {sendChoices.length > 1 && (
              <button
                type="button"
                className={`${rootClass}__move-button`}
                onClick={(e) => {
                  setState({
                    ...state,
                    sendChoices: [...sendChoices.slice(0, 0), sendChoices[0 + 1], sendChoices[0], ...sendChoices.slice(0 + 2)],
                  })
                  e.preventDefault()
                }}
              >
                <Svg name={SvgNames.downArrow} />
              </button>
            )}
          </div>
          {step.sendMode === SendMode.AB_TEST && (
            <FormGroup>
              <div className={`${rootClass}__threshold`}>
                <label>{t('Automatically select the winning message after')}</label>
                <Input defaultValue={step.abThreshold ? String(step.abThreshold) : '100'} register={register('abThreshold')} name="abThreshold" />
                <label>{t('clicks')}.</label>
              </div>
              {sendChoices.map((sendChoice, i) => (
                <div className={`${rootClass}__test-row`} key={sendChoice.id}>
                  <Label>Test {i + 1}</Label>
                  <MessageSelect
                    dataTest={`${dataTest}-message-select-${i}`}
                    register={register}
                    name={`msgIds[${sendChoice.id}]`}
                    value={sendChoice.msgId}
                    onMessageUpdate={(message: ProgramMessage) => {
                      onMessageUpdate(message)
                      setState({
                        ...state,
                        sendChoices: removeSendChoice(i, sendChoices, {
                          ...sendChoice,
                          msgId: message.id,
                        }),
                      })
                    }}
                    programId={program.id}
                    programName={program.name}
                    messages={program.messages}
                    onChange={(event) => {
                      setState({
                        ...state,
                        sendChoices: removeSendChoice(i, sendChoices, {
                          ...sendChoice,
                          msgId: event.target.value,
                        }),
                      })
                    }}
                    programWillOwnMessage
                  />
                  <button
                    type="button"
                    data-test={`${dataTest}-delete-ab-message-${i}`}
                    className={`${rootClass}__delete-icon`}
                    onClick={() => {
                      setState({
                        ...state,
                        sendChoices: removeSendChoice(i, sendChoices),
                      })
                    }}
                  >
                    <Svg name={SvgNames.delete} />
                  </button>
                </div>
              ))}
              <Button
                dataTest={`${dataTest}-add-ab-message`}
                buttonType={ButtonType.FLOAT}
                onClick={() => {
                  setState({
                    ...state,
                    sendChoices: [
                      ...sendChoices,
                      {
                        not: false,
                        srcId: '',
                        msgId: '',
                        id: getUUID(),
                      },
                    ],
                  })
                }}
              >
                <Svg name={SvgNames.plus} type={SvgType.ICON} />
                {t('Add A/B Message')}
              </Button>
            </FormGroup>
          )}
          {step.sendMode === SendMode.CONDITIONAL && (
            <>
              {sendChoices.map((sendChoice, i) => (
                <React.Fragment key={sendChoice.id}>
                  {i !== 0 && (
                    <div className={`${rootClass}__radio-row`}>
                      <Label className={`${rootClass}__label`}>Else</Label>
                      <Button
                        data-test={`${dataTest}-delete-condition-${i}`}
                        buttonType={ButtonType.REMOVE}
                        className={`${rootClass}__delete-button`}
                        onClick={() => {
                          setState({
                            ...state,
                            sendChoices: removeSendChoice(i, sendChoices),
                          })
                        }}
                      >
                        <Svg name={SvgNames.delete} />
                        {t('Delete')}
                      </Button>
                      {i > 0 && (
                        <button
                          type="button"
                          className={`${rootClass}__move-button`}
                          onClick={(e) => {
                            setState({
                              ...state,
                              sendChoices: [...sendChoices.slice(0, i - 1), sendChoices[i], sendChoices[i - 1], ...sendChoices.slice(i + 1)],
                            })
                            e.preventDefault()
                          }}
                        >
                          <Svg name={SvgNames.upArrow} />
                        </button>
                      )}
                      {i < sendChoices.length - 1 && (
                        <button
                          type="button"
                          className={`${rootClass}__move-button`}
                          onClick={(e) => {
                            setState({
                              ...state,
                              sendChoices: [...sendChoices.slice(0, i), sendChoices[i + 1], sendChoices[i], ...sendChoices.slice(i + 2)],
                            })
                            e.preventDefault()
                          }}
                        >
                          <Svg name={SvgNames.downArrow} />
                        </button>
                      )}
                    </div>
                  )}
                  <FormGroup>
                    <ConditionalSelect
                      step={sendChoice}
                      register={register}
                      formReset={reset}
                      formData={getValues}
                      program={program}
                      onProgramSourcesUpdate={onProgramSourcesUpdate}
                      onMessageUpdate={onMessageUpdate}
                      disableAddMessage={true}
                      groupedInputs={{
                        name: 'conditions',
                        index: sendChoice.id,
                      }}
                      position={i}
                    />
                    <MessageSelect
                      dataTest={`${dataTest}-message-select-${i}`}
                      label={t('Send')}
                      register={register}
                      name={`conditions[${sendChoice.id}].defaultMsgId`}
                      value={sendChoice.msgId}
                      noMessageLabel={t('No Message Selected (Do Not Send)')}
                      onMessageUpdate={(message: ProgramMessage) => {
                        onMessageUpdate(message)
                        setState({
                          ...state,
                          sendChoices: removeSendChoice(i, sendChoices, {
                            ...sendChoice,
                            msgId: message.id,
                          }),
                        })
                      }}
                      programId={program.id}
                      programName={program.name}
                      messages={program.messages}
                      programWillOwnMessage
                    />
                  </FormGroup>
                </React.Fragment>
              ))}
              <FormRow>
                <MessageSelect
                  label={t('Otherwise, send')}
                  noMessageLabel={t('No Message Selected (Do Not Send)')}
                  register={register}
                  name="defaultMsgId"
                  value={step.defaultMsgId}
                  onMessageUpdate={onMessageUpdate}
                  programId={program.id}
                  programName={program.name}
                  messages={program.messages}
                  programWillOwnMessage
                />
              </FormRow>
              <FormRow>
                <Button
                  dataTest={`${dataTest}-add-condition-button`}
                  buttonType={ButtonType.FLOAT}
                  onClick={() => {
                    setState({
                      ...state,
                      sendChoices: [
                        ...sendChoices,
                        {
                          not: false,
                          srcId: '',
                          msgId: '',
                          id: getUUID(),
                        },
                      ],
                    })
                  }}
                >
                  <Svg name={SvgNames.plus} type={SvgType.ICON} />
                  {t('Add Conditional Message')}
                </Button>
              </FormRow>
            </>
          )}
        </>
      )}
      {sendChoices.length === 0 && (
        <>
          <FormRow>
            <MessageSelect
              label={t('Select Message')}
              register={register}
              name="defaultMsgId"
              value={step.defaultMsgId}
              onMessageUpdate={onMessageUpdate}
              programId={program.id}
              programName={program.name}
              messages={program.messages}
              programWillOwnMessage
            />
          </FormRow>
          <FormRow>
            <Button
              dataTest={`${dataTest}-switch-to-mode-button`}
              buttonType={ButtonType.FLOAT}
              onClick={() => {
                setState({
                  ...state,
                  sendChoices: [
                    {
                      not: false,
                      srcId: '',
                      msgId: state.step.defaultMsgId,
                      id: getUUID(),
                    },
                  ],
                })
              }}
            >
              <Svg name={SvgNames.plus} type={SvgType.ICON} />
              {t('Add Conditional or A/B Test Message')}
            </Button>
          </FormRow>
        </>
      )}
      <button type="submit" id={submitId} hidden />
    </form>
  )

  return <ModalBody className={rootClass}>{isRunning ? renderView() : renderForm()}</ModalBody>
}

export default EditSendStep
