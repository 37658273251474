import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { SelectV2SingleOption } from '@components/SelectV2/SelectV2.props'
import { WebinarConfig } from '@graphql/types/query-types'
import { detectWebinarType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { getDynamicTimezones, getGmtOffset } from '@utils/timezones'

import { WebinarCustomFields } from '../components/WebinarBlock/WebinarBlock'

dayjs.extend(utc)
dayjs.extend(timezone)

export enum ManageWebinarDetailsModalTabs {
  REGISTRATION = 'REGISTRATION',
  WEBINAR = 'WEBINAR',
}

export interface ManageWebinarDetailsModalForm {
  webinarName: string
  webinarDescription: string
  joinUrl: string
  passcode: string
  webinarId: string
  startDate: string
  endDate: string
  startTime: string
  endTime: string
  timeZone: SelectV2SingleOption
  joinButtonText: string
  calendarDownloadLink?: string
  registrationDescription?: string
  registrationLink?: string
  registrationButtonText: string
  toggleState: ManageWebinarDetailsModalToggleState
  date: string
  time: string
}

export interface ManageWebinarDetailsModalToggleState {
  showJoinLink?: boolean
  showPasscode?: boolean
  showWebinarId?: boolean
  showJoinButton?: boolean
  showCalendarBlock?: boolean
  showPhoneNumbers?: boolean
  showRegistrationButton?: boolean
}

export type ExcludeTypes =
  | 'phoneDetails'
  | 'blockId'
  | 'meetingUrl'
  | 'calendarDownloadLink'
  | 'blockInfoText'
  | 'hasRegistration'
  | 'webinarDurationMinutes'
  | 'webinarStartTime'
  | 'defaultWebinarInfo'
  | 'hasForm'
  | 'usingZoom'
  | 'usingWebex'
  | 'usingCitrix'
  | 'msgType'

type PickTypes = 'joinUrl' | 'passcode' | 'webinarId' | 'calendarDownloadLink' | 'registrationLink' | 'registrationButtonText' | 'joinButtonText'
interface GetToggleFields extends ManageWebinarDetailsModalToggleState, Pick<ManageWebinarDetailsModalForm, PickTypes> {
  hasRegistration: boolean
  fieldShouldBeeHidden: boolean
  phoneDetails?: WebinarCustomFields['phoneDetails']
  webinarConfig?: WebinarConfig
  hasForm: boolean
}
const getToggleFields = ({
  activeTab,
  showCalendarBlock,
  showJoinButton,
  showJoinLink,
  joinButtonText,
  showPasscode,
  showPhoneNumbers,
  showRegistrationButton,
  showWebinarId,
  fieldShouldBeeHidden,
  joinUrl,
  hasRegistration,
  registrationButtonText,
  registrationLink,
  passcode,
  webinarId,
  calendarDownloadLink,
  phoneDetails,
  webinarConfig,
  hasForm,
}: GetToggleFields & {
  activeTab?: ManageWebinarDetailsModalTabs
}) => {
  const { isWebex, isZoom, isSingleSession, isMultiSession } = detectWebinarType(webinarConfig)

  const defaultEnable = isWebex && isMultiSession && !hasForm

  if (activeTab == ManageWebinarDetailsModalTabs.WEBINAR) {
    return {
      showCalendarBlock: showCalendarBlock ?? true,
      showJoinLink,
      showPasscode,
      showWebinarId,
      showJoinButton,
      showPhoneNumbers,
      showRegistrationButton: showRegistrationButton ?? !!(registrationLink || registrationButtonText),
    }
  } else {
    return {
      showJoinLink: showJoinLink ?? (!hasRegistration && !!joinUrl),
      showPasscode: showPasscode ?? (defaultEnable || (!fieldShouldBeeHidden && !!passcode)),
      showWebinarId: showWebinarId ?? (defaultEnable || (!fieldShouldBeeHidden && !!webinarId)),
      showJoinButton: showJoinButton ?? (isMultiSession ? !!(!hasForm && joinButtonText) : !hasRegistration && (showJoinButton || !!joinButtonText)),
      showPhoneNumbers: showPhoneNumbers ?? (!fieldShouldBeeHidden && phoneDetails && !!Object.keys(phoneDetails).length),
      showCalendarBlock: showCalendarBlock ?? !!calendarDownloadLink,
      showRegistrationButton:
        showRegistrationButton ?? (!!(registrationLink || registrationButtonText) || !!((isWebex || isZoom) && isSingleSession)),
    }
  }
}

export const getWebinarDefaultValues = (
  defaultValues: WebinarCustomFields,
  fieldShouldBeeHidden: boolean,
  accountTimeZoneId?: string,
  webinarConfig?: WebinarConfig
): Omit<WebinarCustomFields, ExcludeTypes> => {
  const {
    webinarName,
    webinarDescription,
    startDate,
    endDate,
    startTime,
    endTime,
    joinUrl,
    passcode,
    webinarId,
    joinButtonText,
    calendarDownloadLink,
    registrationButtonText,
    registrationDescription,
    activeTab,
    registrationLink,
    meetingUrl,
    toggleState,
    hasRegistration,
    hasForm,
    date,
    time,
  } = defaultValues
  const { showJoinLink, showPasscode, showWebinarId, showJoinButton, showCalendarBlock, showPhoneNumbers, showRegistrationButton } = toggleState ?? {}

  const timeZoneOptions = getDynamicTimezones().map((option) => ({ label: option.label, value: option.value }))

  if (!timeZoneOptions.find((timeZone) => timeZone.value === defaultValues.timeZone.value)) {
    timeZoneOptions.push({
      label: `${getGmtOffset(defaultValues.timeZone.value)} ${defaultValues.timeZone.value}`,
      value: defaultValues.timeZone.value,
    })
  }

  const defaultTimeZoneOption =
    timeZoneOptions.find(({ value }) => value === defaultValues.timeZone.value) ?? timeZoneOptions.find(({ value }) => value === accountTimeZoneId)

  const computedToggleState = getToggleFields({
    activeTab,
    showCalendarBlock,
    showJoinButton: joinButtonText ? showJoinButton : undefined,
    showJoinLink,
    showPasscode,
    showPhoneNumbers,
    showRegistrationButton: registrationButtonText ? showRegistrationButton : undefined,
    showWebinarId,
    fieldShouldBeeHidden,
    hasRegistration,
    joinButtonText,
    joinUrl,
    passcode,
    registrationButtonText,
    webinarId,
    calendarDownloadLink,
    registrationLink,
    phoneDetails: defaultValues.phoneDetails,
    webinarConfig,
    hasForm,
  })

  return {
    webinarName,
    webinarDescription,
    startDate,
    endDate,
    startTime,
    endTime,
    timeZone: defaultTimeZoneOption!,
    joinUrl: joinUrl || meetingUrl,
    passcode,
    webinarId,
    joinButtonText,
    registrationButtonText,
    registrationDescription,
    registrationLink,
    activeTab,
    toggleState: computedToggleState,
    date,
    time,
  }
}

export const convertToTimezone = (defaultDate: string, startTime: string, endTime: string, defaultTimezone: string, targetTimezone: string) => {
  const dateWithTimezone = dayjs.tz(defaultDate, defaultTimezone)

  const startDateTime = dayjs.tz(`${dateWithTimezone.format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD hh:mm A', defaultTimezone)
  const endDateTime = dayjs.tz(`${dateWithTimezone.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD hh:mm A', defaultTimezone)

  const startTimeInTargetTimezone = startDateTime.tz(targetTimezone).format('hh:mm A')
  const endTimeInTargetTimezone = endDateTime.tz(targetTimezone).format('hh:mm A')
  const dateInTargetTimezone = dateWithTimezone.tz(targetTimezone).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

  return {
    date: dateInTargetTimezone,
    startTime: startTimeInTargetTimezone,
    endTime: endTimeInTargetTimezone,
  }
}
