import React, { FC, useContext, useEffect, useState } from 'react'

import StatusToast from '@components/StatusToast/StatusToast'
import { useTranslation } from '@const/globals'
import syncNow from '@graphql/microservices/crm/syncNow'
import { SyncNowQuery, SyncNowQueryVariables } from '@graphql/types/microservice/crm-types'
import { DataManagementContext, getSyncStatusReadable, RunningSyncStatus } from '@src/pages/datamanagement/context/DataManagementContext'
import { syncRunningStatuses } from '@src/pages/datamanagement/utils/DataManagement.constants'
import useMicroserviceClient, { MicroserviceClients } from '@utils/hooks/useMicroserviceClient'
import useQueryOnMount from '@utils/hooks/useQueryOnMount'

type Props = {
  className?: string
  dataTest?: string
  val?: string
}

const rootClass = 'run-sync'

const RunSync: FC<Props> = (props: Props) => {
  const { dataTest = rootClass, className = '' } = props
  const { t } = useTranslation()

  const { update, values } = useContext(DataManagementContext)

  const { client } = useMicroserviceClient({ serviceName: MicroserviceClients.CRM })
  const [showError, setShowError] = useState(false)
  const { loading, error, data } = useQueryOnMount<SyncNowQuery, SyncNowQueryVariables>(syncNow, {
    client,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!loading && data && data.syncNow) {
      const runningSyncStatus: RunningSyncStatus = {
        isSyncingNow: false,
        syncState: '',
        isCanceling: false,
      }

      if (syncRunningStatuses.includes(data.syncNow)) {
        runningSyncStatus.isSyncingNow = true
        runningSyncStatus.syncState = t(getSyncStatusReadable(data.syncNow.toString()), {
          crm: values.connectorType,
        })
      } else {
        runningSyncStatus.isSyncingNow = false
        runningSyncStatus.syncState = ''
      }
      update('runningSyncStatus', runningSyncStatus)
    }
    if (error) {
      setShowError(true)
    }
  }, [data, error])

  return (
    <>
      {showError && <StatusToast isSuccess={false} message={t('Error running Sync')} closeStatus={() => setShowError(false)} />}
      <div className={className} data-test={`${dataTest}`} />
    </>
  )
}

export default RunSync
