import { gql } from '@apollo/client'

export default gql`
  mutation upsertSubscriptionManagementVersion($dto: SubscriptionManagementDTOInput) {
    upsertSubscriptionManagementVersion(dto: $dto) {
      name
      description
      language
      isEnabled
      needsChanges
      isDefault
      createdTime
      errorMessage
    }
  }
`
