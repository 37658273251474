import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { SendMethod } from '@graphql/types/mutation-types'
import { detectEmailType, findSender, findSenderOption, getComputedMessageType } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { ModifiedMutationSaveEmailArgs } from '@utils/composer/commonComposer/types'
import { DEFAULT_EMAIL_TITLE, SendTimeType } from '@utils/composer/context/EmailComposer.context'
import { ExtendedMessageType } from '@utils/composer/emailComposer/types'
import {
  NOT_PERSONALIZED_FROM_ADDRESS_FALLBACK_SENDER_VALUE,
  PERSONALIZED_FROM_ADDRESS_OPTION_VALUE,
  SALESFORCE_OWNER_OPTION_VALUE,
  UNSPECIFIED_SENDER_OPTION_VALUE,
} from '@utils/composer/settings.constants'

import { DATE_FORMAT } from './BeeEditor.constants'
import { SaveEmailHelperProps } from './EmailComposer.types'
import { addBlockIdsIntoJson, getHourAndMinuteFromTime, getTimeStampForTimezone } from './EmailComposer.utils'
import { AnalyticsVariables } from '../components/EmailComposerSettings/components/EmailComposerSettingsSidebar/components/AnalyticsTrackingPane/components/AnalyticsVariablesModal/AnalyticsVariables.constants'
import { htmlToUploadHtmlJson } from '../components/UploadHtmlComposer/utils/UploadHtmlComposer.utils'

dayjs.extend(utc)
dayjs.extend(timezone)

// According to old Composer logic, when logged-in User selected as sender, values should be ''
export const loggedInUserFieldsToSave = { name: '', uuid: '', email: '' }

// According to old Composer logic, when no sender selected, values are 'Unspecified'
export const unspecifiedUserFieldsToSave = {
  name: UNSPECIFIED_SENDER_OPTION_VALUE,
  uuid: '',
  email: UNSPECIFIED_SENDER_OPTION_VALUE,
}

export const saveEmailHelper = (
  { containerValues, autoSave, userId, timeZoneId, saveContent, isTemplateChanged }: SaveEmailHelperProps,
  isFirstEdit?: boolean,
  isUploadHtml?: boolean,
  isUploadHtmlFirstEdit?: boolean
): ModifiedMutationSaveEmailArgs => {
  const {
    message: {
      id,
      publishId,
      title,
      sender,
      senderUUID,
      subject,
      campaignId,
      sendMethod,
      messageType,
      previewText,
      isTriggered,
      sendto_lists,
      sendto_contacts,
      replyToSender,
      fallbackSender,
      suppress_dups,
      isCrmSentNote,
      templateHtml,
      templateJson,
      suppress_lists,
      analyticsFields,
      isTransactional,
      overrideFatigueRules,
      subscriptionCategoryId,
      hasPrePopulatedUTMParams,
      individualRecipientsSourceIds,
      customTextPart,
      isPlainTextOnly,
      assetReportRecipientDetails,
    },
    subscriptionCategories,
    campaigns,
    emailSenders,
  } = containerValues

  const {
    sendtime,
    sendtimezone,
    senddate,
    sendtype,
    staggerdateend,
    staggerdatestart,
    staggertimestart,
    staggertimeend,
    staggertimezone,
    adaptivedatestart,
    adaptivedateend,
    defaultsendtimezone,
  } = sendMethod ?? {}

  const { isEmailDraft, isEmailCRM, isEmailRSS, isEmailWebinar, isEmailResend, isEmailBlankMessage, isOptInEmail } = detectEmailType(messageType)

  const isLoggedInUser = (sender?: string) => sender === userId

  const senderFields = isLoggedInUser(senderUUID)
    ? loggedInUserFieldsToSave
    : [SALESFORCE_OWNER_OPTION_VALUE, PERSONALIZED_FROM_ADDRESS_OPTION_VALUE].includes(senderUUID ?? '')
    ? { name: sender, uuid: senderUUID, email: senderUUID }
    : findSender(senderUUID ?? '', emailSenders) ?? unspecifiedUserFieldsToSave

  // According to old Composer logic, when sender is not 'Personalized from address', fallbackSender values should be 'none'
  const fallbackSenderFields =
    (senderUUID !== PERSONALIZED_FROM_ADDRESS_OPTION_VALUE
      ? {
          name: NOT_PERSONALIZED_FROM_ADDRESS_FALLBACK_SENDER_VALUE,
          uuid: NOT_PERSONALIZED_FROM_ADDRESS_FALLBACK_SENDER_VALUE,
          email: NOT_PERSONALIZED_FROM_ADDRESS_FALLBACK_SENDER_VALUE,
        }
      : isLoggedInUser(fallbackSender)
      ? loggedInUserFieldsToSave
      : fallbackSender
      ? findSender(fallbackSender, emailSenders)
      : undefined) ?? unspecifiedUserFieldsToSave

  const replyToFields = findSenderOption(emailSenders, replyToSender)

  const analyticsVariableValues: { [key: string]: string } = {
    [AnalyticsVariables.INTERNAL_EMAIL_TITLE]: title,
    [AnalyticsVariables.SUBJECT_LINE]: subject,
    [AnalyticsVariables.SUBSCRIPTION_CATEGORY]: subscriptionCategories.find((category) => category.id === subscriptionCategoryId)?.name || '',
    [AnalyticsVariables.ACT_ON_CAMPAIGN]: campaigns.find((campaign) => campaign.id === campaignId)?.name || '',
  }

  const analyticsTrackingListWithPersonalization = analyticsFields.map(({ key, value }) => ({ parameter: key, value: value }))

  const analyticsTrackingList = analyticsTrackingListWithPersonalization.map((item) => {
    let value = item.value
    Object.keys(analyticsVariableValues).forEach((analyticsVariableValue: string) => {
      if (value?.includes(analyticsVariableValue)) {
        value = value.replace(analyticsVariableValue, analyticsVariableValues[analyticsVariableValue])
      }
    })

    return { ...item, value }
  })

  let sendMethodRestFields: SendMethod = {}

  switch (sendtype) {
    case SendTimeType.STANDARD:
      sendMethodRestFields = {
        launchType: 'STANDARD',
        scheduleTimeZone: '',
        scheduleTimestamp: 0,
      }
      break

    case SendTimeType.LATER:
      const { hour, minute } = getHourAndMinuteFromTime(sendtime)

      // If hour is 12, handle it differently based on AM/PM.
      if (hour === 12) {
        senddate?.setHours(12) // 12 PM is 12:00
      } else if (hour === 0) {
        senddate?.setHours(0) // 12 AM is 00:00
      } else if (hour !== undefined) {
        senddate?.setHours(hour) // For hours 1-11, set directly
      }

      if (minute !== undefined) {
        senddate?.setMinutes(minute)
      }

      senddate?.setSeconds(0)
      const constTimestampForTimezone = senddate && getTimeStampForTimezone(senddate, sendtimezone)
      sendMethodRestFields = {
        launchType: 'STANDARD',
        scheduleTimestamp: constTimestampForTimezone,
        scheduleTimeZone: sendtimezone,
        sendingPeriodStartHour: hour,
        sendingPeriodStartMinute: minute,
      }
      break

    case SendTimeType.STAGGERED:
      const { hour: sendingPeriodStartHour, minute: sendingPeriodStartMinute } = getHourAndMinuteFromTime(staggertimestart)
      const { hour: sendingPeriodEndHour, minute: sendingPeriodEndMinute } = getHourAndMinuteFromTime(staggertimeend)

      const staggeredLaunchStart = dayjs(staggerdatestart)
        .hour(sendingPeriodStartHour ?? 0)
        .minute(sendingPeriodStartMinute ?? 0)
        .tz(staggertimezone, true)
        .valueOf()

      const staggeredLaunchEnd = dayjs(staggerdateend)
        .hour(sendingPeriodEndHour ?? 0)
        .minute(sendingPeriodEndMinute ?? 0)
        .tz(staggertimezone, true)
        .valueOf()

      sendMethodRestFields = {
        launchType: 'STAGGERED',
        staggeredLaunchStart,
        staggeredLaunchEnd,
        sendingPeriodStartHour,
        sendingPeriodStartMinute,
        sendingPeriodEndHour,
        sendingPeriodEndMinute,
        scheduleTimeZone: staggertimezone,
      }
      break
    case SendTimeType.TIME_ZONE:
      const { hour: _hour, minute: _minute } = getHourAndMinuteFromTime(sendtime)
      sendMethodRestFields = {
        launchType: 'TIME_ZONE',
        scheduleTimestamp: senddate?.valueOf(),
        scheduleDate: dayjs(senddate).format(DATE_FORMAT).toString(),
        sendingPeriodStartHour: _hour,
        sendingPeriodStartMinute: _minute,
        sendingPeriodAmPm: sendtime?.slice(-2),
        defaultTimeZone: defaultsendtimezone,
      }
      break
    case SendTimeType.ADAPTIVE:
      sendMethodRestFields = {
        launchType: 'ADAPTIVE',
        adaptiveLaunchStart: adaptivedatestart?.tz(timeZoneId, true).valueOf(),
        adaptiveLaunchEnd: adaptivedateend?.tz(timeZoneId, true).valueOf(),
      }
      break

    default:
      sendMethodRestFields = {}
      break
  }

  const sendToContacts = sendto_contacts?.map((contact) => contact.id)
  const sendDetailsShouldBeSent = isEmailDraft || isEmailWebinar || isEmailRSS || isEmailCRM || isEmailResend || isOptInEmail
  const sendDetails = sendDetailsShouldBeSent
    ? {
        overrideFatigueRules,
        sendToLists: sendto_lists,
        suppressLists: suppress_lists,
        suppressDuplicates: isEmailCRM || suppress_dups,
        sendToContacts,
        individualRecipientsSourceIds,
        assetReportRecipientDetails,
        sendMethod: {
          isCreateCRMMsgSentNotes: isCrmSentNote,
          ...sendMethodRestFields,
        },
      }
    : undefined

  //* For Blank Messages We use TEMPLATE msg type but in FE we set configs as BLANK_MESSAGE
  const computedMsgType: ExtendedMessageType = getComputedMessageType(messageType, isEmailBlankMessage)

  return {
    autoSave,
    messageContent: {
      messageType: computedMsgType,
      sendDetails,
      messageId: isFirstEdit ? publishId : id,
      beeEditorPage:
        isUploadHtml && isUploadHtmlFirstEdit
          ? JSON.stringify(htmlToUploadHtmlJson(templateHtml))
          : saveContent
          ? JSON.stringify(addBlockIdsIntoJson(templateJson))
          : undefined,
      emailContentHtml: saveContent ? templateHtml : undefined,
      isTemplateChanged,
      settings: {
        title: title || DEFAULT_EMAIL_TITLE,
        subject,
        preview: previewText,
        analyticsTrackingList,
        analyticsTrackingListWithPersonalization,
        subscriptionCategoryId,
        hasPrePopulatedUTMParams,
        actOnCampaignId: campaignId,
        replyToName: replyToFields?.label ?? '',
        replyToEmail: replyToFields?.subText ?? '',
        isTransactionalMessage: isTransactional,
        isTemplateAvailableInEBE: isTriggered,
        senderName: senderFields.name,
        senderEmail: senderFields.email,
        senderUUID: senderFields.uuid,
        fallbackSenderName: fallbackSenderFields.name,
        fallbackSenderEmail: fallbackSenderFields.email,
        fallbackSenderUUID: fallbackSenderFields.uuid,
        replyToUUID: replyToSender,
        customTextPart,
        isPlainTextOnly,
      },
    },
  }
}
