import React, { useContext, useEffect, useMemo } from 'react'

import InfoStatus, { InfoStatusTypes } from '@components/InfoStatus/InfoStatus'
import Spinner from '@components/Spinner/Spinner'
import { SvgNames } from '@components/Svg'
import Toggle from '@components/Toggle'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { processIframePreviewHtml } from '@src/pages/EmailComposer/components/EmailComposerPreview/EmailComposerPreview.utils'
import { splitDynamicPlainTextParts } from '@src/pages/EmailComposer/utils/DisplayConditions.utils'
import { detectEmailType, findSender } from '@src/pages/EmailComposer/utils/EmailComposerDetector.utils'
import { useAccountSettings } from '@utils/account/account.utils'
import { EmailComposerContext, PreviewContact } from '@utils/composer/context/EmailComposer.context'
import { useEmailComposerRequests } from '@utils/composer/emailComposer/GraphQL/EmailComposerRequests.graphQL'
import { PERSONALIZED_FROM_ADDRESS_UNSPECIFIED_VALUE } from '@utils/composer/settings.constants'
import { useTranslation } from '@utils/const/globals'
import { logNewRelicError } from '@utils/new-relic.utils'

import { DynamicContentList } from './components/DynamicContentList/DynamicContentList'
import RecipientsCard from './components/RecipientsCard/RecipientsCard'

import './EmailComposerPreviewDetails.css'

const rootClass = 'email-composer-preview-details'

const EmailComposerPreviewDetails = () => {
  const {
    values: {
      emailSenders,
      message,
      message: {
        customTextPart,
        sender,
        sendto_contacts,
        replyToSender,
        subject,
        previewText,
        templateHtml,
        sendto_lists,
        messageType,
        isPlainTextOnly,
      },
      preview: {
        isPreviewPersonalizationOn,
        isPreviewDynamicContentOn,
        selectedContact,
        formattedSubject,
        formattedPreviewText,
        loadingPersonalization,
        contactsList,
        personalizedSender,
        dynamicContentList,
      },
      messageConfiguration: {
        preview: { hidePreviewPersonalization, hideToRecipients },
      },
      disabledFeatures,
      detectedURLChanges: { uploadHtmlMode },
    },
    api: { updatePreview },
  } = useContext(EmailComposerContext)
  const { t } = useTranslation()
  const { getListContactsRequest } = useEmailComposerRequests()
  const { userEmail, userId, userName } = useAccountSettings()

  const { isEmailTemplate } = detectEmailType(messageType)

  const { senderEmail: personalizedSenderEmail, senderName: personalizedSenderName } = personalizedSender ?? {}

  const loadContacts = async () => {
    updatePreview({ loadingPersonalization: true })
    const listId = (sendto_lists ?? [])[0]?.srcId
    if (listId && !sendto_contacts?.length) {
      const { data, errors } = await getListContactsRequest({ listId, pageSize: 100, pageNumber: 0, limit: 20 })
      if (errors) {
        logNewRelicError(errors, 'Fetching preview contacts list')
      } else if (data) {
        const contactsList = data.getListContacts
          .filter((contact) => contact?.id)
          .map((contact, index) => ({ ...contact, index })) as PreviewContact[]
        updatePreview({ contactsList, selectedContact: contactsList[0] })
      }
    } else if (sendto_contacts) {
      const contactsList = sendto_contacts
        .filter((contact) => contact?.id)
        .map((contact, index) => ({ ...contact, id: contact.id, index })) as PreviewContact[]
      updatePreview({ contactsList, selectedContact: contactsList[0] })
    }
  }

  const replyTo = replyToSender ? findSender(replyToSender, emailSenders) : undefined

  const renderEmailAndName = (email?: string, name?: string) => (
    <div>
      <Typography text={name} weight={TextWeight.MEDIUM} dataTest={`${rootClass}-name-typography`} />
      <Typography text={email} dataTest={`${rootClass}-email-typography`} />
    </div>
  )

  const fromDetail = sender === userId ? { email: userEmail, name: userName } : sender ? findSender(sender, emailSenders) : undefined

  const fromDetailValue = fromDetail ? renderEmailAndName(fromDetail.email, fromDetail.name) : t(`EmailComposer.Preview.Details.From`)

  const personalizedFrom =
    isPreviewPersonalizationOn && personalizedSenderEmail !== PERSONALIZED_FROM_ADDRESS_UNSPECIFIED_VALUE
      ? renderEmailAndName(personalizedSenderEmail, personalizedSenderName)
      : fromDetailValue

  const toDetail = useMemo(() => {
    return {
      label: 'TO',
      value: selectedContact ? renderEmailAndName(selectedContact.email, selectedContact.name) : t(`EmailComposer.Preview.Details.To`),
      empty: !selectedContact,
    }
  }, [selectedContact, t])

  const details = [
    ...(hideToRecipients ? [] : [toDetail]),
    {
      label: 'FROM',
      value: !hidePreviewPersonalization ? personalizedFrom : fromDetailValue,
      empty: !fromDetail,
    },
    { label: 'REPLY-TO', value: replyTo?.email || t(`EmailComposer.Preview.Details.Reply`), empty: !replyTo },
    {
      label: 'SUBJECT',
      value: isPreviewPersonalizationOn ? formattedSubject : subject || t('EmailComposer.Preview.Details.Subject'),
      empty: !subject && !formattedSubject,
    },
    ...(isPlainTextOnly || uploadHtmlMode
      ? []
      : [
          {
            label: 'PREVIEW TEXT',
            value: isPreviewPersonalizationOn ? formattedPreviewText : previewText || t(`EmailComposer.Preview.Details.Text`),
            empty: !previewText && !formattedPreviewText,
          },
        ]),
  ]

  const renderEditPlainTextCaution = () => {
    return (
      !disabledFeatures.editPlainText &&
      !!customTextPart && (
        <div className={`${rootClass}__banner-row`}>
          <InfoStatus
            svgName={SvgNames.cautionYellow}
            statusText={t('EmailComposer.Preview.Plain.Text.Edit.Caution')}
            status={InfoStatusTypes.Caution}
            dataTest={`${rootClass}-edit-plain-text-caution`}
          />
        </div>
      )
    )
  }

  const handleChange = (index: number) => {
    if (contactsList) {
      const newSelectedContact = contactsList[index]
      newSelectedContact && updatePreview({ selectedContact: newSelectedContact })
    }
  }

  const handleDynamicContentClick = ({ uuid, open }: { uuid: string; open: boolean }) => {
    updatePreview({ dynamicContentList: dynamicContentList.map((item) => (item.uuid === uuid ? { ...item, isActive: open } : item)) })
  }

  useEffect(() => {
    if (isPreviewPersonalizationOn) {
      loadContacts()
    } else {
      updatePreview({ selectedContact: null, html: processIframePreviewHtml(templateHtml) })
    }
  }, [isPreviewPersonalizationOn])

  const isPersonalizationToggleDisabled = (!sendto_lists?.length && !sendto_contacts?.length) || isPreviewDynamicContentOn
  const isDynamicContentToggleDisabled = !dynamicContentList.length || isPreviewPersonalizationOn

  return (
    <div className={rootClass} data-test={rootClass}>
      {!hidePreviewPersonalization && (
        <div className={`${rootClass}__preview`}>
          <div className={`${rootClass}__preview-toggle`}>
            <Typography text={t('EmailComposer.Preview.Details.Personalization.Toggle')} type={TextType.DATA_CARD_MODAL_HEADER} />
            <Toggle
              large
              isOn={isPreviewPersonalizationOn}
              onToggle={(isOn) => updatePreview({ isPreviewPersonalizationOn: isOn })}
              disabled={isPersonalizationToggleDisabled}
              tooltipText={
                isPreviewDynamicContentOn && (sendto_lists?.length || sendto_contacts?.length)
                  ? t('EmailComposer.Preview.Details.Personalization.Toggle.Tooltip')
                  : isPersonalizationToggleDisabled
                  ? t('EmailComposer.Preview.Details.Personalization.Toggle.MissingRecipients.Tooltip')
                  : ''
              }
              tooltipPosition="bottom"
              tooltipSideOffset={12}
              dataTest={`${rootClass}-preview-toggle`}
            />
          </div>
          {isPreviewPersonalizationOn && (
            <RecipientsCard list={contactsList || []} selectedContact={selectedContact} onChange={handleChange} loading={loadingPersonalization} />
          )}
        </div>
      )}
      {!!dynamicContentList.length && (
        <div className={`${rootClass}__preview`}>
          <div className={`${rootClass}__preview-toggle`}>
            <Typography text={t('EmailComposer.Preview.Details.Dynamic.Content.Toggle')} type={TextType.DATA_CARD_MODAL_HEADER} />
            <Toggle
              large
              isOn={isPreviewDynamicContentOn}
              onToggle={(isOn) => updatePreview({ isPreviewDynamicContentOn: isOn })}
              disabled={isDynamicContentToggleDisabled}
              tooltipText={
                isPreviewPersonalizationOn && dynamicContentList.length && t('EmailComposer.Preview.Details.Dynamic.Content.Toggle.Tooltip')
              }
              tooltipPosition="bottom"
              tooltipSideOffset={12}
              dataTest={`${rootClass}-dynamic-content-toggle`}
            />
          </div>
          {isPreviewDynamicContentOn && (
            <DynamicContentList
              html={templateHtml}
              plainTextParts={
                isPlainTextOnly
                  ? splitDynamicPlainTextParts(message, templateHtml, dynamicContentList)
                      .filter((part) => !!part.text)
                      .map((part) => part.text)
                  : undefined
              }
              list={dynamicContentList}
              onClick={handleDynamicContentClick}
            />
          )}
        </div>
      )}
      {loadingPersonalization ? (
        <Spinner className={`${rootClass}__loading`} />
      ) : (
        <div className={`${rootClass}__list`}>
          {renderEditPlainTextCaution()}
          {details.map((item) => {
            if (isEmailTemplate && item.label == 'TO') return

            const isValueElement = typeof item.value === 'string'
            return (
              <div key={`${item.label}`} className={`${rootClass}__list-item`}>
                <Typography
                  text={`${item.label}:`}
                  type={TextType.BODY_TEXT_SMALL_LIGHT}
                  lineHeight={LineHeight.MEDIUM_SMALL}
                  weight={TextWeight.BOLD}
                  dataTest={`${rootClass}-item-typography`}
                />
                {isValueElement ? (
                  <Typography
                    text={item.value}
                    type={TextType.BODY_TEXT_LIGHT}
                    weight={item.empty ? TextWeight.ITALIC : undefined}
                    dataTest={`${rootClass}-value-typography`}
                  />
                ) : (
                  item.value
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default EmailComposerPreviewDetails
