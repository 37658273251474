import React, { FC, useState } from 'react'

import { usePersistedRowState } from '@components/TableV2/hooks/usePersistedRowState'
import { ListContactGq } from '@graphql/types/query-types'
import { TestListEmailsRefType } from '@src/pages/EmailComposer/EmailModals/components/SendTestEmailModal/utils/SendTestEmailModal.utils'

import TestListRecipientsModal from './TestListRecipientsModal'
import TestListSegmentsModal from './TestListSegmentsModal'
import { TestSegment } from '../../graphQL/SendTestEmailModal.graphQL'

export interface TestListModalContainerProps {
  onFinish: VoidFunction
  defaultActiveSegment?: TestSegment
  segments: TestSegment[]
  selectedEmailsRef: React.MutableRefObject<TestListEmailsRefType>
  loading: boolean
  onRemoveUserEmail: VoidFunction
  userEmail?: string
  hasUserEmailDefault: boolean
  dataTest?: string
}

const TestListModalContainer: FC<TestListModalContainerProps> = (props) => {
  const { onFinish, onRemoveUserEmail, userEmail, selectedEmailsRef, hasUserEmailDefault } = props
  const [activeSegment, setActiveSegment] = useState<TestSegment | undefined>(props.defaultActiveSegment)

  const contactSelection = usePersistedRowState<ListContactGq>({ groupId: activeSegment?.id })
  const segmentSelection = usePersistedRowState<TestSegment>({ isSingleSelect: true })

  const handleFinish = (isCancel = false) => {
    if (isCancel || !activeSegment?.contacts.length) {
      onFinish()
      return
    }
    const newUserEmailIdInSegment = activeSegment.contacts.find(({ email, id }) => {
      return email === userEmail && !(id in selectedEmailsRef.current)
    })?.id
    const newEmails: TestListEmailsRefType = {}
    let userEmailSelected = false

    const addUniqueContactToSelection = (contactId: string) => {
      const email = activeSegment.contacts.find((contact) => contact.id === contactId)?.email
      if (email) {
        const isDuplicate = Object.values(newEmails).includes(email)
        const isDuplicateUserEmail = hasUserEmailDefault && email === userEmail
        if (!isDuplicate && !isDuplicateUserEmail) {
          newEmails[contactId] = email
        }
      }
    }
    contactSelection.idSelectedState.forEach((contactId) => {
      if (contactId !== newUserEmailIdInSegment) {
        addUniqueContactToSelection(contactId)
      } else {
        userEmailSelected = true
      }
    })
    if (newUserEmailIdInSegment && !userEmailSelected) {
      onRemoveUserEmail()
    }
    selectedEmailsRef.current = newEmails

    onFinish()
  }

  return activeSegment ? (
    <TestListRecipientsModal
      {...props}
      activeSegment={activeSegment}
      selectedIds={contactSelection.idSelectedState}
      tableProps={contactSelection.tableProps}
      onBack={() => setActiveSegment(undefined)}
      onFinish={handleFinish}
    />
  ) : (
    <TestListSegmentsModal
      {...props}
      defaultSegmentId={segmentSelection.idSelectedState[0] ?? props.defaultActiveSegment?.id}
      tableProps={segmentSelection.tableProps}
      onNext={(segment: TestSegment) => setActiveSegment(segment)}
    />
  )
}

export default TestListModalContainer
