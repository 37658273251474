import { gql } from '@apollo/client'

export default gql`
  query pushPolicy($listId: String) {
    pushPolicy(listId: $listId) {
      allowPush
      enabledFields
      listId
    }
  }
`
