import { Program, ProgramStep } from '@graphql/types/query-types'
import { timeZoneNames } from '@utils/program/ProgramSteps.constants'

export const DefaultProgramManagerProgram: Program = {
  isTemp: true,
  id: '',
  name: '',
  description: '',
  lastUpdated: new Date('12/01/2021').valueOf(),
  startedOn: new Date('12/01/2021').valueOf(),
  createdOn: new Date('12/01/2021').valueOf(),
  creator: '',
  type: 'TRACK',
  sforceNote: false,
  allowLooping: false,
  suppressBadEmails: false,
  allowReentrantAddresses: false,
  useAlternateUniqueField: false,
  allowBypassFatigueRules: false,
  alternateUniqueFieldHeader: undefined,
  historyDisabled: false,
  historyDisabledStopped: '0',
  sourceList: [],
  sources: [],
  messages: [],
  conditionalMessages: [],
  exit: {
    stepId: 'exit',
    stepType: 'exit',
    exitChoices: [],
  },
  firstTrackId: 't0',
  tracks: [],
  runStatus: {
    valid: false,
    isRunning: false,
  },
  schedule: {
    nextTS: 0,
    nextTime: undefined,
    type: 'NEVER',
    hour: 1,
    minute: 0,
    ampm: 0,
    interval: 0,
    timestamp: undefined,
    waitUntilDate: '1969-12-31',
    waitUntilDateFormatted: '',
    triggerAllowed: false,
    isScheduled: false,
    isUnscheduled: true,
    isTriggered: false,
    weekdaysOnly: false,
    hasEndTime: false,
    endHour: undefined,
    endMinute: undefined,
    endAmpm: undefined,
  },
  timeZoneId: 'US/Pacific',
  timeZoneNames,
  status: 'draft',
  stepTemplates: [
    {
      stepType: 'wait',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      msecDelay: undefined,
      delay: 1,
      delayUnit: 'day',
    } as ProgramStep,
    {
      stepType: 'waitUntil',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      schedule: {
        nextTS: undefined,
        nextTime: undefined,
        type: 'DAILY',
        hour: 8,
        minute: 0,
        ampm: 0,
        interval: 0,
        timestamp: 1637256291174,
        waitUntilDate: undefined,
        waitUntilDateFormatted: undefined,
        triggerAllowed: undefined,
        isScheduled: undefined,
        isUnscheduled: undefined,
        isTriggered: undefined,
        weekdaysOnly: undefined,
        hasEndTime: undefined,
        endHour: undefined,
        endMinute: undefined,
        endAmpm: undefined,
      },
    } as ProgramStep,
    {
      stepType: 'waitUntilInSegment',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      srcId: undefined,
      not: false,
      schedule: {
        nextTS: undefined,
        nextTime: undefined,
        type: 'DAILY',
        hour: 8,
        minute: 0,
        ampm: 0,
        interval: 0,
        timestamp: undefined,
        waitUntilDate: undefined,
        waitUntilDateFormatted: undefined,
        triggerAllowed: undefined,
        isScheduled: undefined,
        isUnscheduled: undefined,
        isTriggered: undefined,
        weekdaysOnly: undefined,
        hasEndTime: undefined,
        endHour: undefined,
        endMinute: undefined,
        endAmpm: undefined,
      },
      waitForScheduledRun: false,
    } as ProgramStep,
    {
      stepType: 'smsSend',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      smsMessageId: undefined,
    } as ProgramStep,
    {
      stepType: 'send',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      defaultMsgId: undefined,
      sendMode: 'Conditional',
      abThreshold: 100,
      sendChoices: [],
      clicks: undefined,
      abWinner: undefined,
    } as ProgramStep,
    {
      stepType: 'copy',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      all: undefined,
      not: undefined,
      srcId: undefined,
      listId: 'none selected',
      update: true,
      updateType: undefined,
      pushToSf: undefined,
      overrideSystemMap: undefined,
      overrideSystemMapField: undefined,
    } as ProgramStep,
    {
      stepType: 'copyFrom',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      srcId: undefined,
      pruneRecords: undefined,
    } as ProgramStep,
    {
      stepType: 'webexSync',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      meetingId: undefined,
      sessionKey: undefined,
      serviceType: undefined,
    } as ProgramStep,
    {
      stepType: 'citrixSync',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      meetingId: undefined,
      serviceType: undefined,
      webinarKey: undefined,
    } as ProgramStep,
    {
      stepType: 'zoomSync',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
    } as ProgramStep,
    {
      stepType: 'fieldSet',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      listId: undefined,
      canPushSF: undefined,
      pushToSf: undefined,
      conditions: undefined,
      operations: [
        {
          rule: 'INCR',
          value: undefined,
          fieldName: undefined,
          campaignId: undefined,
          scoreSheetId: undefined,
        },
      ],
      rule: 'INCR',
      fieldName: undefined,
      value: 5,
    } as ProgramStep,
    {
      stepType: 'goto',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      goToStepId: undefined,
    } as ProgramStep,
    {
      stepType: 'branch',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      condition: 'IN',
      srcId: undefined,
      testSrcId: undefined,
      not: undefined,
      goToStepId: undefined,
    } as ProgramStep,
    {
      stepType: 'alert',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      message: 'Contacts have reached a certain step in an automated program.',
      subject: 'Act-On Software Automated Program Alert',
      userIds: [-1],
      emails: [],
      crmOwner: undefined,
      hideContactData: false,
    } as ProgramStep,
    {
      stepType: 'task',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      method: undefined,
      ownerId: undefined,
      ownerName: undefined,
      subject: undefined,
      comment: undefined,
      status: undefined,
      priority: undefined,
      dateUnit: undefined,
      dateAmount: undefined,
      disabled: false,
    } as ProgramStep,
    {
      stepType: 'crmCreate',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      disabled: false,
      pushNewRecords: true,
      pushType: 'Leads',
      updateExisting: false,
      useListPushPolicy: false,
      createType: 'Tasks',
      additionalEntities: [],
      staticFields: undefined,
      updateMultipleMatches: undefined,
      insertAlways: undefined,
    } as ProgramStep,
    {
      stepType: 'salesforceCampaign',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      method: undefined,
      dynamicCampaign: undefined,
      status: undefined,
      campaignName: undefined,
      campaignId: undefined,
      newCampaignStatus: undefined,
      disabled: false,
    } as ProgramStep,
    {
      stepType: 'externalApi',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
      method: 'POST',
      format: 'FORM',
      headers: [],
      custom: [],
      httpHeaders: [],
      url: undefined,
    } as ProgramStep,
    {
      stepType: 'optOut',
      stepId: undefined,
      displayName: 'Enter Descriptive Name',
      track: undefined,
      depth: undefined,
    } as ProgramStep,
    {
      stepType: 'exit',
      stepId: undefined,
      displayName: 'Exit the Program',
      track: undefined,
      depth: undefined,
    },
  ],
  isReportingTooLarge: false,
  crm: {
    crmName: 'Salesforce',
    crmListPrefix: 'l-sf-',
    crmImageSmall: '16sfdc.png',
    crmImageLarge: '32salesforce.png',
    taskStepEnabled: true,
    salesforceCampaignStepEnabled: true,
    campaignDisplayName: 'Campaign',
    createStepEnabled: false,
    crmType: 'SALESFORCE',
    canCustomizeCampaignStatus: true,
    canCreateCampaign: true,
    canSyncProgramList: true,
    isDynamicCampaign: false,
    crmModelEnabled: false,
    crmModelNotCurrent: true,
    crmCampaignsAreLimited: false,
    modelEntityLimit: 5000,
    defaultTypes: ['Sent', 'Responded'],
    crmEnabled: true,
  },
  users: [],
  localSegmentDetails: [],
  lastRowId: 1,
}
