import React, { FC, useContext, useEffect } from 'react'

import classNames from 'classnames'

import Svg, { SvgNames, SvgType } from '@components/Svg'
import TextWithTooltipOnEllip from '@components/TextWithTooltipOnEllip/TextWithTooltipOnEllip'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { ProgramSendStep } from '@graphql/types/query-types'
import { List } from '@interface/foldersLists/List'
import { ProgramManagerContext } from '@src/pages/programs/manager/context/ProgramManager.context'
import { NewSource } from '@src/pages/programs/upgradeManager/components/NewSource/NewSource'
import { getConditionText, getUpgradedStepSources } from '@src/pages/programs/upgradeManager/components/steps/Steps.utils'
import {
  UpgradedProgramSendStep,
  UpgradedSource,
  UpgradedStepSendChoices,
  UpgradeManagerContext,
} from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'

import './SendEmailMessageUpgradeStep.css'

const rootClass = 'send-email-message-upgrade-step'
const upgradeManagerClass = 'upgrade-manager'

export const SendEmailMessageUpgradeStep: FC = () => {
  const {
    values: { currentStep, upgradedCurrentStep, recommendedSegments, upgradedStepSources },
    update,
  } = useContext(UpgradeManagerContext)
  const {
    values: { program },
  } = useContext(ProgramManagerContext)
  const { t } = useTranslation()
  const { messages, sources } = program
  const upgradedSendStep = upgradedCurrentStep as UpgradedProgramSendStep
  const { sendChoices } = upgradedSendStep

  useEffect(() => {
    if (!upgradedSendStep?.removed && !!currentStep && sendChoices.some((choice) => !choice.choiceId)) {
      let upgradedSendChoices: UpgradedStepSendChoices[] = []
      if (!!sendChoices && sendChoices?.length > 0) {
        upgradedSendChoices = sendChoices?.map((choices, index) => {
          return {
            ...choices,
            choiceId: `choice-${index}`,
          }
        })
      }
      update({
        upgradedCurrentStep: { ...currentStep, sendChoices: upgradedSendChoices } as UpgradedProgramSendStep,
      })
    }
  }, [sendChoices])

  const onSubmitSource = (id: string, newSource: List, upgradeId: string) => {
    if (!!upgradedCurrentStep) {
      update({
        upgradedStepSources: getUpgradedStepSources(upgradedStepSources, upgradedCurrentStep, upgradeId, newSource),
        upgradedCurrentStep: {
          ...upgradedCurrentStep,
          upgraded: true,
          sendChoices: [...upgradedSendStep.sendChoices.map((choice) => (choice.srcId === id ? { ...choice, newSrcId: newSource.id } : choice))],
        } as UpgradedProgramSendStep,
      })
    } else if (!!currentStep) {
      const currentSendStep = currentStep as ProgramSendStep
      update({
        upgradedStepSources: getUpgradedStepSources(upgradedStepSources, currentStep, upgradeId, newSource),
        upgradedCurrentStep: {
          ...currentSendStep,
          upgraded: true,
          sendChoices: [...currentSendStep.sendChoices.map((choice) => (choice.srcId === id ? { ...choice, newSrcId: newSource.id } : choice))],
        } as UpgradedProgramSendStep,
      })
    }
  }

  return (
    <div className={classNames(rootClass, `${upgradeManagerClass}__step`)}>
      {upgradedSendStep?.sendChoices?.map(({ not, msgId, srcId, choiceId }, index) => {
        const source = sources.find((source) => source.id === srcId)
        if (!source) {
          return
        }
        const message = messages.find((message) => message.id === msgId)
        const recommended = Object.entries(recommendedSegments).find(([key, _]) => key === source?.id)
        const upgradedSource: UpgradedSource = {
          source: {
            id: source?.id ?? '',
          },
          recommended: !!recommended ? recommended[1] : undefined,
          removed: false,
          newSource:
            !!upgradedSendStep && upgradedSendStep.upgraded ? upgradedStepSources[upgradedCurrentStep?.stepId ?? ''][choiceId ?? ''] : undefined,
          upgradeId: choiceId ?? '',
        }
        return (
          <div className={`${rootClass}__condition`} key={index}>
            <Typography
              text={t(index === 0 ? getConditionText(undefined, not) : getConditionText(undefined, not).replace('If', 'Else if'))}
              lineHeight={LineHeight.MEDIUM}
            />
            <div className={`${rootClass}__current-field-container`}>
              <div className={`${rootClass}__value-container`}>
                <Typography text={t('Current list')} weight={TextWeight.MEDIUM} lineHeight={LineHeight.MEDIUM} />
                <TextWithTooltipOnEllip
                  typographyProps={{
                    className: `${rootClass}__current-field`,
                    text: source?.name,
                  }}
                />
              </div>
              <div className={`${rootClass}__container`}>
                <Typography className={rootClass} text={t('Send')} lineHeight={LineHeight.MEDIUM} />
                <Typography
                  className={`${rootClass}__source`}
                  text={message?.name}
                  type={TextType.BODY_TEXT_SMALL}
                  lineHeight={LineHeight.MEDIUM_SMALL}
                />
              </div>
            </div>
            <Svg className={`${rootClass}__arrow`} name={SvgNames.arrowRight} type={SvgType.LARGE_ICON} />
            <NewSource sourceToUpgrade={upgradedSource} onSubmitSource={onSubmitSource} />
          </div>
        )
      })}
    </div>
  )
}
