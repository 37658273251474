import { createContext, ReactNode, RefObject } from 'react'
import { GroupBase } from 'react-select'

import type {} from 'react-select/base'

import { SelectV2GroupedOption, SelectV2Option, SelectV2Props } from '@components/SelectV2/SelectV2.props'
import { SvgNames } from '@components/Svg'
import { ItemType } from '@utils/categorization'

declare module 'react-select/base' {
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    renderMenuAction?: () => ReactNode
    menuActionRef?: RefObject<HTMLDivElement>
    dropdownIndicatorRef?: RefObject<HTMLDivElement>
  }
}

export type Update = (fields: Partial<SelectV2ValuesAPI>) => void

export const SelectV2Values: SelectV2ValuesAPI = {
  currentOptions: [],
  currentSearchOptions: [],
  currentPage: 0,
  hideCheckMark: false,
  hideDropdownIndicator: false,
  inputChanged: false,
  inputValue: '',
  isPaginator: false,
  itemType: undefined,
  searchLoading: false,
  hasSearchOpen: false,
}

export interface InfoLabel {
  infoLabel: string
  optionValue: string
}

export interface SelectV2ValuesAPI {
  currentOptions: SelectV2Option[]
  currentSearchOptions: SelectV2Option[]
  currentPage: number
  enableCreateOptionWithoutTyping?: boolean
  hasSearchOnClick?: boolean
  hasSearchOpen: boolean
  searchBoxPlaceholder?: string
  hideCheckMark: boolean
  hideDropdownIndicator: boolean
  inputChanged: boolean
  inputValue: string
  isPaginator: boolean
  selectedOption?: SelectV2Option | SelectV2Option[]
  hiddenChipsCount?: number
  error?: boolean
  infoLabels?: InfoLabel[]
  containerTooltipMessage?: string
  inputGroup?: SelectV2GroupedOption
  itemType?: ItemType
  tabIndex?: number
  inputIcon?: SvgNames
  searchLoading?: boolean
  showIconOnSelect?: boolean
  isMulti?: boolean
  renderCustomIndicator?: () => ReactNode
  renderSelectContainerWrapper?: (children: ReactNode) => ReactNode
  renderSingleValue?: SelectV2Props['renderSingleValue']
  renderCustomOption?: SelectV2Props['renderCustomOption']
  nestedSearch?: boolean
  isCreatable?: boolean
  hideNumberBadge?: boolean
  showGroupsWithoutLabel?: boolean
  hideContainerTooltip?: boolean
  optionsWithLargePadding?: boolean
}

export interface SelectV2API {
  values: SelectV2ValuesAPI
  update: Update
}

export const SelectV2Context = createContext<SelectV2API>({ values: SelectV2Values } as any)
