import React, { FC, useState } from 'react'

import classNames from 'classnames'

import AutoResizeTextArea, { TextAreaProps } from '@components/AutoResizeTextArea/AutoResizeTextArea'
import { MAX_MESSAGE_LENGTH } from '@src/pages/sms/edit/EditSMSContainer'
import SegmentedMessage from '@src/pages/sms/edit/utils/segmentationCalculator'

import './HighlightableTextArea.css'

const rootClass = 'highlightable-text-area'

const HighlightableTextArea: FC<TextAreaProps> = (props: TextAreaProps) => {
  const {
    onBlur,
    value,
    onChangeHandler,
    label,
    hasError = false,
    name,
    role,
    maxLength = MAX_MESSAGE_LENGTH,
    dataTest = rootClass,
    required,
    className = '',
  } = props
  const [parentContainerHeight, setParentContainerHeight] = useState(0)

  const addNewLinesAndHighlight = (message: string) => {
    const newSegmentedMessage = new SegmentedMessage(message)
    return Array.from(message).map((str, i) => {
      if (newSegmentedMessage.hasIncompatibleEncoding(str)) {
        return (
          <span key={i} className={`${rootClass}__highlight`} data-test={`${dataTest}__highlight`}>
            {str}
          </span>
        )
      } else {
        return (
          <span key={i} className={`${rootClass}__no-highlight`}>
            {str}
          </span>
        )
      }
    })
  }

  return (
    <fieldset className={`${rootClass}`} style={{ minHeight: `${parentContainerHeight + 24}px` }}>
      <AutoResizeTextArea
        label={label}
        labelDisplay="flex"
        name={name}
        role={role}
        maxLength={maxLength}
        onBlur={onBlur}
        hasError={hasError}
        className={classNames(className, `${rootClass}__text-area`)}
        dataTest={dataTest}
        onChangeHandler={onChangeHandler}
        value={value ?? ''}
        setParentContainerHeight={setParentContainerHeight}
        required={required}
      />
      <output className={`${rootClass}__highlighted-output`} style={{ minHeight: parentContainerHeight }}>
        {addNewLinesAndHighlight(value ?? '')}
      </output>
    </fieldset>
  )
}

export default HighlightableTextArea
