import React, { FC } from 'react'

import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'
import { Box } from '@src/pages/programs/UpgradeCommonComponents/Box/Box'
import { UpgradedSource } from '@src/pages/programs/upgradeManager/context/UpgradeManager.context'
import { ReviewUpgradeSegmentReplacement } from '@src/pages/programs/UpgradePrograms/components/ReviewUpgrade/sections/components/ReviewUpgradeSegmentReplacement/ReviewUpgradeSegmentReplacement'

import './ReviewSourceReplacement.css'

interface ReviewSourceReplacementProps {
  source: UpgradedSource
}

const rootClass = 'review-source-replacement'

export const ReviewSourceReplacement: FC<ReviewSourceReplacementProps> = ({ source }: ReviewSourceReplacementProps) => {
  const { t } = useTranslation()

  return (
    <Box className={rootClass}>
      <Typography text={t('Source list')} type={TextType.BODY_TEXT} lineHeight={LineHeight.MEDIUM} weight={TextWeight.MEDIUM} />
      <div />
      <Typography text={t('Change to')} type={TextType.BODY_TEXT} lineHeight={LineHeight.MEDIUM} weight={TextWeight.MEDIUM} />
      <ReviewUpgradeSegmentReplacement source={source} />
    </Box>
  )
}
