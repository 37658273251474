import React, { FC } from 'react'

import classNames from 'classnames'

import Caution from '@components/Caution/Caution'
import FormRow from '@components/FormRow'
import Modal, { ModalBody } from '@components/Modal'
import { ModalFooterType } from '@components/Modal/components/ModalFooter'
import ModalFooterV2 from '@components/Modal/components/ModalFooterV2/ModalFooterV2'
import { ModalHeaderType } from '@components/Modal/components/ModalHeader'
import ModalHeaderV2 from '@components/Modal/components/ModalHeaderV2/ModalHeaderV2'
import Svg, { SvgType } from '@components/Svg'
import SvgNames from '@components/Svg/SvgNames'
import Typography, { LineHeight, TextType, TextWeight } from '@components/Typography/Typography'
import { useTranslation } from '@const/globals'

import './FormsUpgradeWarningModal.css'

interface FormsUpgradeWarningModalProps {
  className?: string
  dataTest?: string
  onClose: () => void
  isOpen: boolean
  formNames: string[]
}

const rootClass = 'forms-upgrade-warning-modal'

const FormsUpgradeWarningModal: FC<FormsUpgradeWarningModalProps> = (props: FormsUpgradeWarningModalProps) => {
  const { dataTest = rootClass, className = '', onClose, isOpen, formNames } = props

  const { t } = useTranslation()

  const header = (
    <ModalHeaderV2 headerText={t('UpgradeWarningModal.forms.Header')} headerType={ModalHeaderType.Form} className={`${rootClass}__header`} />
  )

  const renderWarningSection = () => (
    <div className={`${rootClass}__section`}>
      <Typography text={t('FORMS')} type={TextType.SUB_HEADER} lineHeight={LineHeight.LARGER} />
      {formNames.map((formName, index) => (
        <FormRow key={`form_${index}`} className={`${rootClass}__section-row`}>
          <div className={`${rootClass}__icon-container`}>
            <Svg className={classNames(`${rootClass}__icon`)} name={SvgNames.fail} type={SvgType.ICON} />
          </div>
          <Typography text={formName} type={TextType.NORMAL_TEXT_TEAL_LARGER} lineHeight={LineHeight.EXTRA_LARGE} weight={TextWeight.MEDIUM} />
        </FormRow>
      ))}
    </div>
  )

  return (
    <Modal className={classNames(rootClass, className)} data-test={dataTest} isOpen={isOpen} header={header}>
      <ModalBody>
        <div className={`${rootClass}__body-content`}>
          <Caution className={`${rootClass}-caution`} message={t('UpgradeWarningModal.forms.Caution.message')} />
        </div>
        {renderWarningSection()}
      </ModalBody>
      <ModalFooterV2
        footerType={ModalFooterType.Form}
        className={`${rootClass}__footer`}
        buttons={{ actionButtonLabel: t('Continue'), actionButtonOnClick: onClose }}
      />
    </Modal>
  )
}

export default FormsUpgradeWarningModal
