import { gql } from '@apollo/client'

export default gql`
  query getWebhooksEndpointsWithAPStepEvent {
    getWebhooksEndpointsWithAPStepEvent {
      applicationId
      endpointId
      url
      description
      createdTime
      updatedTime
      channels
    }
  }
`
