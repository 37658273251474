import { NAMESPACE } from './account.utils'

export default {
  loadAccountSettings: `${NAMESPACE}/LOAD_ACCOUNT_SETTINGS`,
  loadAccountSettingsReceived: `${NAMESPACE}/LOAD_ACCOUNT_SETTINGS_RECEIVED`,
  loadAccountSettingsFailed: `${NAMESPACE}/LOAD_ACCOUNT_SETTINGS_FAILED`,
  setLanguage: `${NAMESPACE}/SET_LANGUAGE`,
  setTheme: `${NAMESPACE}/SET_THEME`,
  setUserSettings: `${NAMESPACE}/SET_USER_SETTINGS`,
  setPendingIFrameRequest: `${NAMESPACE}/SET_PENDING_IFRAME_REQUEST`,
  checkUserAuthentication: `${NAMESPACE}/CHECK_USER_AUTHENTICATION`,
  updateUserAuthentication: `${NAMESPACE}/UPDATE_USER_AUTHENTICATION`,
}
